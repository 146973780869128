
<template>
<div class="page-wrapper">
			<div class="page-content">
				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Usuario</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-loader-circle"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Mis Aplicaciones</li>
							</ol>
						</nav>
					</div>
					<div class="ms-auto">
						<div class="btn-group">
							<button type="button" class="btn btn-light">Settings</button>
							<button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span class="visually-hidden">Toggle Dropdown</span>
							</button>
							<div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	<a class="dropdown-item" href="javascript:;">Action</a>
								<a class="dropdown-item" href="javascript:;">Another action</a>
								<a class="dropdown-item" href="javascript:;">Something else here</a>
								<div class="dropdown-divider"></div>	<a class="dropdown-item" href="javascript:;">Separated link</a>
							</div>
						</div>
					</div>
				</div>
				<!--end breadcrumb-->
				<div class="card">
					<div class="card-body">
						<div class="d-lg-flex align-items-center mb-4 gap-3">
							<div class="position-relative">
							</div>
                            <!-- Button trigger modal -->
                            <!-- Modal -->
                            <div class="ms-auto"><add_event ref="child_add"></add_event></div>
                        </div>
						<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th>Aplicacion</th>
										<th>Precio</th>
										<th>Estado</th>
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in clienteAplicacionesData" :key="item.id">
										<td >
											<div class="d-flex align-items-center">
												<div>
													<input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
												</div>
												<div class="ms-2">
													<h6 class="mb-0 font-14">{{item.aplicaciones.nombre}}</h6>
												</div>
											</div>
										</td>
										<td>{{item.aplicaciones.precio}}</td>
										<td>{{item.aplicaciones.precio}}</td>
										<td>
											<div class="d-flex order-actions">
												<button v-if="!item.clienteapp" type="button" class="btn btn-light btn-sm px-4" style="margin-right: 15px;">Crear Aplicación</button>
												<button v-if="item.clienteapp" type="button" class="btn btn-light btn-sm px-4" style="margin-right: 15px;">Ir a la Aplicación</button>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modificarModal"  class=""><i class='bx bxs-edit'></i></a>
												<a href="javascript:;" class="ms-3"><i class='bx bxs-trash'></i></a>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>


			</div>
		</div>

<!-- Modal -->
<div class="modal fade" id="crearAplicacion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content bg-dark">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Crear Aplicación</h5>
				<button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="row mb-3">
					<div class="col-sm-3">
						<h6 class="mb-0">Nombre de tu Aplicación</h6>
					</div>
					<div class="col-sm-9">
						<input type="text"  class="form-control" v-model="tenant.nombre"  />
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-sm-3">
						<h6 class="mb-0">Dominio</h6>
					</div>
					<div class="col-sm-9">
						<input type="text" placeholder="Ejemplo: tuempresa"  class="form-control" v-model="vehiculo.modelo"  />
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-sm-3">
						<h6 class="mb-0">Año</h6>
					</div>
					<div class="col-sm-9">
						<input type="number" max="9999" maxlength="4" class="form-control" v-model="vehiculo.año"  />
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-sm-3">
						<h6 class="mb-0">Capacidad Pasajeros</h6>
					</div>
					<div class="col-sm-9">
						<input type="number"  class="form-control" v-model="vehiculo.capacidad_pasajeros"  />
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-sm-3">
						<h6 class="mb-0">Filas</h6>
					</div>
					<div class="col-sm-3">
						<input type="number"  class="form-control" v-model="vehiculo.filas"  />
					</div>
					<div class="col-sm-3">
						<h6 class="mb-0">Asientos por Filas</h6>
					</div>
					<div class="col-sm-3">
						<input type="number"  class="form-control" v-model="vehiculo.asientos_filas"  />
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-sm-3">
						<h6 class="mb-0">Descripcion</h6>
					</div>
					<div class="col-sm-9">
						<textarea  class="form-control"  v-model="vehiculo.descripcion" placeholder="Agrega una Descripción..." rows="5"></textarea>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
				<button type="button" @click="agregarVehiculo()" class="btn btn-primary">Guardar</button>
			</div>
		</div>
	</div>
</div>
        <!-- End Modal -->

</template>
<style>
</style>
<script>
import  Cookies from 'js-cookie';
//import Select2 from 'vue3-select2-component';
//import $ from 'jquery';
//let select2 = require('@/assets/plugins/select2/js/select2.min.js');
//import * as mykey from '../assets/js/mykey.js'
//import mod_event from "./usuarios/modificar_usuario.vue"

//import $ from 'jquery';
//let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')
//import VueNextSelect from 'vue-next-select'
console.log(Cookies.get());
export default {
    name: 'mis_aplicaciones_component',
    components:{
        //'vue-select': VueNextSelect,
    },
data() {
	return{
        usuarioData: [],
        clienteAplicacionesData: [],
		tenant: {
			nombre: '',
			schema_name: '',
			pagado_hasta: '',
			en_prueba: false,
			usuario: '',
		},
		domain: {
			domain: '',
		},
        URL_TENANT: '',
		}
	},
    watch: {
},

computed: {
},



methods: {
	async getusuariosData() {
        const response = await fetch(this.URL_TENANT+'/api/user/');
        this.usuarioData = await response.json();
		this.getClienteAplicaciones();

    },
    async getClienteAplicaciones() {
        const response = await fetch(this.URL_TENANT+'/api/cliente_aplicaciones/?usuario='+this.usuarioData[0].id);
        this.clienteAplicacionesData = await response.json();
    },
    },
mounted() {
        this.$watch(
            "$refs.child_add.usuario_añadido",
            () => (this.getusuariosData()),
        );
    },
created() {
      // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
	this.getusuariosData();
	},
}

</script>
