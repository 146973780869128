
<template>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Nombre</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text"  class="form-control" v-model="consultorio.nombre"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Direccion</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="consultorio.direccion"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Teléfono</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="consultorio.telefono"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Celular</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="consultorio.telefono2"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Correo</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="email" class="form-control" v-model="consultorio.correo" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Sitio Web</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="consultorio.sitio_web" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Descripción</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="consultorio.descripcion" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Logo</h6>
                        </div>
                        <div class="col-sm-2" v-if="consultorio.logo">
                            <div class="input-group mb-3">
                                <img :src="consultorio.logo" class="user-img" alt="Logo Consultorio">
                            </div>
                        </div>
                        <div class="col-sm-7">
                            <div class="input-group mb-3">
                                <label class="input-group-text" for="inputGroupFile01">Subir</label>
                                <input type="file" class="form-control" id="imagenU">
                            </div>
                        </div>
                    </div>
</template>
<style>
</style>
<script>

let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
import $ from 'jquery';
import  Cookies from 'js-cookie';
export default {
    props: ['id_consultorio',],
    name: 'modificar_consultorio_component',
    components:{
    },
data() {
    return{
        modalShow: true,
        consultorio_modificado: false,
        URL_TENANT: '',
        consultorio:  {
            nombre: "",
            direccion: "",
            telefono: "",
            correo: "",
            logo: "",
            sitio_web: "",
            descripcion: "",
            telefono2: ""
            },
            consultorio2:  {
            },
        }
    },
    watch: {
        id_consultorio:{
            handler: function(){
                this.getConsultorio();
            }
        },
},
    computed: {
        /* guatemalaDepartamentoFilter() {
        console.log("LENGT");
        if (this.consultorio.departamento.length >= 1){
            console.log("DENTRO DE LA KAKA");
            return this.guatemala.filter((item) => {
                return item.departamento.includes(this.consultorio.departamento)
            });
            //return this.guatemala.map(function(obj){return obj.municipio})
        }else{
            return '';
        }
    },
    municipiosFilter(){
        if(this.consultorio.departamento.length >0){
            return this.guatemalaDepartamentoFilter.map(function(obj){return obj.municipio}).flat();

        }else{
            return [];
        }
    },*/
    },

    methods: {
        async  limpiarInputs(){
            this.consultorio_modificado= false;
            this.consultorio =  {
                nombre: "",
                direccion: "",
                telefono: "",
                correo: "",
                logo: "",
                sitio_web: "",
                descripcion: "",
                telefono2: ""
            }
        },
        async getConsultorio(){
            console.log("CONSULTORIOOOOO");
            this.limpiarInputs();
            await fetch(this.URL_TENANT+'/api/consultorio/'+this.id_consultorio+'/')
            .then(response => response.json())
            .then(json => this.consultorio = json);
            /*this.consultorio =  {
                nombre: this.consultorio2.nombre,
                direccion: this.consultorio2.direccion,
                telefono: this.consultorio2.telefono,
                correo: this.consultorio2.correo,
                logo: this.consultorio2.logo,
                sitio_web: this.consultorio2.sitio_web,
                descripcion: this.consultorio2.descripcion,
                telefono2: this.consultorio2.telefono2
            }*/
        },
        //Agregar Consultorio:
        async modificarConsultorio(){
            if(!this.consultorio.nombre){
                notificaciones.round_error_noti($.msg='El Nombre del Consultorio es Obligatorio');
            }else{
                try{
                    console.log("GUARDANDO CONSULTORIO");
                    const logo = document.getElementById("imagenU");
                    const formData = new FormData();
                    formData.append("nombre", this.consultorio.nombre);
                    formData.append("direccion", this.consultorio.direccion);
                    formData.append("telefono", this.consultorio.telefono);
                    formData.append("telefono2", this.consultorio.telefono2);
                    formData.append("correo", this.consultorio.correo);
                    formData.append("sitio_web", this.consultorio.sitio_web);
                    formData.append("descripcion", this.consultorio.descripcion);
                    console.log("LOGO");
                    console.log(logo);
                    console.log( logo.files[0]);
                    if(logo.files.length == 0){
                        formData.append("logo", '')
                    }else{
                        formData.append("logo", logo.files[0]);
                    }
                    await fetch(this.URL_TENANT+'/api/consultorio/'+this.id_consultorio+'/',{
                        method: 'PUT',
                        headers: {
                            'X-CSRFToken': Cookies.get('csrftoken'),
                            },
                        body: formData})
                    notificaciones.round_success_noti($.msg='Consultorio Modificado Exitosamente');
                    this.consultorio_modificado = true;
                }catch(error){
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
            }
        },
    },
    mounted() {

        },
    created() {
        const result = window.location.origin;
        this.URL_TENANT = result;

    },
}
</script>