<template>
      <Header/>
      <Nav/>
      <DetalleExpediente/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import DetalleExpediente from '../../components/consultorio_virtual/expediente/detalle_expediente.vue'



export default {
  name: 'DetalleExpedienteComponent',
  components: {
    Header,
	Nav,
	DetalleExpediente,
  },

  data: () => ({
    //
  }),
}
</script>
