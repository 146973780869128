<template>
<div class="page-wrapper">
			<div class="page-content">
				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Compra</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Requisición</li>
							</ol>
						</nav>
					</div>
				</div>
				<!--end breadcrumb-->
            <div v-if="option==1" class="card">
				<div class="card">
					<div class="card-body">
						<div class="d-lg-flex align-items-center mb-4 gap-3">
							<div class="position-relative">
								<input type="text" class="form-control ps-5 radius-30" v-model="buscarRequisicion" placeholder="Buscar Requisicion"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
							</div>
						<div class="ms-auto"><a href="javascript:;" data-bs-toggle="modal" data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Crear una Requisición</a></div>
						</div>
                        <!-- Button trigger modal -->
						<!-- Modal -->
						<div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-lg">
								<div class="modal-content bg-dark">
									<div class="modal-header">
										<h5 class="modal-title" id="exampleModalLabel">Crear Requisición</h5>
										<button type="button" id="agregar_close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Proveedor</h6>
											</div>
											<div class="col-sm-9">
                                                <vue-select  :options="proveedoresData"
                                                    v-model="requisicion_compra.proveedor"
                                                    label-by="nombre"
													track-by="nombre"
													value-by="id"
													search-placeholder="Selecciona un Proveedor"
													multiple
													collapse-tags="true"
													taggable
													searchable
													placeholder="Selecciona un Proveedor"
													close-on-select>
                                                    <template #label="{ selected }">
                                                    <template v-if="selected">
                                                        <div style="color:white;">{{ selected.nombre }}</div>
                                                    </template>
                                                    <template v-else > <div style="color:white;">Seleccione un Proveedor</div></template>
                                                    </template>
                                                    <template #dropdown-item="{ option }">
                                                    <div style="color:white;">{{ option.nombre }}</div>
                                                    </template>
                                                </vue-select>
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Fecha</h6>
											</div>
											<div class="col-sm-9">
                                                <Datepicker
                                                v-model="requisicion_compra.fecha"
                                                dark placeholder="Selecciona una Fecha"
                                                autoApply
                                                :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                :enableTimePicker="false"
                                                monthNameFormat="long"
                                                :format="format"
                                                modelType="dd-MM-yyyy"
                                                locale="es">
                                                </Datepicker>
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Comentarios</h6>
											</div>
											<div class="col-sm-9">
                                            <textarea class="form-control" v-model="requisicion_compra.comentario" placeholder="Escribe un Comentario" rows="3"></textarea>
											</div>
										</div>
                                    </div>
									<div class="modal-footer">
										<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
										<button type="button" @click="crearRequisicionCompra" class="btn btn-primary">Crear</button>
									</div>
								</div>
							</div>
						</div>
						<!-- End Modal -->
						<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th># Referencia</th>
										<th>Fecha</th>
										<th>Proveedor</th>
										<th>Estado</th>
										<th>Ver Detalle</th>
										<th>Comentario</th>
										<th>Usuario</th>
										<th>Acción</th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in requisicionesDataFilter" :key="item.id">
										<td >
											<div class="d-flex align-items-center">
												<div>
													<input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
												</div>
												<div class="ms-2">
													<h6 class="mb-0 font-14">#{{item.referencia}}</h6>
												</div>
											</div>
										</td>
										<td>{{item.fecha}}</td>
										<td>{{item.proveedor_nombre}}</td>
										<td>
                                            <div class="badge rounded-pill bg-light p-2 text-uppercase px-3">
                                                <div v-if="[[item.estado]] == '9'"> <i  class='bx bx-minus me-1'></i>Anulado</div>
                                                <div v-if="[[item.estado]] == 'A'"> <i  class='bx bx-circle me-1'></i>Abierto</div>
                                                <div v-if="[[item.estado]] == 'C'"> <i  class='bx bx-circle me-1'></i>Cerrado</div>
                                            </div>
                                        </td>
										<td>
                                            <div v-if="item.detalle_requisicion_compra.length >0">
                                                <button type="button" data-bs-toggle="modal" :data-bs-target="'#detalleRequisición'+item.id" class="btn btn-light position-relative me-lg-5"> <i class='bx bx-comment-detail align-middle'></i> Ver Productos <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{item.detalle_requisicion_compra.length}}</span>
												</button>
                                                <!-- Modal -->
												<div class="modal fade" :id="'detalleRequisición'+item.id" tabindex="-1" aria-hidden="true">
													<div class="modal-dialog modal-lg">
														<div class="modal-content">
															<div class="modal-header">
																<h5 class="modal-title">Detalle de Requisición Compra</h5>
																<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
															</div>
															<div class="modal-body">
																<div class="card">
																	<div class="card-body">
																		<table class="table mb-0">
                                                                            <thead class="table-light">
                                                                                <tr>
                                                                                    <th>Cantidad</th>
                                                                                    <th>Unidad Medida</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr v-for="item2 in item.detalle_requisicion_compra" :key="item2.id">
                                                                                    <td>{{item2.cantidad}}</td>
                                                                                    <td>{{item2.unidad_medida_nombre}} / {{item2.unidad_medida_nombre_unidad}}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
																	</div>
																</div>
															</div>
															<div class="modal-footer">
																<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
															</div>
														</div>
													</div>
												</div>
                                                <!-- FIN Modal -->
                                            </div>
                                            <div v-else>
                                                <button v-if="[[item.estado]] == 'A'" type="button" @click="fillModificarRequisicion([[item.id]])" class="btn btn-light position-relative me-lg-5"> <i class='bx bx-comment-detail align-middle'></i> Agregar Productos
												</button>
                                            </div>
                                        </td>
										<td>{{item.comentario}}</td>
										<td>{{item.nombre_usuario}}</td>
										<td>
											<div class="d-flex order-actions">
												<a v-if="[[item.estado]] == 'A' || [[item.estado]] == 'C'" href="javascript:;"   @click="fillModificarRequisicion([[item.id]])" class=""><i class='bx bxs-edit'></i></a>
												<a v-if="[[item.estado]] == 'A' || [[item.estado]] == 'C'" href="javascript:;" class="ms-3"><i class='bx bxs-trash'></i></a>
                                                <div class="ms-3">
                                                    <button  v-if="item.cotizacion_compra" type="button" class="btn btn-light"><i class="bx bx-fast-forward"></i>Ver Cotización</button>
                                                    <button  @click="fillCrearCotizacion(item.referencia, item.id, item.fecha, item.proveedor)" v-if="!item.cotizacion_compra && [[item.estado]] == 'C' " type="button" data-bs-toggle="modal" data-bs-target="#convertirCotizacionModal" class="btn btn-light"><i class="bx bx-send mr-1"></i>Convertir en Cotización</button>
                                                </div>
                                                                        <!-- Modal Eliminar -->
                                                                        <div class="modal fade"  id="convertirCotizacionModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                            <div class="modal-dialog">
                                                                                <div class="modal-content bg-dark">
                                                                                    <div class="modal-header">
                                                                                        <h5 class="modal-title" id="exampleModalLabel">Convertir a Cotización</h5>
                                                                                        <button type="button" id="convertir_cotizacion_close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>
                                                                                    <div class="modal-body">
                                                                                            ¿Desea convertir la Requisición {{crearCotizacion.referencia}} en una Cotización?
                                                                                    </div>
                                                                                    <div class="modal-footer">
                                                                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                        <button type="button" @click="convertirCotizacion()" class="btn btn-primary">Crear Cotización</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- End Modal Eliminar -->
											</div>
                                            <!-- Modal Modify
                                            <div class="modal fade"  :id="'modificarRequisicion'+item.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div class="modal-dialog modal-lg">
                                                    <div class="modal-content bg-dark">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="exampleModalLabel">Modificar Requisición</h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div class="row mb-3">
                                                            <div class="col-sm-3">
                                                                <h6 class="mb-0">Proveedor</h6>
                                                            </div>
                                                            <div class="col-sm-9">
                                                                <vue-select  :options="proveedoresData"
                                                                    v-model="requisicionModificar.proveedor"
                                                                    label-by="nombre"
                                                                    track-by="nombre"
                                                                    value-by="id"
                                                                    search-placeholder="Selecciona un Proveedor"
                                                                    multiple
                                                                    collapse-tags="true"
                                                                    taggable
                                                                    searchable
                                                                    placeholder="Selecciona un Proveedor"
                                                                    close-on-select>
                                                                    <template #label="{ selected }">
                                                                    <template v-if="selected">
                                                                        <div style="color:white;">{{ selected.nombre }}</div>
                                                                    </template>
                                                                    <template v-else > <div style="color:white;">Seleccione un Proveedor</div></template>
                                                                    </template>
                                                                    <template #dropdown-item="{ option }">
                                                                    <div style="color:white;">{{ option.nombre }}</div>
                                                                    </template>
                                                                </vue-select>
                                                            </div>
                                                        </div>
                                                            <div class="row mb-3">
                                                                <div class="col-sm-3">
                                                                    <h6 class="mb-0">Fecha</h6>
                                                                </div>
                                                                <div class="col-sm-9">
                                                                    <Datepicker
                                                                    v-model="requisicionModificar.fecha"
                                                                    dark placeholder="Selecciona una Fecha"
                                                                    autoApply
                                                                    :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                    :enableTimePicker="false"
                                                                    monthNameFormat="long"
                                                                    :format="format"
                                                                    modelType="yyyy-MM-dd"
                                                                    locale="es">
                                                                    </Datepicker>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-sm-3">
                                                                    <h6 class="mb-0">Comentarios</h6>
                                                                </div>
                                                                <div class="col-sm-9">
                                                                <textarea class="form-control" v-model="requisicionModificar.comentario" placeholder="Escribe un Comentario" rows="3"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div class="row mb-3">
                                                            </div>
                                                        </div>

                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                            <button type="button" @click="modificarRequisicion()" class="btn btn-primary">Guardar</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>-->
                                            <!-- End Modal Modify -->
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
                    <!-- Paginacion -->
					<div class="card-body">
                        <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a class="page-link" href="javascript:;" @click="cambiarUrl(requisicionesData.links.previous, 'atras')">Atrás</a>
                                    </li>
                                    <li class="page-item" :class="{active: indexPagination == index}" v-for="index in requisicionesData.total_pages" :key="index">
                                        <a class="page-link"  href="javascript:;javascript:;" @click="cambiarUrl('http://127.0.0.1:8000/api/requisicion_compra/?page='+index+'', index)">{{ index }}</a>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="javascript:;" @click="cambiarUrl(requisicionesData.links.next, 'adelante')">Siguiente</a>
                                    </li>
                                </ul>
                        </nav>
                    </div>
                    <!-- Fin Paginacion -->
				</div>
			</div>

                <!-- Modificar Card -->
                <div v-if="option==2" class="card">
				<!--INICIO MODIFICAR-->
				<div class="card">
				<div class="card-body">
					<div class="row">
					<ul class="nav nav-tabs" role="tablist">
						<li class="nav-item" role="presentation">
							<a class="nav-link active" data-bs-toggle="tab" href="#requisicionCompra" role="tab" aria-selected="true">
								<div class="d-flex align-items-center">
									<div class="tab-icon"><i class='bx bx-spreadsheet font-18 me-1'></i>
									</div>
									<div class="tab-title">Editar Requisición de Compra</div>
								</div>
							</a>
						</li>
						<div class="ms-auto">
							<button type="button" @click="cambiarEstado(idOption=1)" class="btn btn-light" style="margin-right:10px;">Regresar</button>
					</div>
					</ul>
					</div>
					<div class="tab-content py-3">
						<div class="tab-pane fade show active" id="requisicionCompra" role="tabpanel">
							<div class="card">
								<div class="card-body p-4">
                                    <div class="row">
                                        <div class="col-lg-10">
                                            <h5 class="card-title">Requisición de Compra</h5>
                                        </div>
                                        <div class="col-lg-2">
                                            <button type="button"   @click="modificarRequisicion()" class="btn btn-light">Modificar Requisición Compra</button>
                                        </div>
                                    </div>

									<hr/>
									<div class="form-body mt-4">
									<div class="row">
										<div class="col-lg-12">
										<div class="border border-3 p-4 rounded">
											<div class="modal-body">
                                                <div class="row mb-3">
                                                    <div class="col-sm-1">
                                                        <h6 class="mb-0">#Referencia</h6>
                                                    </div>
                                                    <div class="col-sm-5">
                                                        <h4>{{ requisicionModificar.referencia }}</h4>
                                                    </div>
                                                        <div class="col-sm-1">
                                                            <h6 class="mb-0">Estado</h6>
                                                        </div>
                                                        <div class="col-sm-5">
                                                            <vue-select   :options="estados"
                                                                v-model="requisicionModificar.estado"
                                                                label-by="nombre"
                                                                hide-selected="true"
                                                                track-by="nombre"
                                                                value-by="id"
                                                                placeholder="Selecciona un Estado"
                                                                close-on-select>
                                                                <template #label="{ selected }">
                                                                <template v-if="selected">
                                                                    <div style="color:white;">{{ selected.nombre }}</div>
                                                                </template>
                                                                <template v-else > <div style="color:white;">Seleccione un Estado</div></template>
                                                                </template>
                                                                <template #dropdown-item="{ option }" >
                                                                <div  style="color:white;">{{ option.nombre }}</div>
                                                                </template>
                                                            </vue-select>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-sm-1">
                                                            <h6 class="mb-0">Proveedor</h6>
                                                        </div>
                                                        <div class="col-sm-5">
                                                            <vue-select  :options="proveedoresData"
                                                                v-model="requisicionModificar.proveedor"
                                                                label-by="nombre"
                                                                track-by="nombre"
                                                                value-by="id"
                                                                search-placeholder="Selecciona un Proveedor"
                                                                multiple
                                                                collapse-tags="true"
                                                                taggable
                                                                searchable
                                                                placeholder="Selecciona un Proveedor"
                                                                close-on-select>
                                                                <template #label="{ selected }">
                                                                <template v-if="selected">
                                                                    <div style="color:white;">{{ selected.nombre }}</div>
                                                                </template>
                                                                <template v-else > <div style="color:white;">Seleccione un Proveedor</div></template>
                                                                </template>
                                                                <template #dropdown-item="{ option }">
                                                                <div style="color:white;">{{ option.nombre }}</div>
                                                                </template>
                                                            </vue-select>
                                                        </div>
                                                            <div class="col-sm-1">
                                                                <h6 class="mb-0">Fecha</h6>
                                                            </div>
                                                            <div class="col-sm-5">
                                                                <Datepicker
                                                                v-model="requisicionModificar.fecha"
                                                                dark placeholder="Selecciona una Fecha"
                                                                autoApply
                                                                :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                :enableTimePicker="false"
                                                                monthNameFormat="long"
                                                                :format="format"
                                                                modelType="dd-MM-yyyy"
                                                                locale="es">
                                                                </Datepicker>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-sm-1">
                                                                <h6 class="mb-0">Comentarios</h6>
                                                            </div>
                                                            <div class="col-sm-11">
                                                            <textarea class="form-control" v-model="requisicionModificar.comentario" placeholder="Escribe un Comentario" rows="3"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
											</div>
                                            <div class="card-body p-4">
                                                <div class="row">
                                                    <div class="col-lg-10">
                                                        <h5 class="card-title">Detalle de Requisición de Compra</h5>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <button type="button" data-bs-toggle="modal" v-if="[[requisicionModificar.estado]] == 'A'" @click="getProductosUnidadMedida()" data-bs-target="#agregarDetalleModal" class="btn btn-light">Añadir Producto a Requisición</button>
                                                        <!-- Modal -->
                                                        <div class="modal fade"  id="agregarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog modal-lg">
                                                                <div class="modal-content bg-dark">
                                                                    <div class="modal-header">
                                                                        <h5 class="modal-title" id="exampleModalLabel">Añadir Producto a Requisición</h5>
                                                                        <button type="button" id="agregar_detalle_close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <div class="row mb-3">
                                                            <div class="col-sm-4">
                                                                <h6 class="mb-0">Producto / Unidad Medida</h6>
                                                            </div>
                                                            <div class="col-sm-8">
                                                                <vue-select  :options="productosUnidadMedidaData"
                                                                    v-model="detalleRequisicion.producto_um"
                                                                    label-by="nombre_producto"
                                                                    track-by="nombre_producto"
                                                                    value-by="id"
                                                                    search-placeholder="Selecciona un Producto"
                                                                    multiple
                                                                    collapse-tags="true"
                                                                    taggable
                                                                    searchable
                                                                    placeholder="Selecciona un Producto"
                                                                    close-on-select>
                                                                    <template #tag="{ option, remove }">
                                                                    <div>
                                                                    {{ option.nombre_producto }} - {{ option.nombre_unidad_medida }}/{{option.cantidad_unidad_medida}}
                                                                    <img style="display: inline-flex;" @click.stop="remove" src="data:image/svg+xml;base64,PHN2ZyBpZD0iZGVsZXRlIiBkYXRhLW5hbWU9ImRlbGV0ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHRpdGxlPmRlbGV0ZTwvdGl0bGU+PHBhdGggZD0iTTI1NiwyNEMzODMuOSwyNCw0ODgsMTI4LjEsNDg4LDI1NlMzODMuOSw0ODgsMjU2LDQ4OCwyNC4wNiwzODMuOSwyNC4wNiwyNTYsMTI4LjEsMjQsMjU2LDI0Wk0wLDI1NkMwLDM5Ny4xNiwxMTQuODQsNTEyLDI1Niw1MTJTNTEyLDM5Ny4xNiw1MTIsMjU2LDM5Ny4xNiwwLDI1NiwwLDAsMTE0Ljg0LDAsMjU2WiIgZmlsbD0iIzViNWI1ZiIvPjxwb2x5Z29uIHBvaW50cz0iMzgyIDE3Mi43MiAzMzkuMjkgMTMwLjAxIDI1NiAyMTMuMjkgMTcyLjcyIDEzMC4wMSAxMzAuMDEgMTcyLjcyIDIxMy4yOSAyNTYgMTMwLjAxIDMzOS4yOCAxNzIuNzIgMzgyIDI1NiAyOTguNzEgMzM5LjI5IDM4MS45OSAzODIgMzM5LjI4IDI5OC43MSAyNTYgMzgyIDE3Mi43MiIgZmlsbD0iIzViNWI1ZiIvPjwvc3ZnPg==" alt="delete tag" class="icon delete">
                                                                    </div>
                                                                    </template>
                                                                    <template #label="{ selected }">
                                                                    <template v-if="selected">
                                                                        <div style="color:white;">{{ selected.nombre_producto }}</div>
                                                                    </template>
                                                                    <template v-else > <div style="color:white;">Seleccione un Producto</div></template>
                                                                    </template>
                                                                    <template #dropdown-item="{ option }">
                                                                    <div style="color:white;">{{ option.nombre_producto }} - {{ option.nombre_unidad_medida}}/{{option.cantidad_unidad_medida}} </div>
                                                                    </template>
                                                                </vue-select>
                                                            </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-sm-4">
                                                                    <h6 class="mb-0">Cantidad a Solicitar</h6>
                                                                </div>
                                                                <div class="col-sm-8">
                                                                <input class="form-control" type="number" v-model="detalleRequisicion.cantidad" placeholder="Cantidad de Unidades" rows="3">
                                                                </div>
                                                            </div>
                                                                    </div>
                                                                    <div class="modal-footer">
                                                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                        <button type="button" @click="crearDetalleRequisicion" class="btn btn-primary">Crear</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- End Modal -->
                                                    </div>
                                                </div>
									<hr/>
									<div class="form-body mt-4">
									<div class="row">
										<div class="col-lg-12">
                                            <div class="border border-3 p-4 rounded">
                                                <div class="modal-body">
                                                    <table class="table mb-0">
                                                        <thead class="table-light">
                                                            <tr>
                                                                <th>Cantidad</th>
                                                                <th>Unidad Medida</th>
                                                                <th>Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item2 in detalleRequisicionModificar" :key="item2.id">
                                                                <td>{{item2.cantidad}}</td>
                                                                <td>{{item2.unidad_medida_nombre}} / {{item2.unidad_medida_nombre_unidad}}</td>
                                                                <td>
                                                                    <div class="d-flex order-actions">
                                                                        <a href="javascript:;" data-bs-toggle="modal" v-if="[[requisicionModificar.estado]] == 'A'" @click="fillDetalleMoficiar(item2.id, item2.producto_um, item2.cantidad);" data-bs-target="#modificarDetalleModal" class=""><i class='bx bxs-edit'></i></a>

                                                                        <!-- Modal -->
                                                                        <div class="modal fade"  id="modificarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                            <div class="modal-dialog modal-lg">
                                                                                <div class="modal-content bg-dark">
                                                                                    <div class="modal-header">
                                                                                        <h5 class="modal-title" id="exampleModalLabel">Modificar Producto a Requisición</h5>
                                                                                        <button type="button" id="modificar_detalle_close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>
                                                                                    <div class="modal-body">
                                                                                        <div class="row mb-3">
                                                                                            <!--
                                                                            <div class="col-sm-4">
                                                                                <h6 class="mb-0">Producto / Unidad Medida</h6>
                                                                            </div>
                                                                            <div class="col-sm-8">
                                                                                <vue-select  :options="productosUnidadMedidaData"
                                                                                    v-model="detalleRequisicion.producto_um"
                                                                                    label-by="nombre_producto"
                                                                                    track-by="nombre_producto"
                                                                                    value-by="id"
                                                                                    search-placeholder="Selecciona un Producto"
                                                                                    multiple
                                                                                    collapse-tags="true"
                                                                                    taggable
                                                                                    searchable
                                                                                    placeholder="Selecciona un Producto"
                                                                                    close-on-select>
                                                                                    <template #tag="{ option, remove }">
                                                                                    <div>
                                                                                    {{ option.nombre_producto }} - {{ option.nombre_unidad_medida }}/{{option.cantidad_unidad_medida}}
                                                                                    <img style="display: inline-flex;" @click.stop="remove" src="data:image/svg+xml;base64,PHN2ZyBpZD0iZGVsZXRlIiBkYXRhLW5hbWU9ImRlbGV0ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHRpdGxlPmRlbGV0ZTwvdGl0bGU+PHBhdGggZD0iTTI1NiwyNEMzODMuOSwyNCw0ODgsMTI4LjEsNDg4LDI1NlMzODMuOSw0ODgsMjU2LDQ4OCwyNC4wNiwzODMuOSwyNC4wNiwyNTYsMTI4LjEsMjQsMjU2LDI0Wk0wLDI1NkMwLDM5Ny4xNiwxMTQuODQsNTEyLDI1Niw1MTJTNTEyLDM5Ny4xNiw1MTIsMjU2LDM5Ny4xNiwwLDI1NiwwLDAsMTE0Ljg0LDAsMjU2WiIgZmlsbD0iIzViNWI1ZiIvPjxwb2x5Z29uIHBvaW50cz0iMzgyIDE3Mi43MiAzMzkuMjkgMTMwLjAxIDI1NiAyMTMuMjkgMTcyLjcyIDEzMC4wMSAxMzAuMDEgMTcyLjcyIDIxMy4yOSAyNTYgMTMwLjAxIDMzOS4yOCAxNzIuNzIgMzgyIDI1NiAyOTguNzEgMzM5LjI5IDM4MS45OSAzODIgMzM5LjI4IDI5OC43MSAyNTYgMzgyIDE3Mi43MiIgZmlsbD0iIzViNWI1ZiIvPjwvc3ZnPg==" alt="delete tag" class="icon delete">
                                                                                    </div>
                                                                                    </template>
                                                                                    <template #label="{ selected }">
                                                                                    <template v-if="selected">
                                                                                        <div style="color:white;">{{ selected.nombre_producto }}</div>
                                                                                    </template>
                                                                                    <template v-else > <div style="color:white;">Seleccione un Producto</div></template>
                                                                                    </template>
                                                                                    <template #dropdown-item="{ option }">
                                                                                    <div style="color:white;">{{ option.nombre_producto }} - {{ option.nombre_unidad_medida}}/{{option.cantidad_unidad_medida}} </div>
                                                                                    </template>
                                                                                </vue-select>
                                                                            </div>
                                                                            -->
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-4">
                                                                                    <h6 class="mb-0">Cantidad a Solicitar</h6>
                                                                                </div>
                                                                                <div class="col-sm-8">
                                                                                <input class="form-control" type="number" v-model="detalleRequisicion.cantidad" placeholder="Cantidad de Unidades" rows="3">
                                                                                </div>
                                                                            </div>
                                                                                    </div>
                                                                                    <div class="modal-footer">
                                                                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                        <button type="button" @click="modificarDetalleRequisicion" class="btn btn-primary">Modificar</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- End Modal -->
                                                                        <a href="javascript:;" v-if="[[requisicionModificar.estado]] == 'A'" @click="fillEliminarDetalleRequisicion(item2.id, item2.unidad_medida_nombre,item2.unidad_medida_nombre_unidad, item2.cantidad)" data-bs-target="#eliminarDetalleModal" data-bs-toggle="modal"  class="ms-3"><i class='bx bxs-trash'></i></a>
                                                                        <!-- Modal Eliminar -->
                                                                        <div class="modal fade"  id="eliminarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                            <div class="modal-dialog modal-lg">
                                                                                <div class="modal-content bg-dark">
                                                                                    <div class="modal-header">
                                                                                        <h5 class="modal-title" id="exampleModalLabel">Modificar Producto a Requisición</h5>
                                                                                        <button type="button" id="eliminar_detalle_close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>
                                                                                    <div class="modal-body">
                                                                                        <div class="row mb-3">
                                                                                            ¿Desea Eliminar el Producto {{ detalleRequisicionEliminar.producto_nombre }}?
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="modal-footer">
                                                                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                        <button type="button" @click="eliminarDetalleRequisicion(detalleRequisicionEliminar.id)" class="btn btn-danger">Eliminar</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- End Modal Eliminar -->
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
											</div>
										</div>
									</div><!--end row-->
									</div>
								</div>
										</div>
									</div><!--end row-->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--FIN MODIFICAR-->

			</div>
                <!-- Fin Modificar Card -->
			</div>
		</div>

</template>
<style>
</style>
<script>
import  Cookies from 'js-cookie';
//import Select2 from 'vue3-select2-component';
//import $ from 'jquery';
//let select2 = require('@/assets/plugins/select2/js/select2.min.js');
//import * as mykey from '../assets/js/mykey.js'
import $ from 'jquery';

//require('@/assets/plugins/datetimepicker/js/legacy.js');
//require('@/assets/plugins/datetimepicker/js/picker.js');
//require('@/assets/plugins/datetimepicker/js/picker.time.js');
//require('@/assets/plugins/datetimepicker/js/picker.date.js');
//require('@/assets/plugins/bootstrap-material-datetimepicker/js/moment.min.js');
//require('@/assets/plugins/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.min.js');
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
import VueNextSelect from 'vue-next-select';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref } from 'vue';
console.log(Cookies.get());
export default {
    name: 'requisicion_component',
    components:{
        'vue-select': VueNextSelect,
        'Datepicker': Datepicker,
    },
setup() {
        // In case of a range picker, you'll receive [Date, Date]
        const date = ref(new Date());
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
        return {
            date,
            format,
        }
    },
data() {
	return{
        URL_TENANT: '',
        option: 1,
        url: "/api/requisicion_compra/",
        indexPagination: 1,
        productosUnidadMedidaData: [],
        requisicion_compra:{
            fecha: null,
            comentario: '',
            proveedor: '',
        },
        crearCotizacion: {
            referencia: null,
            id: null,
            fecha: null,
            proveedor: null
        },
        requisicionesData:[],
        requisicionModificar: {
            id: null,
            fecha: null,
            comentario: '',
            proveedor: [{}],
            referencia: null,
            estado: null
        },
        detalleRequisicion: {
            id: null,
            cantidad: null,
            producto_um: [],
        },
        detalleRequisicionModificar: [],
        detalleRequisicionEliminar: {
            id: null,
            producto_nombre: null,
            cantidad: null,
        },
        empresaData: [0],
        usuariosData: [0],
        proveedoresData: [],
		buscarRequisicion: '',
        estados: [
            {
                id: 'A',
                nombre: 'Abierto',
            },
            {
                id: 'C',
                nombre: 'Cerrado',
            },
            {
                id: '9',
                nombre: 'Anulado',
            }
        ],
		}
	},

computed: {
    requisicionesDataFilter() {
        if (this.buscarRequisicion != ''){
            return this.requisicionesData.results.filter((item) => {
            return item.referencia.toLowerCase().includes(this.buscarRequisicion.toLowerCase()) || item.fecha.toLowerCase().includes(this.buscarRequisicion.toLowerCase());
        })
        }else{
            return this.requisicionesData.results;
        }

    },
},
watch: {
    url: function () {
        this.getRequisicionesData();
    },
    requisicion_compra: {
        handler: function () {
            if(this.requisicion_compra.proveedor.length > 1){
                this.requisicion_compra.proveedor.shift();
            }
        },
        deep: true,
    },
    detalleRequisicion: {
        handler: function () {
            if(this.detalleRequisicion.producto_um.length > 1){
                this.detalleRequisicion.producto_um.shift();
            }
        },
        deep: true,
    },
    requisicionModificar: {
        handler: function () {
            if(this.requisicionModificar.proveedor.length > 1){
                this.requisicionModificar.proveedor.shift();
            }
        },
        deep: true,
    },
},
methods: {
    async fillCrearCotizacion(referencia, id, fecha, proveedor){
        this.crearCotizacion.referencia = referencia;
        this.crearCotizacion.id = id;
        this.crearCotizacion.fecha = fecha;
        this.crearCotizacion.proveedor = proveedor;
    },
    async cambiarUrl(link, index){
        console.log("LINK");
        console.log(link);
        this.url = link;
        if (index === "atras" && link){
            this.indexPagination = this.indexPagination - 1;
        }
        else if (index === "adelante" && link != null){
            this.indexPagination = this.indexPagination + 1;
        }
        else if (link){
            this.indexPagination = index;
        }
    },
    async cambiarEstado(idOption) {
		this.option = idOption;
    },
    async crearRequisicionCompra(){
        if (!this.requisicion_compra.fecha && !this.requisicion_compra.proveedor[0] ){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        }else{
            try{
                const formData = new FormData();
                console.log("VOY A INGRESAR");
                console.log("FECHA");
                console.log(this.requisicion_compra.fecha);
                formData.append("fecha", this.requisicion_compra.fecha);
                formData.append("proveedor", this.requisicion_compra.proveedor[0]);
                formData.append("comentario", this.requisicion_compra.comentario);
                await fetch(this.URL_TENANT+'/api/requisicion_compra/',{
                method: 'post',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Requisición de Compra Creada');
            document.getElementById('agregar_close').click();
            this.getRequisicionesData();
            this.requisicion_compra = {
                fecha: null,
                comentario: '',
                proveedor: '',
            }
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }

        }
    },
    async fillModificarRequisicion(idMod) {
        this.modificarId = idMod[0][0];
        console.log("MODIFICAR");
        console.log(this.modificarId);
        for(var k in this.requisicionesData.results){
            console.log("For 1");
            console.log(this.requisicionesData.results[k]);
            if(this.requisicionesData.results[k].id==this.modificarId){
                console.log("if 1");
				this.requisicionModificar.id = this.requisicionesData.results[k].id,
                this.requisicionModificar.fecha = this.requisicionesData.results[k].fecha,
                this.requisicionModificar.comentario = this.requisicionesData.results[k].comentario,
                this.requisicionModificar.proveedor = [this.requisicionesData.results[k].proveedor],
                this.requisicionModificar.referencia = this.requisicionesData.results[k].referencia,
                this.requisicionModificar.estado = this.requisicionesData.results[k].estado,
                this.detalleRequisicionModificar = [];
                    for(var j in this.requisicionesData.results[k].detalle_requisicion_compra){
                        console.log("for 2");
                        //Lleno el detalle a modificar:
                        const obj = {
                            id: this.requisicionesData.results[k].detalle_requisicion_compra[j].id,
                            cantidad: this.requisicionesData.results[k].detalle_requisicion_compra[j].cantidad,
                            requisicion_compra: this.requisicionesData.results[k].detalle_requisicion_compra[j].requisicion_compra,
                            producto_um: this.requisicionesData.results[k].detalle_requisicion_compra[j].producto_um,
                            unidad_medida_nombre: this.requisicionesData.results[k].detalle_requisicion_compra[j].unidad_medida_nombre,
                            unidad_medida_nombre_unidad: this.requisicionesData.results[k].detalle_requisicion_compra[j].unidad_medida_nombre_unidad,
                        }
                        this.detalleRequisicionModificar.push(obj);
                    }
            }
        }
        this.option=2;
    },
    async modificarRequisicion(){
        if (!this.requisicionModificar.id || !this.requisicionModificar.proveedor[0]){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        }else{
            console.log("ESTAS UPDETEANDO");
            try{
                const formData = new FormData();
                formData.append("fecha", this.requisicionModificar.fecha);
                formData.append("comentario", this.requisicionModificar.comentario);
                formData.append("proveedor", this.requisicionModificar.proveedor[0]);
                formData.append("estado", this.requisicionModificar.estado);
                await fetch(this.URL_TENANT+'/api/requisicion_compra/'+this.requisicionModificar.id+'/',{
                method: 'put',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Requisición de Compra Modificada');
            this.getRequisicionesData();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }
        }
    },
async crearDetalleRequisicion(){
    console.log("crearDetalleRequisicion");
    console.log(this.detalleRequisicion.cantidad);
    console.log(this.detalleRequisicion.producto_um);

        if (!this.detalleRequisicion.cantidad || !this.detalleRequisicion.producto_um[0]){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        }else{
            try{
                document.getElementById('agregar_detalle_close').click();
                const formData = new FormData();
                console.log("VOY A INGRESAR");
                formData.append("requisicion_compra", this.requisicionModificar.id);
                formData.append("cantidad", this.detalleRequisicion.cantidad);
                formData.append("producto_um", this.detalleRequisicion.producto_um[0]);
                await fetch(this.URL_TENANT+'/api/requisicion_compra_detalle/',{
                method: 'post',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Producto Agregado a la Requisición');
            this.detalleRequisicion = {
                id: null,
                cantidad: null,
                producto_um: '',
            }
            //document.getElementById('agregarDetalleModal').click();
            //this.option = 1;
            this.getProductosUnidadMedida();
            await this.getRequisicionesData();
            this.fillModificarRequisicion([[this.requisicionModificar.id]]);
            this.detalleRequisicion = {
                id: null,
                cantidad: null,
                producto_um: [],
            }
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }

        }
    },
    async fillDetalleMoficiar(id, producto_um, cantidad){
        this.detalleRequisicion.id = id;
        this.detalleRequisicion.producto_um = [producto_um];
        this.detalleRequisicion.cantidad = cantidad;
        await this.getProductosUnidadMedida();
    },
    async fillEliminarDetalleRequisicion(id, unidad_medida_nombre, unidad_medida_nombre_unidad, cantidad){
        console.log("ELIMINAR FILL");
        this.producto_nombre = unidad_medida_nombre + unidad_medida_nombre_unidad;
        this.detalleRequisicionEliminar.id = id;
        this.detalleRequisicionEliminar.producto_nombre = this.producto_nombre;
        this.detalleRequisicionEliminar.cantidad = cantidad;
    },
    async modificarDetalleRequisicion(){
        if (!this.detalleRequisicion.id || !this.detalleRequisicion.producto_um[0] || !this.detalleRequisicion.cantidad){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        }else{
            console.log("ESTAS UPDETEANDO");
            try{
                const formData = new FormData();
                formData.append("cantidad", this.detalleRequisicion.cantidad);
                formData.append("producto_um", this.detalleRequisicion.producto_um[0]);
                formData.append("cantidad", this.detalleRequisicion.cantidad);
                formData.append("requisicion_compra", this.requisicionModificar.id);
                await fetch(this.URL_TENANT+'/api/requisicion_compra_detalle/'+this.detalleRequisicion.id+'/',{
                method: 'put',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
                document.getElementById('modificar_detalle_close').click();
            notificaciones.round_success_noti($.msg='Producto Modificado Exitosamente');
            this.getProductosUnidadMedida();
            await this.getRequisicionesData();
            this.fillModificarRequisicion([[this.requisicionModificar.id]])
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }
        }
    },
    async eliminarDetalleRequisicion(idProductoEliminar){
        console.log("ID A ELIMINAR");
        console.log(idProductoEliminar);
		const requestOptions = {
			method: 'DELETE',
			headers: {
			'X-CSRFToken': Cookies.get('csrftoken'),
			}
		};
		await fetch(this.URL_TENANT+'/api/requisicion_compra_detalle/'+idProductoEliminar+'',  requestOptions);
        document.getElementById('eliminar_detalle_close').click();
		notificaciones.round_success_noti($.msg='Producto Eliminado de la Requisición');
		this.getProductosUnidadMedida();
        await this.getRequisicionesData();
        this.fillModificarRequisicion([[this.requisicionModificar.id]]);
	},
    async getProductosUnidadMedida() {
        const response = await fetch(this.URL_TENANT+'/api/producto_unidades_medida/');
        this.productosUnidadMedidaData = await response.json();
    },
    async getEmpresaData() {
        const response = await fetch(this.URL_TENANT+'/api/empresa/');
        this.empresaData = await response.json();
    },
    async getProveedoresData() {
        const response = await fetch(this.URL_TENANT+'/api/proveedor/');
        this.proveedoresData = await response.json();
    },
	async getRequisicionesData() {
        const response = await fetch(this.url);
        this.requisicionesData = await response.json();
    },
    async convertirCotizacion(){
        try{
                const formDataCot = new FormData();
                console.log("VOY A CREAR COTIZACION");
                formDataCot.append("desdeRequisicion", 'desdeRequisicion');
                formDataCot.append("fecha", this.crearCotizacion.fecha);
                formDataCot.append("proveedor", this.crearCotizacion.proveedor);
                formDataCot.append("usuario", '1');
                formDataCot.append("requisicion", this.crearCotizacion.id);
                await fetch(this.URL_TENANT+'/api/cotizacion_compra/',{
                method: 'post',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formDataCot})
            notificaciones.round_success_noti($.msg='Se Creó la Cotización a partir de la Requisición: '+this.crearCotizacion.referencia+'');
            document.getElementById('convertir_cotizacion_close').click();
            this.getProductosUnidadMedida();
            await this.getRequisicionesData();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }
    },
    },
mounted() {
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://unpkg.com/vue-next-select/dist/vue-next-select.iife.prod.js')
        document.head.appendChild(recaptchaScript)
    },
created() {
      // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
    this.url = this.URL_TENANT + this.url
    this.getRequisicionesData();
    this.getEmpresaData();
    this.getProveedoresData();
    this.getProductosUnidadMedida();


	},
}

</script>
