<template>
      <Header/>
      <Nav/>
      <Proveedor/>

</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'
import Proveedor from './components/catalogo/proveedor/proveedor.vue'



export default {
  name: 'ProveedorComponent',

  components: {
    Header,
	Nav,
	Proveedor,
  },

  data: () => ({
    //
  }),
}
</script>
