
<template>
    <div class="modal-body">
        <div class="row mb-3">
            <div class="col-sm-6">
                <h4 class="mb-0">¿Deseas eliminar el Consultorio?</h4>
            </div>
            <div class="col-sm-6">
                <input type="text" readonly :value="titulo" placeholder="Título del Evento" autofocus id="titulo"  class="form-control" />
            </div>
        </div>
    </div>
</template>
    <script>
    import  Cookies from 'js-cookie';
    //import Select2 from 'vue3-select2-component';
    //import $ from 'jquery';
    //let select2 = require('@/assets/plugins/select2/js/select2.min.js');
    //import * as mykey from '../assets/js/mykey.js'
    import $ from 'jquery';
    import 'bootstrap/dist/css/bootstrap.min.css'
    import 'bootstrap/dist/js/bootstrap.bundle.js'
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    //let calendar = require('@/assets/plugins/fullcalendar/js/notification-custom-script.js');
    //import { ref } from 'vue';
    import 'vue-cal/dist/vuecal.css'
    //Componentes Propios:
    console.log(Cookies.get());
    export default {
        props: ['id_consultorio', 'titulo'],
        name: 'eliminar_consultorio',
        components:{
        },
    setup() {
    },
    data() {
        return{
            URL_TENANT: '',
            // Evento a Modificar:
            consultorio_eliminado: false,
            //Anteriores
            }
        },

    computed: {
    },
    watch: {
        titulo: {
            handler: function () {
                this.consultorio_eliminado = false;
            },
            deep: true,
        },
    },
    methods: {
        async eliminarConsultorio(){
                try{
                    await fetch(this.URL_TENANT+'/api/consultorio/'+this.id_consultorio+'/',{
                    method: 'DELETE',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: ''})
                    notificaciones.round_success_noti($.msg='Consultorio Eliminado Existosamente');
                    this.consultorio_eliminado = true;

                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
            }
        },
    mounted() {
        },
    created() {
            const result = window.location.origin;
            this.URL_TENANT = result;
        },
    }
    </script>
    <style>
    </style>