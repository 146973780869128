<template>
      <CambiarContraseña/>
</template>

<script>
import CambiarContraseña from './components/cambiar_contraseña.vue'


export default {
  name: 'CambiarContraseñaComponent',

  components: {
    CambiarContraseña,
  },

  data: () => ({
    //
  }),
}
</script>
