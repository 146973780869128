<template>
      <Header/>
      <Nav/>
      <Calendario/>

</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'
import Calendario from './components/aplicaciones/calendario.vue'



export default {
  name: 'CalendarioComponent',

  components: {
    Header,
	Nav,
	Calendario,
  },

  data: () => ({
    //
  }),
}
</script>
