<template>
      <Header/>
      <Nav/>
      <Consultorio/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import Consultorio from '../../components/consultorio_virtual/consultorio/consultorio.vue'



export default {
  name: 'ConsultorioComponent',
  components: {
    Header,
	Nav,
	Consultorio,
  },

  data: () => ({
    //
  }),
}
</script>
