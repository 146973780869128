<template>
    <div class="page-wrapper">
        <div class="page-content">
            <!--breadcrumb-->
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Agencia de Viajes</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Mis Viajes</li>
                        </ol>
                    </nav>
                </div>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-light">Settings</button>
                        <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	<a class="dropdown-item" href="javascript:;">Action</a>
                            <a class="dropdown-item" href="javascript:;">Another action</a>
                            <a class="dropdown-item" href="javascript:;">Something else here</a>
                            <div class="dropdown-divider"></div>	<a class="dropdown-item" href="javascript:;">Separated link</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end breadcrumb-->
            <div class="card">
                <div class="card-body">
                    <div class="d-lg-flex align-items-center mb-4 gap-3">
                        <div class="position-relative">
                            <input type="text" class="form-control ps-5 radius-30" v-model="buscarViaje" placeholder="Buscar Viaje"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                        </div>
                        <div class="ms-auto">
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4">
					<div class="col" v-for="item in viajesDataFilter" :key="item.id">
						<div class="card" style="height: 420px;">
							<img :src="item.destino_imagen" class="card-img-top" style="height: 224px;" alt="...">
							<div class="card-body">
								<h4 class="card-title">{{item.destino_nombre}} <i class='bx bxs-bus' v-if="item.vehiculo_tipo =='Bus'"></i><i class='lni lni-plane' v-if="item.vehiculo_tipo =='Avión'"></i></h4>
								<p class="card-text"><strong>Fechas:</strong> {{item.fecha_salida}} - {{item.fecha_regreso}}</p>
								<p class="card-text"><strong>Tarifa:</strong> {{item.tarifa}}</p>

                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#detallesViaje" @click="viaje = item" class="btn btn-light">Ver Detalles</a>
							</div>
						</div>
					</div>
				</div>
                </div>
            </div>


        </div>
    </div>
<!-- Modal Asientos -->
<div class="modal fade" id="detallesViaje" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Detalles del Viaje</h5>
                <button type="button" id="close_detallesViaje" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body d-flex flex-column">
                <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Origen</h6>
                            </div>
                            <div class="col-sm-10">
                                <input type="text" disabled  class="form-control" v-model="viaje.origen_nombre"  />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Destino</h6>
                            </div>
                            <div class="col-sm-10">
                                <input type="text" disabled class="form-control" v-model="viaje.destino_nombre"  />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Fecha Salida</h6>
                            </div>
                            <div class="col-sm-6">
                                <Datepicker
                                disabled
                                v-model="viaje.fecha_salida"
                                dark placeholder="Selecciona una Fecha"
                                autoApply
                                :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                :enableTimePicker="false"
                                monthNameFormat="long"
                                :format="format"
                                modelType="dd-MM-yyyy"
                                locale="es">
                                </Datepicker>
                            </div>
                            <div class="col-sm-2">
                                <h6 class="mb-0">Hora Salida</h6>
                            </div>
                            <div class="col-sm-2">
                                <Datepicker disabled v-model="viaje.hora_salida" :startTime="startTime" timePicker dark  minutesIncrement="5" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Fecha Regreso</h6>
                            </div>
                            <div class="col-sm-6">
                                <Datepicker
                                disabled
                                v-model="viaje.fecha_regreso"
                                dark placeholder="Selecciona una Fecha"
                                autoApply
                                :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                :enableTimePicker="false"
                                monthNameFormat="long"
                                :format="format"
                                modelType="dd-MM-yyyy"
                                locale="es">
                                </Datepicker>
                            </div>
                            <div class="col-sm-2">
                                <h6 class="mb-0">Hora Regreso</h6>
                            </div>
                            <div class="col-sm-2">
                                <Datepicker disabled v-model="viaje.hora_regreso" :startTime="last_time" timePicker dark  minutesIncrement="5" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Vehículo</h6>
                            </div>
                            <div class="col-sm-10">
                                <input type="text" disabled  class="form-control" v-model="viaje.vehiculo_nombre"  />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Tarifa</h6>
                            </div>
                            <div class="col-sm-5">
                                <input type="number" disabled  class="form-control" v-model="viaje.tarifa"  />
                            </div>
                            <div class="col-sm-2">
                                <h6 class="mb-0">Estado</h6>
                            </div>
                            <div class="col-sm-3">
                                <input type="text" disabled class="form-control" v-model="viaje.estado"  />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Abordaje</h6>
                            </div>
                            <div class="col-sm-10">
                                <textarea disabled class="form-control"  v-model="viaje.abordaje" placeholder="¿Dónde será el abordaje?..." rows="3"></textarea>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Descripcion</h6>
                            </div>
                            <div class="col-sm-10">
                                <textarea disabled class="form-control"  v-model="viaje.descripcion" placeholder="Agrega una Descripción..." rows="4"></textarea>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Tickets de Abordaje</h6>
                            </div>
                            <div class="col-sm-10">
                                <div class="table-responsive">
                        <table class="table mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th>Número</th>
                                    <th>Asiento</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in viaje.ticket_abordaje_viaje" :key="item.id">
                                    <td>{{index + 1}}</td>
                                    <td>{{item.asiento_codigo}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                            </div>
                        </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" @click="cerrarModal(); reimprimirTickets(viaje)" data-bs-dismiss="modal" >Reimprimir Tickets</button>
            </div>
        </div>
    </div>
</div>
</div>
        <!-- End Modal -->
</template>
<style lang="less">
</style>
<script>


import Datepicker from '@vuepic/vue-datepicker';
import { ref } from 'vue';


export default {
    name: 'viaje_component',
    components:{

        'Datepicker': Datepicker,

        /*del_event,*/
    },
    setup() {
        const date = ref(new Date());
            const format = (date) => {
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
            const startTime = ref({ hours: 0, minutes: 0 });
            const first_time = ref({
                hours: new Date().getHours()+1,
                minutes: 0
            });
            const last_time = ref({
                hours: new Date().getHours()+2,
                minutes: 0
            });
            function formatDate(userDate) {
                // format from M/D/YYYY to YYYYMMDD
                return (new Date(userDate).toJSON().slice(0,10).split('-').reverse().join('-'));
                }
            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            var today_is = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
            var today_date = formatDate(today_is);
        return {
                startTime,
                first_time,
                last_time,
                date,
                format,
                today_date,
            }
    },
    data() {
    return{
        URL_TENANT: '',
        viajesData:  [],
        buscarViaje: '',
        viaje: {},
        id_viaje: '',
        titulo: '',
        viaje_mod: {},
        usuarioData: [],
        }
    },
    watch: {
},
    computed: {
        viajesDataFilter() {
        console.log("LENGT");
        if (this.buscarViaje != ''){
            return this.viajesData.results.filter((item) => {
            return item.codigo.toLowerCase().includes(this.buscarViaje.toLowerCase()) || item.origen_nombre.toLowerCase().includes(this.buscarViaje.toLowerCase()) || item.destino_nombre.toLowerCase().includes(this.buscarViaje.toLowerCase())
        })
        }else{
            return this.viajesData.results;
        }
    },
    },

    methods: {
        async reimprimirTickets(viaje){
            var _tickets = [];
            for(var x=0;x<viaje.ticket_abordaje_viaje.length;x++){
                _tickets.push(viaje.ticket_abordaje_viaje[x].id);
            }
            console.log("Viaje");
            console.log(viaje);
            console.log("TICKETS");
            console.log(_tickets);
            const url = this.URL_TENANT+'/agencia_viaje/generar_ticket/'+_tickets+'/';
            window.open(url, '_blank');
        },
        async getUsuarioData() {
        const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/cliente_log/');
        this.usuarioData = await response.json();
        },
        async cerrarModal(){
            document.getElementById('close_detallesViaje').click();
        },
        async getViajesData(){
            await this.getUsuarioData();
            console.log("USUARIO:" + this.usuarioData[0].usuario);
            const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/mis_viajes_cliente/?cliente='+this.usuarioData[0].usuario)
            this.viajesData = await response.json();
        },
    },
    mounted() {
        this.$watch(
            "$refs.child_asientos.asientos_seleccionados",
            () => {
                this.getViajesData();
            }
        );
        },
    created() {
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.getViajesData();
    },
}
</script>