
<template>

<div class="page-wrapper">
			<div class="page-content">

				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Inventario</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Modificar Producto</li>
							</ol>
						</nav>
					</div>
					<div class="ms-auto">
						<div class="btn-group">
							<button type="button" class="btn btn-light">Settings</button>
							<button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span class="visually-hidden">Toggle Dropdown</span>
							</button>
							<div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	<a class="dropdown-item" href="javascript:;">Action</a>
								<a class="dropdown-item" href="javascript:;">Another action</a>
								<a class="dropdown-item" href="javascript:;">Something else here</a>
								<div class="dropdown-divider"></div>	<a class="dropdown-item" href="javascript:;">Separated link</a>
							</div>
						</div>
					</div>
				</div>
				<!--end breadcrumb-->

            <div class="card">
				<div class="card-body p-4">
					<h5 class="card-title">Agregar Nuevo Producto</h5>
					<hr/>
                    <div class="form-body mt-4">
					<div class="row">
						<div class="col-lg-8">
                        <div class="border border-3 p-4 rounded">
							<div class="row g-3">
							<div class="col-md-6">
								<label class="form-label">Código de Barra</label>
								<input v-model="codigo_barraNuevo" type="text" class="form-control" id="codigo_barraNuevo" placeholder="Ingrese el Código de Barra">
							</div>
							<div class="col-md-6">
								<label class="form-label">Marca</label>
								<input v-model="marcaNuevo" type="text" class="form-control" id="marcaNuevo" placeholder="Ingrese la Marca">
							</div>
							<div class="mb-3">
								<label class="form-label">Nombre Producto</label>
								<input v-model="nombreNuevo" type="text" class="form-control" id="nombreNuevo" placeholder="Ingrese el Nombre del Producto">
							</div>
							</div>
							<div class="mb-3">
								<label class="form-label">Descripción</label>
								<textarea v-model="descripcionNuevo" class="form-control" placeholder="Ingresa una Descripción del Producto" id="descripcionNuevo" rows="3"></textarea>
							</div>
							<div class="mb-3">
								<label class="form-label">Observaciones</label>
								<textarea v-model="observacionesNuevo" class="form-control" placeholder="Observaciones del Producto" id="observacionesNuevo" rows="3"></textarea>
							</div>
								<label class="form-label">Subir Imagen(es)</label><br>
							<div class="input-group mb-3">
								<input type="file" class="form-control" id="imagenesNuevo" multiple>
								<label class="input-group-text">Subir</label>
							</div>
                            </div>
						</div>
						<div class="col-lg-4">
							<div class="border border-3 p-4 rounded">
                            <div class="row g-3">
								<div class="col-md-6">
									<label class="form-label">Precio Compra</label>
									<input v-model="precioCompraNuevo" type="number" min="0" step="0.01" class="form-control" id="precioCompraNuevo" placeholder="00.00">
								</div>
								<div class="col-md-6">
									<label class="form-label">Precio Venta</label>
									<input v-model="precioVentaNuevo" type="number" min="0" step="0.01" class="form-control" id="precioVentaNuevo" placeholder="00.00">
								</div>
								<div class="input-group mb-3">
									<div class="input-group-text">
										<input v-model="permitirCompraNuevo" class="form-check-input"  id="permitirCompraNuevo" type="checkbox" checked aria-label="Checkbox for following text input">
									</div>
									<input type="text" class="form-control" disabled :value="permitirCompraMostrar" aria-label="Text input with checkbox">
								</div>
								<div class="input-group mb-3">
									<div class="input-group-text">
										<input v-model="permitirVentaNuevo" class="form-check-input"  id="permitirVentaNuevo" type="checkbox" checked aria-label="Checkbox for following text input">
									</div>
									<input type="text" class="form-control" disabled :value="permitirVentaMostrar" aria-label="Text input with checkbox">
								</div>
								<div class="input-group mb-3">
									<div class="input-group-text">
										<input v-model="estadoNuevo" class="form-check-input"  id="permitirVentaNuevo" type="checkbox" checked aria-label="Checkbox for following text input">
									</div>
									<input type="text" class="form-control" disabled :value="estadoMostrar" aria-label="Text input with checkbox">
								</div>
								<div class="col-12">
									<label for="inputProductType" class="form-label">Categoría</label>
									<vue-select  :options="categoriasData" 
                                        v-model="selectedCategoria"
                                        label-by="nombre"
                                        track-by="nombre"
                                        value-by="id"
                                        search-placeholder="Selecciona una Categoría"
                                        multiple
										collapse-tags="true"
										taggable
										searchable
                                        placeholder="Selecciona una Categoría"
                                        close-on-select>
                                        <template #label="{ multiple }">
                                        <template v-if="multiple">
                                        <div style="color:white;">{{ multiple.nombre }}</div>
                                        </template>
                                        <template v-else > <div style="color:white;">Seleccione una Categoría</div></template>
                                        </template>
                                        <template #dropdown-item="{ option }">
                                        <div style="color:white;">{{ option.nombre }}</div>
                                        </template>
                                    </vue-select>
								</div>
								<div class="col-12">
									<label class="form-label">Proveedor</label>
									<vue-select  :options="proveedoresData" 
                                        v-model="selectedProveedor"
                                        label-by="nombre"
                                        track-by="nombre"
                                        value-by="id"
                                        search-placeholder="Selecciona un Proveedor"
                                        multiple
										collapse-tags="true"
										taggable
										searchable
                                        placeholder="Selecciona un Proveedor"
                                        close-on-select>
                                        <template #label="{ multiple }">
                                        <template v-if="multiple">
                                        <div style="color:white;">{{ multiple.nombre }}</div>
                                        </template>
                                        <template v-else > <div style="color:white;">Seleccione un Proveedor</div></template>
                                        </template>
                                        <template #dropdown-item="{ option }">
                                        <div style="color:white;">{{ option.nombre }}</div>
                                        </template>
                                    </vue-select>
								</div>
								<div class="col-6">
									<div class="d-grid">
                                        <button type="button" @click="agregarProducto(caso=1)" class="btn btn-light">Guardar y Añadir Otro</button>
									</div>
								</div>
								<div class="col-6">
									<div class="d-grid">
                                        <button type="button"  @click="agregarProducto(caso=2)" class="btn btn-light">Guardar Producto</button>
									</div>
								</div>
							</div>
						</div>
						</div>
					</div><!--end row-->
					</div>
				</div>
			</div>

			</div>
		</div>
</template>
<style>
@import '@/assets/css/imageuploadify.min.css';
</style>
<script>
import  Cookies from 'js-cookie';
//import Select2 from 'vue3-select2-component';
//import $ from 'jquery';
//let select2 = require('@/assets/plugins/select2/js/select2.min.js');
//import * as mykey from '../assets/js/mykey.js'
import $ from 'jquery';
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')

import VueNextSelect from 'vue-next-select'
console.log(Cookies.get());
export default {
    name: 'producto_component',
    components:{
        'vue-select': VueNextSelect,
    },
data() {
	return{
        productosData: [],
		categoriasData: [],
		proveedoresData: [],
		buscarProducto: '',
		//para Registro de Nuevo Producto:
		codigo_barraNuevo: '',
		marcaNuevo: '',
		nombreNuevo: '',
		descripcionNuevo: '',
		precioCompraNuevo: '',
		precioVentaNuevo: '',
		permitirCompraNuevo: true,
		permitirVentaNuevo: true,
		observacionesNuevo: '',
		selectedCategoria: '',
		selectedProveedor: '',
		imagenesNuevo: [],
		estadoNuevo: true,
		estadoNuevoIngresar: '',
		estadoMostrar: 'Activo',
		permitirCompraMostrar: 'Permitir Compra',
		permitirVentaMostrar: 'Permitir Venta'
		}
	},

computed: {
    productoDataFilter() {
        console.log("LENGT");
        console.log(this.productosData.length);
        if (this.buscarProducto != ''){
            return this.productosData.filter((item) => {
            return item.nombre.toLowerCase().includes(this.buscarProducto.toLowerCase()) || item.codigo_barra.toLowerCase().includes(this.buscarProducto.toLowerCase()) || item.marca.toLowerCase().includes(this.buscarProducto.toLowerCase()) || item.categoria_nombre.toLowerCase().includes(this.buscarProducto.toLowerCase())
        })
        }else{
            return this.productosData;
        }

    },
},
watch: {
	
	estadoNuevo: function (){
		if(this.estadoNuevo == true){
			this.estadoMostrar = 'Activo';
		}else{
			this.estadoMostrar = 'Inactivo';
		}
	},
	permitirCompraNuevo: function (){
		if(this.permitirCompraNuevo == true){
			this.permitirCompraMostrar = 'Permitir Compra';
		}else{
			this.permitirCompraMostrar = 'No Permite Compra';
		}
	},
	permitirVentaNuevo: function (){
		if(this.permitirVentaNuevo == true){
			this.permitirVentaMostrar = 'Permitir Venta';
		}else{
			this.permitirVentaMostrar = 'No Permite Venta';
		}
	},
    selectedCategoria: function () {
		console.log("SELECTED");
        console.log(this.selectedCategoria[0]);
		if(this.selectedCategoria.length > 1){
		this.selectedCategoria.shift();
		console.log("QUEDO:");
        console.log(this.selectedCategoria[0]);
		}
		
        }
    },
methods: {
	async limpiarInputs(){
		this.codigo_barraNuevo = '',
		this.marcaNuevo = '',
		this.nombreNuevo = '',
		this.descripcionNuevo = '',
		this.precioCompraNuevo = '',
		this.precioVentaNuevo = '',
		this.permitirCompraNuevo = true,
		this.permitirVentaNuevo = true,
		this.observacionesNuevo = '',
		this.selectedCategoria = '',
		this.selectedProveedor = '',
		this.imagenesNuevo = [],
		this.estadoNuevo = true
	},
	async getProductosData() {
        const response = await fetch('http://127.0.0.1:8000/api/producto/');
        this.productosData = await response.json();
    },
	async getCategoriasData() {
        const response = await fetch('http://127.0.0.1:8000/api/categoria/');
        this.categoriasData = await response.json();
    },
	async getProveedoresData() {
        const response = await fetch('http://127.0.0.1:8000/api/proveedor/');
        this.proveedoresData = await response.json();
    },
	async agregarProducto(caso = null) {
    console.log("Agregar");
	// Condición para el estado:
	if (this.estadoNuevo == true){
		this.estadoNuevoIngresar = 'A';
	}else{
		this.estadoNuevoIngresar = 'I';
	}
	console.log(Cookies.get('csrftoken'));

    try{
		//const imagenUsuario = document.getElementById("imagenesNuevo");
        const formData = new FormData();
        formData.append("codigo_barra", this.codigo_barraNuevo);
        formData.append("descripcion", this.descripcionNuevo);
        formData.append("nombre", this.nombreNuevo);
        formData.append("marca", this.marcaNuevo);
        formData.append("precio_compra",this.precioCompraNuevo);
        formData.append("precio_venta", this.precioVentaNuevo);
        formData.append("inventario", 0);
        formData.append("permitir_venta", this.permitirVentaNuevo);
        formData.append("permitir_compra", this.permitirCompraNuevo);
        formData.append("observaciones", this.observacionesNuevo);
        formData.append("categoria", this.selectedCategoria[0]);
        formData.append("estado", this.estadoNuevoIngresar);
        formData.append("categoria_nombre", '');
        formData.append("_imagenes", []);

		for (var index = 0; index < $('#imagenesNuevo')[0].files.length; index++) {
			formData.append("imagenesNuevo", $('#imagenesNuevo')[0].files[index]);
		}
		//formData.append("imagenes", imagenUsuario.files);

        console.log("DATOS USER");
        await fetch(`http://127.0.0.1:8000/api/producto/`,{
        method: 'post',
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
        },body: formData})
        //await this.getusuarioData();
        notificaciones.round_success_noti($.msg='Producto Agregado Exitosamente');
		if (caso == 1){
			this.limpiarInputs();
		}
		if (caso == 2){
			setInterval(function(){location.href = '/inventario/producto/'},2500);
		}

    }catch(error){
        console.log(error);
        notificaciones.round_error_noti($.msg='Error, algo salió mal');
    }
    },
    },
mounted() {

    },
created() {
      // Fetch tasks on page load
    this.getProductosData();
	this.getProveedoresData();
	this.getCategoriasData();
	console.log("token");
	console.log(Cookies.get('csrftoken'));

	},
}

</script>
