
<template>
    <div class="page-wrapper">
                <div class="page-content">
                    <!--breadcrumb-->
                    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div class="breadcrumb-title pe-3">Compras</div>
                        <div class="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0 p-0">
                                    <li class="breadcrumb-item"><a href="javascript:;"><i class='bx bx-cart'></i></a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">compras</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <!--end breadcrumb-->
                    <div  class="card">
                        <div class="card-body">
                            <div class="d-lg-flex align-items-center mb-4 gap-3">
                                <div class="position-relative">
                                    <input type="text" class="form-control ps-5 radius-30" v-model="buscarCompra" placeholder="Buscar Compra"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                                </div>
                                    <add_compra ref="child_add"></add_compra>
                            </div>
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th><h6>#Número</h6></th>
                                            <th><h6>Fecha</h6></th>
                                            <th><h6>NIT</h6></th>
                                            <th><h6>Proveedor</h6></th>
                                            <th><h6>Estado</h6></th>
                                            <th><h6>Ver Detalle</h6></th>
                                            <th><h6>Total</h6></th>
                                            <th><h6>Usuario</h6></th>
                                            <th><h6>Acción</h6></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in comprasDataFilter" :key="item.id">
                                            <td><h6>
                                                <div class="d-flex align-items-center">
                                                    <div>
                                                        <input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-0 font-14">#{{item.numero}}</h6>
                                                    </div>
                                                </div>
                                            </h6></td>
                                            <td><h6>{{item.fecha_creacion}}</h6></td>
                                            <td><h6>{{item.nit_proveedor}}</h6></td>
                                            <td><h6>{{item.nombre_proveedor}}</h6></td>
                                            <td><h6>
                                                    <button type="button"  v-if="[[item.estado]] == 'A'" class="btn btn-common btn-secondary px-5 radius-30">Abierto</button>
                                                    <button type="button"  v-if="[[item.estado]] == '9'" class="btn btn-common btn-danger px-5 radius-30">Anulado</button>
                                                    <button type="button"  v-if="[[item.estado]] == 'F'" class="btn btn-common btn-success px-5 radius-30">Finalizado</button>
                                            </h6></td>
                                            <td><h6>
                                                <div v-if="item.detalle_compra.length >0">
                                                    <button @click="setVerDetalle(item.detalle_compra);" type="button" data-bs-toggle="modal" data-bs-target="#detalleCompra" class="btn btn-common btn-light position-relative me-lg-5"> <i class='bx bx-comment-detail align-middle'></i> Ver Detalle <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{item.detalle_compra.length}}</span>
                                                    </button>
                                                </div>
                                            </h6></td>
                                            <td><h6>
                                                {{ $filters.currencyGTQ(item.total)}}
                                            </h6></td>
                                            <td><h6>{{item.nombre_usuario}}</h6></td>
                                            <td><h6>
                                                <div class="d-flex order-actions">
                                                <a v-if="[[item.estado]] == 'A'" :href="this.URL_TENANT+'/compra/detalle_compra/'+item.id"><i  class='bx bxs-edit'></i></a>
                                                </div>
                                            </h6></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Paginacion -->
                        <div class="card-body">
                            <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="javascript:;" @click="cambiarUrl(comprasData.links.previous, 'atras')">Atrás</a>
                                        </li>
                                        <li class="page-item" :class="{active: indexPagination == index}" v-for="index in comprasData.total_pages" :key="index">
                                            <a class="page-link"  href="javascript:;javascript:;" @click="cambiarUrl(this.URL_TENANT+'/api/compra/?page='+index+'', index)">{{ index }}</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="javascript:;" @click="cambiarUrl(comprasData.links.next, 'adelante')">Siguiente</a>
                                        </li>
                                    </ul>
                            </nav>
                        </div>
                        <!-- Fin Paginacion -->
                    </div>

                </div>
            </div>
<!-- Modal Ver Detalle -->
<div class="modal fade" id="detalleCompra" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Detalle de Compra</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="card">
                    <div class="card-body">
                        <table class="table mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th><h6>Producto</h6></th>
                                    <th><h6>Cantidad</h6></th>
                                    <th><h6>Unidad Medida</h6></th>
                                    <th><h6>Precio</h6></th>
                                    <th><h6>Sub Total</h6></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item2 in verdetalleCompra" :key="item2.id">
                                    <td><h6>{{item2.nombre_producto}}</h6></td>
                                    <td><h6>{{item2.cantidad}}</h6></td>
                                    <td><h6>{{item2.nombre_unidad_medida}}</h6></td>
                                    <td><h6>{{ $filters.currencyGTQ(item2.precio) }}</h6></td>
                                    <td><h6>{{ $filters.currencyGTQ(item2.cantidad * item2.precio)}} </h6></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<!-- FIN Modal Ver Detalle -->
    </template>
    <style>
    .btn-common {
        width: 100%;
        height: 100%;
        max-width: 175px; /* Ancho máximo para limitar el crecimiento excesivo en pantallas grandes */
        max-height: 50px; /* Altura máxima para limitar el crecimiento excesivo en pantallas grandes */
    }
    </style>
    <script>
    import  Cookies from 'js-cookie';

    import '@vuepic/vue-datepicker/dist/main.css';
    import add_compra from "./agregar_compra.vue"
    //import { ref } from 'vue';
    console.log(Cookies.get());
    export default {
        name: 'compras_component',
        components:{
            add_compra,
        },
    setup() {
        // In case of a range picker, you'll receive [Date, Date]
        /* const date = ref(new Date());
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        return {
            date,
            format,
        }*/
    },
    data() {
        return{
            //Globales:
            URL_TENANT: '',
            empresaData: [0],
            userData: {},
            clientesData: [0],
            url: "/api/compra/",
            indexPagination: 1,
            productosUnidadMedidaData: [],
            buscarCompra: '',
            comprasData: [],
            verdetalleCompra: [],
            }
        },

    computed: {
        comprasDataFilter() {
            if (this.buscarCompra != ''){
                return this.comprasData.results.filter((item) => {
                return item.numero.toLowerCase().includes(this.buscarCompra.toLowerCase()) || item.nombre_proveedor.toLowerCase().includes(this.buscarCompra.toLowerCase()) || item.nit_proveedor.toLowerCase().includes(this.buscarCompra.toLowerCase())
            })
            }else{
                return this.comprasData.results;
            }
        },
    },
    watch:{
        url: function () {
            this.getcomprasData();
        },
    },
    methods: {
        // Llenar Ver Detalle:
        async setVerDetalle(item){
            this.verdetalleCompra = item;
        },
        //Paginación:
        async cambiarUrl(link, index){
            this.url = link;
            if (index === "atras" && link){
                this.indexPagination = this.indexPagination - 1;
            }
            else if (index === "adelante" && link != null){
                this.indexPagination = this.indexPagination + 1;
            }
            else if (link){
                this.indexPagination = index;
            }
        },
        // Llenado:
        async getUserData(){
            const response = await fetch(this.URL_TENANT+'/api/user/');
            this.userData = await response.json();
        },
        async getEmpresaData() {
            const response = await fetch(this.URL_TENANT+'/api/empresa/');
            this.empresaData = await response.json();
        },
        async getClientesData() {
            const response = await fetch(this.URL_TENANT+'/api/cliente_nit/');
            this.clientesData = await response.json();
        },
        async getcomprasData() {
            const response = await fetch(this.url);
            this.comprasData = await response.json();
        },
        async getProductosUnidadMedida() {
            const response = await fetch(this.URL_TENANT+'/api/producto_unidades_medida/');
            this.productosUnidadMedidaData = await response.json();
        },
        },
    mounted() {
        this.$watch(
            "$refs.child_add.compra_creada",
            () => (this.getcomprasData()),
        );
        this.$watch(
            "$refs.child_add.id_compra_creada",
            () => (window.location.href = this.URL_TENANT+'/compra/detalle_compra/'+this.$refs.child_add.id_compra_creada),
        );
        },
created() {
          // Fetch tasks on page load
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.url = this.URL_TENANT + this.url;
        this.getUserData();
        this.getcomprasData();
        this.getEmpresaData();
        this.getClientesData();
        this.getProductosUnidadMedida();
        },
    }
    </script>