<template>
      <Header/>
      <Nav/>
      <AjusteInventario/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import AjusteInventario from '../../components/inventario/ajuste_inventario/ajuste_inventario.vue'



export default {
  name: 'AjusteInventarioComponent',

  components: {
  Header,
	Nav,
	AjusteInventario,
  },

  data: () => ({
    //
  }),
}
</script>
