
<template>
<div class="page-wrapper" >
    <div class="page-content">
        <!--breadcrumb-->
        <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div class="breadcrumb-title pe-3">Aplicaciones</div>
            <div class="ps-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Calendario</li>
                    </ol>
                </nav>
            </div>

            <div class="ms-auto">
                <div class="btn-group">
                    <add_event ref="child_add"></add_event>
                </div>
            </div>

        </div>
        <!--end breadcrumb-->

    </div>
    <div style="height: 1100px">
        <span v-if="selectedEvent">{{ selectedEvent.title }}</span>

        <vue-cal
        :events="events"
        :on-event-click="onEventClick"
        locale="es"
        >
        <template  #event="{ event }">
        <div :style="{'background-color': event.class}" style="margin-left: -5px; height: 100%; color: #fff;" class="vuecal__event">
        <div  class="vuecal__event-title"  v-html="event.title" />
        <div  class="vuecal__event-title" v-if="event.cliente"  v-html="event.cliente.nombre" />
        <!-- Using Vue Cal Date prototypes (activated by default) -->
        <div class="vuecal__event-title" >
            <span>{{ event.start.formatTime("HH:mm") }} - {{ event.end.formatTime("HH:mm") }}</span><br/>
        </div>
        </div>
    </template>
        </vue-cal>
    </div>
    <!-- Modal -->
    <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal fade"   id="detalleEvento" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content bg-dark">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Detalles del Evento</h5>
                    <button type="button" id="closeDetalle" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <mod_event :id_modificar="id_modificar" v-show="editarDetalle" id="modificar_componente" ref="child_mod"></mod_event>
                    <del_event :id_eliminar="id_eliminar" :titulo="selectedEvent.title" v-show="eliminarDetalle" id="eliminar_component" ref="child_del"></del_event>
                    <div class="row mb-3" v-show="mostrarDetalle">
                        <div class="col-sm-12">
                            <h4 class="mb-2"><span class="dot"></span> {{selectedEvent.title}}</h4>
                        </div>
                        <div class="col-sm-12">
                            <h7 class="mb-0" v-if="selectedEvent.daysCount == 1">
                                {{ selectedEvent.start &&  selectedEvent.start.format('dddd, D MMMM (YYYY)') }}
                                •
                                {{ selectedEvent.start &&  selectedEvent.start.formatTime('HH:mm') }}
                                -
                                {{ selectedEvent.end &&  selectedEvent.end.formatTime('HH:mm') }}
                            </h7>

                            <h7 class="mb-0" v-if="selectedEvent.daysCount > 1">
                                {{ selectedEvent.start &&  selectedEvent.start.format('dddd, D MMMM (YYYY)') }}
                                •
                                {{ selectedEvent.start &&  selectedEvent.start.formatTime('HH:mm') }}
                                -
                                {{ selectedEvent.end &&  selectedEvent.end.format('dddd, D MMMM (YYYY)') }}
                                •
                                {{ selectedEvent.end &&  selectedEvent.end.formatTime('HH:mm') }}
                            </h7>
                        </div>
                        <div class="col-sm-12">
                            <h7 class="mb-0" v-if="selectedEvent.cliente"><strong>Cliente: </strong>{{selectedEvent.cliente.nombre}} {{selectedEvent.cliente.apellido}}</h7>
                        </div>
                        <div class="col-sm-12">
                            <h7 class="mb-0"  v-if="selectedEvent.content"><strong>Detalle: </strong> {{selectedEvent.content}}</h7>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a v-show="editarDetalle" @click="cambiarEditarMostrarDetalle(false, false, true);" class="btn btn-danger">Eliminar Evento</a>
                    <a v-show="eliminarDetalle" @click="$refs.child_del.eliminarEvento();" data-bs-dismiss="modal" class="btn btn-danger">Eliminar</a>
                    <a v-show="editarDetalle" @click="$refs.child_mod.modificarEvento();" data-bs-dismiss="modal" class="btn btn-success">Guardar Cambios</a>
                    <a v-show="mostrarDetalle" @click="cambiarEditarMostrarDetalle(false, true, false); $refs.child_mod.getEventData();" class="btn btn-primary">Modificar Evento</a>
                    <a v-show="editarDetalle" @click="cambiarEditarMostrarDetalle(true, false, false);" class="btn btn-primary">Ver Evento</a>
                    <button type="button" class="btn btn-secondary" @click="cambiarEditarMostrarDetalle(true, false, false);" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- End Modal -->
</div>

</template>

<script>
import  Cookies from 'js-cookie';
//import Select2 from 'vue3-select2-component';
//import $ from 'jquery';
//let select2 = require('@/assets/plugins/select2/js/select2.min.js');
//import * as mykey from '../assets/js/mykey.js'
import $ from 'jquery';
import { Modal } from "bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
//let calendar = require('@/assets/plugins/fullcalendar/js/notification-custom-script.js');
import VueCal from 'vue-cal'
import { ref } from 'vue';
import 'vue-cal/dist/vuecal.css'
//Componentes Propios:
import add_event from "./calendario/agregar_evento.vue"
import mod_event from "./calendario/modificar_evento.vue"
import del_event from "./calendario/eliminar_evento.vue"
console.log(Cookies.get());
export default {
    name: 'categoria_component',
    components:{
        add_event,
        mod_event,
        del_event,
        VueCal,
    },
setup() {
    const date = ref(new Date());
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
        const startTime = ref({ hours: 0, minutes: 0 });
        const first_time = ref({
            hours: new Date().getHours()+1,
            minutes: 0
        });
        const last_time = ref({
            hours: new Date().getHours()+2,
            minutes: 0
        });
        //
        return {
            date,
            format,
            first_time,
            last_time,
            startTime,
        }
},
data() {
	return{
        URL_TENANT: '',
        mostrarDetalle: true,
        editarDetalle: false,
        eliminarDetalle: false,
        desdechild: 0,
        modalShow: true,
        uniqueModal:null,
        selectedEvent: {},
        showDialog: false,
        id_modificar: null,
        id_eliminar: null,
        OpenClose:this.visible,
        clientesData: [],
        categoriasData: [],
        coloresData: [],
        events: [],
        clases_css: [],
        selectedColor: '',
        cla: 'deportes',
        eventoNuevo: {
            titulo: '',
            inicio: '',
            inicio_tiempo: this.first_time,
            fin: '',
            fin_tiempo: this.last_time,
            descripcion: '',
            estado: 'A',
            cliente: '',
            categoria: '',
            color: ''
        },
        empresaData: [0],
        usuariosData: [0],
		}
	},

computed: {
},
watch: {
    eventoNuevo: {
        handler: function () {
            if(this.eventoNuevo.cliente.length > 1){
                this.eventoNuevo.cliente.shift();
            }
            if(this.eventoNuevo.categoria.length > 1){
                this.eventoNuevo.categoria.shift();
            }
        },
        deep: true,
    },
},
methods: {
    async cambiarEditarMostrarDetalle(v1, v2, v3){
        console.log("EDITARRRRRRRR");
        this.mostrarDetalle = v1;
        this.editarDetalle = v2;
        this.eliminarDetalle = v3;
    },
    async agregarEventoNuevo(){
        console.log("AGREGAR EVENTO");
        if (!this.eventoNuevo.inicio  || !this.eventoNuevo.inicio_tiempo || !this.eventoNuevo.fin  || !this.eventoNuevo.fin_tiempo || !this.eventoNuevo.titulo ){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        console.log("NEL EVENTO");

        }else{
            console.log("SI EVENTO");

            try{
                let inicio_ing = this.eventoNuevo.inicio.concat(" ", this.eventoNuevo.inicio_tiempo.hours, ":",this.eventoNuevo.inicio_tiempo.minutes);
                let fin_ing = this.eventoNuevo.fin.concat(" ", this.eventoNuevo.fin_tiempo.hours, ":",this.eventoNuevo.fin_tiempo.minutes);
                console.log("TIEMPO PARA INGRESAR");
                console.log((this.eventoNuevo.cliente))
                console.log((this.eventoNuevo.cliente[0]))
                const formData = new FormData();
                formData.append("inicio", inicio_ing);
                formData.append("fin", fin_ing);
                formData.append("titulo", this.eventoNuevo.titulo);
                formData.append("descripcion", this.eventoNuevo.descripcion);
                formData.append("estado", this.eventoNuevo.estado);
                if(this.eventoNuevo.cliente){formData.append("cliente", this.eventoNuevo.cliente[0]);}
                if(this.eventoNuevo.categoria){formData.append("categoria", this.eventoNuevo.categoria[0]);}
                formData.append("color", this.eventoNuevo.color);
                await fetch(this.URL_TENANT+'/api/evento/',{
                method: 'post',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Nuevo Evento Agregado');
            //document.getElementById('agregarModal').click();
            document.getElementById('close').click();
            this.getEventsData();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }

        }
    },
    async SeleccionarColor(color){
        this.selectedColor = color;
        this.eventoNuevo.color = color;
    },
    onEventClick (event, e) {
        this.cambiarEditarMostrarDetalle(true, false);
        this.uniqueModal = new Modal(document.getElementById("detalleEvento"),{ keyboard: false });
        this.uniqueModal.show();
        this.selectedEvent = event;
        this.id_modificar = event.id;
        this.id_eliminar = event.id;
        this.showDialog = true;
        // Prevent navigating to narrower view (default vue-cal behavior).
        e.stopPropagation()
    },
    async getEventsData() {
        const response = await fetch(this.URL_TENANT+'/api/evento_lista/');
        this.events = await response.json();
        this.events.forEach(object => {
            console.log("TEST");
            console.log(object.color);
            object.class = object.color;
        });
        const response2 = await fetch(this.URL_TENANT+'/api/evento_categoria');
        this.clases_css = await response2.json();
    },
    async getClientesData() {
        const response = await fetch(this.URL_TENANT+'/api/cliente/');
        this.clientesData = await response.json();
    },
    async getCategoriasData() {
        const response = await fetch(this.URL_TENANT+'/api/evento_categoria/');
        this.categoriasData = await response.json();
    },
    async getColoresData() {
        const response = await fetch(this.URL_TENANT+'/api/global_colores/');
        this.coloresData = await response.json();
        const random = Math.floor(Math.random() * this.coloresData.length);
        this.selectedColor =  this.coloresData[random].color;
        this.eventoNuevo.color =  this.coloresData[random].color;
    },
    },
mounted() {
    this.$watch(
        "$refs.child_add.evento_añadido",
        () => (this.getEventsData()),
    );
        this.$watch(
        "$refs.child_mod.evento_modificado",
        () => (this.getEventsData()),
    );
    this.$watch(
        "$refs.child_del.evento_eliminado",
        () => (this.getEventsData()),
    );
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://unpkg.com/vue-next-select/dist/vue-next-select.iife.prod.js')
        document.head.appendChild(recaptchaScript)
    },
created() {
      // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
    this.getEventsData();
    this.getClientesData();
    this.getCategoriasData();
    this.getColoresData();
	},
}

</script>
<style>
body, html {
    font-family: Roboto,Helvetica,Arial,sans-serif;
}
/*
.vuecal__event.leisure {background-color: rgba(253, 156, 66, 0.9);border: 1px solid rgb(233, 136, 46);color: #fff;}
.vuecal__event.sport {background-color: rgba(255, 102, 102, 0.9);border: 1px solid rgb(235, 82, 82);color: #fff;}*/


.dot {
    height: 16px;
    width: 16px;
    background-color: v-bind('selectedEvent.class');;
    border-radius: 50%;
    display: inline-block;
}
.dot2 {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
}
.vuecal__event {cursor: pointer;
    left: calc((100% - 0px) * 0 + 0px);
    width: calc((100% - 0px) * 1);
    position: relative;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: var(--surface);
    margin-top: 0px;
    outline: none;
}
    .vuecal__event-title {
    font-size: 13px;
    margin: 1px 0 0px;
    padding-left: 12px;
    }

    .vuecal__event-time {
    display: inline-block;
    margin-bottom: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    .vuecal__cell {text-align: left;padding: 4px;}
    .vuecal__event-content {
    font-style: italic;
    }
</style>