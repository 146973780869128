<template>
      <Header/>
      <Nav/>
      <Venta/>

</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'
import Venta from './components/venta/venta.vue'



export default {
  name: 'VentaComponent',

  components: {
  Header,
	Nav,
	Venta,
  },

  data: () => ({
    //
  }),
}
</script>
