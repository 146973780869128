
<template>
    <div class="ms-auto"><a href="javascript:;" data-bs-toggle="modal" @click="limpiarInputs()"  data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Crear Nuevo Gasto</a></div>
    <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content bg-dark">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Crear Gasto</h5>
                    <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-sm-2">
                            <h6 class="mb-0">Monto</h6>
                        </div>
                        <div class="col-sm-10">
                            <input ref="inputInModal" type="number" id="monto"  class="form-control" v-model="gasto.monto"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">
                            <h6 class="mb-0">Descripción</h6>
                        </div>
                        <div class="col-sm-10">
                            <textarea  class="form-control"  v-model="gasto.descripcion" placeholder="Agrega una Descripción..." rows="5"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" @click="crearGasto()" class="btn btn-primary">Nuevo Gasto</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>
    import  Cookies from 'js-cookie';
    //import Select2 from 'vue3-select2-component';
    //import $ from 'jquery';
    //let select2 = require('@/assets/plugins/select2/js/select2.min.js');
    //import * as mykey from '../assets/js/mykey.js'
    import $ from 'jquery';
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    //import VueNextSelect from 'vue-next-select';
    import '@vuepic/vue-datepicker/dist/main.css';
    console.log(Cookies.get());
    export default {
        name: 'agregar_gasto_component',
        components:{
            //'vue-select': VueNextSelect,
        },
    setup() {
    },
    data() {
        return{
            //Globales:
            gasto_creada: false,
            id_gasto_creada: '',
            URL_TENANT: '',
            userData: {},
            focusInput: null,
            nameError: [],
            //Crear Venta
            gasto: {
                caja: '',
                monto: '',
                descripcion: '',
            },
            }
        },

    computed: {
    },
    watch:{
    },
    methods: {
            // Validadores:
        validateInputs(){
            this.nameError =  (this.gasto.monto == '') ? ['Debe Ingresar el Monto'] : [];
            this.nameError = (this.gasto.descripcion == '') ? [...this.nameError, 'Debe Ingresar una Descripción'] : this.nameError;
        },
        async limpiarInputs(){
            this.gasto = {
                caja: '',
                monto: '',
                descripcion: '',
            }
            this.gasto_creada = false;
        },
        async getUserData(){
            const response = await fetch(this.URL_TENANT+'/api/user/');
            this.userData = await response.json();
        },
        //Crear Gasto:
        async crearGasto(){
            try{
                this.validateInputs();
                if(this.nameError.length != 0){
                    this.nameError.forEach((element) =>{
                        notificaciones.round_info_noti($.msg=element);
                    });
                }else{
                    const formData = new FormData();
                    console.log(this.userData[0]);
                    console.log(this.userData[0].caja_del_dia);
                    formData.append("caja", this.userData[0].caja_del_dia.id);
                    formData.append("monto", this.gasto.monto);
                    formData.append("descripcion", this.gasto.descripcion);
                    const response = await fetch(this.URL_TENANT+'/api/gasto/',{
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: formData})
                    if(response.ok){
                        const data = await response.json();
                        this.id_gasto_creada = data.id;
                    }
                    notificaciones.round_success_noti($.msg='Gasto Creado Exitosamente');
                    //document.getElementById('agregarModal').click();
                    document.getElementById('close').click();
                    this.gasto_creada = true;
                }
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
        },
        },
mounted() {
    var myModalEl = document.getElementById('agregarModal')
        myModalEl.addEventListener('shown.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.inputInModal.focus();
                this.$refs.inputInModal.select();
            });
        });
    },
beforeUnmount() {
    var myModalEl = document.getElementById('agregarModal')
    myModalEl.removeEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.inputInModal.focus();
        });
    });
},
created() {
        // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
    this.getUserData();
    },
}
    </script>