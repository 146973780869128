<template>
      <Header/>
      <Nav/>
      <ViajeCliente/>
</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import ViajeCliente from '../../components/agencia_viaje/viaje/viaje_cliente.vue'



export default {
  name: 'ViajesComponent',
  components: {
  Header,
	Nav,
	ViajeCliente,
  },

  data: () => ({
    //
  }),
}
</script>
