<template>
      <Header/>
      <Nav/>
      <Medico/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import Medico from '../../components/consultorio_virtual/medico/medico.vue'



export default {
  name: 'MedicoComponent',
  components: {
    Header,
	Nav,
	Medico,
  },

  data: () => ({
    //
  }),
}
</script>
