
<template>
    <div class="ms-auto"><a href="javascript:;" data-bs-toggle="modal" @click="limpiarInputs()"  data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Crear Nueva Venta</a></div>
    <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content bg-dark">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Crear Venta</h5>
                    <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-sm-2">
                            <h6 class="mb-0">NIT</h6>
                        </div>
                        <div class="col-sm-10">
                            <input ref="inputInModal" type="text" id="nit"  class="form-control" v-model="venta.nit"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">
                            <h6 class="mb-0">Nombre</h6>
                        </div>
                        <div class="col-sm-10">
                            <input type="text" id="nombre"  class="form-control" v-model="venta.nombre"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">
                            <h6 class="mb-0">Dirección</h6>
                        </div>
                        <div class="col-sm-10">
                            <input type="text" id="direccion"  class="form-control" v-model="venta.direccion"  />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" @click="crearVenta()" class="btn btn-primary">Nueva Venta</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>
    import  Cookies from 'js-cookie';
    //import Select2 from 'vue3-select2-component';
    //import $ from 'jquery';
    //let select2 = require('@/assets/plugins/select2/js/select2.min.js');
    //import * as mykey from '../assets/js/mykey.js'
    import $ from 'jquery';
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    //import VueNextSelect from 'vue-next-select';
    import '@vuepic/vue-datepicker/dist/main.css';
    console.log(Cookies.get());
    export default {
        name: 'agregar_venta_component',
        components:{
            //'vue-select': VueNextSelect,
        },
    setup() {
    },
    data() {
        return{
            //Globales:
            venta_creada: false,
            id_venta_creada: '',
            URL_TENANT: '',
            cliente: null,
            empresaData: [0],
            usuariosData: [0],
            clientesData: [0],
            focusInput: null,
            //Crear Venta
            venta: {
                nit: 'CF',
                nombre: 'Consumidor Final',
                direccion: 'Ciudad',
            },
            condicion_nit: 0,
            }
        },

    computed: {
    },
    watch:{
        venta: {
            handler: function () {
                console.log("BUSQUEDA IMPLACABLE");
                if (this.venta.nit != ''){
                    console.log("BUSQUEDA IMPLACABLE222");
                    this.clienteEncontrado = this.clientesData.find(element => element.nit === this.venta.nit);
                    console.log(this.clienteEncontrado);
                    if (this.clienteEncontrado){
                        this.condicion_nit = 1;
                        this.cliente = this.clienteEncontrado;
                        this.venta.nombre = this.clienteEncontrado.nombre + ' ' + this.clienteEncontrado.apellido;
                        this.venta.direccion = this.clienteEncontrado.direccion;
                    }
                    if (!this.clienteEncontrado && this.condicion_nit==1){
                        this.cliente = null;
                        this.venta.nombre = '';
                        this.venta.direccion = '';
                        this.condicion_nit = 0;
                    }
                }
                if(this.venta.nit.toUpperCase() == 'CF'){
                    this.cliente = null;
                    this.condicion_nit = 0;
                }
            },
            deep: true,
        },
        url: function () {
            this.getVentasData();
        },
        cliente: function () {
            if(this.cliente){
                notificaciones.round_info_noti($.msg='Se encontró al Cliente: '+this.clienteEncontrado.nombre + ' ' + this.clienteEncontrado.apellido);
            }
        },
    },
    methods: {
        async limpiarInputs(){
            this.venta = {
                nit: 'CF',
                nombre: 'Consumidor Final',
                direccion: 'Ciudad',
            }
            this.getClientesData();
            this.venta_creada = false;
        },
        //Crear Venta:
        async crearVenta(){
            try{
                if(this.venta.nit == ''){
                    this.venta = {
                        nit: 'CF',
                        nombre: 'Consumidor Final',
                        direccion: 'Ciudad',
                    }
                }
                const formData = new FormData();
                formData.append("nit", this.venta.nit);
                formData.append("nombre", this.venta.nombre);
                formData.append("direccion", this.venta.direccion == '' ? 'Ciudad': this.venta.direccion);
                const response = await fetch(this.URL_TENANT+'/api/venta/',{
                method: 'post',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
                if(response.ok){
                    const data = await response.json();
                    this.id_venta_creada = data.id;
                }
                notificaciones.round_success_noti($.msg='Venta Creada Exitosamente');
                //document.getElementById('agregarModal').click();
                document.getElementById('close').click();
                this.venta_creada = true;
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
        },
        // Llenado:
        async getEmpresaData() {
            const response = await fetch(this.URL_TENANT+'/api/empresa/');
            this.empresaData = await response.json();
        },
        async getClientesData() {
            const response = await fetch(this.URL_TENANT+'/api/cliente_nit/');
            this.clientesData = await response.json();
        },
        },
mounted() {
    var myModalEl = document.getElementById('agregarModal')
        myModalEl.addEventListener('shown.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.inputInModal.focus();
                this.$refs.inputInModal.select();
            });
        });
    },
beforeUnmount() {
    var myModalEl = document.getElementById('agregarModal')
    myModalEl.removeEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.inputInModal.focus();
        });
    });
},
created() {
        // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
    this.getEmpresaData();
    this.getClientesData();
    },
}
    </script>