<template>
      <Header/>
      <Nav/>
      <ProductosModificar/>
  
</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'
import ProductosModificar from './components/catalogo/producto/producto_modificar.vue'



export default {
  name: 'ProductosComponent',

  components: {
    Header,
    Nav,
    ProductosModificar,
  },

  data: () => ({
    //
  }),
}
</script>
