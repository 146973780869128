<template>
      <Header/>
      <Nav/>
      <Categoria/>

</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'
import Categoria from './components/catalogo/categoria.vue'



export default {
  name: 'CategoriaComponent',

  components: {
    Header,
	Nav,
	Categoria,
  },

  data: () => ({
    //
  }),
}
</script>
