
<template>
<header>
	<div class="topbar d-flex align-items-center" >
		<nav class="navbar navbar-expand">
			<div class="topbar-logo-header" >
				<div class="">
					<img :src="empresaData[0].logo" class="logo-icon" alt="logo icon">
				</div>
				<div class="">
					<h4 class="logo-text">{{ empresaData[0].nombre }}</h4>
				</div>
			</div>
			<div class="mobile-toggle-menu"><i class='bx bx-menu'></i></div>
			<div class="search-bar flex-grow-1">
				<div class="position-relative search-bar-box">
					<input type="text" class="form-control search-control" placeholder="Escribe para buscars..."> <span class="position-absolute top-50 search-show translate-middle-y"><i class='bx bx-search'></i></span>
					<span class="position-absolute top-50 search-close translate-middle-y"><i class='bx bx-x'></i></span>
				</div>
			</div>
			<div class="top-menu ms-auto" >
				<ul class="navbar-nav align-items-center">
					<li class="nav-item mobile-search-icon">
						<a class="nav-link" href="#">	<i class='bx bx-search'></i>
						</a>
					</li>
					<li class="nav-item dropdown dropdown-large">
						<a class="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">	<i class='bx bx-category'></i>
						</a>
						<div class="dropdown-menu dropdown-menu-end">
							<div class="row row-cols-3 g-3 p-3">
								<div class="col text-center">
									<div class="app-box mx-auto"><i class='bx bx-group'></i>
									</div>
									<div class="app-title">Teams</div>
								</div>
								<div class="col text-center">
									<div class="app-box mx-auto"><i class='bx bx-atom'></i>
									</div>
									<div class="app-title">Projects</div>
								</div>
								<div class="col text-center">
									<div class="app-box mx-auto"><i class='bx bx-shield'></i>
									</div>
									<div class="app-title">Tasks</div>
								</div>
								<div class="col text-center">
									<div class="app-box mx-auto"><i class='bx bx-notification'></i>
									</div>
									<div class="app-title">Feeds</div>
								</div>
								<div class="col text-center">
									<div class="app-box mx-auto"><i class='bx bx-file'></i>
									</div>
									<div class="app-title">Files</div>
								</div>
								<div class="col text-center">
									<div class="app-box mx-auto"><i class='bx bx-filter-alt'></i>
									</div>
									<div class="app-title">Alerts</div>
								</div>
							</div>
						</div>
					</li>
					<li class="nav-item dropdown dropdown-large" v-if="usuarioData.length != 0">
						<a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">7</span>
							<i class='bx bx-bell'></i>
						</a>
						<div class="dropdown-menu dropdown-menu-end">
							<a href="javascript:;">
								<div class="msg-header">
									<p class="msg-header-title">Notifications</p>
									<p class="msg-header-clear ms-auto">Marks all as read</p>
								</div>
							</a>
							<div class="header-notifications-list">
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="notify"><i class="bx bx-group"></i>
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">New Customers<span class="msg-time float-end">14 Sec
										ago</span></h6>
											<p class="msg-info">5 new user registered</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="notify"><i class="bx bx-cart-alt"></i>
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">New Orders <span class="msg-time float-end">2 min
										ago</span></h6>
											<p class="msg-info">You have recived new orders</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="notify"><i class="bx bx-file"></i>
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">24 PDF File<span class="msg-time float-end">19 min
										ago</span></h6>
											<p class="msg-info">The pdf files generated</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="notify"><i class="bx bx-send"></i>
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">Time Response <span class="msg-time float-end">28 min
										ago</span></h6>
											<p class="msg-info">5.1 min avarage time response</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="notify"><i class="bx bx-home-circle"></i>
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">New Product Approved <span
										class="msg-time float-end">2 hrs ago</span></h6>
											<p class="msg-info">Your new product has approved</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="notify"><i class="bx bx-message-detail"></i>
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">New Comments <span class="msg-time float-end">4 hrs
										ago</span></h6>
											<p class="msg-info">New customer comments recived</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="notify"><i class='bx bx-check-square'></i>
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">Your item is shipped <span class="msg-time float-end">5 hrs
										ago</span></h6>
											<p class="msg-info">Successfully shipped your item</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="notify"><i class='bx bx-user-pin'></i>
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">New 24 authors<span class="msg-time float-end">1 day
										ago</span></h6>
											<p class="msg-info">24 new authors joined last week</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="notify"><i class='bx bx-door-open'></i>
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">Defense Alerts <span class="msg-time float-end">2 weeks
										ago</span></h6>
											<p class="msg-info">45% less alerts last 4 weeks</p>
										</div>
									</div>
								</a>
							</div>
							<a href="javascript:;">
								<div class="text-center msg-footer">View All Notifications</div>
							</a>
						</div>
					</li>
					<li class="nav-item dropdown dropdown-large" v-if="usuarioData.length != 0">
						<a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">8</span>
							<i class='bx bx-comment'></i>
						</a>
						<div class="dropdown-menu dropdown-menu-end">
							<a href="javascript:;">
								<div class="msg-header">
									<p class="msg-header-title">Messages</p>
									<p class="msg-header-clear ms-auto">Marks all as read</p>
								</div>
							</a>
							<div class="header-message-list">
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="user-online">
											<img src="" class="msg-avatar" alt="user avatar">
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">Daisy Anderson <span class="msg-time float-end">5 sec
										ago</span></h6>
											<p class="msg-info">The standard chunk of lorem</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="user-online">
											<img src="" class="msg-avatar" alt="user avatar">
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">Althea Cabardo <span class="msg-time float-end">14
										sec ago</span></h6>
											<p class="msg-info">Many desktop publishing packages</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="user-online">
											<img src="" class="msg-avatar" alt="user avatar">
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">Oscar Garner <span class="msg-time float-end">8 min
										ago</span></h6>
											<p class="msg-info">Various versions have evolved over</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="user-online">
											<img src="" class="msg-avatar" alt="user avatar">
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">Katherine Pechon <span class="msg-time float-end">15
										min ago</span></h6>
											<p class="msg-info">Making this the first true generator</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="user-online">
											<img src="" class="msg-avatar" alt="user avatar">
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">Amelia Doe <span class="msg-time float-end">22 min
										ago</span></h6>
											<p class="msg-info">Duis aute irure dolor in reprehenderit</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="user-online">
											<img src="" class="msg-avatar" alt="user avatar">
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">Cristina Jhons <span class="msg-time float-end">2 hrs
										ago</span></h6>
											<p class="msg-info">The passage is attributed to an unknown</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="user-online">
											<img src="" class="msg-avatar" alt="user avatar">
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">James Caviness <span class="msg-time float-end">4 hrs
										ago</span></h6>
											<p class="msg-info">The point of using Lorem</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="user-online">
											<img src="" class="msg-avatar" alt="user avatar">
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">Peter Costanzo <span class="msg-time float-end">6 hrs
										ago</span></h6>
											<p class="msg-info">It was popularised in the 1960s</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="user-online">
											<img src="" class="msg-avatar" alt="user avatar">
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">David Buckley <span class="msg-time float-end">2 hrs
										ago</span></h6>
											<p class="msg-info">Various versions have evolved over</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="user-online">
											<img src="" class="msg-avatar" alt="user avatar">
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">Thomas Wheeler <span class="msg-time float-end">2 days
										ago</span></h6>
											<p class="msg-info">If you are going to use a passage</p>
										</div>
									</div>
								</a>
								<a class="dropdown-item" href="javascript:;">
									<div class="d-flex align-items-center">
										<div class="user-online">
											<img src="" class="msg-avatar" alt="user avatar">
										</div>
										<div class="flex-grow-1">
											<h6 class="msg-name">Johnny Seitz <span class="msg-time float-end">5 days
										ago</span></h6>
											<p class="msg-info">All the Lorem Ipsum generators</p>
										</div>
									</div>
								</a>
							</div>
							<a href="javascript:;">
								<div class="text-center msg-footer">View All Messages</div>
							</a>
						</div>
					</li>
				</ul>
			</div>
			<div class="user-box dropdown" v-if="usuarioData.length != 0">
				<a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
					<img :src="usuarioData[0].imagen" class="user-img" alt="user avatar">
					<div class="user-info ps-3">
						<p class="user-name mb-0">{{usuarioData[0].first_name}} {{usuarioData[0].last_name}}</p>
						<p class="designattion mb-0">{{usuarioData[0].puesto_nombre}} - {{usuarioData[0].departamento_nombre}}</p>
					</div>
				</a>
				<ul class="dropdown-menu dropdown-menu-end">
					<li><a class="dropdown-item" href="/empresa/perfil_usuario/"><i class="bx bx-user"></i><span>Perfil</span></a>
					</li>
					<li><a class="dropdown-item" href="/usuarios/mis_aplicaciones"><i class="bx bx-cog"></i><span>Mis Aplicaciones</span></a>
					</li>
					<li><a class="dropdown-item" href="javascript:;"><i class='bx bx-home-circle'></i><span>Dashboard</span></a>
					</li>
					<li><a class="dropdown-item" href="javascript:;"><i class='bx bx-dollar-circle'></i><span>Earnings</span></a>
					</li>
					<li><a class="dropdown-item" href="javascript:;"><i class='bx bx-download'></i><span>Downloads</span></a>
					</li>
					<li>
						<div class="dropdown-divider mb-0"></div>
					</li>
					<li><a class="dropdown-item" href="/logout/"><i class='bx bx-log-out-circle'></i><span>Cerrar Sesión</span></a>
					</li>
				</ul>
			</div>
			<div v-if="usuarioData.length == 0">
				<a href="/login/" class="btn btn-light">Iniciar Sesión</a>
			</div>
		</nav>
	</div>
</header>
</template>

<script>
//import * as mykey from '../assets/js/mykey.js'
export default {
    name: 'HeaderComponent',
data: () => ({
    empresaData: [0],
    usuarioData: [0],
	URL_TENANT: '',
	update: '',
	}
),
watch:{
	update: {
            handler: function () {
                this.getEmpresaData()
            },
            deep: true,
        },
},
methods: {
    async getEmpresaData() {
        const response = await fetch(this.URL_TENANT+'/api/navyapp/');
        this.empresaData = await response.json();
          //console.log(userData);
    },
	async getUsuarioData() {
		try {
		const response = await fetch(this.URL_TENANT+'/api/user/');
		if (!response.ok) {
			this.usuarioData = [];
		}
		else{
			this.usuarioData = await response.json();
		}
	} catch (error) {
		console.error(error);
		// manejar el error aquí
	}
    },
},
created() {
	const result = window.location.origin;
    this.URL_TENANT = result;
      // Fetch tasks on page load
    this.getEmpresaData();
	this.getUsuarioData();
	}
}
</script>
