
<template>
    <div class="page-wrapper">
                <div class="page-content">
                    <!--breadcrumb-->
                    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div class="breadcrumb-title pe-3">Reportes</div>
                        <div class="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0 p-0">
                                    <li class="breadcrumb-item"><a href="javascript:;"><i class='bx bxs-report'></i></a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">Kardex</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <!--end breadcrumb-->
                    <div class="card">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <!-- Columna para búsqueda -->
                                <div class="col-md-6 mb-3">
                                    <label class="form-label mb-2"><h6>Selecciona el rango de Fechas para mostrar o descargar el Kardex</h6></label>
                                </div>

                                <!-- Columna para Fecha Inicio -->
                                <div class="col-md-2 mb-3">
                                    <label class="form-label mb-2"><h6>Fecha Fin:</h6></label>
                                    <!-- Añade aquí tu Datepicker -->
                                    <Datepicker
                                        v-model="filtro.fecha_inicio"
                                        dark placeholder="Fecha Inicio"
                                        autoApply
                                        :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                        :enableTimePicker="false"
                                        monthNameFormat="long"
                                        :format="format"
                                        modelType="dd-MM-yyyy"
                                        locale="es">
                                    </Datepicker>
                                </div>

                                <!-- Columna para Fecha Fin -->
                                <div class="col-md-2 mb-3">
                                    <label class="form-label mb-2"><h6>Fecha Fin:</h6></label>
                                    <!-- Añade aquí tu Datepicker -->
                                    <Datepicker
                                        v-model="filtro.fecha_fin"
                                        dark placeholder="Fecha Fin"
                                        autoApply
                                        :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                        :enableTimePicker="false"
                                        monthNameFormat="long"
                                        :format="format"
                                        modelType="dd-MM-yyyy"
                                        locale="es">
                                    </Datepicker>
                                </div>
                                <!-- Columna para Buscar -->
                                <div class="col-md-2 mb-3">
                                    <button @click="descargarKardex()" class="btn btn-light w-100 mb-2"><i class='bx bxs-download'></i> Descargar Excel</button>
                                    <button @click="getKardex()" class="btn btn-success w-100">Mostrar</button>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th><h6>Fecha</h6></th>
                                            <th><h6>Operación</h6></th>
                                            <th><h6>Entrada</h6></th>
                                            <th><h6>Salida</h6></th>
                                            <th><h6>Saldo</h6></th>
                                            <th><h6>Producto</h6></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="itemProd in kardexData" :key="itemProd.id">
                                            <td><h6>
                                                <label>{{itemProd.fecha}}</label>
                                            </h6></td>
                                            <td><h6>
                                                <label>{{itemProd.detalle}}</label>
                                            </h6></td>
                                            <td><h6>
                                                <label>{{itemProd.entrada}}</label>
                                            </h6></td>
                                            <td><h6>
                                                <label>{{itemProd.salida}}</label>
                                            </h6></td>
                                            <td><h6>
                                                <label>{{itemProd.saldo}}</label>
                                            </h6></td>
                                            <td><h6>
                                                <label>{{itemProd.nombre_producto}}</label>
                                            </h6></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>
<style>
</style>
<script>
    import  Cookies from 'js-cookie';
    console.log(Cookies.get());
    import { ref } from 'vue';
    import '@vuepic/vue-datepicker/dist/main.css';
    import Datepicker from '@vuepic/vue-datepicker';
    export default {
        name: 'kardex_component',
        components:{
            Datepicker,
        },
        setup() {
        const date = ref(new Date());
            function formatDate(userDate) {
            // format from M/D/YYYY to YYYYMMDD
            return (new Date(userDate).toJSON().slice(0,10).split('-').reverse().join('-'));
            }
            const format = (date) => {
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
            const startTime = ref({ hours: 0, minutes: 0 });
            const first_time = ref({
                hours: new Date().getHours()+1,
                minutes: 0
            });
            const last_time = ref({
                hours: new Date().getHours()+2,
                minutes: 0
            });
            //const today_date = ref(new Date().toISOString().split('T')[0]);
            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            var today_is = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
            //var today_dat = new Date().toLocaleString("en-US", {timeZone: "America/New_York"})
            //console.log("TIEMPO: "+ formatDate(today_date));
            var today_date = formatDate(today_is);
            //
            return {
                date,
                format,
                first_time,
                last_time,
                startTime,
                today_date,
            }
    },
    data() {
        return{
            URL_TENANT: '',
            kardexData: [],
            busqueda: '',
            filtro: {
                codigo: '',
                fecha_inicio: this.today_date,
                fecha_fin: this.today_date
            }
            }
        },
    watch: {
        },
    computed: {
    },
    methods: {
        descargarKardex() {
            const url = this.URL_TENANT+'/kardex/'+this.filtro.fecha_inicio+'/'+this.filtro.fecha_fin+'/';
            window.open(url, '_blank'); // Esto abrirá la URL en una nueva pestaña
        },
        async getKardex() {
        const response = await fetch(this.URL_TENANT+'/api/kardex/'+this.filtro.fecha_inicio+'/'+this.filtro.fecha_fin+'/');
        this.kardexData = await response.json();
    },
        },
        mounted() {
    },
    created() {
          // Fetch tasks on page load
        const result = window.location.origin;
        this.URL_TENANT = result;
        },
    }
    </script>