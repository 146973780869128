<template>
      <Header/>
      <Nav/>
      <Caja/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import Caja from '../../components/empresa/caja/caja.vue'



export default {
  name: 'CajaComponent',

  components: {
  Header,
	Nav,
	Caja,
  },

  data: () => ({
    //
  }),
}
</script>
