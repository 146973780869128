
<template>
    <div class="ms-auto"><a href="javascript:;" data-bs-toggle="modal" @click="limpiarInputs()"  data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Crear Nueva Devolución</a></div>
    <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content bg-dark">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Crear Devolución</h5>
                    <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!-- Modal -->
                    <div class="row mb-3">
                        <div class="col-sm-2">
                            <h6 class="mb-0">Número de Venta</h6>
                        </div>
                        <div class="col-sm-10">
                            <input ref="inputInModal" type="number" id="venta"  class="form-control" v-model="devolucion.venta"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">
                            <h6 class="mb-0">Tipo</h6>
                        </div>
                        <div class="col-sm-10">
                            <select v-model="devolucion.tipo" required class="form-select form-control mb-3" aria-label="Default select example">
                                <option selected="">Seleccione el Tipo de Devolución</option>
                                <option value="DINERO">Devolución por Dinero</option>
                                <option value="PRODUCTO">Devolución por Producto</option>
							</select>
                        </div>
                    </div>
                    <hr/>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" @click="crearDevolucion()" class="btn btn-primary">Nueva Devolución</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>
    import  Cookies from 'js-cookie';
    //import Select2 from 'vue3-select2-component';
    //import $ from 'jquery';
    //let select2 = require('@/assets/plugins/select2/js/select2.min.js');
    //import * as mykey from '../assets/js/mykey.js'
    import $ from 'jquery';
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    //import VueNextSelect from 'vue-next-select';
    import '@vuepic/vue-datepicker/dist/main.css';
    console.log(Cookies.get());
    export default {
        name: 'agregar_devolucion_component',
        components:{
            //'vue-select': VueNextSelect,
        },
    setup() {
    },
    data() {
        return{
            //Globales:
            devolucion_creada: false,
            id_devolucion_creada: '',
            URL_TENANT: '',
            cliente: null,
            empresaData: [0],
            usuariosData: [0],
            focusInput: null,
            //Crear Devolucion
            devolucion: {
                venta: '',
                tipo: ''
            },
            condicion_nit: 0,
            }
        },

    computed: {
    proveedorDataFilter() {
        if (this.busqueda != ''){
            return this.proveedoresData.filter((item) => {
                return  item.nombre.toLowerCase().includes(this.busqueda.toLowerCase()) ||  item.nit.toLowerCase().includes(this.busqueda.toLowerCase())
        })
        }else{
            return [];
        }
    },
},
    watch:{
    },
    methods: {
        async limpiarInputs(){
            this.devolucion = {
                venta: '',
                tipo: ''
            }
            this.devolucion_creada = false;
        },
        // Validadores:
        validateInputs(){
                this.nameError =  (this.devolucion.venta == '') ? ['Debe Ingresar Número de Venta'] : [];
                this.nameError = (this.devolucion.tipo == '') ? [...this.nameError, 'Debe Seleccionar Tipo de Devolución'] : this.nameError;
            },
        //Crear Devolucion:
        async crearDevolucion(){
            this.validateInputs();
            if(this.nameError.length != 0){
            console.log("NAME ERROR");
            this.nameError.forEach((element) =>{
                notificaciones.round_info_noti($.msg=element);
            });
        }else{
            try{
                const response_venta = await fetch(this.URL_TENANT+'/api/venta/?numero='+this.devolucion.venta);
                const id_venta = await response_venta.json();
                const formData = new FormData();
                console.log("ID_VENTA");
                console.log(id_venta);
                formData.append("venta", id_venta.results[0].id);
                formData.append("tipo", this.devolucion.tipo);
                const response = await fetch(this.URL_TENANT+'/api/devolucion/',{
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: formData})
                    if(response.ok){
                        const data = await response.json();
                        this.id_devolucion_creada = data.id;
                        notificaciones.round_success_noti($.msg='Devolución Creada Exitosamente');
                        document.getElementById('close').click();
                        setInterval(function(){this.devolucion_creada = true; },2000);
                    }else {
                        switch(response.status){
                            case 400:
                                notificaciones.round_error_noti($.msg='Número de Venta No Existe');
                                break;
                            // Agrega más casos si es necesario
                            default:
                                notificaciones.round_error_noti($.msg='Error Desconocido');
                                break;
                        }
                    }
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
            }
        },
        },
mounted() {
    var myModalEl = document.getElementById('agregarModal')
        myModalEl.addEventListener('shown.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.inputInModal.focus();
                this.$refs.inputInModal.select();
            });
        });
    },
beforeUnmount() {
    var myModalEl = document.getElementById('agregarModal')
    myModalEl.removeEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.inputInModal.focus();
        });
    });
},
created() {
        // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
    },
}
    </script>