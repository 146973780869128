
<template>
    <div class="page-wrapper">
                <div class="page-content">
                    <!--breadcrumb-->
                    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div class="breadcrumb-title pe-3">Ventas</div>
                        <div class="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0 p-0">
                                    <li class="breadcrumb-item"><a href="javascript:;"><i class='bx bx-money'></i></a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">Pagos</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <!--end breadcrumb-->
                    <div class="card">
                        <div class="card-body">
                            <div class="d-lg-flex align-items-center mb-4 gap-3">
                                <div class="position-relative">
                                    <input type="text" class="form-control ps-5 radius-30" v-model="buscarPago" placeholder="Buscar Pagos"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                                </div>
                                <div v-if="userData && userData[0] && !userData[0].caja_del_dia" class="ms-auto">
                                    <span class="badge bg-warning text-dark"><i  class='bx bxs-lock-alt'></i>No Has Aperturado la Caja</span>
                                </div>
                                <div v-show="userData && userData[0] && userData[0].caja_del_dia" class="ms-auto">
                                    <add_pago ref="child_add"></add_pago>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th><h6>Fecha</h6></th>
                                            <th><h6>Cliente</h6></th>
                                            <th><h6>Número de Venta</h6></th>
                                            <th><h6>Monto</h6></th>
                                            <th><h6>Usuario</h6></th>
                                            <th><h6>Acción</h6></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in pagosDataFilter" :key="item.id">
                                            <td ><h6>
                                                {{ item.fecha_pago }}</h6>
                                            </td>
                                            <td><h6>{{item.nit_venta}}</h6></td>
                                            <td><h6>{{item.numero_venta}}</h6></td>
                                            <td><h6>{{$filters.currencyGTQ(item.monto)}}</h6></td>
                                            <td><h6>{{item.nombre_usuario}}</h6></td>
                                            <td><h6>
                                                <div class="d-flex order-actions">
                                                    <a href="javascript:;" v-if="!item.es_caja_cerrada_o_pago_anterior" data-bs-toggle="modal" @click="pagoEliminar(item)" data-bs-target="#EliminarPago" class="ms-3"><i class='bx bxs-trash'></i></a>
                                                </div></h6>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Paginacion -->
                        <div class="card-body">
                            <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="javascript:;" @click="cambiarUrl(pagosData.links.previous, 'atras')">Atrás</a>
                                        </li>
                                        <li class="page-item" :class="{active: indexPagination == index}" v-for="index in pagosData.total_pages" :key="index">
                                            <a class="page-link"  href="javascript:;javascript:;" @click="cambiarUrl(this.URL_TENANT+'/api/pago_credito/?page='+index+'', index)">{{ index }}</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="javascript:;" @click="cambiarUrl(pagosData.links.next, 'adelante')">Siguiente</a>
                                        </li>
                                    </ul>
                            </nav>
                        </div>
                        <!-- Fin Paginacion -->
                    </div>

                </div>
            </div>
<!-- Modal Ver Detalle -->
<div class="modal fade" id="EliminarPago" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Detalle de Venta</h5>
                <button type="button" id="close_delete" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="card">
                    <div class="card-body">
                        ¿Desea Eliminar el Pago al Crédito de la Venta {{ pagoEliminarData.numero_venta }} de {{ $filters.currencyGTQ(pagoEliminarData.monto) }}?
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-danger" @click="eliminarPago();" data-bs-dismiss="modal">Eliminar</button>
            </div>
        </div>
    </div>
</div>
<!-- FIN Modal Ver Detalle -->
    </template>
    <style>
    .btn-common {
        width: 100%;
        height: 100%;
        max-width: 175px; /* Ancho máximo para limitar el crecimiento excesivo en pantallas grandes */
        max-height: 50px; /* Altura máxima para limitar el crecimiento excesivo en pantallas grandes */
    }
    </style>
    <script>
    import  Cookies from 'js-cookie';
    import $ from 'jquery';
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    import '@vuepic/vue-datepicker/dist/main.css';
    import add_pago from "./agregar_pago.vue"
    //import { ref } from 'vue';
    console.log(Cookies.get());
    export default {
        name: 'pagos_component',
        components:{
            add_pago,
        },
    setup() {

    },
    data() {
        return{
            //Globales:
            URL_TENANT: '',
            userData: {},
            url: "/api/pago_credito/",
            indexPagination: 1,
            buscarPago: '',
            pagosData: [],
            pagoEliminarData: '',
            }
        },

    computed: {
        pagosDataFilter() {
            if (this.buscarPago != ''){
                return this.pagosData.results.filter((item) => {
                return item.numero_venta.toLowerCase().includes(this.buscarPago.toLowerCase()) || item.nit_venta.toLowerCase().includes(this.buscarPago.toLowerCase())
            })
            }else{
                return this.pagosData.results;
            }
        },
    },
    watch:{
        url: function () {
            this.getPagosData();
        },
    },
    methods: {
        async eliminarPago(){
            await fetch(this.URL_TENANT+'/api/pago_credito/'+this.pagoEliminarData.id+'/',{
            method: 'DELETE',
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
            },})
            notificaciones.round_success_noti($.msg='Pago Eliminado Exitosamente');
            this.getPagosData();
            document.getElementById('close_delete').click();
        },
        async pagoEliminar(item){
            this.pagoEliminarData = item;
        },
        //Paginación:
        async cambiarUrl(link, index){
            this.url = link;
            if (index === "atras" && link){
                this.indexPagination = this.indexPagination - 1;
            }
            else if (index === "adelante" && link != null){
                this.indexPagination = this.indexPagination + 1;
            }
            else if (link){
                this.indexPagination = index;
            }
        },
        // Llenado:
        async getPagosData() {
            const response = await fetch(this.url);
            this.pagosData = await response.json();
        },
        async getUserData(){
		const response = await fetch(this.URL_TENANT+'/api/user/');
		this.userData = await response.json();
	},
        },
    mounted() {
        console.log("MOUNTED");
        this.$watch(
            "$refs.child_add.pago_creada",
            () => (this.getPagosData()),
        );
        },
created() {
          // Fetch tasks on page load
        console.log("CREATED");

        const result = window.location.origin;
        this.URL_TENANT = result;
        this.url = this.URL_TENANT + this.url
        this.getUserData();
        this.getPagosData();
        },
    }
    </script>