<template>
<div class="page-wrapper">
            <div class="page-content">
                <!--breadcrumb-->
                <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div class="breadcrumb-title pe-3">Expediente</div>
                    <div class="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb mb-0 p-0">
                                <li class="breadcrumb-item"><a href="javascript:;"><i class='bx bx-plus-medical'></i></a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Detalle del Expediente</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="ms-auto">
                        <div class="btn-group">
                            <button type="button" class="btn btn-light">Configuración</button>
                            <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span class="visually-hidden">Toggle Dropdown</span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	<a class="dropdown-item" href="/empresa/usuarios">Administrar Usuarios</a>
                                <a class="dropdown-item" href="javascript:;">Another action</a>
                                <a class="dropdown-item" href="javascript:;">Something else here</a>
                                <div class="dropdown-divider"></div>	<a class="dropdown-item" href="javascript:;">Separated link</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end breadcrumb-->
                    <div class="main-body">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex flex-column align-items-center text-center">
                                            <div class="mt-3">
                                                <h3>{{ expedienteData.numero_expediente }}</h3>
                                                <h4>{{ expedienteData.nombre_completo }}</h4>
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                        <ul class="list-group list-group-flush" >
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h8 class="mb-0"><i class="bx bx-id-card">&nbsp;</i>Nit</h8>
                                                <span class="text-white">{{ expedienteData.nit }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h8 class="mb-0"><i class="bx bx-phone">&nbsp;</i>Teléfono</h8>
                                                <span class="text-white">{{ expedienteData.telefono }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h8 class="mb-0"><i class="bx bx-mobile">&nbsp;</i>Celular</h8>
                                                <span class="text-white">{{ expedienteData.celular }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h8 class="mb-0"><i class="bx bx-map">&nbsp;</i>Dirección</h8>
                                                <span class="text-white">{{ expedienteData.direccion }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h8 class="mb-0"><i class="bx bx-mail-send">&nbsp;</i>Correo</h8>
                                                <span class="text-white">{{ expedienteData.correo }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h8 class="mb-0"><i class="bx bx-calendar">&nbsp;</i>Fecha Nacimiento</h8>
                                                <span class="text-white">{{ expedienteData.fecha_nacimiento }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h8 class="mb-0"><i class="bx bx-location-plus">&nbsp;</i>Lugar de Nacimiento</h8>
                                                <span class="text-white">{{ expedienteData.lugar_nacimiento }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h8 class="mb-0"><i class="bx bx-current-location">&nbsp;</i>Departamento de Recidencia</h8>
                                                <span class="text-white">{{ expedienteData.departamento }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h8 class="mb-0"><i class="bx bx-current-location">&nbsp;</i>Municipio de Recidencia</h8>
                                                <span class="text-white">{{ expedienteData.municipio }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h8 class="mb-0"><i class="bx bx-plus-medical">&nbsp;</i>Médico Asignado</h8>
                                                <span class="text-white">{{ expedienteData.medico_nombre }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link active" data-bs-toggle="tab" href="#antecedentes" role="tab" aria-selected="true">
                                                    <div class="d-flex align-items-center">
                                                        <div class="tab-icon"><i class='bx bx-pulse font-18 me-1'></i>
                                                        </div>
                                                        <div class="tab-title">Antecedentes</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" data-bs-toggle="tab" @click="getExploracionFisicaData()" href="#exploracion_fisica" role="tab" aria-selected="false">
                                                    <div class="d-flex align-items-center">
                                                        <div class="tab-icon"><i class='bx bx-body font-18 me-1'></i>
                                                        </div>
                                                        <div class="tab-title">Exploración Física</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" data-bs-toggle="tab" @click="getInterrogatorioData()" href="#interrogatorio" role="tab" aria-selected="false">
                                                    <div class="d-flex align-items-center">
                                                        <div class="tab-icon"><i class='bx bx-question-mark font-18 me-1'></i>
                                                        </div>
                                                        <div class="tab-title">Interrogatorio</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" data-bs-toggle="tab" @click="getEstiloDeVidaData()" href="#estilo_de_vida" role="tab" aria-selected="false">
                                                    <div class="d-flex align-items-center">
                                                        <div class="tab-icon"><i class='bx bx-heart-circle font-18 me-1'></i>
                                                        </div>
                                                        <div class="tab-title">Estilo de Vida</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" data-bs-toggle="tab" @click="getAlergiasData()" href="#alergias" role="tab" aria-selected="false">
                                                    <div class="d-flex align-items-center">
                                                        <div class="tab-icon"><i class='bx bx-meh font-18 me-1'></i>
                                                        </div>
                                                        <div class="tab-title">Alergias</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" data-bs-toggle="tab" @click="getIncapacidadesData()" href="#incapacidad" role="tab" aria-selected="false">
                                                    <div class="d-flex align-items-center">
                                                        <div class="tab-icon"><i class='bx bx-user-minus font-18 me-1'></i>
                                                        </div>
                                                        <div class="tab-title">Incapacidad</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" data-bs-toggle="tab" @click="getDocumentosData()" href="#documentos" role="tab" aria-selected="false">
                                                    <div class="d-flex align-items-center">
                                                        <div class="tab-icon"><i class='bx bx-file font-18 me-1'></i>
                                                        </div>
                                                        <div class="tab-title">Documentos</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" data-bs-toggle="tab" @click="getEstadoDeCuenta()" href="#estado_cuenta" role="tab" aria-selected="false">
                                                    <div class="d-flex align-items-center">
                                                        <div class="tab-icon"><i class='bx bx-money font-18 me-1'></i>
                                                        </div>
                                                        <div class="tab-title">Estado de Cuenta</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" data-bs-toggle="tab" @click="$refs.child_consultas.getConsultasPacienteData(); " href="#consultas" role="tab" aria-selected="false">
                                                    <div class="d-flex align-items-center">
                                                        <div class="tab-icon"><i class='bx bx-calendar font-18 me-1'></i>
                                                        </div>
                                                        <div class="tab-title">Consultas</div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                        </div>
                                        <!-- Inside Panel Principal -->
                                        <div class="tab-content py-3">
                                            <!-- Inside Panel Antecedentes -->
                                            <div class="tab-pane fade show active" id="antecedentes" role="tabpanel">
                                                <div class="row">
                                                    <!-- Links -->
                                                    <div class="col-3">
                                                        <ul class="nav flex-column nav-tabs text-center" role="tablist">
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link active" @click="getAntecedentesHereditariosData()" data-bs-toggle="tab" href="#hereditarios" role="tab" aria-selected="true">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Hereditarios</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" @click="getAntecedentesPatologicosData()" data-bs-toggle="tab" href="#patologicos" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Patológicos</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" @click="getAntecedentesNoPatologicosData()" data-bs-toggle="tab" href="#no_patologicos" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">No Patológicos</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" @click="getAntecedentesPerinatalesData()" data-bs-toggle="tab" href="#perinatales" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Perinatales</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" @click="getAntecedentesGinecologicosData()" href="#ginecologicos" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Ginecológicos</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" @click="getAntecedentesObstetricosData()" href="#obstetricos" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Obstétricos</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <!-- End Links -->

                                                    <!-- Content -->
                                                    <div class="col-9">
                                                        <div class="tab-content py-3">
                                                            <div class="tab-pane fade show active" id="hereditarios" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Antecedentes Hereditarios</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarAntecedentesHereditarios()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <table class="table mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">Enfermedad</th>
                                                                                        <th scope="col">Madre</th>
                                                                                        <th scope="col">Padre</th>
                                                                                        <th scope="col">Abuelos</th>
                                                                                        <th scope="col">Hermanos</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="item in antecedentesHereditariosData" :key="item.id">
                                                                                        <th scope="row">{{item.enfermedad_nombre}}</th>
                                                                                        <td>
                                                                                            <input class="form-check-input" type="checkbox" v-model="item.madre">
                                                                                        </td>
                                                                                        <td><input class="form-check-input" type="checkbox" v-model="item.padre"></td>
                                                                                        <td><input class="form-check-input" type="checkbox" v-model="item.abuelos"></td>
                                                                                        <td><input class="form-check-input" type="checkbox" v-model="item.hermanos"></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="patologicos" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Antecedentes Patológicos</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarAntecedentesPatologicos()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <textarea  class="form-control" v-if="antecedentesPatologicosData[0]" v-model="antecedentesPatologicosData[0].descripcion" placeholder="Antecedentes Patológicos..." rows="15"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="no_patologicos" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Antecedentes No Patológicos</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarAntecedentesNoPatologicos()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Tipo de Sangre</h7>
                                                                                </div>
                                                                                <div class="col-sm-9">
                                                                                    <vue-select  :options="tipo_sangre"
                                                                                        @blur="removeObjectArray()"
                                                                                        v-if="antecedentesNoPatologicosData[0]"
                                                                                        v-model="antecedentesNoPatologicosData[0].tipo_sangre"
                                                                                        label-by="tipo"
                                                                                        track-by="tipo"
                                                                                        value-by="tipo"
                                                                                        search-placeholder="Selecciona un Tipo de Sangre"
                                                                                        multiple
                                                                                        collapse-tags="true"
                                                                                        taggable
                                                                                        searchable
                                                                                        placeholder="Selecciona un Tipo de Sangre"
                                                                                        close-on-select>
                                                                                        <template #label="{ selected }">
                                                                                        <template v-if="selected">
                                                                                            <div style="color:white;">{{ selected.tipo }}</div>
                                                                                        </template>
                                                                                        <template v-else > <div style="color:white;">Seleccione un Tipo de Sangre</div></template>
                                                                                        </template>
                                                                                        <template #dropdown-item="{ option }">
                                                                                        <div style="color:white;">{{ option.tipo }}</div>
                                                                                        </template>
                                                                                    </vue-select>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Alcoholismo</h7>
                                                                                </div>
                                                                                <div class="col-sm-9">
                                                                                    <vue-select  :options="tipo_antecedente"
                                                                                        v-if="antecedentesNoPatologicosData[0]"
                                                                                        @blur="removeObjectArray()"
                                                                                        v-model="antecedentesNoPatologicosData[0].alcoholismo"
                                                                                        label-by="tipo_antecedente"
                                                                                        track-by="tipo_antecedente"
                                                                                        value-by="tipo_antecedente"
                                                                                        search-placeholder="Selecciona una Opción"
                                                                                        multiple
                                                                                        collapse-tags="true"
                                                                                        taggable
                                                                                        searchable
                                                                                        placeholder="Selecciona una Opción"
                                                                                        close-on-select>
                                                                                        <template #label="{ selected }">
                                                                                        <template v-if="selected">
                                                                                            <div style="color:white;">{{ selected.tipo_antecedente }}</div>
                                                                                        </template>
                                                                                        <template v-else > <div style="color:white;">Seleccione una Opción</div></template>
                                                                                        </template>
                                                                                        <template #dropdown-item="{ option }">
                                                                                        <div style="color:white;">{{ option.tipo_antecedente }}</div>
                                                                                        </template>
                                                                                    </vue-select>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Tabaquismo</h7>
                                                                                </div>
                                                                                <div class="col-sm-9">
                                                                                    <vue-select  :options="tipo_antecedente"
                                                                                        @blur="removeObjectArray()"
                                                                                        v-if="antecedentesNoPatologicosData[0]"
                                                                                        v-model="antecedentesNoPatologicosData[0].tabaquismo"
                                                                                        label-by="tipo_antecedente"
                                                                                        track-by="tipo_antecedente"
                                                                                        value-by="tipo_antecedente"
                                                                                        search-placeholder="Selecciona una Opción"
                                                                                        multiple
                                                                                        collapse-tags="true"
                                                                                        taggable
                                                                                        searchable
                                                                                        placeholder="Selecciona una Opción"
                                                                                        close-on-select>
                                                                                        <template #label="{ selected }">
                                                                                        <template v-if="selected">
                                                                                            <div style="color:white;">{{ selected.tipo_antecedente }}</div>
                                                                                        </template>
                                                                                        <template v-else > <div style="color:white;">Seleccione una Opción</div></template>
                                                                                        </template>
                                                                                        <template #dropdown-item="{ option }">
                                                                                        <div style="color:white;">{{ option.tipo_antecedente }}</div>
                                                                                        </template>
                                                                                    </vue-select>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-12">
                                                                                    <h7 class="mb-0">Descripción</h7>
                                                                                </div>
                                                                            </div>

                                                                            <div class="row mb-3">
                                                                                <textarea  class="form-control" v-if="antecedentesNoPatologicosData[0]" v-model="antecedentesNoPatologicosData[0].descripcion" placeholder="Antecedentes No Patológicos..." rows="15"></textarea>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="perinatales" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Antecedentes Perinatales</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarAntecedentesPerinatales()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Hora de Nacimiento:</h7>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="col-sm-12">
                                                                                        <Datepicker v-if="antecedentesPerinatalesData[0]"  v-model="antecedentesPerinatalesData[0].hora_nacimiento" :startTime="startTime" timePicker dark  minutesIncrement="1" />
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Tipo de Parto:</h7>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" v-if="antecedentesPerinatalesData[0]" value="Parto Normal" type="radio" name="tipo_parto" v-model="antecedentesPerinatalesData[0].tipo_parto" id="tipo_parto1">
                                                                                        <label class="form-check-label" for="tipo_parto">Parto Normal</label>
                                                                                    </div>
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" v-if="antecedentesPerinatalesData[0]" value="Cesarea" type="radio" name="tipo_parto"  v-model="antecedentesPerinatalesData[0].tipo_parto" id="tipo_parto2">
                                                                                        <label class="form-check-label" for="tipo_parto">Cesárea</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">No. de Gestación:</h7>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesPerinatalesData[0]" type="number" name="no_de_gestacion"  v-model="antecedentesPerinatalesData[0].no_de_gestacion">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Edad Gestacional:</h7>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesPerinatalesData[0]" type="number" name="edad_gestacional"  v-model="antecedentesPerinatalesData[0].edad_gestacional">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Sitio de Atención del Parto:</h7>
                                                                                </div>
                                                                                <div class="col-sm-9">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesPerinatalesData[0]" type="text" name="sitio_atencion_parto"  v-model="antecedentesPerinatalesData[0].sitio_atencion_parto">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Peso al Nacer:</h7>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesPerinatalesData[0]" type="number" name="peso_al_nacer"  v-model="antecedentesPerinatalesData[0].peso_al_nacer">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Talla:</h7>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesPerinatalesData[0]" type="number" name="talla"  v-model="antecedentesPerinatalesData[0].talla">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <h8 class="mb-0">Perímetros (cm)</h8>
                                                                            <hr/>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Cefálico:</h7>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesPerinatalesData[0]" type="number" name="cefalico"  v-model="antecedentesPerinatalesData[0].cefalico">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Torácico:</h7>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesPerinatalesData[0]" type="number" name="toracico"  v-model="antecedentesPerinatalesData[0].toracico">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Abdominal:</h7>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesPerinatalesData[0]" type="number" name="abdominal"  v-model="antecedentesPerinatalesData[0].abdominal">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Apgar:</h7>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesPerinatalesData[0]" type="number" name="apgar"  v-model="antecedentesPerinatalesData[0].apgar">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Ballard:</h7>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesPerinatalesData[0]" type="number" name="ballard"  v-model="antecedentesPerinatalesData[0].ballard">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Silverman/And:</h7>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesPerinatalesData[0]" type="number" name="silverman_and"  v-model="antecedentesPerinatalesData[0].silverman_and">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Capurro:</h7>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesPerinatalesData[0]" type="number" name="capurro"  v-model="antecedentesPerinatalesData[0].capurro">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Complicaciones:</h7>
                                                                                </div>
                                                                                <div class="col-sm-9">
                                                                                    <div class="col-sm-12">
                                                                                        <textarea  class="form-control" v-if="antecedentesPerinatalesData[0]" v-model="antecedentesPerinatalesData[0].complicaciones" placeholder="Complicaciones..." rows="3"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesPerinatalesData[0]" v-model="antecedentesPerinatalesData[0].anestecia">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Anestecia</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-9">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesPerinatalesData[0]" placeholder="Especifique Anestecia" :disabled="antecedentesPerinatalesData[0].anestecia === false" type="text" name="tipo_anestecia"  v-model="antecedentesPerinatalesData[0].tipo_anestecia">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesPerinatalesData[0]" v-model="antecedentesPerinatalesData[0].hemorragias">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Hemorragias</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesPerinatalesData[0]" v-model="antecedentesPerinatalesData[0].convulsiones">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Convulsiones</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesPerinatalesData[0]" v-model="antecedentesPerinatalesData[0].cianosis">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Cianosis</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesPerinatalesData[0]" v-model="antecedentesPerinatalesData[0].ictericia">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Ictericia</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesPerinatalesData[0]" v-model="antecedentesPerinatalesData[0].apnea_neonatal">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Apnea Neonatal</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Observaciones:</h7>
                                                                                </div>
                                                                                <div class="col-sm-9">
                                                                                    <div class="col-sm-12">
                                                                                        <textarea  class="form-control" v-if="antecedentesPerinatalesData[0]" v-model="antecedentesPerinatalesData[0].observaciones" placeholder="Observaciones..." rows="12"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="ginecologicos" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Antecedentes Ginecológicos</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarAntecedentesGinecologicos()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Menarca</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesGinecologicosData[0]" type="number" name="menarca"  v-model="antecedentesGinecologicosData[0].menarca">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Menopausia</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesGinecologicosData[0]" type="number" name="menopausia"  v-model="antecedentesGinecologicosData[0].menopausia">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Fórmula Menstrual</h7>
                                                                                </div>
                                                                                <div class="col-sm-10">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesGinecologicosData[0]" type="text" name="formula_menstrual"  v-model="antecedentesGinecologicosData[0].formula_menstrual">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">F.U.M</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <Datepicker
                                                                                            v-if="antecedentesGinecologicosData[0]"
                                                                                            v-model="antecedentesGinecologicosData[0].fum"
                                                                                            dark placeholder="Selecciona una Fecha"
                                                                                            autoApply
                                                                                            :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                                            :enableTimePicker="false"
                                                                                            monthNameFormat="long"
                                                                                            :format="format"
                                                                                            modelType="dd-MM-yyyy"
                                                                                            locale="es">
                                                                                        </Datepicker>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">F.U.Citología</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <Datepicker
                                                                                            v-if="antecedentesGinecologicosData[0]"
                                                                                            v-model="antecedentesGinecologicosData[0].fu_citologia"
                                                                                            dark placeholder="Selecciona una Fecha"
                                                                                            autoApply
                                                                                            :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                                            :enableTimePicker="false"
                                                                                            monthNameFormat="long"
                                                                                            :format="format"
                                                                                            modelType="dd-MM-yyyy"
                                                                                            locale="es">
                                                                                        </Datepicker>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <h7 class="mb-0">Alteraciones de la Menstruación</h7>
                                                                            <hr/>
                                                                            <div class="row mb-2">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">de Frecuencia</h7>
                                                                                </div>
                                                                                <div class="col-sm-10">
                                                                                    <div class="col-sm-12">
                                                                                        <vue-select  :options="alteracion_menstruacion_frecuencia"
                                                                                            @blur="removeObjectArray()"
                                                                                            v-if="antecedentesGinecologicosData[0]"
                                                                                            v-model="antecedentesGinecologicosData[0].alteracion_menstruacion_frecuencia"
                                                                                            label-by="tipo"
                                                                                            track-by="tipo"
                                                                                            value-by="tipo"
                                                                                            search-placeholder="Selecciona un Tipo de Frecuencia"
                                                                                            multiple
                                                                                            collapse-tags="true"
                                                                                            taggable
                                                                                            searchable
                                                                                            placeholder="Selecciona un Tipo de Frecuencia"
                                                                                            close-on-select>
                                                                                            <template #label="{ selected }">
                                                                                            <template v-if="selected">
                                                                                                <div style="color:white;">{{ selected.tipo }}</div>
                                                                                            </template>
                                                                                            <template v-else > <div style="color:white;">Seleccione un Tipo de Frecuencia</div></template>
                                                                                            </template>
                                                                                            <template #dropdown-item="{ option }">
                                                                                            <div style="color:white;">{{ option.tipo }}</div>
                                                                                            </template>
                                                                                        </vue-select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">de Duración</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <vue-select  :options="alteracion_menstruacion_duracion"
                                                                                            @blur="removeObjectArray()"
                                                                                            v-if="antecedentesGinecologicosData[0]"
                                                                                            v-model="antecedentesGinecologicosData[0].alteracion_menstruacion_duracion"
                                                                                            label-by="tipo"
                                                                                            track-by="tipo"
                                                                                            value-by="tipo"
                                                                                            search-placeholder="Selecciona un Tipo de Duración"
                                                                                            multiple
                                                                                            collapse-tags="true"
                                                                                            taggable
                                                                                            searchable
                                                                                            placeholder="Selecciona un Tipo de Duración"
                                                                                            close-on-select>
                                                                                            <template #label="{ selected }">
                                                                                            <template v-if="selected">
                                                                                                <div style="color:white;">{{ selected.tipo }}</div>
                                                                                            </template>
                                                                                            <template v-else > <div style="color:white;">Seleccione un Tipo de Duración</div></template>
                                                                                            </template>
                                                                                            <template #dropdown-item="{ option }">
                                                                                            <div style="color:white;">{{ option.tipo }}</div>
                                                                                            </template>
                                                                                        </vue-select>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">de Cantidad</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <vue-select  :options="alteracion_menstruacion_cantidad"
                                                                                            @blur="removeObjectArray()"
                                                                                            v-if="antecedentesGinecologicosData[0]"
                                                                                            v-model="antecedentesGinecologicosData[0].alteracion_menstruacion_cantidad"
                                                                                            label-by="tipo"
                                                                                            track-by="tipo"
                                                                                            value-by="tipo"
                                                                                            search-placeholder="Selecciona un Tipo de Cantidad"
                                                                                            multiple
                                                                                            collapse-tags="true"
                                                                                            taggable
                                                                                            searchable
                                                                                            placeholder="Selecciona un Tipo de Cantidad"
                                                                                            close-on-select>
                                                                                            <template #label="{ selected }">
                                                                                            <template v-if="selected">
                                                                                                <div style="color:white;">{{ selected.tipo }}</div>
                                                                                            </template>
                                                                                            <template v-else > <div style="color:white;">Seleccione un Tipo de Cantidad</div></template>
                                                                                            </template>
                                                                                            <template #dropdown-item="{ option }">
                                                                                            <div style="color:white;">{{ option.tipo }}</div>
                                                                                            </template>
                                                                                        </vue-select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Inicio Vida Sexual</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesGinecologicosData[0]" type="number" name="inicio_vida_sexual"  v-model="antecedentesGinecologicosData[0].inicio_vida_sexual">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">No. Parejas</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesGinecologicosData[0]" type="number" name="no_parejas"  v-model="antecedentesGinecologicosData[0].no_parejas">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <hr/>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].vaginitis">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Vaginitis</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].cervicitis_mucopurulenta">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Cervicitis Mucopurulenta</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].chancroide">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Chancroide</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].clamidia">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Clamidia</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].enfermedad_inflamatoria_pelvica_eip">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Enfermedad Inflamatoria Pélvica (EIP)</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].gonorrea">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Gonorrea</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].hepatitis">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Hepatitis</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].herpes">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Herpes</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].linfogranuloma_venerio_lgv">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Linfogranuloma Venéreo (LGV)</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].molusco_contagioso">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Molusco Contagioso</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].piojos_ladillas_publicos">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Piojos "Ladillas" Públicos</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].sarna">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Sarna</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].sifilis">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Sífilis</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].tricomoniasis">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Tricomoniasis</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].vaginosis_bacteriana_vb">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Vaginosis Bacteriana (VB)</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].vih">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">VIH</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].virus_del_papiloma_humano_vph">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Virus del Papiloma Humano (VPH)</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <hr/>
                                                                            <h7 class="mb-0">Anticoncepción</h7>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Tipo</h7>
                                                                                </div>
                                                                                <div class="col-sm-10">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesGinecologicosData[0]" type="text" name="tipo_anticoncepcion"  v-model="antecedentesGinecologicosData[0].tipo_anticoncepcion">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Inicio</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <Datepicker
                                                                                            v-if="antecedentesGinecologicosData[0]"
                                                                                            v-model="antecedentesGinecologicosData[0].inicio_anticoncepcion"
                                                                                            dark placeholder="Selecciona una Fecha"
                                                                                            autoApply
                                                                                            :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                                            :enableTimePicker="false"
                                                                                            monthNameFormat="long"
                                                                                            :format="format"
                                                                                            modelType="dd-MM-yyyy"
                                                                                            locale="es">
                                                                                        </Datepicker>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Suspención</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <Datepicker
                                                                                            v-if="antecedentesGinecologicosData[0]"
                                                                                            v-model="antecedentesGinecologicosData[0].suspencion_anticoncepcion"
                                                                                            dark placeholder="Selecciona una Fecha"
                                                                                            autoApply
                                                                                            :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                                            :enableTimePicker="false"
                                                                                            monthNameFormat="long"
                                                                                            :format="format"
                                                                                            modelType="dd-MM-yyyy"
                                                                                            locale="es">
                                                                                        </Datepicker>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Observación</h7>
                                                                                </div>
                                                                                <div class="col-sm-10">
                                                                                    <div class="col-sm-12">
                                                                                        <textarea  class="form-control" v-if="antecedentesGinecologicosData[0]" v-model="antecedentesGinecologicosData[0].observaciones" placeholder="Observaciones..." rows="12"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="obstetricos" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Antecedentes Obstétricos</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarAntecedentesObstetricos()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">F.P.P</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <Datepicker
                                                                                            v-if="antecedentesObstetricosData[0]"
                                                                                            v-model="antecedentesObstetricosData[0].f_p_p"
                                                                                            dark placeholder="Selecciona una Fecha"
                                                                                            autoApply
                                                                                            :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                                            :enableTimePicker="false"
                                                                                            monthNameFormat="long"
                                                                                            :format="format"
                                                                                            modelType="dd-MM-yyyy"
                                                                                            locale="es">
                                                                                        </Datepicker>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">G</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesObstetricosData[0]" type="number" name="g"  v-model="antecedentesObstetricosData[0].g">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">A</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesObstetricosData[0]" type="number" name="a"  v-model="antecedentesObstetricosData[0].a">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">P</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesObstetricosData[0]" type="number" name="p"  v-model="antecedentesObstetricosData[0].p">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">C</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesObstetricosData[0]" type="number" name="c"  v-model="antecedentesObstetricosData[0].c">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Nacidos Vivos</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesObstetricosData[0]" type="number" name="nacidos_vivos"  v-model="antecedentesObstetricosData[0].nacidos_vivos">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Viven</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesObstetricosData[0]" type="number" name="viven"  v-model="antecedentesObstetricosData[0].viven">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Nacidos Muertos</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesObstetricosData[0]" type="number" name="nacidos_muertos"  v-model="antecedentesObstetricosData[0].nacidos_muertos">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Muertos 1ra Semana</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesObstetricosData[0]" type="number" name="muertos_primera_semana"  v-model="antecedentesObstetricosData[0].muertos_primera_semana">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Muertos Después 1ra Semana</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesObstetricosData[0]" type="number" name="muertos_despues_primera_semana"  v-model="antecedentesObstetricosData[0].muertos_despues_primera_semana">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <hr/>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesObstetricosData[0]" v-model="antecedentesObstetricosData[0].intergenesia">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Intergenesia</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesObstetricosData[0]" v-model="antecedentesObstetricosData[0].parto_prematuro">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Parto Prematuro</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesObstetricosData[0]" v-model="antecedentesObstetricosData[0].malformaciones">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Malformaciones</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesObstetricosData[0]" v-model="antecedentesObstetricosData[0].isoinmunizacion">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Isoinmunización</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-3">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="antecedentesObstetricosData[0]" v-model="antecedentesObstetricosData[0].atencion_prenatal">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Atención Prenatal</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Medicación Gestacional</h7>
                                                                                </div>
                                                                                <div class="col-sm-10">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesObstetricosData[0]" type="text" name="medicacion_gestacional"  v-model="antecedentesObstetricosData[0].medicacion_gestacional">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Exámenes Complementarios</h7>
                                                                                </div>
                                                                                <div class="col-sm-10">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="antecedentesObstetricosData[0]" type="text" name="examenes_complementarios"  v-model="antecedentesObstetricosData[0].examenes_complementarios">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Observación</h7>
                                                                                </div>
                                                                                <div class="col-sm-10">
                                                                                    <div class="col-sm-12">
                                                                                        <textarea  class="form-control" v-if="antecedentesObstetricosData[0]" v-model="antecedentesObstetricosData[0].observaciones" placeholder="Observaciones..." rows="12"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- End Content -->
                                                </div>
                                            </div>
                                            <!-- End Inside Panel Antecedentes -->
                                            <!-- Inside Panel Exploración Física -->
                                            <div class="tab-pane fade" id="exploracion_fisica" role="tabpanel">
                                                <div class="row">
                                                    <!-- Links -->
                                                    <div class="col-3">
                                                        <ul class="nav flex-column nav-tabs text-center" role="tablist">
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#habitus_exterior" role="tab" aria-selected="true">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Habitus Exterior</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#cabeza" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Cabeza</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#ojos" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Ojos</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#otorrinolaringologia" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Otorrinolaringología</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#cuello" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Cuello</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#torax" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Tórax</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#abdomen" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Abdomen</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#exploracion_ginecologica" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Exploración Ginecológica</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#genitales" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Genitales</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#columna_vertebral" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Columna Vertebral</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#extremidades" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Extremidades</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#exploracion_neurologica" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Exploración Neurológica</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <!-- End Links -->

                                                    <!-- Content -->
                                                    <div class="col-9">
                                                        <div class="tab-content py-3">
                                                            <div class="tab-pane fade show active" id="habitus_exterior" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Habitus Exterior</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarExploracionFisica()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="form-body mt-4">
                                                                                <textarea  class="form-control" v-if="exploracionFisicaData[0]" v-model="exploracionFisicaData[0].habitus_exterior" placeholder="Habitus Exterior..." rows="20"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="cabeza" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Cabeza</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarExploracionFisica()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="form-body mt-4">
                                                                                <textarea  class="form-control" v-if="exploracionFisicaData[0]" v-model="exploracionFisicaData[0].cabeza" placeholder="Cabeza..." rows="20"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="ojos" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Ojos</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarExploracionFisica()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="form-body mt-4">
                                                                                <textarea  class="form-control" v-if="exploracionFisicaData[0]" v-model="exploracionFisicaData[0].ojos" placeholder="Ojos..." rows="20"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="otorrinolaringologia" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Otorrinolaringología</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarExploracionFisica()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="form-body mt-4">
                                                                                <textarea  class="form-control" v-if="exploracionFisicaData[0]" v-model="exploracionFisicaData[0].otorrinoralingologia" placeholder="Otorrinoralingología..." rows="20"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="cuello" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Cuello</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarExploracionFisica()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="form-body mt-4">
                                                                                <textarea  class="form-control" v-if="exploracionFisicaData[0]" v-model="exploracionFisicaData[0].cuello" placeholder="Cuello..." rows="20"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="torax" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Tórax</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarExploracionFisica()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="form-body mt-4">
                                                                                <textarea  class="form-control" v-if="exploracionFisicaData[0]" v-model="exploracionFisicaData[0].torax" placeholder="Torax..." rows="20"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="abdomen" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Abdomen</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarExploracionFisica()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="form-body mt-4">
                                                                                <textarea  class="form-control" v-if="exploracionFisicaData[0]" v-model="exploracionFisicaData[0].abdomen" placeholder="Abdomen..." rows="20"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="exploracion_ginecologica" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Exploración Ginecológica</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarExploracionFisica()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="form-body mt-4">
                                                                                <textarea  class="form-control" v-if="exploracionFisicaData[0]" v-model="exploracionFisicaData[0].exploracion_ginecologica" placeholder="Exploración Ginecológica..." rows="20"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="genitales" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Genitales</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarExploracionFisica()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="form-body mt-4">
                                                                                <textarea  class="form-control" v-if="exploracionFisicaData[0]" v-model="exploracionFisicaData[0].genitales" placeholder="Genitales..." rows="20"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="columna_vertebral" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Columna Vertebral</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarExploracionFisica()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="form-body mt-4">
                                                                                <textarea  class="form-control" v-if="exploracionFisicaData[0]" v-model="exploracionFisicaData[0].columna_vertebral" placeholder="Columna Vertebral..." rows="20"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="extremidades" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Extremidades</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarExploracionFisica()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="form-body mt-4">
                                                                                <textarea  class="form-control" v-if="exploracionFisicaData[0]" v-model="exploracionFisicaData[0].extremidades" placeholder="Extremidades..." rows="20"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="exploracion_neurologica" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Exploración Neurológica</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarExploracionFisica()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="form-body mt-4">
                                                                                <textarea  class="form-control" v-if="exploracionFisicaData[0]" v-model="exploracionFisicaData[0].exploracion_neurologica" placeholder="Exploración Neurológica..." rows="20"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- End Content -->
                                                </div>
                                            </div>
                                            <!-- End Inside Panel Exploración Física -->
                                            <!-- Inside Panel Interrogatorio -->
                                            <div class="tab-pane fade" id="interrogatorio" role="tabpanel">
                                                <div class="row">
                                                    <!-- Links -->
                                                    <div class="col-3">
                                                        <ul class="nav flex-column nav-tabs text-center" role="tablist">
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#cardiovascular" role="tab" aria-selected="true">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Cardiovascular</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#digestivo" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Digestivo</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#endocrino" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Endocrino</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#hemolinfatico" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Hemolinfatico</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#mamas" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Mamas</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#musculo_esqueletico" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Músculo Esquelético</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#piel_anexos" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Piel y Anexos</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#reproductor" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Reproductor</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#respiratorio" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Respiratorio</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#sistema_nervioso" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Sistema Nervioso</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#sistema_general" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Sistema General</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" data-bs-toggle="tab" href="#urinario" role="tab" aria-selected="false">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                                                                        </div>
                                                                        <div class="tab-title">Urinario</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <!-- End Links -->

                                                    <!-- Content -->
                                                    <div class="col-9">
                                                        <div class="tab-content py-3">
                                                            <div class="tab-pane fade show active" id="cardiovascular" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Cardiovascular</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarInterrogatorio()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <textarea  class="form-control" v-if="interrogatorioData[0]" v-model="interrogatorioData[0].cardiovascular" placeholder="Cardiovascular..." rows="20"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="digestivo" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Digestivo</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarInterrogatorio()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <textarea  class="form-control" v-if="interrogatorioData[0]" v-model="interrogatorioData[0].digestivo" placeholder="Digestivo..." rows="20"></textarea>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="endocrino" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Endocrino</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarInterrogatorio()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <textarea  class="form-control" v-if="interrogatorioData[0]" v-model="interrogatorioData[0].endocrino" placeholder="Endocrino..." rows="20"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="hemolinfatico" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Hemolinfático</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarInterrogatorio()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <textarea  class="form-control" v-if="interrogatorioData[0]" v-model="interrogatorioData[0].hemolinfatico" placeholder="Hemolinfatico..." rows="20"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="mamas" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Mamas</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarInterrogatorio()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <textarea  class="form-control" v-if="interrogatorioData[0]" v-model="interrogatorioData[0].mamas" placeholder="Mamas..." rows="20"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="musculo_esqueletico" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Músculo Esquelético</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarInterrogatorio()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <textarea  class="form-control" v-if="interrogatorioData[0]" v-model="interrogatorioData[0].musculo_esqueletico" placeholder="Músculo Esquelético..." rows="20"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="piel_anexos" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Piel y Anexos</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarInterrogatorio()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <textarea  class="form-control" v-if="interrogatorioData[0]" v-model="interrogatorioData[0].piel_anexos" placeholder="Piel Anexos..." rows="20"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="reproductor" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Reproductor</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarInterrogatorio()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <textarea  class="form-control" v-if="interrogatorioData[0]" v-model="interrogatorioData[0].reproductor" placeholder="Aparato Reproductor..." rows="20"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="respiratorio" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Respiratorio</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarInterrogatorio()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <textarea  class="form-control" v-if="interrogatorioData[0]" v-model="interrogatorioData[0].respiratorio" placeholder="Respiratorio..." rows="20"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="sistema_nervioso" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Sistema Nervioso</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarInterrogatorio()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <textarea  class="form-control" v-if="interrogatorioData[0]" v-model="interrogatorioData[0].sistema_nervioso" placeholder="Sistema Nervioso..." rows="20"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="sistema_general" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Sistema General</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarInterrogatorio()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <textarea  class="form-control" v-if="interrogatorioData[0]" v-model="interrogatorioData[0].sistema_general" placeholder="Sistema General..." rows="20"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="urinario" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Urinario</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarInterrogatorio()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <textarea  class="form-control" v-if="interrogatorioData[0]" v-model="interrogatorioData[0].urinario" placeholder="Urinario..." rows="20"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- End Content -->
                                                </div>
                                            </div>
                                            <!-- End Inside Panel Interrogatorio -->
                                            <!-- Inside Panel Estilo de Vida -->
                                            <div class="tab-pane fade" id="estilo_de_vida" role="tabpanel">
                                                <div class="row">
                                                    <!-- Content -->
                                                    <div class="col-12">
                                                        <div class="tab-content py-3">
                                                            <div class="tab-pane fade show active" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Actividad Física</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarEstiloVida()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].realiza_ejercicio">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Realiza Ejercicio?</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Minutos al Día</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Minutos al Día" :disabled="estiloDeVidaData[0].realiza_ejercicio === false" type="number" name="minutos_al_dia_ejercicio"  v-model="estiloDeVidaData[0].minutos_al_dia_ejercicio">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].practica_deporte">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Practica Algún Deporte?</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">¿Cuál Deporte?</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Deporte que Practica" :disabled="estiloDeVidaData[0].practica_deporte === false" type="text" name="cual_deporte"  v-model="estiloDeVidaData[0].cual_deporte">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-3">
                                                                                    <h7 class="mb-0">Frecuencia Con La Que Practica</h7>
                                                                                </div>
                                                                                <div class="col-sm-9">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Frecuencia con la que lo Practica" :disabled="estiloDeVidaData[0].practica_deporte === false" type="text" name="frecuencia_practica_deporte"  v-model="estiloDeVidaData[0].frecuencia_practica_deporte">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].duerme_durante_dia">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Duerme Durante el Día?</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Horas Que Duerme Durante el Día</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Minutos al Día" :disabled="estiloDeVidaData[0].duerme_durante_dia === false" type="number" name="horas_duerme_durante_dia"  v-model="estiloDeVidaData[0].horas_duerme_durante_dia">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Hábitos Alimenticios</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarEstiloVida()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].desayuna">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Desayuna?</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Número de Comidas al Día</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Número de Comidas al Día"  type="number" name="numero_comidas_al_dia"  v-model="estiloDeVidaData[0].numero_comidas_al_dia">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].toma_cafe">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Toma Café?</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Número de Tazas al Día</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Número de Tazas al Día" :disabled="estiloDeVidaData[0].toma_cafe === false" type="number" name="numero_tazas_al_dia"  v-model="estiloDeVidaData[0].numero_tazas_al_dia">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].toma_refresco">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Toma Refresco?</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].sigue_alguna_dieta">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Sigue Alguna Dieta?</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Información Sobre la Dieta</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Información Sobre la Dieta" :disabled="estiloDeVidaData[0].sigue_alguna_dieta === false" type="text" name="informacion_dieta"  v-model="estiloDeVidaData[0].informacion_dieta">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Alcoholismo</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarEstiloVida()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-4">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].consume_alcohol">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Consume Alcohol?</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <h7 class="mb-0">Edad a la que Comenzó a Beber</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Edad a la que Comenzó a Beber" :disabled="estiloDeVidaData[0].consume_alcohol === false" type="number" name="edad_comenzo_beber"  v-model="estiloDeVidaData[0].edad_comenzo_beber">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-4">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].ex_alcoholico">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Ex Alcohólico?</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <h7 class="mb-0">Edad a la que Dejó de Beber</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Edad a la que Dejó de Beber" :disabled="estiloDeVidaData[0].ex_alcoholico === false" type="number" name="edad_dejo_beber"  v-model="estiloDeVidaData[0].edad_dejo_beber">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Copas de Vino al Día</h7>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Copas" type="number" name="copas_vino_al_dia"  v-model="estiloDeVidaData[0].copas_vino_al_dia">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Vasos de Licor al Día</h7>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Vasos" type="number" name="vasos_licor_al_dia"  v-model="estiloDeVidaData[0].vasos_licor_al_dia">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Cervezas al Día</h7>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Cervezas" type="number" name="cervezas_al_dia"  v-model="estiloDeVidaData[0].cervezas_al_dia">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Tabaquismo</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarEstiloVida()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-4">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].fuma">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Fuma?</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <h7 class="mb-0">Edad en la que Comenzó a Fumar</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Edad en la que Comenzó a Fumar" :disabled="estiloDeVidaData[0].fuma === false" type="number" name="edad_comenzo_fumar"  v-model="estiloDeVidaData[0].edad_comenzo_fumar">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-4">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].fumador_pasivo">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Fumador Pasivo?</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <h7 class="mb-0">Cigarros al Día</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Cigarros al Día" :disabled="estiloDeVidaData[0].fuma === false" type="number" name="cigarros_al_dia"  v-model="estiloDeVidaData[0].cigarros_al_dia">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-4">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].ex_fumador">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Ex Fumador?</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <h7 class="mb-0">Edad en la que Dejó de Fumar</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Edad en la que Dejó de Fumar" :disabled="estiloDeVidaData[0].ex_fumador === false" type="number" name="edad_dejo_fumar"  v-model="estiloDeVidaData[0].edad_dejo_fumar">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Consumo de Drogas</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarEstiloVida()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-4">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].consume_drogas">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Consume Algún Tipo de Droga?</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <h7 class="mb-0">Edad en la que Comenzó a Consumir</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Edad en la que Comenzó a Consumir" :disabled="estiloDeVidaData[0].consume_drogas === false" type="number" name="edad_comenzo_droga"  v-model="estiloDeVidaData[0].edad_comenzo_droga">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-4">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].ex_adicto_droga">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Ex Adicto a las Drogas?</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <h7 class="mb-0">Edad en la que Dejó de Consumir</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <input class="form-control" v-if="estiloDeVidaData[0]" placeholder="Edad en la que Dejó de Consumir" :disabled="estiloDeVidaData[0].ex_adicto_droga === false" type="number" name="edad_dejo_droga"  v-model="estiloDeVidaData[0].edad_dejo_droga">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-4">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].usa_droga_intravenosa">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Usa Drogas Intravenosas?</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Otros</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarEstiloVida()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-4">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].autonomia_en_el_trabajo">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">¿Autonomía en el Trabajo?</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-4">
                                                                                    <h7 class="mb-0">Turnos de Trabajo</h7>
                                                                                </div>
                                                                                <div class="col-sm-8">
                                                                                    <div class="col-sm-12">
                                                                                        <textarea  class="form-control" v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].turnos_de_trabao" placeholder="Turnos en el Trabajo..." rows="3"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-4">
                                                                                    <h7 class="mb-0">¿Qué actividades Realiza en sus Días Libres?</h7>
                                                                                </div>
                                                                                <div class="col-sm-8">
                                                                                    <div class="col-sm-12">
                                                                                        <textarea  class="form-control" v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].actividades_dias_libres" placeholder="Actividades que Realiza en sus Días Libres..." rows="3"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-4">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].duelo">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Duelo</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].embarazos">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Embarazos</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"   v-if="estiloDeVidaData[0]" v-model="estiloDeVidaData[0].divorcios">
                                                                                        <label  class="form-check-label" for="flexCheckChecked">Divorcios</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- End Content -->
                                                </div>
                                            </div>
                                            <!-- End Inside Panel Estilo de Vida -->
                                            <!-- Inside Panel Alergias -->
                                            <div class="tab-pane fade" id="alergias" role="tabpanel">
                                                <div class="row">
                                                    <!-- Content -->
                                                    <div class="col-12">
                                                        <div class="tab-content py-3">
                                                            <div class="tab-pane fade show active" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Alergias</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="modificarAlergias()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-12">
                                                                                    <div class="col-sm-12">
                                                                                        <textarea  class="form-control" v-if="alergiasData[0]" v-model="alergiasData[0].descripcion" placeholder="Alergias del Paciente..." rows="15"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- End Content -->
                                                </div>
                                            </div>
                                            <!-- End Inside Panel Alergias -->
                                            <!-- Inside Panel Incapacidad -->
                                            <div class="tab-pane fade" id="incapacidad" role="tabpanel">
                                                <div class="row">
                                                    <!-- Content -->
                                                    <div class="col-12">
                                                        <div class="tab-content py-3">
                                                            <div class="tab-pane fade show active" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Ingresar Incapacidad</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="saveIncapacidades()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Incapacidad</h7>
                                                                                </div>
                                                                                <div class="col-sm-10">
                                                                                    <div class="form-check">
                                                                                        <input class="form-control" placeholder="Incapacidad..." type="text" name="incapacidad"  v-model="incapacidad.incapacidad">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Fecha Inicio</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <Datepicker
                                                                                            v-model="incapacidad.fecha_inicial"
                                                                                            dark placeholder="Selecciona una Fecha"
                                                                                            autoApply
                                                                                            :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                                            :enableTimePicker="false"
                                                                                            monthNameFormat="long"
                                                                                            :format="format"
                                                                                            modelType="dd-MM-yyyy"
                                                                                            locale="es">
                                                                                        </Datepicker>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Fecha Final</h7>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <div class="col-sm-12">
                                                                                        <Datepicker
                                                                                            v-model="incapacidad.fecha_final"
                                                                                            dark placeholder="Selecciona una Fecha"
                                                                                            autoApply
                                                                                            :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                                            :enableTimePicker="false"
                                                                                            monthNameFormat="long"
                                                                                            :format="format"
                                                                                            modelType="dd-MM-yyyy"
                                                                                            locale="es">
                                                                                        </Datepicker>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Notas</h7>
                                                                                </div>
                                                                                <div class="col-sm-10">
                                                                                    <div class="col-sm-12">
                                                                                        <textarea  class="form-control" v-model="incapacidad.notas" placeholder="Notas..." rows="3"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <h5 class="card-title">Lista de Incapacidades</h5>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <table class="table mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">Incapacidad</th>
                                                                                        <th scope="col">Fecha Inicial</th>
                                                                                        <th scope="col">Fecha Final</th>
                                                                                        <th scope="col">Notas</th>
                                                                                        <th scope="col">Acciones</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="item in incapacidadesData" :key="item.id">
                                                                                        <th scope="row">{{item.incapacidad}}</th>
                                                                                        <td>{{ item.fecha_inicial }}</td>
                                                                                        <td>{{ item.fecha_final }}</td>
                                                                                        <td>{{ item.notas }}</td>
                                                                                        <td>
                                                                                            <div class="d-flex order-actions">
                                                                                                <a href="javascript:;" data-bs-toggle="modal" :data-bs-target="'#eliminarIncapacidad'+item.id" class="ms-3"><i class='bx bxs-trash'></i></a>
                                                                                                <!-- Modal -->
                                                                                                <div class="modal fade"   :id="'eliminarIncapacidad'+item.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                                                    <div class="modal-dialog modal-lg">
                                                                                                        <div class="modal-content bg-dark">
                                                                                                            <div class="modal-header">
                                                                                                                <h5 class="modal-title" id="exampleModalLabel">Eliminar la Incapacidad</h5>
                                                                                                                <button type="button" id="closeDetalle" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                                            </div>
                                                                                                            <div class="modal-body">
                                                                                                                <div class="row mb-3">
                                                                                                                    <div class="col-sm-7">
                                                                                                                        <h4 class="mb-0">¿Deseas eliminar la Incapacidad?</h4>
                                                                                                                    </div>
                                                                                                                    <div class="col-sm-5">
                                                                                                                        <input type="text" readonly :value="item.incapacidad"  id="titulo"  class="form-control" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="modal-footer">
                                                                                                                <button @click="eliminarIncapacidad(item.id);" data-bs-dismiss="modal" class="btn btn-danger">Eliminar</button>
                                                                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!-- End Modal -->
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- End Content -->
                                                </div>
                                            </div>
                                            <!-- End Inside Panel Incapacidad -->
                                            <!-- Inside Panel Documentos -->
                                            <div class="tab-pane fade" id="documentos" role="tabpanel">
                                                <div class="row">
                                                    <!-- Content -->
                                                    <div class="col-12">
                                                        <div class="tab-content py-3">
                                                            <div class="tab-pane fade show active" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Ingresar Documentos</h5>
                                                                            </div>
                                                                            <div class="ms-auto"><a href="javascript:;" @click="saveDocumentos()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Nombre</h7>
                                                                                </div>
                                                                                <div class="col-sm-5">
                                                                                    <input type="text"  placeholder="Nombre del Archivo..." v-model="documento.nombre" id="titulo"  class="form-control" />
                                                                                </div>
                                                                                <div class="col-sm-5">
                                                                                    <div class="input-group mb-3">
                                                                                        <input type="file" class="form-control" id="documentoExpediente">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <div class="col-sm-2">
                                                                                    <h7 class="mb-0">Descripción</h7>
                                                                                </div>
                                                                                <div class="col-sm-10">
                                                                                    <textarea  class="form-control" v-model="documento.descripcion" placeholder="Descripción del Archivo..." rows="4"></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-body mt-4">
                                                                            <table class="table mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col" style="width: 25%">Nombre</th>
                                                                                        <th scope="col" style="width: 35%">Descripción</th>
                                                                                        <th scope="col" style="width: 25%">Fecha</th>
                                                                                        <th scope="col" style="width: 15%">Acciones</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="item in documentosData" :key="item.id">
                                                                                        <th scope="row">{{item.nombre}}</th>
                                                                                        <td>
                                                                                            {{item.descripcion}}
                                                                                        </td>
                                                                                        <td>{{item.fecha}}</td>
                                                                                        <td>
                                                                                            <div class="d-flex order-actions">
                                                                                                <a target="_blank" :href="item.archivo" data-bs-toggle="tooltip" data-bs-placement="top" title="Ver Documento" class="ms-3"><i class='bx bxs-show'></i></a>
                                                                                                <a href="javascript:;" data-bs-toggle="modal" :data-bs-target="'#eliminarDocumentoExpediente'+item.id" class="ms-3"><i class='bx bxs-trash'></i></a>
                                                                                                <!-- Modal -->
                                                                                                <div class="modal fade"   :id="'eliminarDocumentoExpediente'+item.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                                                    <div class="modal-dialog modal-lg">
                                                                                                        <div class="modal-content bg-dark">
                                                                                                            <div class="modal-header">
                                                                                                                <h5 class="modal-title" id="exampleModalLabel">Eliminar El Documento</h5>
                                                                                                                <button type="button" id="closeDetalle" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                                            </div>
                                                                                                            <div class="modal-body">
                                                                                                                <div class="row mb-3">
                                                                                                                    <div class="col-sm-7">
                                                                                                                        <h4 class="mb-0">¿Deseas eliminar el Documento?</h4>
                                                                                                                    </div>
                                                                                                                    <div class="col-sm-5">
                                                                                                                        <input type="text" readonly :value="item.nombre"  id="titulo"  class="form-control" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="modal-footer">
                                                                                                                <button @click="eliminarDocumentoExpediente(item.nombre, item.id);" data-bs-dismiss="modal" class="btn btn-danger">Eliminar</button>
                                                                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!-- End Modal -->
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- End Content -->
                                                </div>
                                            </div>
                                            <!-- End Inside Panel Documentos -->
                                            <!-- Inside Panel Estado de Cuenta -->
                                            <div class="tab-pane fade" id="estado_cuenta" role="tabpanel">
                                                <div class="row">
                                                    <!-- Content -->
                                                    <div class="col-12">
                                                        <div class="tab-content py-3">
                                                            <div class="tab-pane fade show active" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Estado de Cuenta</h5>
                                                                            </div>
                                                                            <div class="ms-auto">
                                                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#NuevoCargo" @click="getCargoConceptoData();" class="btn btn-light mt-2 mt-lg-0"><i class="bx bxs-detail"></i>Nuevo Cargo</a> &nbsp;
                                                                                <!-- Modal -->
                                                                                <div class="modal fade"   id="NuevoCargo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                                    <div class="modal-dialog modal-lg">
                                                                                        <div class="modal-content bg-dark">
                                                                                            <div class="modal-header">
                                                                                                <h5 class="modal-title" id="exampleModalLabel">Nuevo Cargo</h5>
                                                                                                <button type="button" id="closeDetalle" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                            </div>
                                                                                            <div class="modal-body">
                                                                                                <div class="row mb-3">
                                                                                                    <div class="col-sm-2">
                                                                                                        <h7 class="mb-0">Concepto</h7>
                                                                                                    </div>
                                                                                                    <div class="col-sm-10">
                                                                                                        <div class="col-sm-12">
                                                                                                            <vue-select  :options="cargoConceptoData"
                                                                                                                @blur="removeObjectArrayEstadoCuenta()"
                                                                                                                v-model="nuevoCargoDetalle.cargo_concepto"
                                                                                                                label-by="descripcion"
                                                                                                                track-by="descripcion"
                                                                                                                value-by="id"
                                                                                                                search-placeholder="Selecciona un Concepto de Cargo"
                                                                                                                multiple
                                                                                                                collapse-tags="true"
                                                                                                                taggable
                                                                                                                searchable
                                                                                                                placeholder="Selecciona un Concepto de Cargo"
                                                                                                                close-on-select>
                                                                                                                <template #label="{ selected }">
                                                                                                                <template v-if="selected">
                                                                                                                    <div style="color:white;">{{ selected.descripcion }}</div>
                                                                                                                </template>
                                                                                                                <template v-else > <div style="color:white;">Seleccione un Concepto de Cargo</div></template>
                                                                                                                </template>
                                                                                                                <template #dropdown-item="{ option }">
                                                                                                                <div style="color:white;">{{ option.descripcion }}</div>
                                                                                                                </template>
                                                                                                            </vue-select>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="row mb-3">
                                                                                                    <div class="col-sm-2">
                                                                                                        <h7 class="mb-0">% IVA</h7>
                                                                                                    </div>
                                                                                                    <div class="col-sm-4">
                                                                                                        <div class="col-sm-12">
                                                                                                            <input type="number" step="0.01" v-model="nuevoCargoDetalle.iva_porcentaje"  id="iva_porcentaje"  class="form-control" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-sm-2">
                                                                                                        <h7 class="mb-0">IVA</h7>
                                                                                                    </div>
                                                                                                    <div class="col-sm-4">
                                                                                                        <div class="col-sm-12">
                                                                                                            <input type="number" step="0.01" readonly v-model="nuevoCargoDetalle.iva"  id="iva"  class="form-control" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="row mb-3">
                                                                                                    <div class="col-sm-2">
                                                                                                        <h7 class="mb-0">Importe</h7>
                                                                                                    </div>
                                                                                                    <div class="col-sm-4">
                                                                                                        <div class="col-sm-12">
                                                                                                            <input type="number" step="0.01" v-model="nuevoCargoDetalle.importe" onblur="this.value = parseFloat(this.value).toFixed(2);"  id="importe"  class="form-control" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-sm-2">
                                                                                                        <h7 class="mb-0">Sub Total</h7>
                                                                                                    </div>
                                                                                                    <div class="col-sm-4">
                                                                                                        <div class="col-sm-12">
                                                                                                            <input type="number" step="0.01" readonly v-model="nuevoCargoDetalle.subtotal"  id="subtotal"  class="form-control" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="row mb-3">
                                                                                                    <div class="col-sm-8">
                                                                                                    </div>
                                                                                                    <div class="col-sm-4">
                                                                                                        <button class="btn btn-success" @click="añadirDetalleCargo()">Añadir Concepto de Cargo</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr/>
                                                                                                <div class="row mb-3">
                                                                                                    <div class="col-sm-2">
                                                                                                        <h7 class="mb-0">Fecha</h7>
                                                                                                    </div>
                                                                                                    <div class="col-sm-10">
                                                                                                        <div class="col-sm-12">
                                                                                                            <Datepicker
                                                                                                                v-model="nuevoCargo.fecha"
                                                                                                                dark placeholder="Selecciona una Fecha"
                                                                                                                autoApply
                                                                                                                :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                                                                :enableTimePicker="false"
                                                                                                                monthNameFormat="long"
                                                                                                                :format="format"
                                                                                                                modelType="dd-MM-yyyy"
                                                                                                                locale="es">
                                                                                                            </Datepicker>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="row mb-3">
                                                                                                    <div class="col-sm-1">
                                                                                                        <h7 class="mb-0">Subtotal</h7>
                                                                                                    </div>
                                                                                                    <div class="col-sm-3">
                                                                                                        <div class="col-sm-12">
                                                                                                            <input type="number" step="0.01" readonly v-model="nuevoCargo.subtotal"  id="subtotal"  class="form-control" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-sm-1">
                                                                                                        <h7 class="mb-0">IVA</h7>
                                                                                                    </div>
                                                                                                    <div class="col-sm-3">
                                                                                                        <div class="col-sm-12">
                                                                                                            <input type="number" step="0.01" readonly v-model="nuevoCargo.iva"  id="iva"  class="form-control" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-sm-1">
                                                                                                        <h7 class="mb-0">Total</h7>
                                                                                                    </div>
                                                                                                    <div class="col-sm-3">
                                                                                                        <div class="col-sm-12">
                                                                                                            <input type="number" step="0.01" readonly v-model="nuevoCargo.total"  id="total"  class="form-control" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr/>
                                                                                                <div class="row mb-3">
                                                                                                    <div class="col-sm-12">
                                                                                                        <table class="table mb-0">
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th scope="col">#</th>
                                                                                                                    <th scope="col">Concepto</th>
                                                                                                                    <th scope="col">Importe</th>
                                                                                                                    <th scope="col">% IVA</th>
                                                                                                                    <th scope="col">IVA</th>
                                                                                                                    <th scope="col">Sub Total</th>
                                                                                                                    <th scope="col">Acciones</th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                <tr v-for="(item, index) in nuevoCargo.detalle" :key="item.id">
                                                                                                                    <th scope="row">{{ index + 1 }}</th>
                                                                                                                    <td>{{ item.concepto_nombre }}</td>
                                                                                                                    <td>{{ item.importe }}</td>
                                                                                                                    <td>{{ item.iva_porcentaje }}</td>
                                                                                                                    <td>{{ item.iva }}</td>
                                                                                                                    <td>{{ item.subtotal }}</td>
                                                                                                                    <td><a href="javascript:;" @click="eliminarConceptoDetalle(index)" class="ms-3"><i class='bx bxs-trash'></i></a></td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="modal-footer">
                                                                                                <button data-bs-dismiss="modal" @click="saveCargo()" class="btn btn-success">Guardar</button>
                                                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <!-- End Modal -->
                                                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#NuevoPago" @click="getFormasDePago()" class="btn btn-light mt-2 mt-lg-0"><i class="bx bxs-detail"></i>Nuevo Pago</a> &nbsp;
                                                                                <!-- Modal -->
                                                                                <div class="modal fade"   id="NuevoPago" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                                    <div class="modal-dialog modal-lg">
                                                                                        <div class="modal-content bg-dark">
                                                                                            <div class="modal-header">
                                                                                                <h5 class="modal-title" id="exampleModalLabel">Nuevo Pago</h5>
                                                                                                <button type="button" id="closeDetalle" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                            </div>
                                                                                            <div class="modal-body">
                                                                                                <div class="row mb-3">
                                                                                                    <div class="col-sm-2">
                                                                                                        <h7 class="mb-0">Fecha</h7>
                                                                                                    </div>
                                                                                                    <div class="col-sm-10">
                                                                                                        <div class="col-sm-12">
                                                                                                            <Datepicker
                                                                                                                v-model="nuevoPago.fecha"
                                                                                                                dark placeholder="Selecciona una Fecha"
                                                                                                                autoApply
                                                                                                                :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                                                                :enableTimePicker="false"
                                                                                                                monthNameFormat="long"
                                                                                                                :format="format"
                                                                                                                modelType="dd-MM-yyyy"
                                                                                                                locale="es">
                                                                                                            </Datepicker>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="row mb-3">
                                                                                                    <div class="col-sm-2">
                                                                                                        <h7 class="mb-0">Forma de Pago</h7>
                                                                                                    </div>
                                                                                                    <div class="col-sm-4">
                                                                                                        <div class="col-sm-12">
                                                                                                            <vue-select  :options="formasDePagoData"
                                                                                                                @blur="removeObjectArrayFormasDePago()"
                                                                                                                v-model="nuevoPago.forma_pago"
                                                                                                                label-by="nombre"
                                                                                                                track-by="nombre"
                                                                                                                value-by="id"
                                                                                                                search-placeholder="Selecciona un Tipo de Pago"
                                                                                                                multiple
                                                                                                                collapse-tags="true"
                                                                                                                taggable
                                                                                                                searchable
                                                                                                                placeholder="Selecciona un Tipo de Pago"
                                                                                                                close-on-select>
                                                                                                                <template #label="{ selected }">
                                                                                                                <template v-if="selected">
                                                                                                                    <div style="color:white;">{{ selected.nombre }}</div>
                                                                                                                </template>
                                                                                                                <template v-else > <div style="color:white;">Seleccione un Tipo de Pago</div></template>
                                                                                                                </template>
                                                                                                                <template #dropdown-item="{ option }">
                                                                                                                <div style="color:white;">{{ option.nombre }}</div>
                                                                                                                </template>
                                                                                                            </vue-select>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-sm-2">
                                                                                                        <h7 class="mb-0">Cantidad</h7>
                                                                                                    </div>
                                                                                                    <div class="col-sm-4">
                                                                                                        <div class="col-sm-12">
                                                                                                            <input type="number" step="0.01" v-model="nuevoPago.cantidad"  id="cantidad"  class="form-control" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="row mb-3">
                                                                                                    <div class="col-sm-2">
                                                                                                        <h7 class="mb-0">Notas</h7>
                                                                                                    </div>
                                                                                                    <div class="col-sm-10">
                                                                                                        <div class="col-sm-12">
                                                                                                            <textarea  class="form-control" v-model="nuevoPago.notas" placeholder="Notas..." rows="3"></textarea>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr/>
                                                                                            </div>
                                                                                            <div class="modal-footer">
                                                                                                <button data-bs-dismiss="modal" @click="savePago()" class="btn btn-success">Guardar</button>
                                                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <!-- End Modal -->
                                                                            </div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="row row-cols-auto g-3">
                                                                                <div class="col">
                                                                                    <button type="button" class="btn btn-light mt-2 mt-lg-0 px-5">Pagos: {{ formatCurrency(estadoDeCuentaInfo.total_pagos) }}</button>
                                                                                </div>
                                                                                <div class="col">
                                                                                    <button type="button" class="btn btn-light mt-2 mt-lg-0 px-5">Cargos: {{ formatCurrency(estadoDeCuentaInfo.total_cargos) }}</button>

                                                                                </div>
                                                                                <div class="col">
                                                                                    <button type="button" class="btn btn-light mt-2 mt-lg-0 px-5">Saldo: {{ formatCurrency(estadoDeCuentaInfo.total_saldo) }}</button>
                                                                                </div>
                                                                            </div>
                                                                        <hr/>
                                                                            <table class="table mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">Fecha Del Registro</th>
                                                                                        <th scope="col">Forma de Pago</th>
                                                                                        <th scope="col">Pago</th>
                                                                                        <th scope="col">Cargo</th>
                                                                                        <th scope="col">Ver Detalle</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="item in estadoDeCuentaData" :key="item.id">
                                                                                        <th scope="row">{{item.fecha_creacion}}</th>
                                                                                        <td>{{item.forma_pago_nombre}}</td>
                                                                                        <td>{{formatCurrency(item.cantidad)}}</td>
                                                                                        <td>{{formatCurrency(item.total)}}</td>
                                                                                        <td>
                                                                                            <a href="javascript:;" v-if="item.total"  data-bs-toggle="modal" @click="seleccionarCargoMod(item.id)" :data-bs-target="'#modificar_cargo'+item.id" class=""><i class='bx bxs-edit'></i></a>
                                                                                            <!-- Modal -->
                                                                                            <div class="modal fade"   :id="'modificar_cargo'+item.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                                                <div class="modal-dialog modal-lg">
                                                                                                    <div class="modal-content bg-dark">
                                                                                                        <div class="modal-header">
                                                                                                            <h5 class="modal-title" id="exampleModalLabel">Modificar Cargo</h5>
                                                                                                            <button type="button" id="closeModificarCargo" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                                        </div>
                                                                                                        <div class="modal-body">
                                                                                                            <div class="row mb-3">
                                                                                                                <div class="col-sm-2">
                                                                                                                    <h7 class="mb-0">Concepto</h7>
                                                                                                                </div>
                                                                                                                <div class="col-sm-10">
                                                                                                                    <div class="col-sm-12">
                                                                                                                        <vue-select  :options="cargoConceptoData"
                                                                                                                            @blur="removeObjectArrayEstadoCuenta()"
                                                                                                                            v-model="nuevoCargoDetalle.cargo_concepto"
                                                                                                                            label-by="descripcion"
                                                                                                                            track-by="descripcion"
                                                                                                                            value-by="id"
                                                                                                                            search-placeholder="Selecciona un Concepto de Cargo"
                                                                                                                            multiple
                                                                                                                            collapse-tags="true"
                                                                                                                            taggable
                                                                                                                            searchable
                                                                                                                            placeholder="Selecciona un Concepto de Cargo"
                                                                                                                            close-on-select>
                                                                                                                            <template #label="{ selected }">
                                                                                                                            <template v-if="selected">
                                                                                                                                <div style="color:white;">{{ selected.descripcion }}</div>
                                                                                                                            </template>
                                                                                                                            <template v-else > <div style="color:white;">Seleccione un Concepto de Cargo</div></template>
                                                                                                                            </template>
                                                                                                                            <template #dropdown-item="{ option }">
                                                                                                                            <div style="color:white;">{{ option.descripcion }}</div>
                                                                                                                            </template>
                                                                                                                        </vue-select>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="row mb-3">
                                                                                                                <div class="col-sm-2">
                                                                                                                    <h7 class="mb-0">% IVA</h7>
                                                                                                                </div>
                                                                                                                <div class="col-sm-4">
                                                                                                                    <div class="col-sm-12">
                                                                                                                        <input type="number" step="0.01" v-model="nuevoCargoDetalle.iva_porcentaje"  id="iva_porcentaje"  class="form-control" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div class="col-sm-2">
                                                                                                                    <h7 class="mb-0">IVA</h7>
                                                                                                                </div>
                                                                                                                <div class="col-sm-4">
                                                                                                                    <div class="col-sm-12">
                                                                                                                        <input type="number" step="0.01" readonly v-model="nuevoCargoDetalle.iva"  id="iva"  class="form-control" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="row mb-3">
                                                                                                                <div class="col-sm-2">
                                                                                                                    <h7 class="mb-0">Importe</h7>
                                                                                                                </div>
                                                                                                                <div class="col-sm-4">
                                                                                                                    <div class="col-sm-12">
                                                                                                                        <input type="number" step="0.01" v-model="nuevoCargoDetalle.importe" onblur="this.value = parseFloat(this.value).toFixed(2);"  id="importe"  class="form-control" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div class="col-sm-2">
                                                                                                                    <h7 class="mb-0">Sub Total</h7>
                                                                                                                </div>
                                                                                                                <div class="col-sm-4">
                                                                                                                    <div class="col-sm-12">
                                                                                                                        <input type="number" step="0.01" readonly v-model="nuevoCargoDetalle.subtotal"  id="subtotal"  class="form-control" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="row mb-3">
                                                                                                                <div class="col-sm-8">
                                                                                                                </div>
                                                                                                                <div class="col-sm-4">
                                                                                                                    <button class="btn btn-success" @click="añadirDetalleCargoMod()">Añadir Concepto de Cargo</button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <hr/>
                                                                                                            <div class="row mb-3">
                                                                                                                <div class="col-sm-2">
                                                                                                                    <h7 class="mb-0">Fecha</h7>
                                                                                                                </div>
                                                                                                                <div class="col-sm-10">
                                                                                                                    <div class="col-sm-12">
                                                                                                                        <Datepicker
                                                                                                                            v-model="modificarCargo.fecha"
                                                                                                                            dark placeholder="Selecciona una Fecha"
                                                                                                                            autoApply
                                                                                                                            :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                                                                            :enableTimePicker="false"
                                                                                                                            monthNameFormat="long"
                                                                                                                            :format="format"
                                                                                                                            modelType="dd-MM-yyyy"
                                                                                                                            locale="es">
                                                                                                                        </Datepicker>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="row mb-3">
                                                                                                                <div class="col-sm-1">
                                                                                                                    <h7 class="mb-0">Subtotal</h7>
                                                                                                                </div>
                                                                                                                <div class="col-sm-3">
                                                                                                                    <div class="col-sm-12">
                                                                                                                        <input type="number" step="0.01" readonly v-model="modificarCargo.subtotal"  id="subtotal"  class="form-control" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div class="col-sm-1">
                                                                                                                    <h7 class="mb-0">IVA</h7>
                                                                                                                </div>
                                                                                                                <div class="col-sm-3">
                                                                                                                    <div class="col-sm-12">
                                                                                                                        <input type="number" step="0.01" readonly v-model="modificarCargo.iva"  id="iva"  class="form-control" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div class="col-sm-1">
                                                                                                                    <h7 class="mb-0">Total</h7>
                                                                                                                </div>
                                                                                                                <div class="col-sm-3">
                                                                                                                    <div class="col-sm-12">
                                                                                                                        <input type="number" step="0.01" readonly v-model="modificarCargo.total"  id="total"  class="form-control" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <hr/>
                                                                                                            <div class="row mb-3">
                                                                                                                <div class="col-sm-12">
                                                                                                                    <table class="table mb-0">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th scope="col">#</th>
                                                                                                                                <th scope="col">Concepto</th>
                                                                                                                                <th scope="col">Importe</th>
                                                                                                                                <th scope="col">% IVA</th>
                                                                                                                                <th scope="col">IVA</th>
                                                                                                                                <th scope="col">Sub Total</th>
                                                                                                                                <th scope="col">Acciones</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            <tr v-for="(item, index) in modificarCargo.detalle" :key="item.id">
                                                                                                                                <th scope="row">{{ index + 1 }}</th>
                                                                                                                                <td>{{ item.concepto_nombre }}</td>
                                                                                                                                <td>{{ item.importe }}</td>
                                                                                                                                <td>{{ item.iva_porcentaje }}</td>
                                                                                                                                <td>{{ item.iva }}</td>
                                                                                                                                <td>{{ item.subtotal }}</td>
                                                                                                                                <td><a href="javascript:;" @click="eliminarConceptoDetalleMod(item.id, index)" class="ms-3"><i class='bx bxs-trash'></i></a></td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="modal-footer">
                                                                                                            <button data-bs-dismiss="modal" data-bs-toggle="modal" @click="selectEliminar(item.id, 'cargo')" data-bs-target="#eliminarCargo" class="btn btn-danger">Eliminar</button>
                                                                                                            <button data-bs-dismiss="modal" @click="changeCargo()" class="btn btn-success">Guardar</button>
                                                                                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!-- End Modal -->
                                                                                            <a href="javascript:;" v-if="item.cantidad"  data-bs-toggle="modal" @click="seleccionarPagoMod(item.id)" :data-bs-target="'#modificar_pago'+item.id" class=""><i class='bx bxs-edit'></i></a>
                                                                                            <!-- Modal -->
                                                                                            <div class="modal fade"   :id="'modificar_pago'+item.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                                                <div class="modal-dialog modal-lg">
                                                                                                    <div class="modal-content bg-dark">
                                                                                                        <div class="modal-header">
                                                                                                            <h5 class="modal-title" id="exampleModalLabel">Modificar Pago</h5>
                                                                                                            <button type="button" id="closeModificarPago" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                                        </div>
                                                                                                        <div class="modal-body">
                                                                                                            <div class="row mb-3">
                                                                                                                <div class="col-sm-2">
                                                                                                                    <h7 class="mb-0">Fecha</h7>
                                                                                                                </div>
                                                                                                                <div class="col-sm-10">
                                                                                                                    <div class="col-sm-12">
                                                                                                                        <Datepicker
                                                                                                                            v-model="modificarPago.fecha"
                                                                                                                            dark placeholder="Selecciona una Fecha"
                                                                                                                            autoApply
                                                                                                                            :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                                                                            :enableTimePicker="false"
                                                                                                                            monthNameFormat="long"
                                                                                                                            :format="format"
                                                                                                                            modelType="dd-MM-yyyy"
                                                                                                                            locale="es">
                                                                                                                        </Datepicker>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="row mb-3">
                                                                                                                <div class="col-sm-2">
                                                                                                                    <h7 class="mb-0">Forma de Pago</h7>
                                                                                                                </div>
                                                                                                                <div class="col-sm-4">
                                                                                                                    <div class="col-sm-12">
                                                                                                                        <vue-select  :options="formasDePagoData"
                                                                                                                            @blur="removeObjectArrayFormasDePago()"
                                                                                                                            v-model="modificarPago.forma_pago"
                                                                                                                            label-by="nombre"
                                                                                                                            track-by="nombre"
                                                                                                                            value-by="id"
                                                                                                                            search-placeholder="Selecciona un Tipo de Pago"
                                                                                                                            multiple
                                                                                                                            collapse-tags="true"
                                                                                                                            taggable
                                                                                                                            searchable
                                                                                                                            placeholder="Selecciona un Tipo de Pago"
                                                                                                                            close-on-select>
                                                                                                                            <template #label="{ selected }">
                                                                                                                            <template v-if="selected">
                                                                                                                                <div style="color:white;">{{ selected.nombre }}</div>
                                                                                                                            </template>
                                                                                                                            <template v-else > <div style="color:white;">Seleccione un Tipo de Pago</div></template>
                                                                                                                            </template>
                                                                                                                            <template #dropdown-item="{ option }">
                                                                                                                            <div style="color:white;">{{ option.nombre }}</div>
                                                                                                                            </template>
                                                                                                                        </vue-select>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div class="col-sm-2">
                                                                                                                    <h7 class="mb-0">Cantidad</h7>
                                                                                                                </div>
                                                                                                                <div class="col-sm-4">
                                                                                                                    <div class="col-sm-12">
                                                                                                                        <input type="number" step="0.01" v-model="modificarPago.cantidad"  id="cantidad"  class="form-control" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="row mb-3">
                                                                                                                <div class="col-sm-2">
                                                                                                                    <h7 class="mb-0">Notas</h7>
                                                                                                                </div>
                                                                                                                <div class="col-sm-10">
                                                                                                                    <div class="col-sm-12">
                                                                                                                        <textarea  class="form-control" v-model="modificarPago.notas" placeholder="Notas..." rows="3"></textarea>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <hr/>
                                                                                                        </div>
                                                                                                        <div class="modal-footer">
                                                                                                            <button data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#eliminarPago" @click="selectEliminar(item.id, 'pago')" class="btn btn-danger">Eliminar</button>
                                                                                                            <button data-bs-dismiss="modal" @click="changePago()" class="btn btn-success">Guardar</button>
                                                                                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!-- End Modal -->
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- End Content -->
                                                </div>
                                            </div>
                                            <!-- End Inside Panel Estado de Cuenta -->
                                            <!-- Inside Panel Consultas -->
                                            <div class="tab-pane fade" id="consultas" role="tabpanel">
                                                <div class="row">
                                                    <!-- Content -->
                                                    <div class="col-12">
                                                        <div class="tab-content py-3">
                                                            <div class="tab-pane fade show active" role="tabpanel">
                                                                <div class="card">
                                                                    <div class="card-body p-">
                                                                        <div class="d-lg-flex align-items-center mb-4 gap-3">
                                                                            <div class="position-relative">
                                                                                <h5 class="card-title">Consultas</h5>
                                                                            </div>
                                                                            <div class="ms-auto">
                                                                                <add_consulta v-show="show_add_consulta" :id_expediente="id_expediente.id_expediente" ref="child_add"></add_consulta>
                                                                                <a href="javascript:;" v-if="!show_add_consulta" @click="this.show_add_consulta = true; regresarConsulta();"   class="btn btn-light mt-2 mt-lg-0"><i class="bx bxs-arrow-back"></i>Regresar a Consultas</a>
                                                                            </div>
                                                                        </div>

                                                                        <hr/>
                                                                        <div class="form-body mt-4">
                                                                            <div class="ms-auto"><consultas_component :id_expediente="id_expediente.id_expediente" ref="child_consultas"></consultas_component></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- End Content -->
                                                </div>
                                            </div>
                                            <!-- End Inside Panel Consultas -->
                                        </div>
                                        <!-- End Inside Panel Principal -->
                                </div>
                            </div>
                        </div><!-- End Row -->
                    </div>
                </div>
                <!-- Modal -->
                <div class="modal fade"   id="eliminarCargo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-sm">
                    <div class="modal-content bg-dark">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Eliminar Cargo</h5>
                            <button type="button" id="eliminarCloseCargo" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            ¿Deseas eliminar el Cargo?
                            <hr/>
                        </div>
                        <div class="modal-footer">
                            <button data-bs-dismiss="modal" @click="eliminarCargo()" class="btn btn-danger">Eliminar</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->
            <!-- Modal -->
            <div class="modal fade"   id="eliminarPago" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-sm">
                    <div class="modal-content bg-dark">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Eliminar Pago</h5>
                            <button type="button" id="eliminarClosePago" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            ¿Deseas eliminar el Pago?
                            <hr/>
                        </div>
                        <div class="modal-footer">
                            <button data-bs-dismiss="modal" @click="eliminarPago()" class="btn btn-danger">Eliminar</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->
            </div>
            </div>


</template>

<script>
import consultas_component from "./consulta/consulta.vue";
import add_consulta from "./consulta/agregar_consulta.vue";
import  Cookies from 'js-cookie';
import $ from 'jquery';
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
//import * as mykey from '../assets/js/mykey.js'
import VueNextSelect from 'vue-next-select';
import Datepicker from '@vuepic/vue-datepicker';
import { ref } from 'vue';


//console.log(Cookies.get());
export default {
    name: 'DetalleExpedienteComponent',
    components:{
        consultas_component,
        add_consulta,
        'vue-select': VueNextSelect,
        'Datepicker': Datepicker,
        },
setup() {
    const startTime = ref({ hours: 0, minutes: 0 });
    const date = ref(new Date());
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
        function formatDate(userDate) {
            // format from M/D/YYYY to YYYYMMDD
            return (new Date(userDate).toJSON().slice(0,10).split('-').reverse().join('-'));
            }
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var today_is = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
        var today_date = formatDate(today_is);
    return {
            startTime,
            date,
            format,
            today_date,
        }
},
data() {
	return{
        id_expediente: '',
		expedienteData: [],
        detalleExpedienteData: [],
        productoEncontrado: {
            cantidad: '',
            precio: '',
            producto: '',
            nombre: '',
            unidades_medida: [],
            unidad_medida: [],
        },
        productoSeleccionado: '',
		URL_TENANT: '',
        // Antecedentes:
        // -----> Antecedentes Hereditarios:
        antecedentesHereditariosData: [],
        // -----> Antecedentes Patológicos:
        antecedentesPatologicosData: [],
        // -----> Antecedentes No Patológicos:
        antecedentesNoPatologicosData: [],
        tipo_sangre: [
            {tipo: "A+"},
            {tipo: "B+"},
            {tipo: "O+"},
            {tipo: "A-"},
            {tipo: "B-"},
            {tipo: "O-"},
            {tipo: "AB+"},
            {tipo: "AB-"},
        ],
        tipo_antecedente: [
            {tipo_antecedente: "Nunca"},
            {tipo_antecedente: "Casual"},
            {tipo_antecedente: "Moderado"},
            {tipo_antecedente: "Intenso"},
            {tipo_antecedente: "Otro"},
        ],
        // -----> Antecedentes Perinatales:
        antecedentesPerinatalesData: [],
        // -----> Antecedentes Ginecológicos:
        antecedentesGinecologicosData: [],
        alteracion_menstruacion_frecuencia: [
            {tipo: "Amenorrea"},
            {tipo: "Polimenorrea"},
            {tipo: "Oligomenorrea"},
        ],
        alteracion_menstruacion_duracion: [
            {tipo: "Menometroragia"},
        ],
        alteracion_menstruacion_cantidad: [
            {tipo: "Hipermenorrea"},
            {tipo: "Hipomenorrea"},
        ],
            // -----> Antecedentes Obstetrico:
        antecedentesObstetricosData: [],
            // -----> Exloración Física:
        exploracionFisicaData: [],
            // -----> Interrogatorio:
        interrogatorioData: [],
            // -----> Estilo de Vida:
        estiloDeVidaData: [],
            // -----> Alergias:
        alergiasData: [],
            // -----> Incapacidades:
        incapacidadesData: [],
        incapacidad: {
            incapacidad: "",
            fecha_inicial: "",
            fecha_final: "",
            notas: ""
        },
            // -----> Documentos:
        documento: {
            nombre: "",
            descripcion: ""
        },
        documentosData: [],
            // -----> Estado de Cuenta:
        cargoConceptoData: [],
        estadoDeCuentaData: [],
        estadoDeCuentaInfo: {
            total_cargos: 0.00,
            total_pagos: 0.00,
            total_saldo: 0.00,
            positivo: true
        },
        nuevoCargo:{
            fecha: this.today_date,
            subtotal: "0.00",
            iva: "0.00",
            total: "0.00",
            observaciones: "",
            expediente: "",
            detalle: []
        },
        nuevoCargoDetalle:{
            cargo_concepto: "",
            concepto_nombre: "",
            iva_porcentaje: "12",
            iva: "0.00",
            importe: "0.00",
            subtotal: "0.00"
        },
        modificarCargo: {
            fecha: this.today_date,
            subtotal: "0.00",
            iva: "0.00",
            total: "0.00",
            observaciones: "",
            expediente: "",
            detalle: [],
            id: ""
        },
            // -----> Pagos
        nuevoPago: {
            fecha: this.today_date,
            forma_pago: "",
            cantidad: 0.00,
            notas: "",
        },
        modificarPago: {
            fecha: "",
            forma_pago: "",
            cantidad: 0.00,
            notas: "",
            id: "",
        },
        eliminarCargoPago:{
            id_eliminar: "",
            tipo: "",
        },
        formasDePagoData: [],
        // Consulta:
        show_add_consulta: true,
		}
	},
computed: {
},
watch:{
    nuevoCargoDetalle: {
        handler: function () {
            const _iva = (parseFloat(this.nuevoCargoDetalle.importe).toFixed(2) * parseFloat(this.nuevoCargoDetalle.iva_porcentaje).toFixed(2)) /100;
            this.nuevoCargoDetalle.iva = parseFloat(_iva).toFixed(2);
            const _total = (Number(this.nuevoCargoDetalle.importe)) + (Number(this.nuevoCargoDetalle.iva));
            this.nuevoCargoDetalle.subtotal = parseFloat(_total).toFixed(2);
        },
        deep: true,
    },
},

methods: {
    // Consulta Regresar:
    async regresarConsulta(){
        this.$refs.child_consultas.editar = false;
    },
    // Filtro Moneda:
    formatCurrency(value) {
        if (typeof value == 'string' && value !== "") {
            value = parseFloat(value);
        }
        if (typeof value !== 'number') {
            return value;
        }
        const formatter = new Intl.NumberFormat('es-GT', {
            style: 'currency',
            currency: 'GTQ',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return formatter.format(value);
    },
    //Quitar Objeto:
    async removeObjectArray() {
        //console.log("REMOVE ARRAY");
        if(this.antecedentesNoPatologicosData[0].tipo_sangre.length > 1){
            this.antecedentesNoPatologicosData[0].tipo_sangre.shift();
        }
        if(this.antecedentesNoPatologicosData[0].tabaquismo.length > 1){
            this.antecedentesNoPatologicosData[0].tabaquismo.shift();
        }
        if(this.antecedentesNoPatologicosData[0].alcoholismo.length > 1){
            this.antecedentesNoPatologicosData[0].alcoholismo.shift();
        }
    },
    async removeObjectArrayEstadoCuenta() {
        if(this.nuevoCargoDetalle.cargo_concepto.length > 1){
            this.nuevoCargoDetalle.cargo_concepto.shift();
        }
    },
    async removeObjectArrayFormasDePago() {
        if(this.nuevoPago.forma_pago.length > 1){
            this.nuevoPago.forma_pago.shift();
        }
    },
    // Agregar Detalle Cargo:
    async seleccionarCargoMod(_id){
        await this.getCargoConceptoData();
        //console.log("ID mod");
        //console.log(_id);
        const _object_cargo = this.estadoDeCuentaData.find(el => el.id == _id);
        //console.log(_object_cargo);
        this.modificarCargo.fecha = _object_cargo.fecha;
        this.modificarCargo.subtotal = _object_cargo.subtotal;
        this.modificarCargo.iva = _object_cargo.iva;
        this.modificarCargo.total = _object_cargo.total;
        this.modificarCargo.observaciones = _object_cargo.observaciones;
        this.modificarCargo.expediente = _object_cargo.expediente;
        this.modificarCargo.id = _object_cargo.id;
        //console.log(this.cargoConceptoData);
        this.modificarCargo.detalle = [];
        for(var x = 0; x < _object_cargo.cargo_paciente_detalle_cargo_paciente.length; x++){
            //console.log("PUSH ARRAY");
            //console.log(_object_cargo.cargo_paciente_detalle_cargo_paciente[x].cargo_concepto);
            //console.log(this.cargoConceptoData);
            this._concepto_nombre = this.cargoConceptoData.find(item => item.id === _object_cargo.cargo_paciente_detalle_cargo_paciente[x].cargo_concepto);
            //console.log(this._concepto_nombre);
            this._nuevoCargoDetalle = {
                cargo_concepto: _object_cargo.cargo_paciente_detalle_cargo_paciente[x].cargo_concepto,
                concepto_nombre: this._concepto_nombre.descripcion,
                iva_porcentaje: _object_cargo.cargo_paciente_detalle_cargo_paciente[x].iva_porcentaje,
                iva: _object_cargo.cargo_paciente_detalle_cargo_paciente[x].iva,
                importe: _object_cargo.cargo_paciente_detalle_cargo_paciente[x].importe,
                subtotal: _object_cargo.cargo_paciente_detalle_cargo_paciente[x].subtotal,
                nuevo: false,
            }
            this.modificarCargo.detalle.push(this._nuevoCargoDetalle);
        }

    },
    async detalleCargoCalculo(){
        this.subtotal = 0.00;
        this.iva = 0.00;
        this.total = 0.00;
        for(var x in this.nuevoCargo.detalle){
            this.subtotal = this.subtotal + (Number(this.nuevoCargo.detalle[x].importe));
            this.iva = this.iva + (Number(this.nuevoCargo.detalle[x].iva));
            this.total = this.total + (Number(this.nuevoCargo.detalle[x].subtotal));
        }
            this.nuevoCargo.subtotal = this.subtotal;
            this.nuevoCargo.iva = this.iva;
            this.nuevoCargo.total = this.total;
    },
    async añadirDetalleCargo(){
        if(this.nuevoCargoDetalle.cargo_concepto != ""){
            this._concepto_nombre = this.cargoConceptoData.find(el => el.id == this.nuevoCargoDetalle.cargo_concepto);
            this.nuevoCargoDetalle.concepto_nombre = this._concepto_nombre.descripcion;
            this.nuevoCargo.detalle.push(this.nuevoCargoDetalle);
            this.nuevoCargoDetalle = {
                cargo_concepto: "",
                concepto_nombre: "",
                iva_porcentaje: "12",
                iva: "0.00",
                importe: "0.00",
                subtotal: "0.00"
            }
            this.detalleCargoCalculo();
        }else{
            notificaciones.round_error_noti($.msg='Concepto es Obligatorio');
        }
    },
    async detalleCargoCalculoMod(){
        this.subtotal = 0.00;
        this.iva = 0.00;
        this.total = 0.00;
        for(var x in this.modificarCargo.detalle){
            this.subtotal = this.subtotal + (Number(this.modificarCargo.detalle[x].importe));
            this.iva = this.iva + (Number(this.modificarCargo.detalle[x].iva));
            this.total = this.total + (Number(this.modificarCargo.detalle[x].subtotal));
        }
            this.modificarCargo.subtotal = this.subtotal;
            this.modificarCargo.iva = this.iva;
            this.modificarCargo.total = this.total;
    },
    async añadirDetalleCargoMod(){
        if(this.nuevoCargoDetalle.cargo_concepto != ""){
            this._concepto_nombre = this.cargoConceptoData.find(el => el.id == this.nuevoCargoDetalle.cargo_concepto);
            this.nuevoCargoDetalle.concepto_nombre = this._concepto_nombre.descripcion;
            this.nuevoCargoDetalle.nuevo = true;
            this.modificarCargo.detalle.push(this.nuevoCargoDetalle);
            this.nuevoCargoDetalle = {
                cargo_concepto: "",
                concepto_nombre: "",
                iva_porcentaje: "12",
                iva: "0.00",
                importe: "0.00",
                subtotal: "0.00"
            }
            this.detalleCargoCalculoMod();
        }else{
            notificaciones.round_error_noti($.msg='Concepto es Obligatorio');
        }
    },
    async seleccionarPagoMod(_id){
        await this.getFormasDePago();
        const _object_pago = this.estadoDeCuentaData.find(el => el.id == _id);
        this.modificarPago.fecha = _object_pago.fecha;
        this.modificarPago.forma_pago = [_object_pago.forma_pago];
        this.modificarPago.cantidad = _object_pago.cantidad;
        this.modificarPago.notas = _object_pago.notas;
        this.modificarPago.id = _object_pago.id;
    },
    async getExpedienteData() {
        const response = await fetch(this.URL_TENANT+'/api/expediente_get/'+this.id_expediente.id_expediente);
        this.expedienteData = await response.json();
    },
    async getDetalleExpedienteData() {
        const response = await fetch(this.URL_TENANT+'/api/expediente_get/?id_expediente='+this.id_expediente.id_expediente);
        this.detalleVentaData = await response.json();
    },
    // Buscar Antecedentes:
        // Antecedentes Hereditarios:
    async getAntecedentesHereditariosData(){
        const response = await fetch(this.URL_TENANT+'/api/antecedente_hereditario/?expediente='+this.id_expediente.id_expediente);
        this.antecedentesHereditariosData = await response.json();
    },
        //Antecedentes Patológicos:
    async getAntecedentesPatologicosData(){
        const response = await fetch(this.URL_TENANT+'/api/antecedente_patologico/?expediente='+this.id_expediente.id_expediente);
        this.antecedentesPatologicosData = await response.json();
    },
        //Antecedentes No Patológicos:
    async getAntecedentesNoPatologicosData(){
        const response = await fetch(this.URL_TENANT+'/api/antecedente_no_patologico/?expediente='+this.id_expediente.id_expediente);
        this._antecedentesNoPatologicosData = await response.json();
        this._alcoholismo = this._antecedentesNoPatologicosData[0].alcoholismo != "" ? [this._antecedentesNoPatologicosData[0].alcoholismo] : "";
        this._tabaquismo = this._antecedentesNoPatologicosData[0].tabaquismo != "" ? [this._antecedentesNoPatologicosData[0].tabaquismo] : "";
        this._tipo_sangre = this._antecedentesNoPatologicosData[0].tipo_sangre != "" ? [this._antecedentesNoPatologicosData[0].tipo_sangre] : "";
        this.antecedentesNoPatologicosData = [{
            alcoholismo:  this._alcoholismo,
            descripcion: this._antecedentesNoPatologicosData[0].descripcion,
            expediente: this._antecedentesNoPatologicosData[0].expediente,
            id: this._antecedentesNoPatologicosData[0].id,
            tabaquismo: this._tabaquismo,
            tipo_sangre: this._tipo_sangre,
        }]
    },
        //Antecedentes Perinatal:
    async getAntecedentesPerinatalesData(){
        const response = await fetch(this.URL_TENANT+'/api/antecedente_perinatal/?expediente='+this.id_expediente.id_expediente);
        this.antecedentesPerinatalesData = await response.json();
        if(this.antecedentesPerinatalesData[0].hora_nacimiento != undefined){
            this.antecedentesPerinatalesData[0].hora_nacimiento = {
                hours: parseInt(this.antecedentesPerinatalesData[0].hora_nacimiento.substring(0,2)),
                minutes: parseInt(this.antecedentesPerinatalesData[0].hora_nacimiento.substring(3,5)),
                seconds: 0,
            }
        }
    },
        //Antecedentes Ginecológicos:
    async getAntecedentesGinecologicosData(){
        const response = await fetch(this.URL_TENANT+'/api/antecedente_ginecologico/?expediente='+this.id_expediente.id_expediente);
        this.antecedentesGinecologicosData = await response.json();
    },
        //Antecedentes Obstétricos:
    async getAntecedentesObstetricosData(){
        const response = await fetch(this.URL_TENANT+'/api/antecedente_obstetrico/?expediente='+this.id_expediente.id_expediente);
        this.antecedentesObstetricosData = await response.json();
    },
        //Exploración Física:
    async getExploracionFisicaData(){
        const response = await fetch(this.URL_TENANT+'/api/exploracion_fisica/?expediente='+this.id_expediente.id_expediente);
        this.exploracionFisicaData = await response.json();
    },
        //Interrogatorio:
    async getInterrogatorioData(){
        const response = await fetch(this.URL_TENANT+'/api/interrogatorio/?expediente='+this.id_expediente.id_expediente);
        this.interrogatorioData = await response.json();
    },
        //Estilo de Vida:
    async getEstiloDeVidaData(){
        const response = await fetch(this.URL_TENANT+'/api/estilo_de_vida/?expediente='+this.id_expediente.id_expediente);
        this.estiloDeVidaData = await response.json();
    },
        //Alergias:
    async getAlergiasData(){
        const response = await fetch(this.URL_TENANT+'/api/alergia/?expediente='+this.id_expediente.id_expediente);
        this.alergiasData = await response.json();
    },
        //Incapacidades:
    async getIncapacidadesData(){
        const response = await fetch(this.URL_TENANT+'/api/incapacidad/?expediente='+this.id_expediente.id_expediente);
        this.incapacidadesData = await response.json();
    },
        //Documentos:
    async getDocumentosData(){
        const response = await fetch(this.URL_TENANT+'/api/documentos_expediente/?expediente='+this.id_expediente.id_expediente);
        this.documentosData = await response.json();
    },
    async getCargoConceptoData(){
        const response = await fetch(this.URL_TENANT+'/api/cargo_concepto/');
        this.cargoConceptoData = await response.json();
    },
    async getFormasDePago(){
        const response = await fetch(this.URL_TENANT+'/api/global_forma_pago/');
        this.formasDePagoData = await response.json();
    },
        //Estado de Cuenta:
        //Cargos:
    async getEstadoDeCuenta(){
        const response = await fetch(this.URL_TENANT+'/api/cargo_paciente/?expediente='+this.id_expediente.id_expediente);
        const _cargos_json = await response.json();
        const response2 = await fetch(this.URL_TENANT+'/api/pago_paciente/?expediente='+this.id_expediente.id_expediente);
        const _pagos_json = await response2.json();
        //console.log("ESTADO DE CUENTA");
        //console.log(_cargos_json);
        //console.log(_pagos_json);
        const merged = [ ..._cargos_json, ..._pagos_json ];
        //console.log(merged);
        this.estadoDeCuentaData = merged.sort(function(a, b) {
            var fechaA = new Date(a.fecha_creacion.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
            var fechaB = new Date(b.fecha_creacion.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
            return fechaB - fechaA;
        });
        //console.log(this.estadoDeCuentaData);
        this.total_cargos =  0;
        for (let i = 0; i < _cargos_json.length; i++) {
            this.total_cargos = parseFloat(this.total_cargos) + parseFloat(_cargos_json[i].total);
            //console.log("VA SUMAR:"+ parseFloat(_cargos_json[i].total));
        }
        this.total_pagos =  0;
        for (let i = 0; i < _pagos_json.length; i++) {
            this.total_pagos += (parseFloat(_pagos_json[i].cantidad));
        }
        //console.log("ESTADO DE CUENTAsssssssssss");

        //console.log(this.total_pagos);
        //console.log(this.total_cargos);
        this.total_saldo = parseFloat(this.total_pagos) - parseFloat(this.total_cargos);
        if(this.total_saldo < 0){
            this.estadoDeCuentaInfo.positivo = false;
            this.estadoDeCuentaInfo.total_saldo = (this.total_saldo *-1).toFixed(2);
        }else{
            this.estadoDeCuentaInfo.positivo = true;
            this.estadoDeCuentaInfo.total_saldo = this.total_saldo.toFixed(2);
        }
        this.estadoDeCuentaInfo.total_cargos = this.total_cargos.toFixed(2);
        this.estadoDeCuentaInfo.total_pagos = this.total_pagos.toFixed(2);
    },
    ////////////////////// GUARDAR //////////////////////////////////
    // Guardar Incapacidades:
    async saveIncapacidades(){
        if(this.incapacidad.incapacidad != ""){

            try{
                const formIncapacidad = new FormData();
                formIncapacidad.append("incapacidad", this.incapacidad.incapacidad);
                formIncapacidad.append("fecha_inicial", this.incapacidad.fecha_inicial);
                formIncapacidad.append("fecha_final", this.incapacidad.fecha_final);
                formIncapacidad.append("notas", this.incapacidad.notas);
                formIncapacidad.append("expediente", this.id_expediente.id_expediente);
                await fetch(this.URL_TENANT+'/api/incapacidad/',{
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    }, body: formIncapacidad
                })
                notificaciones.round_success_noti($.msg='Se Ingresó la Incapacidad '+this.incapacidad.incapacidad);
                this.incapacidad ={
                    incapacidad: "",
                    fecha_inicial: "",
                    fecha_final: "",
                    notas: ""
                }
                this.getIncapacidadesData();
            }catch(error){
                //console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');

            }
        }else{
            notificaciones.round_error_noti($.msg='Debe de ingresar por lo menos la Incapacidad');
        }
    },
    // Guardar Documentos:
    async saveDocumentos(){
        const archivo = document.getElementById("documentoExpediente");
        //console.log(archivo);
        if(this.documento.nombre != "" && archivo.files[0] != undefined){
            try{
                const formData = new FormData();
                formData.append("nombre", this.documento.nombre);
                formData.append("expediente", this.id_expediente.id_expediente);
                formData.append("descripcion", this.documento.descripcion);
                formData.append("archivo", archivo.files[0]);
                await fetch(this.URL_TENANT+'/api/documentos_expediente/',{
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    }, body: formData
                })
                notificaciones.round_success_noti($.msg='Se Guardó el Documento '+this.documento.nombre);
                this.documento ={
                    nombre: "",
                    descripcion: "",
                }
                document.getElementById("documentoExpediente").value=null;
                this.getDocumentosData();
            }catch(error){
                //console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');

            }
        }else{
            notificaciones.round_error_noti($.msg='Nombre y Documento son Obligatorios');
        }
    },
    /// Guardar Estado de Cuenta
    // Cargos:
    async saveCargo(){
        if(this.nuevoCargo.fecha != ""){
            try{
                let responseData;
                const formData = new FormData();
                formData.append("expediente", this.id_expediente.id_expediente);
                formData.append("fecha", this.nuevoCargo.fecha);
                formData.append("iva", this.nuevoCargo.iva);
                formData.append("subtotal", this.nuevoCargo.subtotal);
                formData.append("total", this.nuevoCargo.total);
                formData.append("observaciones", this.nuevoCargo.observaciones);
                await fetch(this.URL_TENANT+'/api/cargo_paciente/',{
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    }, body: formData
                }).then(response => response.json())
                .then(data => {
                    responseData = data;
                })
                //console.log("RESPONSE DATA:");
                //console.log(responseData);
                //console.log(responseData.id);
                //console.log(responseData['id']);
                for(var x in this.nuevoCargo.detalle){
                    const formData2 = new FormData();
                    //console.log("X");
                    //console.log(x);
                    formData2.append("cargo_paciente", responseData['id']);
                    formData2.append("cargo_concepto", this.nuevoCargo.detalle[x].cargo_concepto[0]);
                    formData2.append("iva_porcentaje", this.nuevoCargo.detalle[x].iva_porcentaje);
                    formData2.append("iva", this.nuevoCargo.detalle[x].iva);
                    formData2.append("importe", this.nuevoCargo.detalle[x].importe);
                    formData2.append("subtotal", this.nuevoCargo.detalle[x].subtotal);
                    await fetch(this.URL_TENANT+'/api/cargo_paciente_detalle/',{
                        method: "POST",
                        headers: {
                            'X-CSRFToken': Cookies.get('csrftoken'),
                        }, body:formData2
                    })
                }
                notificaciones.round_success_noti($.msg='Se Guardó el Cargo al Paciente');
                this.getEstadoDeCuenta();
                this.nuevoCargo ={
                    fecha: this.today_date,
                    subtotal: "0.00",
                    iva: "0.00",
                    total: "0.00",
                    observaciones: "",
                    expediente: "",
                    detalle: []
                }
            }catch(error){
                //console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');

            }
        }else{
            notificaciones.round_error_noti($.msg='La Fecha es Requerida');
        }
    },
    // Pagos:
    async savePago(){
        if(this.nuevoPago.fecha != "" && this.nuevoPago.cantidad != 0.00 ){
            try{
                const formData = new FormData();
                formData.append("expediente", this.id_expediente.id_expediente);
                formData.append("fecha", this.nuevoPago.fecha);
                formData.append("forma_pago", this.nuevoPago.forma_pago[0]);
                formData.append("cantidad", this.nuevoPago.cantidad);
                formData.append("notas", this.nuevoPago.notas);
                await fetch(this.URL_TENANT+'/api/pago_paciente/',{
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    }, body: formData
                })
                notificaciones.round_success_noti($.msg='Se Guardó el Pago al Paciente');
                this.getEstadoDeCuenta();
                this.nuevoPago ={
                    fecha: this.today_date,
                    forma_pago: "",
                    cantidad: "0.00",
                    notas: "",
                }
            }catch(error){
                //console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');

            }
        }else{
            notificaciones.round_error_noti($.msg='Fecha y Cantidad son Obligatorios');
        }
    },
    ////////////////////// MODIFICAR //////////////////////////////////
    async changePago(){
        if(this.modificarPago.fecha != ""){
            try{
                const formData = new FormData();
                formData.append("expediente", this.id_expediente.id_expediente);
                formData.append("fecha", this.modificarPago.fecha);
                formData.append("forma_pago", this.modificarPago.forma_pago[0]);
                formData.append("cantidad", this.modificarPago.cantidad);
                formData.append("notas", this.modificarPago.notas);
                formData.append("notas", this.modificarPago.notas);
                await fetch(this.URL_TENANT+'/api/pago_paciente/'+this.modificarPago.id+'/',{
                    method: 'PUT',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    }, body: formData
                })
                notificaciones.round_success_noti($.msg='Se Modificó el Pago al Paciente');
                this.getEstadoDeCuenta();
                this.modificarPago ={
                    fecha: "",
                    forma_pago: "",
                    cantidad: "0.00",
                    notas: "",
                    id: "",
                }
            }catch(error){
                //console.log(error);
            }
        }
    },
    async changeCargo(){
        if(this.modificarCargo.fecha != ""){
            try{
                let responseData;
                const formData = new FormData();
                formData.append("expediente", this.id_expediente.id_expediente);
                formData.append("fecha", this.modificarCargo.fecha);
                formData.append("iva", this.modificarCargo.iva);
                formData.append("subtotal", this.modificarCargo.subtotal);
                formData.append("total", this.modificarCargo.total);
                formData.append("observaciones", this.modificarCargo.observaciones);
                await fetch(this.URL_TENANT+'/api/cargo_paciente/'+this.modificarCargo.id+'/',{
                    method: 'PUT',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    }, body: formData
                }).then(response => response.json())
                .then(data => {
                    responseData = data;
                })
                //console.log("RESPONSE DATA:");
                //console.log(responseData);
                //console.log(responseData.id);
                //console.log(responseData['id']);
                for(var x in this.modificarCargo.detalle){
                    if(this.modificarCargo.detalle[x].nuevo === true){
                        const formData2 = new FormData();
                        formData2.append("cargo_paciente", responseData['id']);
                        formData2.append("cargo_concepto", this.modificarCargo.detalle[x].cargo_concepto[0]);
                        formData2.append("iva_porcentaje", this.modificarCargo.detalle[x].iva_porcentaje);
                        formData2.append("iva", this.modificarCargo.detalle[x].iva);
                        formData2.append("importe", this.modificarCargo.detalle[x].importe);
                        formData2.append("subtotal", this.modificarCargo.detalle[x].subtotal);
                        await fetch(this.URL_TENANT+'/api/cargo_paciente_detalle/',{
                            method: "POST",
                            headers: {
                                'X-CSRFToken': Cookies.get('csrftoken'),
                            }, body:formData2
                        })
                    }

                }
                notificaciones.round_success_noti($.msg='Se Guardó el Cargo al Paciente');
                this.getEstadoDeCuenta();
                this.modificarCargo ={
                    fecha: this.today_date,
                    subtotal: "0.00",
                    iva: "0.00",
                    total: "0.00",
                    observaciones: "",
                    expediente: "",
                    detalle: [],
                    id: ""
                }
            }catch(error){
                //console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');

            }
        }else{
            notificaciones.round_error_noti($.msg='La Fecha es Requerida');
        }
    },
    // Modificar Antecedentes Hereditarios:
    async modificarAntecedentesHereditarios(){
        try{
            for(var i = 0; i < this.antecedentesHereditariosData.length; i++)
            {
                const formData = new FormData();
                formData.append("abuelos", this.antecedentesHereditariosData[i].abuelos);
                formData.append("hermanos", this.antecedentesHereditariosData[i].hermanos);
                formData.append("padre", this.antecedentesHereditariosData[i].padre);
                formData.append("madre", this.antecedentesHereditariosData[i].madre);
                formData.append("id", this.antecedentesHereditariosData[i].id);
                await fetch(this.URL_TENANT+'/api/antecedente_hereditario/'+this.antecedentesHereditariosData[i].id+'/',{
                    method: 'PUT',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    }, body: formData
                })
            }
            notificaciones.round_success_noti($.msg='Antecedentes Hereditarios Modificados Exitosamente');
            this.getAntecedentesHereditariosData();
        }catch(error){
            //console.log(error);
            notificaciones.round_error_noti($.msg='Error, algo salió mal');
        }
    },
    // Modificar Antecedentes Patológicos:
    async modificarAntecedentesPatologicos(){
        try{
            const formData = new FormData();
            formData.append("descripcion", this.antecedentesPatologicosData[0].descripcion);
            formData.append("id", this.antecedentesPatologicosData[0].id);
            await fetch(this.URL_TENANT+'/api/antecedente_patologico/'+this.antecedentesPatologicosData[0].id+'/',{
                method: 'PUT',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }, body: formData
            })
            notificaciones.round_success_noti($.msg='Antecedentes Patológicos Modificados Exitosamente');
            this.getAntecedentesPatologicosData();
        }catch(error){
            //console.log(error);
            notificaciones.round_error_noti($.msg='Error, algo salió mal');

        }
    },
    // Mosificar Antecedentes No Patológicos:
    async modificarAntecedentesNoPatologicos(){
        try{
            const formData = new FormData();
            formData.append("descripcion", this.antecedentesNoPatologicosData[0].descripcion);
            if(this.antecedentesNoPatologicosData[0].alcoholismo != ""){
                formData.append("alcoholismo", this.antecedentesNoPatologicosData[0].alcoholismo[0]);
            }else{
                formData.append("alcoholismo", this.antecedentesNoPatologicosData[0].alcoholismo);
            }
            if(this.antecedentesNoPatologicosData[0].tabaquismo != ""){
                formData.append("tabaquismo", this.antecedentesNoPatologicosData[0].tabaquismo[0]);
            }else{
                formData.append("tabaquismo", this.antecedentesNoPatologicosData[0].tabaquismo);
            }
            if(this.antecedentesNoPatologicosData[0].tipo_sangre != ""){
                formData.append("tipo_sangre", this.antecedentesNoPatologicosData[0].tipo_sangre[0]);
            }else{
                formData.append("tipo_sangre", this.antecedentesNoPatologicosData[0].tipo_sangre);
            }
            formData.append("id", this.antecedentesNoPatologicosData[0].id);
            await fetch(this.URL_TENANT+'/api/antecedente_no_patologico/'+this.antecedentesNoPatologicosData[0].id+'/',{
                method: 'PUT',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }, body: formData
            })
            notificaciones.round_success_noti($.msg='Antecedentes No Patológicos Modificados Exitosamente');
            this.getAntecedentesNoPatologicosData();
        }catch(error){
            notificaciones.round_error_noti($.msg='Error, algo salió mal');
        }
    },
    // Mosificar Antecedentes Perinatales:
    async modificarAntecedentesPerinatales(){
        try{
            const formData = new FormData();
            formData.append("id", this.antecedentesPerinatalesData[0].id);
            formData.append("abdominal", this.antecedentesPerinatalesData[0].abdominal == undefined ? "" : this.antecedentesPerinatalesData[0].abdominal);
            formData.append("anestecia", this.antecedentesPerinatalesData[0].anestecia);
            formData.append("apgar", this.antecedentesPerinatalesData[0].apgar == undefined ? "" : this.antecedentesPerinatalesData[0].apgar);
            formData.append("apnea_neonatal", this.antecedentesPerinatalesData[0].apnea_neonatal);
            formData.append("ballard",this.antecedentesPerinatalesData[0].ballard == undefined ? "" : this.antecedentesPerinatalesData[0].ballard);
            formData.append("capurro", this.antecedentesPerinatalesData[0].capurro == undefined ? "" : this.antecedentesPerinatalesData[0].capurro);
            formData.append("cefalico", this.antecedentesPerinatalesData[0].cefalico == undefined ? "" : this.antecedentesPerinatalesData[0].cefalico);
            formData.append("cianosis", this.antecedentesPerinatalesData[0].cianosis);
            formData.append("complicaciones", this.antecedentesPerinatalesData[0].complicaciones);
            formData.append("convulsiones", this.antecedentesPerinatalesData[0].convulsiones);
            formData.append("edad_gestacional", this.antecedentesPerinatalesData[0].edad_gestacional == undefined ? "" : this.antecedentesPerinatalesData[0].edad_gestacional);
            formData.append("expediente", this.antecedentesPerinatalesData[0].expediente);
            formData.append("hemorragias", this.antecedentesPerinatalesData[0].hemorragias);
            formData.append("ictericia", this.antecedentesPerinatalesData[0].ictericia);
            formData.append("no_de_gestacion", this.antecedentesPerinatalesData[0].no_de_gestacion == undefined ? "" : this.antecedentesPerinatalesData[0].no_de_gestacion);
            formData.append("observaciones", this.antecedentesPerinatalesData[0].observaciones);
            formData.append("peso_al_nacer", this.antecedentesPerinatalesData[0].peso_al_nacer == undefined ? "" : this.antecedentesPerinatalesData[0].peso_al_nacer);
            formData.append("silverman_and", this.antecedentesPerinatalesData[0].silverman_and == undefined ? "" : this.antecedentesPerinatalesData[0].silverman_and);
            formData.append("sitio_atencion_parto", this.antecedentesPerinatalesData[0].sitio_atencion_parto);
            formData.append("talla", this.antecedentesPerinatalesData[0].talla == undefined ? "" : this.antecedentesPerinatalesData[0].talla);
            formData.append("tipo_anestecia", this.antecedentesPerinatalesData[0].tipo_anestecia);
            formData.append("tipo_parto", this.antecedentesPerinatalesData[0].tipo_parto);
            formData.append("toracico", this.antecedentesPerinatalesData[0].toracico == undefined ? "" : this.antecedentesPerinatalesData[0].toracico);
            //console.log("PERI");
            this._hora_nacimiento = this.antecedentesPerinatalesData[0].hora_nacimiento != undefined ? this.antecedentesPerinatalesData[0].hora_nacimiento.hours+":"+ this.antecedentesPerinatalesData[0].hora_nacimiento.minutes : "";
            formData.append("hora_nacimiento", this._hora_nacimiento);
            await fetch(this.URL_TENANT+'/api/antecedente_perinatal/'+this.antecedentesPerinatalesData[0].id+'/',{
                method: 'PUT',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }, body: formData
            })
            notificaciones.round_success_noti($.msg='Antecedentes Perinatales Modificados Exitosamente');
            this.getAntecedentesPerinatalesData();
        }catch(error){
            //console.log(error);
            notificaciones.round_error_noti($.msg='Error, algo salió mal');
        }
    },
    // Mosificar Antecedentes Perinatales:
    async modificarAntecedentesGinecologicos(){
        try{
            const formData = new FormData();
            formData.append("id", this.antecedentesGinecologicosData[0].id);
            formData.append("expediente",  this.antecedentesGinecologicosData[0].expediente);
            formData.append("menarca",this.antecedentesGinecologicosData[0].menarca == undefined ? "" : this.antecedentesGinecologicosData[0].menarca);
            formData.append("menopausia",this.antecedentesGinecologicosData[0].menopausia == undefined ? "" : this.antecedentesGinecologicosData[0].menopausia);
            formData.append("formula_menstrual",this.antecedentesGinecologicosData[0].formula_menstrual);
            formData.append("fum",this.antecedentesGinecologicosData[0].fum == undefined ? "" : this.antecedentesGinecologicosData[0].fum);
            formData.append("fu_citologia",this.antecedentesGinecologicosData[0].fu_citologia == undefined ? "" : this.antecedentesGinecologicosData[0].fu_citologia);
            formData.append("alteracion_menstruacion_frecuencia",this.antecedentesGinecologicosData[0].alteracion_menstruacion_frecuencia);
            formData.append("alteracion_menstruacion_duracion",this.antecedentesGinecologicosData[0].alteracion_menstruacion_duracion);
            formData.append("alteracion_menstruacion_cantidad",this.antecedentesGinecologicosData[0].alteracion_menstruacion_cantidad);
            formData.append("inicio_vida_sexual",this.antecedentesGinecologicosData[0].inicio_vida_sexual == undefined ? "" : this.antecedentesGinecologicosData[0].inicio_vida_sexual);
            formData.append("no_parejas",this.antecedentesGinecologicosData[0].no_parejas == undefined ? "" : this.antecedentesGinecologicosData[0].no_parejas);
            formData.append("vaginitis",this.antecedentesGinecologicosData[0].vaginitis);
            formData.append("cervicitis_mucopurulenta",this.antecedentesGinecologicosData[0].cervicitis_mucopurulenta);
            formData.append("chancroide",this.antecedentesGinecologicosData[0].chancroide);
            formData.append("clamidia",this.antecedentesGinecologicosData[0].clamidia);
            formData.append("enfermedad_inflamatoria_pelvica_eip",this.antecedentesGinecologicosData[0].enfermedad_inflamatoria_pelvica_eip);
            formData.append("gonorrea",this.antecedentesGinecologicosData[0].gonorrea);
            formData.append("hepatitis",this.antecedentesGinecologicosData[0].hepatitis);
            formData.append("herpes",this.antecedentesGinecologicosData[0].herpes);
            formData.append("linfogranuloma_venerio_lgv",this.antecedentesGinecologicosData[0].linfogranuloma_venerio_lgv);
            formData.append("molusco_contagioso",this.antecedentesGinecologicosData[0].molusco_contagioso);
            formData.append("piojos_ladillas_publicos",this.antecedentesGinecologicosData[0].piojos_ladillas_publicos);
            formData.append("sarna",this.antecedentesGinecologicosData[0].sarna);
            formData.append("sifilis",this.antecedentesGinecologicosData[0].sifilis);
            formData.append("tricomoniasis",this.antecedentesGinecologicosData[0].tricomoniasis);
            formData.append("vaginosis_bacteriana_vb",this.antecedentesGinecologicosData[0].vaginosis_bacteriana_vb);
            formData.append("vih",this.antecedentesGinecologicosData[0].vih);
            formData.append("virus_del_papiloma_humano_vph",this.antecedentesGinecologicosData[0].virus_del_papiloma_humano_vph);
            formData.append("tipo_anticoncepcion",this.antecedentesGinecologicosData[0].tipo_anticoncepcion);
            formData.append("inicio_anticoncepcion",this.antecedentesGinecologicosData[0].inicio_anticoncepcion == undefined ? "" : this.antecedentesGinecologicosData[0].inicio_anticoncepcion);
            formData.append("suspencion_anticoncepcion",this.antecedentesGinecologicosData[0].suspencion_anticoncepcion == undefined ? "" : this.antecedentesGinecologicosData[0].suspencion_anticoncepcion);
            formData.append("observaciones",this.antecedentesGinecologicosData[0].observaciones);
            await fetch(this.URL_TENANT+'/api/antecedente_ginecologico/'+this.antecedentesGinecologicosData[0].id+'/',{
                method: 'PUT',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }, body: formData
            })
            notificaciones.round_success_noti($.msg='Antecedentes Ginecológicos Modificados Exitosamente');
            this.getAntecedentesGinecologicosData();

        }catch(error){
            //console.log(error);
            notificaciones.round_error_noti($.msg='Error, algo salió mal');
        }
    },
    // Mosificar Antecedentes Obstétricos:
    async modificarAntecedentesObstetricos(){
        try{
            const formData = new FormData();
            formData.append("id", this.antecedentesObstetricosData[0].id);
            formData.append("expediente",  this.antecedentesObstetricosData[0].expediente);
            formData.append("f_p_p",this.antecedentesObstetricosData[0].f_p_p == undefined ? "" : this.antecedentesObstetricosData[0].f_p_p);
            formData.append("g",this.antecedentesObstetricosData[0].g == undefined ? "" : this.antecedentesObstetricosData[0].g);
            formData.append("a",this.antecedentesObstetricosData[0].a == undefined ? "" : this.antecedentesObstetricosData[0].a);
            formData.append("p",this.antecedentesObstetricosData[0].p == undefined ? "" : this.antecedentesObstetricosData[0].p);
            formData.append("c",this.antecedentesObstetricosData[0].c == undefined ? "" : this.antecedentesObstetricosData[0].c);
            formData.append("nacidos_vivos",this.antecedentesObstetricosData[0].nacidos_vivos == undefined ? "" : this.antecedentesObstetricosData[0].nacidos_vivos);
            formData.append("nacidos_vivos",this.antecedentesObstetricosData[0].nacidos_vivos == undefined ? "" : this.antecedentesObstetricosData[0].nacidos_vivos);
            formData.append("nacidos_vivos",this.antecedentesObstetricosData[0].nacidos_vivos == undefined ? "" : this.antecedentesObstetricosData[0].nacidos_vivos);
            formData.append("muertos_primera_semana",this.antecedentesObstetricosData[0].muertos_primera_semana == undefined ? "" : this.antecedentesObstetricosData[0].muertos_primera_semana);
            formData.append("muertos_despues_primera_semana",this.antecedentesObstetricosData[0].muertos_despues_primera_semana == undefined ? "" : this.antecedentesObstetricosData[0].muertos_despues_primera_semana);
            formData.append("intergenesia",  this.antecedentesObstetricosData[0].intergenesia);
            formData.append("parto_prematuro",  this.antecedentesObstetricosData[0].parto_prematuro);
            formData.append("malformaciones",  this.antecedentesObstetricosData[0].malformaciones);
            formData.append("isoinmunizacion",  this.antecedentesObstetricosData[0].isoinmunizacion);
            formData.append("atencion_prenatal",  this.antecedentesObstetricosData[0].atencion_prenatal);
            formData.append("medicacion_gestacional",  this.antecedentesObstetricosData[0].medicacion_gestacional);
            formData.append("examenes_complementarios",  this.antecedentesObstetricosData[0].examenes_complementarios);
            formData.append("observaciones",  this.antecedentesObstetricosData[0].observaciones);
            await fetch(this.URL_TENANT+'/api/antecedente_obstetrico/'+this.antecedentesObstetricosData[0].id+'/',{
                method: 'PUT',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }, body: formData
            })
            notificaciones.round_success_noti($.msg='Antecedentes Obstétricos Modificados Exitosamente');
            this.getAntecedentesObstetricosData();
        }catch(error){
            //console.log(error);
            notificaciones.round_error_noti($.msg='Error, algo salió mal');
        }
    },
    // Modificar Exploración Física:
    async modificarExploracionFisica(){
        try{
            const formData = new FormData();
            formData.append("id", this.exploracionFisicaData[0].id);
            formData.append("expediente", this.exploracionFisicaData[0].expediente);
            formData.append("habitus_exterior", this.exploracionFisicaData[0].habitus_exterior);
            formData.append("cabeza", this.exploracionFisicaData[0].cabeza);
            formData.append("ojos", this.exploracionFisicaData[0].ojos);
            formData.append("otorrinoralingologia", this.exploracionFisicaData[0].otorrinoralingologia);
            formData.append("cuello", this.exploracionFisicaData[0].cuello);
            formData.append("torax", this.exploracionFisicaData[0].torax);
            formData.append("abdomen", this.exploracionFisicaData[0].abdomen);
            formData.append("exploracion_ginecologica", this.exploracionFisicaData[0].exploracion_ginecologica);
            formData.append("genitales", this.exploracionFisicaData[0].genitales);
            formData.append("columna_vertebral", this.exploracionFisicaData[0].columna_vertebral);
            formData.append("extremidades", this.exploracionFisicaData[0].extremidades);
            formData.append("exploracion_neurologica", this.exploracionFisicaData[0].exploracion_neurologica);
            await fetch(this.URL_TENANT+'/api/exploracion_fisica/'+this.exploracionFisicaData[0].id+'/',{
                method: 'PUT',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }, body: formData
            })
            notificaciones.round_success_noti($.msg='Exploración Física Modificada Exitosamente');
            this.getAntecedentesObstetricosData();
        }catch(error){
            //console.log(error);
            notificaciones.round_error_noti($.msg='Error, algo salió mal.');
        }
    },
    // Modificar Interrogatorio:
    async modificarInterrogatorio(){
        try{
            const formData = new FormData();
            formData.append("id", this.interrogatorioData[0].id);
            formData.append("expediente", this.interrogatorioData[0].expediente);
            formData.append("cardiovascular", this.interrogatorioData[0].cardiovascular);
            formData.append("digestivo", this.interrogatorioData[0].digestivo);
            formData.append("endocrino", this.interrogatorioData[0].endocrino);
            formData.append("hemolinfatico", this.interrogatorioData[0].hemolinfatico);
            formData.append("mamas", this.interrogatorioData[0].mamas);
            formData.append("musculo_esqueletico", this.interrogatorioData[0].musculo_esqueletico);
            formData.append("piel_anexos", this.interrogatorioData[0].piel_anexos);
            formData.append("reproductor", this.interrogatorioData[0].reproductor);
            formData.append("sistema_nervioso", this.interrogatorioData[0].sistema_nervioso);
            formData.append("sistema_general", this.interrogatorioData[0].sistema_general);
            formData.append("urinario", this.interrogatorioData[0].urinario);
            await fetch(this.URL_TENANT+'/api/interrogatorio/'+this.interrogatorioData[0].id+'/',{
                method: 'PUT',
                headers:{
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }, body: formData
            })
            notificaciones.round_success_noti($.msg='Interrogatorio Modificado Exitosamente');
            this.getInterrogatorioData();
        }catch(error){
            //console.log(error);
            notificaciones.round_error_noti($.msg='Error, algo salió mal.');
        }
    },
    // Modificar Interrogatorio:
    async modificarEstiloVida(){
        try{
            const formData = new FormData();
            formData.append("id", this.estiloDeVidaData[0].id);
            formData.append("expediente", this.estiloDeVidaData[0].expediente);
            formData.append("realiza_ejercicio", this.estiloDeVidaData[0].realiza_ejercicio);
            formData.append("minutos_al_dia_ejercicio",this.estiloDeVidaData[0].minutos_al_dia_ejercicio == undefined ? "" : this.estiloDeVidaData[0].minutos_al_dia_ejercicio);
            formData.append("practica_deporte", this.estiloDeVidaData[0].practica_deporte);
            formData.append("cual_deporte", this.estiloDeVidaData[0].cual_deporte);
            formData.append("frecuencia_practica_deporte", this.estiloDeVidaData[0].frecuencia_practica_deporte);
            formData.append("duerme_durante_dia", this.estiloDeVidaData[0].duerme_durante_dia);
            formData.append("horas_duerme_durante_dia",this.estiloDeVidaData[0].horas_duerme_durante_dia == undefined ? "" : this.estiloDeVidaData[0].horas_duerme_durante_dia);
            formData.append("desayuna", this.estiloDeVidaData[0].desayuna);
            formData.append("numero_comidas_al_dia",this.estiloDeVidaData[0].numero_comidas_al_dia == undefined ? "" : this.estiloDeVidaData[0].numero_comidas_al_dia);
            formData.append("toma_cafe", this.estiloDeVidaData[0].toma_cafe);
            formData.append("numero_tazas_al_dia",this.estiloDeVidaData[0].numero_tazas_al_dia == undefined ? "" : this.estiloDeVidaData[0].numero_tazas_al_dia);
            formData.append("toma_refresco", this.estiloDeVidaData[0].toma_refresco);
            formData.append("sigue_alguna_dieta", this.estiloDeVidaData[0].sigue_alguna_dieta);
            formData.append("informacion_dieta", this.estiloDeVidaData[0].informacion_dieta);
            formData.append("consume_alcohol", this.estiloDeVidaData[0].consume_alcohol);
            formData.append("ex_alcoholico", this.estiloDeVidaData[0].ex_alcoholico);
            formData.append("edad_comenzo_beber",this.estiloDeVidaData[0].edad_comenzo_beber == undefined ? "" : this.estiloDeVidaData[0].edad_comenzo_beber);
            formData.append("edad_dejo_beber",this.estiloDeVidaData[0].edad_dejo_beber == undefined ? "" : this.estiloDeVidaData[0].edad_dejo_beber);
            formData.append("copas_vino_al_dia",this.estiloDeVidaData[0].copas_vino_al_dia == undefined ? "" : this.estiloDeVidaData[0].copas_vino_al_dia);
            formData.append("vasos_licor_al_dia",this.estiloDeVidaData[0].vasos_licor_al_dia == undefined ? "" : this.estiloDeVidaData[0].vasos_licor_al_dia);
            formData.append("cervezas_al_dia",this.estiloDeVidaData[0].cervezas_al_dia == undefined ? "" : this.estiloDeVidaData[0].cervezas_al_dia);
            formData.append("fuma", this.estiloDeVidaData[0].fuma);
            formData.append("ex_fumador", this.estiloDeVidaData[0].ex_fumador);
            formData.append("fumador_pasivo", this.estiloDeVidaData[0].fumador_pasivo);
            formData.append("edad_comenzo_fumar",this.estiloDeVidaData[0].edad_comenzo_fumar == undefined ? "" : this.estiloDeVidaData[0].edad_comenzo_fumar);
            formData.append("edad_dejo_fumar",this.estiloDeVidaData[0].edad_dejo_fumar == undefined ? "" : this.estiloDeVidaData[0].edad_dejo_fumar);
            formData.append("cigarros_al_dia",this.estiloDeVidaData[0].cigarros_al_dia == undefined ? "" : this.estiloDeVidaData[0].cigarros_al_dia);
            formData.append("consume_drogas", this.estiloDeVidaData[0].consume_drogas);
            formData.append("usa_droga_intravenosa", this.estiloDeVidaData[0].usa_droga_intravenosa);
            formData.append("ex_adicto_droga", this.estiloDeVidaData[0].ex_adicto_droga);
            formData.append("edad_comenzo_droga",this.estiloDeVidaData[0].edad_comenzo_droga == undefined ? "" : this.estiloDeVidaData[0].edad_comenzo_droga);
            formData.append("edad_dejo_droga",this.estiloDeVidaData[0].edad_dejo_droga == undefined ? "" : this.estiloDeVidaData[0].edad_dejo_droga);
            formData.append("autonomia_en_el_trabajo", this.estiloDeVidaData[0].autonomia_en_el_trabajo);
            formData.append("turnos_de_trabajo", this.estiloDeVidaData[0].turnos_de_trabajo);
            formData.append("actividades_dias_libres", this.estiloDeVidaData[0].actividades_dias_libres);
            formData.append("duelo", this.estiloDeVidaData[0].duelo);
            formData.append("embarazos", this.estiloDeVidaData[0].embarazos);
            formData.append("divorcios", this.estiloDeVidaData[0].divorcios);
            await fetch(this.URL_TENANT+'/api/estilo_de_vida/'+this.estiloDeVidaData[0].id+'/',{
                method: 'PUT',
                headers:{
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }, body: formData
            })
            notificaciones.round_success_noti($.msg='Estilo de Vida Modificado Exitosamente');
            this.getEstiloDeVidaData();
        }catch(error){
            //console.log(error);
            notificaciones.round_error_noti($.msg='Error, algo salió mal.');
        }
    },
    // Modificar Alergias:
    async modificarAlergias(){
        try{
            const formData = new FormData();
            formData.append("id", this.alergiasData[0].id);
            formData.append("expediente", this.alergiasData[0].expediente);
            formData.append("descripcion", this.alergiasData[0].descripcion);
            await fetch(this.URL_TENANT+'/api/alergia/'+this.alergiasData[0].id+'/',{
                method: 'PUT',
                headers:{
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }, body: formData
            })
            notificaciones.round_success_noti($.msg='Alergia Modificada Exitosamente');
            this.getAlergiasData();
        }catch(error){
            //console.log(error);
            notificaciones.round_error_noti($.msg='Error, algo salió mal.');
        }
    },
    ////////////////////// ELIMINAR //////////////////////////////////
    // Eliminar Cargo y Pago:
    async selectEliminar(id_eliminar, tipo){
        this.eliminarCargoPago.tipo = tipo;
        this.eliminarCargoPago.id_eliminar = id_eliminar;
        document.getElementById('closeModificarPago').click();
        document.getElementById('closeModificarCargo').click();
    },
    async eliminarPago(){
        try{
            await fetch(this.URL_TENANT+'/api/pago_paciente/'+this.eliminarCargoPago.id_eliminar+'/',{
            method: 'DELETE',
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
            },body: ''})
            notificaciones.round_success_noti($.msg='Pago Eliminado Existosamente');
            this.getEstadoDeCuenta();
            document.getElementById('eliminarClosePago').click();
        }catch(error){
            //console.log(error);
            notificaciones.round_error_noti($.msg='Error, algo salió mal');
        }
    },
    async eliminarCargo(){
        try{
            await fetch(this.URL_TENANT+'/api/cargo_paciente/'+this.eliminarCargoPago.id_eliminar+'/',{
            method: 'DELETE',
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
            },body: ''})
            notificaciones.round_success_noti($.msg='Cargo Eliminado Existosamente');
            this.getEstadoDeCuenta();
            document.getElementById('eliminarCloseCargo').click();

        }catch(error){
            //console.log(error);
            notificaciones.round_error_noti($.msg='Error, algo salió mal');
        }
    },
    async eliminarIncapacidad(id_eliminar_incapacidad){
        try{
            await fetch(this.URL_TENANT+'/api/incapacidad/'+id_eliminar_incapacidad+'/',{
            method: 'DELETE',
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
            },body: ''})
            notificaciones.round_success_noti($.msg='Incapacidad Eliminada Existosamente');
            this.getIncapacidadesData();
        }catch(error){
            //console.log(error);
            notificaciones.round_error_noti($.msg='Error, algo salió mal');
        }
    },
    async eliminarDocumentoExpediente(nombre_documento, id_documento){
        try{
            await fetch(this.URL_TENANT+'/api/documentos_expediente/'+id_documento+'/',{
            method: 'DELETE',
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
            },body: ''})
            notificaciones.round_success_noti($.msg='Documento '+nombre_documento+' Eliminado Existosamente');
            this.getDocumentosData();
        }catch(error){
            //console.log(error);
            notificaciones.round_error_noti($.msg='Error, algo salió mal');
        }
    },
    // Eliminar Cargo Detalle:
    async eliminarConceptoDetalle(index){
        this.nuevoCargo.detalle.splice(index, 1);
        notificaciones.round_error_noti($.msg="Concepto de Cargo Eliminado exitosamente")
        this.detalleCargoCalculo();
    },
    async eliminarConceptoDetalleMod(id_eliminar, index){
        await fetch(this.URL_TENANT+'/api/cargo_paciente_detalle/'+id_eliminar+'/',{
            method: 'DELETE',
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
            }, body: ''})
            notificaciones.round_success_noti($.msg="Detalle Eliminado Exitosamente");
            this.modificarCargo.detalle.splice(index, 1);
            this.detalleCargoCalculoMod();
    },

},

beforeMount(){
    this.id_expediente = JSON.parse(document.getElementsByTagName('body')[0].getAttribute('data') || '{}');
},
created() {
    // Fetch tasks on page load
	const result = window.location.origin;
    this.URL_TENANT = result;
    /*let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    //console.log("ID::::");
    //console.log(params);*/
},
mounted(){
    this.$watch(
        "$refs.child_add.consulta_añadido",
        () => (this.$refs.child_consultas.getConsultasPacienteData()),
    );
    this.$watch(
        "$refs.child_consultas.editar",
        (newVal) => {
            if(newVal === true){
                this.show_add_consulta = false;
            }
        }
    );
    this.getExpedienteData();
    this.getDetalleExpedienteData();
    this.getAntecedentesHereditariosData()

},
}
</script>
