
<template>
    <div class="page-wrapper">
                <div class="page-content">
                    <!--breadcrumb-->
                    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div class="breadcrumb-title pe-3">Caja</div>
                        <div class="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0 p-0">
                                    <li class="breadcrumb-item"><a href="javascript:;"><i class='bx bx-money'></i></a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">cajas</li>
                                </ol>
                            </nav>
                        </div>
                        <div class="ms-auto">
                            <a v-if="userData && userData[0] && userData[0].is_superuser" href="javascript:;" data-bs-toggle="modal" data-bs-target="#reporteCaja" @click="llenarReporteCaja()" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Reporte de Caja</a>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4">
					<div v-for="item in cajasData" :key="item.id" class="col">
						<div class="card radius-15">
							<div class="card-body text-center">
								<div class="p-4 border radius-15">
									<h1 class="mb-0 mt-8">{{item.nombre}}</h1>
                                    <hr/>
                                    <h6>
									<p class="mb-3">
                                        <i  v-if="item.estado == 'Abierta'" class="bx bx-lock-open"></i>
                                        <i  v-if="item.estado == 'Cerrada'" class="bx bxs-lock"></i>
                                        {{item.estado}}
                                    </p>
                                    </h6>
                                    <h6>
                                    <p class="mb-3"  v-if="item.estado == 'Abierta'">
                                        <i class="bx bxs-user"></i>
                                        {{item.nombre_usuario}}
                                    </p>
                                    </h6>
                                    <h6>
									<div class="d-grid">
                                        <a v-if="item.estado == 'Abierta' && item.usuario_abierto == userData[0].id"   @click="set_caja_cierre(item.nombre, item.id)"   data-bs-toggle="modal" data-bs-target="#cerrarCaja" class="btn btn-light radius-15 keychainify-checked">Cerrar Caja</a>
                                        <a v-if="item.estado == 'Cerrada'" @click="set_caja_apertura(item.nombre, item.id)" data-bs-toggle="modal" data-bs-target="#abrirCaja" class="btn btn-light radius-15 keychainify-checked">Aperturar Caja</a>
									</div>
                                </h6>
								</div>
							</div>
						</div>
					</div>
				</div>

                </div>
            </div>
<!-- Modal Ver Detalle -->
<div class="modal fade" id="abrirCaja" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 >Aperturar Caja</h5>
                <button type="button" id="closeApertura" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Caja</h6>
                            </div>
                            <div class="col-sm-10">
                                <input disabled type="text" id="nit"  class="form-control" v-model="caja_apertura.nombre_caja"  />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Saldo Inicial</h6>
                            </div>
                            <div class="col-sm-10">
                                <input ref="saldo_inicial" type="text" id="nit"  class="form-control" v-model="caja_apertura.saldo_inicial"  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="aperturarCaja()">Aperturar</button>
            </div>
        </div>
    </div>
</div>
<!-- FIN Modal Ver Detalle -->
<!-- Modal Ver Detalle -->
<div class="modal fade" id="cerrarCaja" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Cerrar Caja</h5>
                <button type="button" id="closeCierre" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Caja</h6>
                            </div>
                            <div class="col-sm-9">
                                <input disabled type="text" id="nit"  class="form-control" v-model="caja_cierre.nombre_caja"  />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Total de Cierre</h6>
                            </div>
                            <div class="col-sm-9">
                                <input ref="total_real" type="text" id="nit"  class="form-control" v-model="caja_cierre.total_real"  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="cerrarCaja()">Cuadrar Caja</button>
            </div>
        </div>
    </div>
</div>
<!-- FIN Modal Ver Detalle -->
<!-- Modal Reporte Caja -->
<div class="modal fade" id="reporteCaja" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Reporte de Caja</h5>
                <button type="button" id="closeCierre" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Fecha Inicio</h6>
                            </div>
                            <div class="col-sm-9">
                                <Datepicker
                                    v-model="reporteCaja.fecha"
                                    dark placeholder="Selecciona una Fecha"
                                    autoApply
                                    :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                    :enableTimePicker="false"
                                    monthNameFormat="long"
                                    :format="format"
                                    modelType="dd-MM-yyyy"
                                    locale="es">
                                </Datepicker>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Fecha Fin</h6>
                            </div>
                            <div class="col-sm-9">
                                <Datepicker
                                    v-model="reporteCaja.fecha_fin"
                                    dark placeholder="Selecciona una Fecha"
                                    autoApply
                                    :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                    :enableTimePicker="false"
                                    monthNameFormat="long"
                                    :format="format"
                                    modelType="dd-MM-yyyy"
                                    locale="es">
                                </Datepicker>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Usuario(s)</h6>
                            </div>
                            <div class="col-sm-9">
                            <select v-model="reporteCaja.usuario" required class="form-select form-control mb-3" aria-label="Default select example">
                                <option value="0" selected>Todos</option> <!-- Agregar el atributo 'selected' -->
                                <option v-for="(item, index) in usersData" :value="item.id" :key="index">
                                    {{item.first_name}} {{item.last_name}} ({{item.username}})
                                </option>
                            </select>
                        </div>
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="generarReporteCaja()">Reporte de Caja</button>
            </div>
        </div>
    </div>
</div>
<!-- FIN Modal Ver Detalle -->
    </template>
    <style>
    .btn-common {
        width: 100%;
        height: 100%;
        max-width: 175px; /* Ancho máximo para limitar el crecimiento excesivo en pantallas grandes */
        max-height: 50px; /* Altura máxima para limitar el crecimiento excesivo en pantallas grandes */
    }
    </style>
    <script>
    import  Cookies from 'js-cookie';

    import '@vuepic/vue-datepicker/dist/main.css';
    import { ref } from 'vue';

    import $ from 'jquery';
    import Datepicker from '@vuepic/vue-datepicker';

    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    console.log(Cookies.get());
    export default {
        name: 'cajas_component',
        delimiters: ['[[', ']]'],

        components:{
            Datepicker,
        },
        setup() {
        const date = ref(new Date());
            function formatDate(userDate) {
            // format from M/D/YYYY to YYYYMMDD
            return (new Date(userDate).toJSON().slice(0,10).split('-').reverse().join('-'));
            }
            const format = (date) => {
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
            const startTime = ref({ hours: 0, minutes: 0 });
            const first_time = ref({
                hours: new Date().getHours()+1,
                minutes: 0
            });
            const last_time = ref({
                hours: new Date().getHours()+2,
                minutes: 0
            });
            //const today_date = ref(new Date().toISOString().split('T')[0]);
            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            var today_is = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
            //var today_dat = new Date().toLocaleString("en-US", {timeZone: "America/New_York"})
            //console.log("TIEMPO: "+ formatDate(today_date));
            var today_date = formatDate(today_is);
            //
            return {
                date,
                format,
                first_time,
                last_time,
                startTime,
                today_date,
            }
    },
    data() {
        return{
            //Globales:
            URL_TENANT: '',
            userData: {},
            usersData: [],
            url: "/api/caja/",
            indexPagination: 1,
            cajasData: [],
            reporteCaja:{
                fecha: '',
                fecha_fin: '',
                usuario: '0'
            },
            caja_apertura:{
                saldo_inicial: '',
                caja: '',
                nombre_caja: ''

            },
            caja_cierre:{
                total_real: '',
                caja: '',
                nombre_caja: ''
            }
            }
        },

    computed: {

    },
    watch:{

    },
    methods: {
        async convertDateFormat(str) {
            // str1 format should be dd-mm-yyyy. Separator can be anything e.g. / or -. It wont affect
            let dateParts = str.split("-");
            return dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
        },
        async llenarReporteCaja(){
            this.reporteCaja = {
                fecha: this.today_date,
                fecha_fin: this.today_date,
                usuario: '0'
            }
        },
        async generarReporteCaja(){
            const _fecha = await this.convertDateFormat(this.reporteCaja.fecha);
            const _fecha_fin = await this.convertDateFormat(this.reporteCaja.fecha_fin);
            window.open('/empresa/reporte_caja/'+_fecha+'/'+_fecha_fin+'/'+this.reporteCaja.usuario+'/', '_blank');
        },
        async aperturarCaja(){
            try{
                if(this.caja_apertura.saldo_inicial == ''){
                    notificaciones.round_error_noti($.msg='Colocar un Saldo Inicial');
                    return
                }
                const formData = new FormData();
                formData.append("caja", this.caja_apertura.caja);
                formData.append("saldo_inicial", this.caja_apertura.saldo_inicial);
                const response = await fetch(this.URL_TENANT+'/api/caja_apertura/',{
                method: 'POST',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
                if(response.ok){
                    //const data = await response.json();
                    //this.id_venta_creada = data.id;
                    this.getCajasData();
                    notificaciones.round_success_noti($.msg=''+this.caja_apertura.nombre_caja+' Aperturada Exitosamente');
                    document.getElementById('closeApertura').click();
                }else {
                    // Aquí manejas la respuesta de error
                    console.log("kakakan;");
                    const data = await response.json();
                    notificaciones.round_error_noti($.msg=data.detail);
                }
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Ya hay una Caja Abierta con tu Usuario');
            }
        },
        async cerrarCaja(){
            try{
                if(this.caja_cierre.total_real == ''){
                    notificaciones.round_error_noti($.msg='Colocar un Total de Cierre');
                    return
                }
                const formData = new FormData();
                formData.append("caja", this.caja_cierre.caja);
                formData.append("total_real", this.caja_cierre.total_real);
                const response = await fetch(this.URL_TENANT+'/api/caja_cierre/',{
                method: 'POST',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
                if(response.ok){
                    //const data = await response.json();
                    //this.id_venta_creada = data.id;
                    this.getCajasData();
                    notificaciones.round_success_noti($.msg=''+this.caja_cierre.nombre_caja+' Cerrada Exitosamente');
                    document.getElementById('closeCierre').click();
                }else {
                    // Aquí manejas la respuesta de error
                    console.log("kakakan;");
                    const data = await response.json();
                    notificaciones.round_error_noti($.msg=data.detail);
                }
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error del Sistema');
            }
        },
        // Llenado:
        async set_caja_apertura(nombre_caja, caja){
            this.caja_apertura = {
                saldo_inicial: '',
                caja: caja,
                nombre_caja: nombre_caja
            }
        },
        async set_caja_cierre(nombre_caja, caja){
            this.caja_cierre = {
                total_real: '',
                caja: caja,
                nombre_caja: nombre_caja
            }
        },
        async getCajasData() {
            const response = await fetch(this.url);
            this.cajasData = await response.json();
        },
        async getUsersData() {
            const response = await fetch(this.URL_TENANT+'/api/users/');
            this.usersData = await response.json();
        },
        async getUserData(){
            const response = await fetch(this.URL_TENANT+'/api/user/');
            this.userData = await response.json();
        },
        },
mounted(){
    var myModalEl = document.getElementById('abrirCaja')
        myModalEl.addEventListener('shown.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.saldo_inicial.focus();
            });
        });
        var myModalEl2 = document.getElementById('cerrarCaja')
        myModalEl2.addEventListener('shown.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.total_real.focus();
            });
        });
},
beforeUnmount() {
    var myModalEl = document.getElementById('abrirCaja')
    myModalEl.removeEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.saldo_inicial.focus();
        });
    });
    var myModalEl2 = document.getElementById('cerrarCaja')
    myModalEl2.removeEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.total_real.focus();
        });
    });
},
created() {
          // Fetch tasks on page load
        console.log("CREATED");

        const result = window.location.origin;
        this.URL_TENANT = result;
        this.url = this.URL_TENANT + this.url
        this.getUserData();
        this.getCajasData();
        this.getUsersData();
        
        this.$nextTick(() => {
        this.$set(this.reporteCaja, 'usuario', 'todos');
    });
        },
    }
    </script>