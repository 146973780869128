<template>
      <Header/>
      <Nav/>
      <UtilidadesDelProducto/>


</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import UtilidadesDelProducto from '../../components/reportes/utilidades_del_producto.vue'





export default {
  name: 'UtilidadesDelProductoComponent',

  components: {
    Header,
    Nav,
    UtilidadesDelProducto,
  },

  data: () => ({
    //
  }),
}
</script>
