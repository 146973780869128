
<template>
<div class="page-wrapper">
			<div class="page-content">
				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Empresa</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Departamentos</li>
							</ol>
						</nav>
					</div>

				</div>
				<!--end breadcrumb-->
				<div class="card">
					<div class="card-body">
						<div class="d-lg-flex align-items-center mb-4 gap-3">
							<div class="position-relative">
								<input type="text" class="form-control ps-5 radius-30" v-model="buscarDepartamento" placeholder="Buscar Departamento"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
							</div>
						<div class="ms-auto"><a href="javascript:;" @click="cleanAñadirData" data-bs-toggle="modal" data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Añadir Nuevo Departamento</a></div>
						</div>
                        <!-- Button trigger modal -->
						<!-- Modal -->
						<div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-lg">
								<div class="modal-content bg-dark">
									<div class="modal-header">
										<h5 class="modal-title" id="exampleModalLabel">Añadir Departamento</h5>
										<button type="button" class="btn-close"  id="close_departamento"  data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Nombre Departamento</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="nombreNuevoDepartamento"  class="form-control" v-model="nombreNuevoDepartamento"  />
											</div>
										</div>
                                    </div>
									<div class="modal-footer">
										<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
										<button type="button" @click="agregarNuevoDepartamento" class="btn btn-primary">Guardar</button>
									</div>
								</div>
							</div>
						</div>
						<!-- End Modal -->
						<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th><h6>Departamento</h6></th>
										<th><h6>Estado</h6></th>
										<th><h6>Acciones</h6></th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in departamentosDataFilter" :key="item.id">
										<td><h6>{{item.nombre}}</h6></td>
										<td>
                                            <div class="badge rounded-pill bg-light p-2 text-uppercase px-3">
                                                <div v-if="[[item.estado]] == 'I'"> <i  class='bx bx-minus me-1'></i>Inactivo</div>
                                                <div v-if="[[item.estado]] == 'A'"> <i  class='bx bx-circle me-1'></i>Activo</div>
                                            </div>
                                        </td>
										<td>
											<div class="d-flex order-actions">
												<a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modificarModal" @click="fillmodificarDepartamento([[item.id]])" class=""><i class='bx bxs-edit'></i></a>
												<a href="javascript:;" class="ms-3"><i class='bx bxs-trash'></i></a>
											</div>
										</td>
									</tr>


								</tbody>
							</table>
						</div>
					</div>
				</div>


			</div>
		</div>
        <!-- Modal Modify -->
		<div class="modal fade"  id="modificarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content bg-dark">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">Modificar Departamento</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" id="close_mod" aria-label="Close"></button>
					</div>
					<div class="modal-body">
                        <div class="row mb-3">
							<div class="col-sm-3">
								<h6 class="mb-0">Departamento</h6>
							</div>
							<div class="col-sm-9">
								<input type="text" id="nombreNuevoDepartamento"  class="form-control" v-model="nombreNuevoDepartamento"  />
							</div>
						</div>
                    </div>
					<div class="modal-body">
                                    <div class="row mb-3">
							<div class="col-sm-3">
								<h6 class="mb-0">Estado</h6>
							</div>
                            <div class="col-sm-9">
                                <vue-select   :options="estados"
                                    v-model="selectedEstado"
                                    label-by="nombre"
                                    hide-selected="true"
                                    track-by="nombre"
                                    value-by="id"
                                    placeholder="Selecciona un Estado"
                                    close-on-select>
                                    <template #label="{ selected }">
                                    <template v-if="selected">
                                        <div style="color:white;">{{ selected.nombre }}</div>
                                    </template>
                                    <template v-else > <div style="color:white;">Seleccione un Estado</div></template>
                                    </template>
                                    <template #dropdown-item="{ option }" >
                                    <div  style="color:white;">{{ option.nombre }}</div>
                                    </template>
                                </vue-select>
							</div>
                        </div>
                    </div>

					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
						<button type="button" @click="modificarDepartamento" class="btn btn-primary">Guardar</button>
					</div>
				</div>
			</div>
		</div>
		<!-- End Modal Modify -->
</template>
<style>
</style>
<script>
import  Cookies from 'js-cookie';
//import Select2 from 'vue3-select2-component';
//import $ from 'jquery';
//let select2 = require('@/assets/plugins/select2/js/select2.min.js');
//import * as mykey from '../assets/js/mykey.js'
import $ from 'jquery';
import VueNextSelect from 'vue-next-select'
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')
export default {
    name: 'departamento_component',
    components:{
        'vue-select': VueNextSelect,
    },
data() {
	return{
        URL_TENANT: '',
        empresaData: [0],
        departamentosData: [0],
		buscarDepartamento: '',
        // PARA AGREGAR DEPARTAMENTO:
        nombreNuevoDepartamento: '',
        // PARA MODIFICAR DEPARTAMENTO:
        selectedEstado: null,
        modificarId : null,
        estados: [
            {
                id: 'A',
                nombre: 'Activo',
            },
            {
                id: 'I',
                nombre: 'Inactivo',
            }
        ],
		}
	},

computed: {
    departamentosDataFilter() {
        if (this.buscarDepartamento != ''){
            return this.departamentosData.filter((item) => {
            return item.nombre.toLowerCase().includes(this.buscarDepartamento.toLowerCase())
        })
        }else{
            return this.departamentosData;
        }

    },
},
methods: {
    async cleanAñadirData() {
        console.log("CLEAN AÑADIR DATA");
        this.nombreNuevoDepartamento = null;
    },
    async fillmodificarDepartamento(idMod) {
        this.modificarId = idMod[0][0];
        for(var k in this.departamentosData){
            if(this.departamentosData[k].id==this.modificarId){
            this.nombreNuevoDepartamento = this.departamentosData[k].nombre;
            this.selectedEstado = this.departamentosData[k].estado;
            }
        }
    },
    async modificarDepartamento(){

        if (!this.selectedEstado  || !this.nombreNuevoDepartamento || !this.modificarId ){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        }else{
            console.log("ESTAS UPDETEANDO");
            try{
                const formData = new FormData();
                formData.append("nombre", this.nombreNuevoDepartamento);
                formData.append("estado", this.selectedEstado);
                await fetch(this.URL_TENANT+'/api/departamento/'+this.modificarId+'/',{
                method: 'put',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Departamento Modificado');
            document.getElementById('close_mod').click();
            this.getDepartamentosData();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }

        }
    },
    async agregarNuevoDepartamento(){

        if (!this.nombreNuevoDepartamento ){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        }else{

            try{
                const formData = new FormData();
                formData.append("nombre", this.nombreNuevoDepartamento);
                await fetch(this.URL_TENANT+'/api/departamento/',{
                method: 'post',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Nuevo Departamento Agregado');
            document.getElementById('close_departamento').click();
            this.getDepartamentosData();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }

        }
    },
    async getEmpresaData() {
        const response = await fetch(this.URL_TENANT+'/api/empresa/');
        this.empresaData = await response.json();
    },
	async getDepartamentosData() {
        const response = await fetch(this.URL_TENANT+'/api/departamento/');
        this.departamentosData = await response.json();
    },
    },
mounted() {
    },
created() {
      // al cargar la página:
    const result = window.location.origin;
    this.URL_TENANT = result;
    this.getDepartamentosData();
    this.getEmpresaData();

	},
}

</script>
