<template>
      <Header/>
      <Nav/>
      <Expediente/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import Expediente from '../../components/consultorio_virtual/expediente/expediente.vue'



export default {
  name: 'ExpedienteComponent',
  components: {
    Header,
	Nav,
	Expediente,
  },

  data: () => ({
    //
  }),
}
</script>
