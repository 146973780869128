<template>
      <Header/>
      <MisAplicaciones/>
</template>

<script>
import Header from '../../components/header.vue'
import MisAplicaciones from '../../components/usuarios/mis_aplicaciones.vue'



export default {
  name: 'MisAplicacionesComponent',
  components: {
    Header,
    MisAplicaciones,
  },

  data: () => ({
    //
  }),
}
</script>
