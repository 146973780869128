
<template>
    <div class="modal-body">
        <div class="row mb-3">
            <div class="col-sm-6">
                <h4 class="mb-0">¿Deseas eliminar el evento?</h4>
            </div>
            <div class="col-sm-6">
                <input type="text" readonly :value="titulo" placeholder="Título del Evento" autofocus id="titulo"  class="form-control" />
            </div>
        </div>
    </div>
</template>
    <script>
    import  Cookies from 'js-cookie';
    //import Select2 from 'vue3-select2-component';
    //import $ from 'jquery';
    //let select2 = require('@/assets/plugins/select2/js/select2.min.js');
    //import * as mykey from '../assets/js/mykey.js'
    import $ from 'jquery';
    import 'bootstrap/dist/css/bootstrap.min.css'
    import 'bootstrap/dist/js/bootstrap.bundle.js'
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    //let calendar = require('@/assets/plugins/fullcalendar/js/notification-custom-script.js');
    import { ref } from 'vue';
    import 'vue-cal/dist/vuecal.css'
    //Componentes Propios:
    console.log(Cookies.get());
    export default {
        props: ['id_eliminar', 'titulo'],
        name: 'eliminar_evento',
        components:{
        },
    setup() {
        const date = ref(new Date());
            const format = (date) => {
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            }
            const startTime = ref({ hours: 0, minutes: 0 });
            const first_time = ref({
                hours: new Date().getHours()+1,
                minutes: 0
            });
            const last_time = ref({
                hours: new Date().getHours()+2,
                minutes: 0
            });
            //
            return {
                date,
                format,
                first_time,
                last_time,
                startTime,
            }
    },
    data() {
        return{
            URL_TENANT: '',
            // Evento a Modificar:
            evento: {
                cliente: '',
                categoria: '',
            },
            evento_eliminado: false,
            //Anteriores
            modalShow: true,
            uniqueModal:null,
            selectedEvent: {},
            showDialog: false,
            OpenClose:this.visible,
            clientesData: [],
            categoriasData: [],
            coloresData: [],
            selectedColor: '',
            empresaData: [0],
            usuariosData: [0],
            }
        },

    computed: {
    },
    watch: {
        evento: {
            handler: function () {
                this.evento_eliminado = false;
            },
            deep: true,
        },
    },
    methods: {
        async eliminarEvento(){
                try{
                    await fetch(this.URL_TENANT+'/api/evento/'+this.id_eliminar+'/',{
                    method: 'DELETE',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: ''})
                    notificaciones.round_success_noti($.msg='Evento Eliminado Existosamente');
                    this.evento_eliminado = true;

                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
            }
        },
    mounted() {
            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', 'https://unpkg.com/vue-next-select/dist/vue-next-select.iife.prod.js')
            document.head.appendChild(recaptchaScript)
        },
    created() {
          // Fetch tasks on page load
            const result = window.location.origin;
            this.URL_TENANT = result;
        },
    }

    </script>
    <style>
    </style>