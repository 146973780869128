
<template>
<!-- Inside Panel Antecedentes -->
<div class="row">
<!-- Links -->
<div class="col-3">
    <ul class="nav flex-column nav-tabs text-center" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active"  data-bs-toggle="tab" href="#consulta_interrogatorio" role="tab" aria-selected="true">
                <div class="d-flex align-items-center">
                    <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                    </div>
                    <div class="tab-title">Interrogatorio</div>
                </div>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link"  data-bs-toggle="tab" href="#consulta_exploracion" role="tab" aria-selected="false">
                <div class="d-flex align-items-center">
                    <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                    </div>
                    <div class="tab-title">Exploración</div>
                </div>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link"  data-bs-toggle="tab" @click="getEnfermedadesCieData();" href="#consulta_enfermedades" role="tab" aria-selected="false">
                <div class="d-flex align-items-center">
                    <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                    </div>
                    <div class="tab-title">Enfermedades - CIE</div>
                </div>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link"  data-bs-toggle="tab" href="#consulta_evaluacion" role="tab" aria-selected="false">
                <div class="d-flex align-items-center">
                    <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                    </div>
                    <div class="tab-title">Evaluación</div>
                </div>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link"  data-bs-toggle="tab" href="#consulta_estudios_laboratorio" role="tab" aria-selected="false">
                <div class="d-flex align-items-center">
                    <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                    </div>
                    <div class="tab-title">Estudios de Laboratorio</div>
                </div>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link"  data-bs-toggle="tab" href="#consulta_vacunas" role="tab" aria-selected="false">
                <div class="d-flex align-items-center">
                    <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                    </div>
                    <div class="tab-title">Vacunas</div>
                </div>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link"  data-bs-toggle="tab" href="#consulta_imagenes" role="tab" aria-selected="false">
                <div class="d-flex align-items-center">
                    <div class="tab-icon"><i class='bx bx-chevron-right font-18 me-1'></i>
                    </div>
                    <div class="tab-title">Imágenes</div>
                </div>
            </a>
        </li>
    </ul>
</div>
<!-- End Links -->

<!-- Content -->
<div class="col-9">
    <div class="tab-content py-3">
        <div class="tab-pane fade show active" id="consulta_interrogatorio" role="tabpanel">
            <div class="card">
                <div class="card-body p-">
                    <div class="d-lg-flex align-items-center mb-4 gap-3">
                        <div class="position-relative">
                            <h5 class="card-title">Interrogatorio</h5>
                        </div>
                        <div class="ms-auto"><a href="javascript:;" @click="updateConsultaInterrogatorio()"  class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                    </div>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row mb-3">
                            <div class="row mb-3">
                                <div class="col-sm-2">
                                    <h7 class="mb-0">Fecha</h7>
                                </div>
                                <div class="col-sm-4">
                                    <div class="col-sm-12">
                                        <Datepicker
                                            v-model="consulta_interrogatorio.fecha"
                                            dark placeholder="Selecciona una Fecha"
                                            autoApply
                                            :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                            :enableTimePicker="false"
                                            monthNameFormat="long"
                                            :format="format"
                                            modelType="dd-MM-yyyy"
                                            locale="es">
                                        </Datepicker>
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <h7 class="mb-0">Tipo de Consulta</h7>
                                </div>
                                <div class="col-sm-4">
                                    <vue-select  :options="tipo_consulta"
                                        v-model="consulta_interrogatorio.tipo_consulta"
                                        label-by="tipo"
                                        track-by="tipo"
                                        value-by="id"
                                        search-placeholder="Selecciona un Tipo de Consulta"
                                        multiple
                                        collapse-tags="true"
                                        taggable
                                        searchable
                                        placeholder="Selecciona un Tipo de Consulta"
                                        close-on-select>
                                        <template #label="{ selected }">
                                        <template v-if="selected">
                                            <div style="color:white;">{{ selected.tipo }}</div>
                                        </template>
                                        <template v-else > <div style="color:white;">Seleccione un Tipo de Consulta</div></template>
                                        </template>
                                        <template #dropdown-item="{ option }">
                                        <div style="color:white;">{{ option.tipo }}</div>
                                        </template>
                                    </vue-select>
                                </div>
                            </div>
                            <hr/>
                            <div class="row mb-3">
                                <div class="form-body mt-4">
                                    <div class="col-sm-12">
                                        <h7 class="mb-0">Motivo de Consulta</h7>
                                    </div>
                                    <div class="form-body mt-4">
                                        <textarea  class="form-control" v-model="consulta_interrogatorio.motivo_consulta" placeholder="Motivo de la Consulta..." rows="5"></textarea>
                                    </div>
                                </div>
                                <div class="form-body mt-4">
                                    <div class="col-sm-12">
                                        <h7 class="mb-0">Síntomas</h7>
                                    </div>
                                    <div class="form-body mt-4">
                                        <textarea  class="form-control" v-model="consulta_interrogatorio.sintomas" placeholder="Síntomas..." rows="5"></textarea>
                                    </div>
                                </div>
                                <div class="form-body mt-4">
                                    <div class="col-sm-12">
                                        <h7 class="mb-0">Aparatos y Sistemas</h7>
                                    </div>
                                    <div class="form-body mt-4">
                                        <textarea  class="form-control" v-model="consulta_interrogatorio.aparatos_sistemas" placeholder="Síntomas..." rows="5"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="consulta_exploracion" role="tabpanel">
            <div class="card">
                <div class="card-body p-">
                    <div class="d-lg-flex align-items-center mb-4 gap-3">
                        <div class="position-relative">
                            <h5 class="card-title">Exploración</h5>
                        </div>
                        <div class="ms-auto"><a href="javascript:;" @click="updateConsultaExploracion()"  class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                    </div>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row mb-3">
                            <div class="col-sm-1">
                                <h7 class="mb-0">Peso</h7>
                            </div>
                            <div class="col-sm-3">
                                <input type="number" step="0.01" v-model="consulta_exploracion.peso"  id="peso"  class="form-control" />
                            </div>
                            <div class="col-sm-1">
                                <h7 class="mb-0">Talla</h7>
                            </div>
                            <div class="col-sm-3">
                                <input type="number" step="0.01" v-model="consulta_exploracion.talla"  id="talla"  class="form-control" />
                            </div>
                            <div class="col-sm-1">
                                <h7 class="mb-0">IMC</h7>
                            </div>
                            <div class="col-sm-3">
                                <input type="number" step="0.01" v-model="consulta_exploracion.imc"  id="imc"  class="form-control" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h7 class="mb-0">Diagnóstico IMC</h7>
                            </div>
                            <div class="col-sm-9" >
                                <vue-select   :options="consulta_exploracion_imc"
                                @blur="removeIMC()"
                                    v-model="selectedIMC"
                                    label-by="nombre"
                                    hide-selected="true"
                                    track-by="nombre"
                                    value-by="nombre"
                                    placeholder="Selecciona un Estado"
                                    multiple
                                    collapse-tags="true"
                                    taggable
                                    searchable
                                    close-on-select>
                                    <template #label="{ selected }">
                                    <template v-if="selected">
                                        <div style="color:white;">{{ selected.nombre }}</div>
                                    </template>
                                    <template v-else > <div style="color:white;">Seleccione un Estado</div></template>
                                    </template>
                                    <template #dropdown-item="{ option }" >
                                    <div  style="color:white;">{{ option.nombre }}</div>
                                    </template>
                                </vue-select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h7 class="mb-0">Frecuencia Respiratoria</h7>
                            </div>
                            <div class="col-sm-3" >
                                <input type="number" step="0.01" v-model="consulta_exploracion.frecuencia_respiratoria"   class="form-control" />
                            </div>
                            <div class="col-sm-3">
                                <h7 class="mb-0">Frecuencia Cardiaca</h7>
                            </div>
                            <div class="col-sm-3" >
                                <input type="number" step="0.01" v-model="consulta_exploracion.frecuencia_cardiaca" class="form-control" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h7 class="mb-0">Presión Arterial</h7>
                            </div>
                            <div class="col-sm-2" >
                                <input type="number" step="0.01" v-model="consulta_exploracion.presion_arterial_sistolica"   class="form-control" />
                            </div>
                            /
                            <div class="col-sm-2" >
                                <input type="number" step="0.01" v-model="consulta_exploracion.presion_arterial_diastolica" class="form-control" />
                            </div>
                            <div class="col-sm-2">
                                <h7 class="mb-0">Oximetría</h7>
                            </div>
                            <div class="col-sm-2" >
                                <input type="number" step="0.01" v-model="consulta_exploracion.oximetria" class="form-control" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h7 class="mb-0">Temperatura</h7>
                            </div>
                            <div class="col-sm-3" >
                                <input type="number" step="0.01" v-model="consulta_exploracion.temperatura"   class="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="consulta_enfermedades" role="tabpanel">
            <div class="card">
                <div class="card-body p-">
                    <div class="d-lg-flex align-items-center mb-4 gap-3">
                        <div class="position-relative">
                            <h5 class="card-title">Enfermedades - CIE</h5>
                        </div>
                        <div class="ms-auto"><a href="javascript:;" data-bs-toggle="modal" data-bs-target="#agregar_enfermedad_cie"   class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Añadir Enfermedad</a></div>
                        <!-- Modal -->
                        <div class="modal fade"   id="agregar_enfermedad_cie" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content bg-dark">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Agregar Enfermedad CIE</h5>
                                        <button type="button" id="closeModificarPago" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row mb-3">
                                            <div class="col-sm-2">
                                                <h7 class="mb-0">Enfermedad</h7>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="col-sm-12">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                    <div class="modal-footer">
                                        <button data-bs-dismiss="modal" class="btn btn-danger">Eliminar</button>
                                        <button data-bs-dismiss="modal" class="btn btn-success">Guardar</button>
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Modal -->
                    </div>
                    <hr/>
                    <div class="form-body mt-4">
                        <table class="table mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Enfermedad</th>
                                    <th scope="col">Severidad</th>
                                    <th scope="col">Activa</th>
                                    <th scope="col">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in consultaEnfermedadesCie" :key="item.id">
                                    <th scope="row">{{item.enfermedad}}</th>
                                    <td>{{ item.enfermedad_nombre }}</td>
                                    <td>{{ item.severidad }}</td>
                                    <td><input type="checkbox" name="myCheckbox" value="1" :checked="item.enfermedad_activa" disabled></td>
                                    <td>
                                        <a href="javascript:;" @click="editarConsulta(item.id)" class="ms-3"><i class='bx bxs-show'></i></a>
                                        <a href="javascript:;" @click="deleteConsulta(item.id)" class="ms-3"><i class='bx bxs-trash'></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="consulta_evaluacion" role="tabpanel">
            <div class="card">
                <div class="card-body p-">
                    <div class="d-lg-flex align-items-center mb-4 gap-3">
                        <div class="position-relative">
                            <h5 class="card-title">Evaluación</h5>
                        </div>
                        <div class="ms-auto"><a href="javascript:;"  class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                    </div>
                    <hr/>
                    <div class="form-body mt-4">
                        <textarea  class="form-control"  placeholder="Antecedentes Patológicos..." rows="15"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="consulta_estudios_laboratorio" role="tabpanel">
            <div class="card">
                <div class="card-body p-">
                    <div class="d-lg-flex align-items-center mb-4 gap-3">
                        <div class="position-relative">
                            <h5 class="card-title">Estudios de Laboratorio</h5>
                        </div>
                        <div class="ms-auto"><a href="javascript:;"  class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                    </div>
                    <hr/>
                    <div class="form-body mt-4">
                        <textarea  class="form-control"  placeholder="Antecedentes Patológicos..." rows="15"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="consulta_vacunas" role="tabpanel">
            <div class="card">
                <div class="card-body p-">
                    <div class="d-lg-flex align-items-center mb-4 gap-3">
                        <div class="position-relative">
                            <h5 class="card-title">Vacunas</h5>
                        </div>
                        <div class="ms-auto"><a href="javascript:;"  class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                    </div>
                    <hr/>
                    <div class="form-body mt-4">
                        <textarea  class="form-control"  placeholder="Antecedentes Patológicos..." rows="15"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="consulta_imagenes" role="tabpanel">
            <div class="card">
                <div class="card-body p-">
                    <div class="d-lg-flex align-items-center mb-4 gap-3">
                        <div class="position-relative">
                            <h5 class="card-title">Imágenes</h5>
                        </div>
                        <div class="ms-auto"><a href="javascript:;"  class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Guardar</a></div>
                    </div>
                    <hr/>
                    <div class="form-body mt-4">
                        <textarea  class="form-control"  placeholder="Antecedentes Patológicos..." rows="15"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Content -->
</div>
<!-- End Inside Panel Antecedentes -->
</template>
<style>
</style>
<script>

let notificaciones = require('@/assets/plugins/notifications//js/notification-custom-script.js');
import $ from 'jquery';
import  Cookies from 'js-cookie';
import { ref } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import VueNextSelect from 'vue-next-select';

export default {
    props: ['id_consulta',],
    name: 'modificar_consultas_paciente_component',
    components:{
        'Datepicker': Datepicker,
        'vue-select': VueNextSelect,
    },
    setup() {
        // In case of a range picker, you'll receive [Date, Date]
        const date = ref(new Date());
        function formatDate(userDate) {
            // format from M/D/YYYY to YYYYMMDD
            return (new Date(userDate).toJSON().slice(0,10).split('-').reverse().join('-'));
            }
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var today_is = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
        var today_date = formatDate(today_is);
        return {
            date,
            format,
            today_date,
        }
    },
data() {
    return{

        consulta_modificado: false,
        URL_TENANT: '',
        consulta: {
            fecha: this.today_date,
        },
        // Interrogatorio:
        consulta_interrogatorio: {
            id: "",
            consulta: "",
            fecha: this.today_date,
            tipo_consulta: "",
            motivo_consulta: "",
            sintomas: "",
            aparatos_sistemas: "",
        },
        // Exploración:
        consulta_exploracion: {
            id: "",
            peso: "0.00",
            talla: "0.00",
            imc: "0.00",
            diagnostico_imc: "SS",
            frecuencia_respiratoria: 0,
            presion_arterial_sistolica: 0,
            presion_arterial_diastolica: 0,
            temperatura: "0.00",
            frecuencia_cardiaca: "0.00",
            oximetria: 0,
            consulta: ""
        },
        // Enfermedades Cie:
        consultaEnfermedadesCie: [],
        enfermedadCie: {
            enfermedad: "",
            estado: "",
            severidad: "",
            enfermedad_activa: false,
            enfermedad_infecciosa: false,
            fecha_diagnostico: this.today_date,
            observaciones: "",
            edad_al_diagnosticar: "",
            curada: "",
            enfermedad_alergica: false,
            tipo_alergia: "",
            advertencia_durante_embarazo: false,
            contraido_semana_embarazo: "",
            actualmente_en_tratamiento: false,
            informacion_adicional: ""
        },
        selectedIMC: "",
        estados: [
            {
                id: 'A',
                nombre: 'Activo',
            },
            {
                id: 'I',
                nombre: 'Inactivo',
            }
        ],
        consulta_exploracion_imc: [
            {id: '1', nombre: 'Peso Bajo',},
            {id: '2',nombre: 'Peso Normal',},
            {id: '3',nombre: 'Sobrepeso',},
            {id: '4',nombre: 'Obesidad Leve',},
            {id: '5',nombre: 'Obesidad Media',},
            {id: '6',nombre: 'Obesidad Mórbida',}
        ],
        tipo_consulta: [],
        }
    },
    watch: {
    },
    methods: {
        async removeIMC() {
            if(this.selectedIMC.length > 1){
                this.selectedIMC.shift();
            }
        },
        async createConsulta(){
            const formData = new FormData();
            formData.append("fecha", this.consulta.fecha);
            formData.append("expediente", this.id_expediente);

            await fetch(this.URL_TENANT+'/api/consulta/',{
                method: 'POST',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }, body: formData
            })
            notificaciones.round_success_noti($.msg='Nueva Consulta Creada');
            this.consulta_modificado = true;
        },
        // Leer Datos:
        async getTipoConsulta(){
            const response = await fetch(this.URL_TENANT+'/api/consulta_tipo/');
            this.tipo_consulta = await response.json();
        },
        async getEnfermedadesCieData(){
            const response = await fetch(this.URL_TENANT+'/api/consulta_enfermedad_cie/?consulta='+this.id_consulta);
            this.consultaEnfermedadesCie = await response.json();
        },
        async getInterrogatorioConsultaData(){
            console.log("ID CONSULTA");
            console.log(this.id_consulta);
            const response = await fetch(this.URL_TENANT+'/api/consulta_interrogatorio/?consulta='+this.id_consulta);
            const _consulta_interrogatorio = await response.json();
            console.log("the gref");
            console.log(_consulta_interrogatorio);
            const _tipo_consulta = _consulta_interrogatorio[0].tipo_consulta == undefined ? "": [_consulta_interrogatorio[0].tipo_consulta];
            this.consulta_interrogatorio = {
                id: _consulta_interrogatorio[0].id,
                consulta: _consulta_interrogatorio[0].consulta,
                fecha: _consulta_interrogatorio[0].fecha,
                tipo_consulta: _tipo_consulta,
                motivo_consulta: _consulta_interrogatorio[0].motivo_consulta,
                sintomas: _consulta_interrogatorio[0].sintomas,
                aparatos_sistemas: _consulta_interrogatorio[0].aparatos_sistemas,
            }
        },
        async getExploracionConsultaData(){
            console.log("ID CONSULTA");
            console.log(this.id_consulta);
            const response = await fetch(this.URL_TENANT+'/api/consulta_exploracion/?consulta='+this.id_consulta);
            const _consulta_exploracion = await response.json();
            console.log("the gref");
            console.log(_consulta_exploracion);
            const _diagnostico_imc = _consulta_exploracion[0].diagnostico_imc == undefined || _consulta_exploracion[0].diagnostico_imc == "" ?  "": [_consulta_exploracion[0].diagnostico_imc];
            this.consulta_exploracion = {
                id: _consulta_exploracion[0].id,
                peso: _consulta_exploracion[0].peso,
                talla: _consulta_exploracion[0].talla,
                imc: _consulta_exploracion[0].imc,
                frecuencia_respiratoria: _consulta_exploracion[0].frecuencia_respiratoria,
                frecuencia_cardiaca: _consulta_exploracion[0].frecuencia_cardiaca,
                presion_arterial_sistolica: _consulta_exploracion[0].presion_arterial_sistolica,
                presion_arterial_diastolica: _consulta_exploracion[0].presion_arterial_diastolica,
                temperatura: _consulta_exploracion[0].temperatura,
                oximetria: _consulta_exploracion[0].oximetria,
                consulta: _consulta_exploracion[0].consulta,
                diagnostico_imc: _diagnostico_imc,
            }
            this.selectedIMC = _diagnostico_imc;
        },
        // Modificar:
        async updateConsultaInterrogatorio(){
            const formData = new FormData();
            console.log("TIPO CONSULA INTERGATORIO")
            console.log(this.consulta_interrogatorio.tipo_consulta);
            formData.append("fecha", this.consulta_interrogatorio.fecha);
            formData.append("tipo_consulta", this.consulta_interrogatorio.tipo_consulta === "" ? "" : this.consulta_interrogatorio.tipo_consulta[0]);
            formData.append("motivo_consulta", this.consulta_interrogatorio.motivo_consulta);
            formData.append("sintomas", this.consulta_interrogatorio.sintomas);
            formData.append("aparatos_sistemas", this.consulta_interrogatorio.aparatos_sistemas);
            formData.append("consulta", this.id_consulta);
            await fetch(this.URL_TENANT+'/api/consulta_interrogatorio/'+this.consulta_interrogatorio.id+'/',{
                method: 'PUT',
                headers:{
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }, body: formData
            })
            notificaciones.round_success_noti($.msg='Interrogatorio Guardado Exitosamente');
            this.getInterrogatorioConsultaData();
        },
        async updateConsultaExploracion(){
            const formData = new FormData();
            console.log("TIPO CONSULA INTERGATORIO")
            formData.append("peso", this.consulta_exploracion.peso);
            formData.append("talla", this.consulta_exploracion.talla);
            formData.append("imc", this.consulta_exploracion.imc);
            formData.append("diagnostico_imc", this.selectedIMC === "" ? "" : this.selectedIMC[0]);
            formData.append("frecuencia_respiratoria", this.consulta_exploracion.frecuencia_respiratoria);
            formData.append("presion_arterial_sistolica", this.consulta_exploracion.presion_arterial_sistolica);
            formData.append("presion_arterial_diastolica", this.consulta_exploracion.presion_arterial_diastolica);
            formData.append("temperatura", this.consulta_exploracion.temperatura);
            formData.append("frecuencia_cardiaca", this.consulta_exploracion.frecuencia_cardiaca);
            formData.append("oximetria", this.consulta_exploracion.oximetria);
            formData.append("consulta", this.id_consulta);
            await fetch(this.URL_TENANT+'/api/consulta_exploracion/'+this.consulta_exploracion.id+'/',{
                method: 'PUT',
                headers:{
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }, body: formData
            })
            notificaciones.round_success_noti($.msg='Exploración Guardada Exitosamente');
            this.getExploracionConsultaData();
        },
    },
    mounted() {
        },
    created() {
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.getTipoConsulta();
        this.getInterrogatorioConsultaData();
        this.getExploracionConsultaData();

    },
}
</script>