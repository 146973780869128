
<template>
<div v-if="editar==false">
    <table class="table mb-0">
        <thead>
            <tr>
                <th scope="col">Paciente</th>
                <th scope="col">Fecha de Consulta</th>
                <th scope="col">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in consultasPacienteData.results" :key="item.id">
                <th scope="row">{{item.nombre_paciente}}</th>
                <td>{{ item.fecha }}</td>
                <td>
                    <a href="javascript:;" @click="editarConsulta(item.id)" class="ms-3"><i class='bx bxs-edit'>Editar Consulta</i></a>
                    <a href="javascript:;" @click="deleteConsulta(item.id)" class="ms-3"><i class='bx bxs-trash'></i></a>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- Paginacion -->
    <div v-if="consultasPacienteData.count >0" class="card-body">
        <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link" href="javascript:;" @click="cambiarUrl(consultasPacienteData.links.previous, 'atras')">Atrás</a>
                    </li>
                    <li class="page-item" :class="{active: indexPagination == index}" v-for="index in consultasPacienteData.total_pages" :key="index">
                        <a class="page-link"  href="javascript:;javascript:;" @click="cambiarUrl(this.URL_TENANT+'/api/consulta/?expediente='+id_expediente+'&page='+index+'', index)">{{ index }}</a>
                    </li>
                    <li class="page-item"><a class="page-link" href="javascript:;" @click="cambiarUrl(consultasPacienteData.links.next, 'adelante')">Siguiente</a>
                    </li>
                </ul>
        </nav>
    </div>
    <!-- Fin Paginacion -->
</div>
<div v-if="editar==true">
    <mod_consulta :id_consulta="id_consulta"  ref="child_mod"></mod_consulta>
</div>
</template>
<style>
</style>
<script>
import mod_consulta from "./modificar_consulta.vue";

let notificaciones = require('@/assets/plugins/notifications//js/notification-custom-script.js');
import $ from 'jquery';
import  Cookies from 'js-cookie';
export default {
    props: ['id_expediente',],
    name: 'consultas_paciente_component',
    components:{
        mod_consulta,
    },
data() {
    return{
        URL_TENANT: '',
        editar: false,
        consultasPacienteData: [],
        url: "/api/consulta/",
        indexPagination: 1,
        id_consulta: "",
        }
    },
    watch: {
        id_expediente:{
            handler: function(){
                this.getConsultasPacienteData();
            }
        },
        url: function () {
            this.getConsultasPacienteData();
        },
    },
    methods: {
        async editarConsulta(id_consulta){
            this.id_consulta = id_consulta;
            this.editar = true;
        },
        async deleteConsulta(id_consulta){
            await fetch(this.URL_TENANT+'/api/consulta/'+id_consulta+'/',{
            method: 'DELETE',
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
            },body: ''})
            notificaciones.round_success_noti($.msg='Consulta Eliminada Existosamente');
            this.getConsultasPacienteData();
        },
        async cambiarUrl(link, index){
            this.url = link;
            if (index === "atras" && link){
                this.indexPagination = this.indexPagination - 1;
            }
            else if (index === "adelante" && link != null){
                this.indexPagination = this.indexPagination + 1;
            }
            else if (link){
                this.indexPagination = index;
            }
        },
        async getConsultasPacienteData(){
            console.log("CONSUL YAYAYYYYY");
            console.log(this.url);
            await fetch(this.url)
            .then(response => response.json())
            .then(json => this.consultasPacienteData = json);
        },
    },
    mounted() {
        },
    created() {
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.url = this.URL_TENANT + this.url + '?expediente='+this.id_expediente;
    },
}
</script>