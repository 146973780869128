
<template>
    <button type="button" class="btn btn-light" @click="limpiarInputs()" data-bs-toggle="modal" data-bs-target="#agregarModal">Agregar Vehículo</button>
    <!-- Modal -->
        <div class="modal fade"  v-if="modalShow" id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content bg-dark">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Agregar Vehículo</h5>
                        <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Tipo Vehículo</h6>
                            </div>
                            <div class="col-sm-9">
                                <vue-select
                                    :options="tiposVehiculosData"
                                    @blur="removeObjectArray()"
                                    v-model="vehiculo.tipo"
                                    label-by="tipo"
                                    track-by="tipo"
                                    value-by="id"
                                    search-placeholder="Selecciona un Tipo de Vehículo"
                                    multiple
                                    collapse-tags="true"
                                    taggable
                                    searchable
                                    placeholder="Selecciona un Tipo de Vehículo"
                                    close-on-select>
                                    <template #label="{ selected }">
                                    <template v-if="selected">
                                        <div style="color:white;">{{ selected.tipo }}</div>
                                    </template>
                                    <template v-else > <div style="color:white;">Seleccione un Tipo de Vehículo</div></template>
                                    </template>
                                    <template #dropdown-item="{ option }">
                                    <div style="color:white;">{{ option.tipo }}</div>
                                    </template>
                                </vue-select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Modelo</h6>
                            </div>
                            <div class="col-sm-9">
                                <input type="text"  class="form-control" v-model="vehiculo.modelo"  />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Año</h6>
                            </div>
                            <div class="col-sm-9">
                                <input type="number" max="9999" maxlength="4" class="form-control" v-model="vehiculo.año"  />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Capacidad Pasajeros</h6>
                            </div>
                            <div class="col-sm-9">
                                <input type="number"  class="form-control" v-model="vehiculo.capacidad_pasajeros"  />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Filas</h6>
                            </div>
                            <div class="col-sm-3">
                                <input type="number"  class="form-control" v-model="vehiculo.filas"  />
                            </div>
                            <div class="col-sm-3">
                                <h6 class="mb-0">Asientos por Filas</h6>
                            </div>
                            <div class="col-sm-3">
                                <input type="number"  class="form-control" v-model="vehiculo.asientos_filas"  />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Descripcion</h6>
                            </div>
                            <div class="col-sm-9">
                                <textarea  class="form-control"  v-model="vehiculo.descripcion" placeholder="Agrega una Descripción..." rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" @click="agregarVehiculo()" class="btn btn-primary">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal -->
    </template>
    <style>
    </style>
    <script>
    let notificaciones = require('@/assets/plugins/notifications//js/notification-custom-script.js');
    import VueNextSelect from 'vue-next-select';

    import $ from 'jquery';
    import  Cookies from 'js-cookie';
    export default {
        name: 'agregar_consultorio_component',
        components:{
        'vue-select': VueNextSelect,
        },
    data() {
        return{
            modalShow: true,
            vehiculo_añadido: false,
            tiposVehiculosData: [],
            URL_TENANT: '',
            seats: [],
            vehiculo:  {
                tipo: "",
                modelo: "",
                año: "",
                capacidad_pasajeros: "",
                descripcion: "",
                estado: "",
                filas: 0,
                asientos_filas: 0
                },
            }
        },
        watch: {
        },
        methods: {
            //Quitar Objeto:
            async removeObjectArray() {
                //console.log("REMOVE ARRAY");
                if(this.vehiculo.tipo.length > 1){
                    this.vehiculo.tipo.shift();
                }
                if(this.vehiculo.estado.length > 1){
                    this.vehiculo.estado.shift();
                }
            },
            async  limpiarInputs(){
                this.vehiculo_añadido = false;
                this.vehiculo =  {
                tipo: "",
                modelo: "",
                año: "",
                capacidad_pasajeros: "",
                descripcion: "",
                estado: "",
                }
            },
            async getTiposVehiculosData(){
                const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/tipo_vehiculo/')
                this.tiposVehiculosData = await response.json();
            },
            //Agregar Vehículo:
            async agregarVehiculo(){
                if(this.vehiculo.tipo == "" || !this.vehiculo.capacidad_pasajeros || !this.vehiculo.modelo || !this.vehiculo.filas || !this.vehiculo.asientos_filas){
                    notificaciones.round_error_noti($.msg='Tipo de Vehículo, Capacidad de Pasajeros y Modelo son Obligatorios');
                }else{
                    try{
                        const formData = new FormData();
                        formData.append("tipo", this.vehiculo.tipo == "" ? "" : this.vehiculo.tipo[0]);
                        formData.append("modelo", this.vehiculo.modelo);
                        formData.append("año", this.vehiculo.año);
                        formData.append("filas", this.vehiculo.filas);
                        formData.append("asientos_fila", this.vehiculo.asientos_filas);
                        formData.append("capacidad_pasajeros", this.vehiculo.capacidad_pasajeros);
                        formData.append("descripcion", this.vehiculo.descripcion);
                        const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/vehiculo/',{
                            method: 'POST',
                            headers: {
                                'X-CSRFToken': Cookies.get('csrftoken'),
                                },
                            body: formData})
                        const data_vehiculo = await response.json();
                        notificaciones.round_success_noti($.msg='Vehículo Creado Exitosamente');
                        document.getElementById('close').click();
                        if(this.vehiculo.filas >0 && this.vehiculo.asientos_filas > 0){
                            await this.generarArray();
                            for (let i = 0; i < this.seats.length; i++) {
                                for (let j = 0; j < this.seats[i].length; j++) {
                                    const formData  = new FormData();
                                    formData.append("vehiculo", data_vehiculo.id);
                                    formData.append("codigo", this.seats[i][j].codigo);
                                    formData.append("asiento", this.seats[i][j].asiento);
                                    formData.append("habilitado", this.seats[i][j].habilitado);
                                    formData.append("estado", this.seats[i][j].estado);
                                    await fetch(this.URL_TENANT+'/api/agencia_viaje/asiento/',{
                                    method: 'POST',
                                    headers: {
                                        'X-CSRFToken': Cookies.get('csrftoken'),
                                        },
                                    body: formData})
                                }
                            }
                        }
                        this.vehiculo_añadido = true;
                    }catch(error){
                        notificaciones.round_error_noti($.msg='Error, algo salió mal');
                    }
                }
            },
            generarArray() {
                const array = [];
                for (let i = 0; i < this.vehiculo.filas; i++) {
                    const fila = [];
                    for (let j = 0; j < this.vehiculo.asientos_filas; j++) {
                        const asiento = {
                            codigo: `${String.fromCharCode(97 + i).toUpperCase()}${j + 1}`,
                            asiento: `Asiento ${i + 1}-${j + 1}`,
                            habilitado: true,
                            estado: "Disponible"
                        };
                        fila.push(asiento);
                    }
                array.push(fila);
                }
                this.seats = array;
            },
        },
        mounted() {
            },
        created() {
            const result = window.location.origin;
            this.URL_TENANT = result;
            this.getTiposVehiculosData();
        },
    }
    </script>