
<template>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Usuario</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="usernameNuevoUsuario"  class="form-control" v-model="asistente.username"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Contraseña</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="password" placeholder="Escriba una Nueva Contraseña si Desea Cambiarla" id="usernameNuevoContraseña"  class="form-control" v-model="asistente.password"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Nombre</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="nombreUsuario"  class="form-control" v-model="asistente.first_name"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Apellido</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="apellidoUsuario" class="form-control" v-model="asistente.last_name" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Correo</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="email" id="correoUsuario" class="form-control" v-model="asistente.email" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Es Activo</h6>
                        </div>
                        <div class="col-sm-2">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="asistente.is_active">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Es Super Usuario</h6>
                        </div>
                        <div class="col-sm-2">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="asistente.is_superuser">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Teléfono Casa</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="telefonoUsuario" class="form-control" v-model="asistente.telefono" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Celular</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="celularUsuario" class="form-control" v-model="asistente.celular" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Dirección</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="direccionUsuario" class="form-control" v-model="asistente.direccion" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Imágen</h6>
                        </div>
                        <div class="col-sm-1" v-if="asistente.imagen">
                            <div class="input-group mb-3">
                                <img :src="asistente.imagen" class="user-img" alt="Imagen Médico">
                            </div>
                        </div>
                        <div class="col-sm-8">
                            <div class="input-group mb-3">
                                <label class="input-group-text" for="inputGroupFile01">Subir</label>
                                <input type="file" class="form-control" id="imagenDoctorMod">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Observaciones</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="direccionUsuario" class="form-control" v-model="asistente.observaciones" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Consultorio</h6>
                        </div>
                        <div class="col-sm-9">
                            <vue-select
                                :options="consultoriosData"
                                v-model="asistente.consultorio"
                                label-by="nombre"
                                track-by="nombre"
                                value-by="id"
                                search-placeholder="Selecciona un Consultorio"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Consultorio"
                                close-on-select>
                                <template #label="{ selected }">
                                <template v-if="selected">
                                    <div style="color:white;">{{ selected.nombre }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Consultorio</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.nombre }}</div>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Médico</h6>
                        </div>
                        <div class="col-sm-9">
                            <vue-select
                                :options="medicosData"
                                v-model="asistente.medico"
                                label-by="medico_nombre"
                                track-by="medico_nombre"
                                value-by="id"
                                search-placeholder="Selecciona un Médico"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Médico"
                                close-on-select>
                                <template #label="{ selected }">
                                <template v-if="selected">
                                    <div style="color:white;">{{ selected.medico_nombre }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Médico</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.medico_nombre }}</div>
                                </template>
                            </vue-select>
                        </div>
                    </div>
        <!-- End Modal -->
    </template>
    <style>
    </style>
    <script>
    let notificaciones = require('@/assets/plugins/notifications//js/notification-custom-script.js');
    import $ from 'jquery';
    import  Cookies from 'js-cookie';
    import VueNextSelect from 'vue-next-select'
    export default {
        props: ['id_asistente'],
        name: 'modificar_asistente_component',
        components:{
            'vue-select': VueNextSelect,
        },
    data() {
        return{
            modalShow: true,
            asistente_modificado: false,
            URL_TENANT: '',
            asistente:  {
                username: "",
                password: "",
                first_name: "",
                last_name: "",
                email: "",
                is_staff: "",
                is_active: "",
                is_superuser: "",
                telefono: "",
                celular: "",
                imagen: "",
                direccion: "",
                observaciones: "",
                consultorio: [],
                medico: []
                },
            consultoriosData: [],
            medicosData: [],
            }
        },
        watch: {
            id_asistente:{
            handler: function(){
                this.getasistenteData();
                this.asistente_modificado=false;
            }
        },
        'asistente.consultorio':{
                handler: function () {
                    if(this.asistente.consultorio.length > 1){
                        this.asistente.consultorio.shift();
                    }
                }
            },
        'asistente.medico':{
                handler: function () {
                    if(this.asistente.medico.length > 1){
                        this.asistente.medico.shift();
                    }
                }
            },
        },
        methods: {
            async getConsultoriosData(){
                const response = await fetch(this.URL_TENANT+'/api/consultorio/')
                this.consultoriosData = await response.json();
            },
            async getMedicosData(){
                const response = await fetch(this.URL_TENANT+'/api/medico_get/')
                this.medicosData = await response.json();
            },
            async getasistenteData(){
                await fetch(this.URL_TENANT+'/api/asistente_get/'+this.id_asistente+'/')
                .then(response => response.json())
                .then(json => this.asistente_ = json);
                var consul = this.asistente_.consultorio || "";
                if(consul != ""){consul = [consul]}
                var medic = this.asistente_.medico || "";
                if(medic != ""){medic = [parseInt(medic)]}
                this.asistente = {
                    username: this.asistente_.username,
                    password: "",
                    first_name: this.asistente_.first_name,
                    last_name: this.asistente_.last_name,
                    email: this.asistente_.email,
                    is_active: this.asistente_.is_active,
                    is_superuser: this.asistente_.is_superuser,
                    telefono: this.asistente_.telefono,
                    imagen: this.asistente_.imagen,
                    celular: this.asistente_.celular,
                    direccion: this.asistente_.direccion,
                    observaciones: this.asistente_.observaciones,
                    consultorio: consul,
                    medico: medic
                }
                console.log("GET DATA asistente");
            },
            //Modificar Médico:
            async modificarAsistente(){
                if(!this.asistente.username || !this.asistente.first_name){
                    notificaciones.round_error_noti($.msg='Usuario y Nombre son Obligatorios');
                }else{
                    try{
                        const imagenMod = document.getElementById("imagenDoctorMod");
                        const formData = new FormData();
                        formData.append("username", this.asistente.username);
                        formData.append("password", this.asistente.password);
                        formData.append("first_name", this.asistente.first_name);
                        formData.append("last_name", this.asistente.last_name);
                        formData.append("email", this.asistente.email);
                        formData.append("is_active", this.asistente.is_active);
                        formData.append("is_superuser", this.asistente.is_superuser);
                        formData.append("telefono", this.asistente.telefono);
                        formData.append("celular", this.asistente.celular);
                        formData.append("direccion", this.asistente.direccion);
                        console.log("CONSULTORIO");
                        console.log(this.asistente.consultorio);
                        console.log(this.asistente.consultorio.length);
                        if(this.asistente.consultorio != ""){
                            console.log("CON CONSULTORIO");
                            formData.append("consultorio", this.asistente.consultorio[0]);
                        }else{
                            console.log("CONSULTORIO VACÍO");
                            formData.append("consultorio", '');
                        }
                        if(this.asistente.medico != ""){
                            console.log("CON medico");
                            formData.append("medico", this.asistente.medico[0]);
                        }else{
                            console.log("medico VACÍO");
                            formData.append("medico", '');
                        }
                        formData.append("observaciones", this.asistente.observaciones);
                        if(!this.asistente.password){
                            console.log("CON PASSWORD");
                            formData.append("password", this.asistente.password);
                        }
                        console.log("passs pass");
                        console.log(imagenMod);
                        if(imagenMod.files.length == 0){
                            console.log("CON IMAGEN");

                            formData.append("imagen", '')
                        }else{
                            console.log("SIN IMAGEN");

                            formData.append("imagen", imagenMod.files[0]);
                        }
                        console.log("CASI");
                        await fetch(this.URL_TENANT+'/api/asistente_create/'+this.id_asistente+'/',{
                            method: 'PUT',
                            headers: {
                                'X-CSRFToken': Cookies.get('csrftoken'),
                                },
                            body: formData})
                        console.log("ERR");

                        notificaciones.round_success_noti($.msg='Asistente Modificado Exitosamente');
                        this.asistente_modificado = true;
                        document.getElementById('close').click();
                    }catch(error){
                        notificaciones.round_error_noti($.msg='Error, algo salió mal');
                    }
                }
            },
        },
        mounted() {
            },
        created() {
            const result = window.location.origin;
            this.URL_TENANT = result;
            this.getConsultoriosData();
            this.getMedicosData();
        },
    }
    </script>