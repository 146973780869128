
<template>
    <button type="button" class="btn btn-light" @click="limpiarInputs()" data-bs-toggle="modal" data-bs-target="#agregarModal">Crear Expediente</button>
    <!-- Modal -->
        <div class="modal fade"  v-if="modalShow" id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content bg-dark">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Crear Expediente</h5>
                        <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Nit</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="expediente.nit"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Nombre</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text"  class="form-control" v-model="expediente.nombre"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Apellido</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="expediente.apellido"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Fecha de Nacimiento</h6>
                        </div>
                        <div class="col-sm-9">
                            <Datepicker
                            v-model="expediente.fecha_nacimiento"
                            dark placeholder="Selecciona una Fecha"
                            autoApply
                            :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                            :enableTimePicker="false"
                            monthNameFormat="long"
                            :format="format"
                            modelType="dd-MM-yyyy"
                            locale="es">
                            </Datepicker>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Dirección</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="expediente.direccion" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Teléfono</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="expediente.telefono" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Celular</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="expediente.celular" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Correo</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="email" class="form-control" v-model="expediente.correo" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Lugar de Nacimiento</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="expediente.lugar_nacimiento" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Departamento</h6>
                        </div>
                        <div class="col-sm-9">
                            <vue-select
                                :options="departamentosData"
                                v-model="expediente.departamento"
                                label-by="departamento"
                                track-by="departamento"
                                value-by="departamento"
                                search-placeholder="Selecciona un Departamento"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Departamento"
                                close-on-select>
                                <template #label="{ selected }">
                                <template v-if="selected">
                                    <div style="color:white;">{{ selected.departamento }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Departamento</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.departamento }}</div>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Municipio</h6>
                        </div>
                        <div class="col-sm-9">
                            <vue-select
                                :options="municipiosData"
                                v-model="expediente.municipio"
                                label-by="municipio"
                                track-by="municipio"
                                value-by="municipio"
                                search-placeholder="Selecciona un Municipio"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Municipio"
                                close-on-select>
                                <template #label="{ selected }">
                                <template v-if="selected">
                                    <div style="color:white;">{{ selected.municipio }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Municipio</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.municipio }}</div>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Sexo</h6>
                        </div>
                        <div class="col-sm-9">
                            <vue-select
                                :options="sexoData"
                                v-model="expediente.sexo"
                                label-by="sexo"
                                track-by="sexo"
                                value-by="id"
                                search-placeholder="Selecciona un Sexo"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Sexo"
                                close-on-select>
                                <template #label="{ selected }">
                                <template v-if="selected">
                                    <div style="color:white;">{{ selected.sexo }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Sexo</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.sexo }}</div>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Médico</h6>
                        </div>
                        <div class="col-sm-9">
                            <vue-select
                                :options="medicosData"
                                v-model="expediente.medico"
                                label-by="medico_nombre"
                                track-by="medico_nombre"
                                value-by="id"
                                search-placeholder="Selecciona un Consultorio"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Consultorio"
                                close-on-select>
                                <template #label="{ selected }">
                                <template v-if="selected">
                                    <div style="color:white;">{{ selected.medico_nombre }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Consultorio</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.medico_nombre }}</div>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Fotografía</h6>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group mb-3">
                                <label class="input-group-text" for="inputGroupFile01">Subir</label>
                                <input type="file" class="form-control" id="imagenExpediente">
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" @click="agregarExpediente()" class="btn btn-primary">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal -->
</template>
<style>
</style>
<script>
    let notificaciones = require('@/assets/plugins/notifications//js/notification-custom-script.js');
    import $ from 'jquery';
    import  Cookies from 'js-cookie';
    import VueNextSelect from 'vue-next-select';
    import { ref } from 'vue';
    import Datepicker from '@vuepic/vue-datepicker';

    export default {
        name: 'agregar_expediente_component',
        components:{
            'vue-select': VueNextSelect,
            'Datepicker': Datepicker,

        },
        setup() {
        // In case of a range picker, you'll receive [Date, Date]
        const date = ref(new Date());
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
        return {
            date,
            format,
        }
    },
    data() {
        return{
            modalShow: true,
            expediente_añadido: false,
            URL_TENANT: '',
            expediente:  {
                nit: "",
                nombre: "",
                apellido: "",
                direccion: "",
                fecha_nacimiento: "",
                telefono: "",
                celular: "",
                correo: "",
                estado: "A",
                lugar_nacimiento: "",
                departamento: "",
                municipio: "",
                sexo: "",
                fotografia: "",
                medico: ""
                },
            medicosData: [],
            departamentosData: [],
            municipiosData: [],
            sexoData: [
                {
                    id: "M",
                    sexo: "Masculino"
                },
                {
                    id: "F",
                    sexo: "Femenino"
                }
            ]
            }
        },
        watch: {
            'expediente.medico':{
                handler: function () {
                    if(this.expediente.medico.length > 1){
                        this.expediente.medico.shift();
                    }
                }
            },
            'expediente.departamento':{
                handler: function () {
                    if(this.expediente.departamento.length > 1){
                        this.expediente.departamento.shift();
                    }
                    this.getMunicipiosData();
                }
            },
            'expediente.municipio':{
                handler: function () {
                    if(this.expediente.municipio.length > 1){
                        this.expediente.municipio.shift();
                    }
                }
            },
            'expediente.sexo':{
                handler: function () {
                    if(this.expediente.sexo.length > 1){
                        this.expediente.sexo.shift();
                    }
                }
            }
        },
        methods: {
            async  limpiarInputs(){
                this.expediente_añadido= false;
                this.expediente =  {
                    nit: "",
                    nombre: "",
                    apellido: "",
                    direccion: "",
                    fecha_nacimiento: "",
                    telefono: "",
                    celular: "",
                    correo: "",
                    estado: "A",
                    lugar_nacimiento: "",
                    departamento: "",
                    municipio: "",
                    sexo: "",
                    fotografia: "",
                    medico: ""
                }
            },
            async getMedicosData(){
                const response = await fetch(this.URL_TENANT+'/api/medico_get/')
                this.medicosData = await response.json();
            },
            async getDepartamentosData(){
                const response = await fetch(this.URL_TENANT+'/api/global_departamentos/')
                this.departamentosData = await response.json();
            },
            async getMunicipiosData(){
                this._depto = this.departamentosData.find(element => element.departamento == this.expediente.departamento[0]);
                const response = await fetch(this.URL_TENANT+'/api/global_municipios/?departamento='+this._depto.id)
                this.municipiosData = await response.json();
            },
            //Agregar Médico:
            async agregarExpediente(){
                if(!this.expediente.nombre || !this.expediente.apellido){
                    notificaciones.round_error_noti($.msg='Nombre y Apellido son Obligatorios');
                }else{
                    try{
                        const imagen = document.getElementById("imagenExpediente");
                        const formData = new FormData();
                        formData.append("nit", this.expediente.nit);
                        formData.append("nombre", this.expediente.nombre);
                        formData.append("apellido", this.expediente.apellido);
                        formData.append("direccion", this.expediente.direccion);
                        formData.append("fecha_nacimiento", this.expediente.fecha_nacimiento);
                        formData.append("telefono", this.expediente.telefono);
                        formData.append("celular", this.expediente.celular);
                        formData.append("correo", this.expediente.correo);
                        formData.append("estado", this.expediente.estado);
                        formData.append("lugar_nacimiento", this.expediente.lugar_nacimiento);
                        if(this.expediente.departamento != ""){
                            formData.append("departamento", this.expediente.departamento[0]);
                        }else{
                            formData.append("departamento", '');
                        }
                        if(this.expediente.municipio != ""){
                            formData.append("municipio", this.expediente.municipio[0]);
                        }else{
                            formData.append("municipio", '');
                        }
                        if(this.expediente.sexo != ""){
                            formData.append("sexo", this.expediente.sexo[0]);
                        }else{
                            formData.append("sexo", '');
                        }
                        if(this.expediente.medico != ""){
                            formData.append("medico", this.expediente.medico[0]);
                        }else{
                            formData.append("medico", '');
                        }
                        if(imagen.files.length == 0){
                            formData.append("fotografia", '')
                        }else{
                            formData.append("fotografia", imagen.files[0]);
                        }
                        await fetch(this.URL_TENANT+'/api/expediente_create/',{
                            method: 'POST',
                            headers: {
                                'X-CSRFToken': Cookies.get('csrftoken'),
                                },
                            body: formData})
                        notificaciones.round_success_noti($.msg='Expediente Creado Exitosamente');
                        this.expediente_añadido = true;
                        document.getElementById('close').click();
                    }catch(error){
                        notificaciones.round_error_noti($.msg='Error, algo salió mal');
                    }
                }
            },
        },
        mounted() {
            },
        created() {
            const result = window.location.origin;
            this.URL_TENANT = result;
            this.getMedicosData();
            this.getDepartamentosData();
        },
    }
    </script>