<template>
      <Header/>
      <Nav/>
      <Devolucion/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import Devolucion from '../../components/inventario/devolucion/devolucion.vue'



export default {
  name: 'DevolucionComponent',

  components: {
  Header,
	Nav,
	Devolucion,
  },

  data: () => ({
    //
  }),
}
</script>
