
<template>
    <div class="ms-auto"><a href="javascript:;" data-bs-toggle="modal" @click="limpiarInputs()"  data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Crear Nueva Compra</a></div>
    <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content bg-dark">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Crear Compra</h5>
                    <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!-- Modal -->
                    <div class="col-12">
                        <div class="position-relative">
                            <input ref="inputInModal" type="text" class="form-control ps-5" v-model="busqueda" autofocus  placeholder="Buscar Proveedor...(Por Nombre o NIT)"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                        </div>
                    </div>
                    <hr/>
                    <table class="table mb-0">
                        <thead class="table-light">
                            <tr v-if="busqueda != ''">
                                <th><h6>NIT</h6></th>
                                <th><h6>Proveedor</h6></th>
                                <th><h6>Seleccionar</h6></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="itemProd in proveedorDataFilter" :key="itemProd.id">
                                <td><h6>
                                    {{ itemProd.nit }}
                                </h6></td>
                                <td><h6>
                                    <label>{{itemProd.nombre}}</label>
                                </h6></td>
                                <td><h6>
                                    <button type="button" data-bs-dismiss="modal"  @click="crearCompra(itemProd.id)"  class="btn btn-light">Seleccionar</button>
                                </h6></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>
    import  Cookies from 'js-cookie';
    //import Select2 from 'vue3-select2-component';
    //import $ from 'jquery';
    //let select2 = require('@/assets/plugins/select2/js/select2.min.js');
    //import * as mykey from '../assets/js/mykey.js'
    import $ from 'jquery';
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    //import VueNextSelect from 'vue-next-select';
    import '@vuepic/vue-datepicker/dist/main.css';
    console.log(Cookies.get());
    export default {
        name: 'agregar_compra_component',
        components:{
            //'vue-select': VueNextSelect,
        },
    setup() {
    },
    data() {
        return{
            //Globales:
            compra_creada: false,
            id_compra_creada: '',
            URL_TENANT: '',
            cliente: null,
            empresaData: [0],
            usuariosData: [0],
            busqueda: '',
            proveedoresData: [0],
            focusInput: null,
            //Crear Compra
            compra: {
                proveedor: '',
            },
            condicion_nit: 0,
            }
        },

    computed: {
    proveedorDataFilter() {
        if (this.busqueda != ''){
            return this.proveedoresData.filter((item) => {
                return  item.nombre.toLowerCase().includes(this.busqueda.toLowerCase()) ||  item.nit.toLowerCase().includes(this.busqueda.toLowerCase())
        })
        }else{
            return [];
        }
    },
},
    watch:{
    },
    methods: {
        async limpiarInputs(){
            this.compra = {
                proveedor: '',
            }
            this.busqueda = '';
            this.getproveedoresData();
            this.compra_creada = false;
        },
        //Crear Compra:
        async crearCompra(id_proveedor){
            try{
                const formData = new FormData();
                formData.append("proveedor", id_proveedor);
                notificaciones.round_success_noti($.msg='Compra Creada Exitosamente');
                const response = await fetch(this.URL_TENANT+'/api/compra/',{
                method: 'POST',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
                if(response.ok){
                    const data = await response.json();
                    this.id_compra_creada = data.id;
                }
                document.getElementById('close').click();
                setInterval(function(){this.compra_creada = true; },2000);
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
        },
        async getproveedoresData() {
            const response = await fetch(this.URL_TENANT+'/api/proveedor/');
            this.proveedoresData = await response.json();
        },
        },
mounted() {
    var myModalEl = document.getElementById('agregarModal')
        myModalEl.addEventListener('shown.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.inputInModal.focus();
                this.$refs.inputInModal.select();
            });
        });
    },
beforeUnmount() {
    var myModalEl = document.getElementById('agregarModal')
    myModalEl.removeEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.inputInModal.focus();
        });
    });
},
created() {
        // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
    this.getproveedoresData();
    },
}
    </script>