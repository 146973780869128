
<template>
<div class="page-wrapper">
			<div class="page-content">
				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Catálogo</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Clientes</li>
							</ol>
						</nav>
					</div>
				</div>
				<!--end breadcrumb-->
				<div class="card">
					<div class="card-body">
						<div class="d-lg-flex align-items-center mb-4 gap-3">
							<div class="position-relative">
								<input autofocus type="text" class="form-control ps-5 radius-30" v-model="buscarCliente" placeholder="Buscar Cliente"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
							</div>
						<div class="ms-auto"><a href="javascript:;" @click="cleanAñadirData" data-bs-toggle="modal" data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Añadir  Cliente</a></div>
						</div>
                        <!-- Button trigger modal -->
						<!-- Modal -->
						<div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-lg">
								<div class="modal-content bg-dark">
									<div class="modal-header">
										<h5 class="modal-title" id="exampleModalLabel">Añadir Cliente</h5>
										<button type="button" class="btn-close" id="close_cliente" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">NIT</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="nitCliente"  class="form-control" v-model="nitCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Nombre</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="nombreCliente"  class="form-control" v-model="nombreCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Apellido</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="apellidoCliente"  class="form-control" v-model="apellidoCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Dirección</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="direccionCliente"  class="form-control" v-model="direccionCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Fecha de Nacimiento</h6>
											</div>
											<div class="col-sm-9">
												<input type="date" id="fechaNacimientoCliente"  class="form-control" v-model="fechaNacimientoCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Teléfono</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="telefonoCliente"  class="form-control" v-model="telefonoCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Celular</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="celularCliente"  class="form-control" v-model="celularCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Correo</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="correoCliente"  class="form-control" v-model="correoCliente"  />
											</div>
										</div>
                                    </div>
									<div class="modal-footer">
										<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
										<button type="button" @click="agregarCliente" class="btn btn-primary">Guardar</button>
									</div>
								</div>
							</div>
						</div>
						<!-- End Modal -->
                        <!-- Modal Modify -->
						<div class="modal fade"  id="modificarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-lg">
								<div class="modal-content bg-dark">
									<div class="modal-header">
										<h5 class="modal-title" id="exampleModalLabel">Modificar Categoría</h5>
										<button type="button" class="btn-close" id="close_mod" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">NIT</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="nitCliente"  class="form-control" v-model="nitCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Nombre</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="nombreCliente"  class="form-control" v-model="nombreCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Apellido</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="apellidoCliente"  class="form-control" v-model="apellidoCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Dirección</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="direccionCliente"  class="form-control" v-model="direccionCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Fecha de Nacimiento</h6>
											</div>
											<div class="col-sm-9">
												<input type="date" id="fechaNacimientoCliente"  class="form-control" v-model="fechaNacimientoCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Teléfono</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="telefonoCliente"  class="form-control" v-model="telefonoCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Celular</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="celularCliente"  class="form-control" v-model="celularCliente"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Correo</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="correoCliente"  class="form-control" v-model="correoCliente"  />
											</div>
										</div>
                                    </div>
									<div class="modal-body">
                                    <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Estado</h6>
											</div>
                                            <div class="col-sm-9">
                                                <vue-select   :options="estados"
                                                    v-model="selectedEstado"
                                                    label-by="nombre"
                                                    hide-selected="true"
                                                    track-by="nombre"
                                                    value-by="id"
                                                    placeholder="Selecciona un Estado"
                                                    close-on-select>
                                                    <template #label="{ selected }">
                                                    <template v-if="selected">
                                                        <div style="color:white;">{{ selected.nombre }}</div>
                                                    </template>
                                                    <template v-else > <div style="color:white;">Seleccione un Estado</div></template>
                                                    </template>
                                                    <template #dropdown-item="{ option }" >
                                                    <div  style="color:white;">{{ option.nombre }}</div>
                                                    </template>
                                                </vue-select>
											</div>
                                        </div>
                                    </div>

									<div class="modal-footer">
										<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
										<button type="button" @click="modificarCliente" class="btn btn-primary">Guardar</button>
									</div>
								</div>
							</div>
						</div>
						<!-- End Modal Modify -->
						<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th><h6># NIT</h6></th>
										<th><h6>Nombre</h6></th>
										<th><h6>Apellido</h6></th>
										<th><h6>Dirección</h6></th>
										<th><h6>Fecha de Nacimiento</h6></th>
										<th><h6>Teléfono</h6></th>
										<th><h6>Celular</h6></th>
										<th><h6>Correo</h6></th>
										<th><h6>Estado</h6></th>
										<th><h6>Acciones</h6></th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in clientesDataFilter" :key="item.id">
										<td >
											<div class="d-flex align-items-center">
												<div>
													<input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
												</div>
												<div class="ms-2">
													<h6 class="mb-0 font-14">#{{item.nit}}</h6>
												</div>
											</div>
										</td>
										<td><h6>{{item.nombre}}</h6></td>
										<td><h6>{{item.apellido}}</h6></td>
										<td><h6>{{item.direccion}}</h6></td>
										<td><h6>{{item.fecha_nacimiento}}</h6></td>
										<td><h6>{{item.telefono}}</h6></td>
										<td><h6>{{item.celular}}</h6></td>
										<td><h6>{{item.correo}}</h6></td>
										<td>
                                            <div class="badge rounded-pill bg-light p-2 text-uppercase px-3">
                                                <div v-if="[[item.estado]] == 'I'"> <i  class='bx bx-minus me-1'></i>Inactivo</div>
                                                <div v-if="[[item.estado]] == 'A'"> <i  class='bx bx-circle me-1'></i>Activo</div>
                                            </div>
                                        </td>
										<td>
											<div class="d-flex order-actions">
												<a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modificarModal" @click="fillmodificarCliente([[item.id]])" class=""><i class='bx bxs-edit'></i></a>
												<a href="javascript:;" class="ms-3"><i class='bx bxs-trash'></i></a>
											</div>
										</td>
									</tr>


								</tbody>
							</table>
						</div>
					</div>
				</div>


			</div>
		</div>

</template>
<style>
</style>
<script>
import  Cookies from 'js-cookie';
//import Select2 from 'vue3-select2-component';
//import $ from 'jquery';
//let select2 = require('@/assets/plugins/select2/js/select2.min.js');
//import * as mykey from '../assets/js/mykey.js'
import $ from 'jquery';
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')
import VueNextSelect from 'vue-next-select'
console.log(Cookies.get());
export default {
    name: 'cliente_component',
    components:{
        'vue-select': VueNextSelect,
    },
data() {
	return{
		URL_TENANT: '',
        empresaData: [0],
        clientesData: [],
        // Agregar Cliente:
        nitCliente: null,
        nombreCliente: null,
        apellidoCliente: null,
        direccionCliente: null,
        fechaNacimientoCliente: null,
        telefonoCliente: null,
        celularCliente: null,
        correoCliente: null,
        selectedEstado: null,
        estados: [
            {
                id: 'A',
                nombre: 'Activo',
            },
            {
                id: 'I',
                nombre: 'Inactivo',
            }
        ],
        modificarId: null,
		buscarCliente: '',
		}
	},

computed: {
    clientesDataFilter() {
        if (this.buscarCliente != ''){
            return this.clientesData.filter((item) => {
            return item.nombre.toLowerCase().includes(this.buscarCliente.toLowerCase()) || item.apellido.toLowerCase().includes(this.buscarCliente.toLowerCase()) || item.direccion.toLowerCase().includes(this.buscarCliente.toLowerCase()) || item.telefono.toLowerCase().includes(this.buscarCliente.toLowerCase()) || item.celular.toLowerCase().includes(this.buscarCliente.toLowerCase()) || item.correo.toLowerCase().includes(this.buscarCliente.toLowerCase())
        })
        }else{
            return this.clientesData;
        }
    },
},
methods: {
    async cleanAñadirData() {
        console.log("CLEAN AÑADIR DATA");
        this.nombreCliente = '';
		this.nitCliente = '';
        this.apellidoCliente = '';
        this.direccionCliente = '';
        this.fechaNacimientoCliente = '';
        this.telefonoCliente = '';
        this.celularCliente = '';
        this.correoCliente = '';
        this.selectedEstado = null;
    },
    async fillmodificarCliente(idMod) {
        this.modificarId = idMod[0][0];
        for(var k in this.clientesData){
            if(this.clientesData[k].id==this.modificarId){
            console.log(k, this.clientesData[k]);
            this.nitCliente = this.clientesData[k].nit;
            this.nombreCliente = this.clientesData[k].nombre;
            this.apellidoCliente = this.clientesData[k].apellido;
            this.direccionCliente = this.clientesData[k].direccion;
            this.fechaNacimientoCliente = this.clientesData[k].fecha_nacimiento;
            this.telefonoCliente = this.clientesData[k].telefono;
            this.celularCliente = this.clientesData[k].celular;
            this.correoCliente = this.clientesData[k].correo;
            this.selectedEstado = this.clientesData[k].estado;

            }
        }
    },
    async modificarCliente(){

        if (!this.selectedEstado  || !this.nombreCliente || !this.apellidoCliente || !this.modificarId ){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        }else{
            console.log("ESTAS UPDETEANDO");
            try{
                const formData = new FormData();
                formData.append("nit", this.nitCliente);
                formData.append("nombre", this.nombreCliente);
                formData.append("apellido", this.apellidoCliente);
                formData.append("direccion", this.direccionCliente);
                formData.append("fecha_nacimiento", this.fechaNacimientoCliente == null ? "":this.fechaNacimientoCliente);
                formData.append("telefono", this.telefonoCliente);
                formData.append("celular", this.celularCliente);
                formData.append("correo", this.correoCliente);
                formData.append("estado", this.selectedEstado);
                await fetch(this.URL_TENANT+'/api/cliente/'+this.modificarId+'/',{
                method: 'put',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Cliente Modificado');
            document.getElementById('close_mod').click();
            this.getClientesData();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }

        }
    },
    async agregarCliente(){

        if (!this.nombreCliente || !this.nitCliente  ){
            notificaciones.round_error_noti($.msg='Nit (Puede ser CF) y Nombre Obligatorios');
        }else{

            try{
                const formData = new FormData();
                formData.append("nit", this.nitCliente);
                formData.append("nombre", this.nombreCliente);
                formData.append("apellido", this.apellidoCliente);
                formData.append("direccion", this.direccionCliente);
                formData.append("fecha_nacimiento", this.fechaNacimientoCliente);
                formData.append("telefono", this.telefonoCliente);
                formData.append("celular", this.celularCliente);
                formData.append("correo", this.correoCliente);
                formData.append("estado", 'A');
                await fetch(this.URL_TENANT+'/api/cliente/',{
                method: 'post',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg=' Cliente Agregado');
            document.getElementById('close_cliente').click();
            this.getClientesData();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }

        }
    },
    async getEmpresaData() {
        const response = await fetch(this.URL_TENANT+'/api/empresa/');
        this.empresaData = await response.json();
    },
	async getClientesData() {
        const response = await fetch(this.URL_TENANT+'/api/cliente/');
        this.clientesData = await response.json();
    },
    },

created() {
      // Fetch tasks on page load
	const result = window.location.origin;
    this.URL_TENANT = result;
    this.getClientesData();
    this.getEmpresaData();

	},
}

</script>
