<template>
      <Header/>
      <Nav/>
      <Kardex/>


</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import Kardex from '../../components/reportes/kardex.vue'





export default {
  name: 'KardexComponent',

  components: {
    Header,
    Nav,
    Kardex,
  },

  data: () => ({
    //
  }),
}
</script>
