import { createApp } from 'vue'
import Dashboard from './Dashboard.vue'
//import Nav from './nav.vue'
//import App from './App.vue'

//Empresa:
import PerfilUsuario from './PerfilUsuario.vue'
import CambiarContraseña from './CambiarContraseña.vue'
import Usuarios from './Usuarios.vue'
import Departamentos from './Departamento.vue'
import Puestos from './Puesto.vue'
import Caja from './main_vue/caja/Caja.vue'
import Gasto from './main_vue/gasto/Gasto.vue'

//Inventarios:
import Categoria from './Categoria.vue'
import Productos from './Productos.vue'
import BuscarProductos from './main_vue/inventario/BuscarProductos.vue'
import ProductosAgregar from './ProductosAgregar.vue'
import ProductosModificar from './ProductosModificar.vue'

//Catalogos:
import Proveedores from './Proveedor.vue'
import Clientes from './Cliente.vue'

//Compra:
import Requisiciones from './Requisicion.vue'
import CotizacionCompra from './CotizacionCompra.vue'
import OrdenCompra from './OrdenCompra.vue'
import Compra from './main_vue/compra/Compra.vue'
import DetalleCompra from './main_vue/compra/DetalleCompra.vue'


//Reportes:
import UtilidadesDelProducto from './main_vue/reportes/UtilidadesDelProducto.vue'
import Kardex from './main_vue/reportes/Kardex.vue'

//Venta:
import Venta from './Venta.vue'
import DetalleVenta from './DetalleVenta.vue'
import Pagos from './main_vue/venta/Pagos.vue'


//Inventario:
import AjusteInventario from './main_vue/inventario/AjusteInventario.vue'
import Devolucion from './main_vue/inventario/Devolucion.vue'
import DetalleDevolucion from './main_vue/inventario/DetalleDevolucion.vue'

//Aplicaciones:
import Calendario from './Calendario.vue'

///Consultorio:
import Consultorio from './main_vue/consultorio_virtual/Consultorio.vue'
import Medico from './main_vue/consultorio_virtual/Medico.vue'
import Asistente from './main_vue/consultorio_virtual/Asistente.vue'
import Expediente from './main_vue/consultorio_virtual/Expediente.vue'
import DetalleExpediente from './main_vue/consultorio_virtual/DetalleExpediente.vue'

//Agencia Viaje:
import Vehiculo from './main_vue/agencia_viaje/Vehiculo.vue'
import Viaje from './main_vue/agencia_viaje/Viaje.vue'
import ViajeCliente from './main_vue/agencia_viaje/ViajeCliente.vue'
import MisViajes from './main_vue/agencia_viaje/MisViajes.vue'

//Home:
import Home from './main_vue/home/Home.vue'

//Usuarios:
import MisAplicaciones from './main_vue/usuarios/MisAplicaciones.vue'

// CSS Importados:
import "@/assets/css/pace.min.css";
import "@/assets/css/index.css";
import "@/assets/plugins/notifications/css/lobibox.min.css";
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/bootstrap-extended.css";
import "@/assets/css/app.css";
import "@/assets/css/icons.css";
import "@/assets/plugins/select2/css/select2.min.css";
import "@/assets/plugins/simplebar/css/simplebar.css";
import "@/assets/plugins/select2/css/select2-bootstrap4.css";
import "@/assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css";
import "@/assets/plugins/metismenu/css/metisMenu.min.css";
import 'vue-search-select/dist/VueSearchSelect.css'
import '@/assets/css/imageuploadify.min.css';
//import "bootstrap";
// import js:
//Currency:
const currency = new Intl.NumberFormat('es-GT', {style: 'currency', currency: 'GTQ'})


createApp(Dashboard)
  .mount('#dashboard')

//Usuarios:
createApp(MisAplicaciones)
  .mount('#mis_aplicaciones')

//Empresa:
createApp(PerfilUsuario)
  .mount('#perfil_usuario')

createApp(CambiarContraseña)
  .mount('#cambiar_contraseña')

createApp(Usuarios)
  .mount('#usuarios')

createApp(Departamentos)
  .mount('#departamento')

createApp(Puestos)
  .mount('#puesto')

const appCaja = createApp(Caja)
  appCaja.config.globalProperties.$filters = {
    currencyGTQ(value){
      return currency.format(value);
    }
  }
  appCaja.mount('#caja')

const appGasto = createApp(Gasto)
  appGasto.config.globalProperties.$filters = {
    currencyGTQ(value){
      return currency.format(value);
    }
  }
  appGasto.mount('#gasto')

//Inventarios:
createApp(Categoria)
  .mount('#categoria')

createApp(Productos)
  .mount('#productos')

createApp(BuscarProductos)
  .mount('#buscar_productos')

createApp(ProductosAgregar)
  .mount('#productos_agregar')

createApp(ProductosModificar)
  .mount('#productos_modificar')


//Catalogos:
createApp(Proveedores)
  .mount('#proveedores')

createApp(Clientes)
  .mount('#clientes')

//Compra:
createApp(Requisiciones)
  .mount('#requisiciones')

createApp(CotizacionCompra)
  .mount('#cotizacion_compra')

createApp(OrdenCompra)
  .mount('#orden_compra')

const appCompra = createApp(Compra)
  appCompra.config.globalProperties.$filters = {
    currencyGTQ(value){
      return currency.format(value);
    }
  }
  appCompra.mount('#compra')

const appDetalleCompra = createApp(DetalleCompra)
  appDetalleCompra.config.globalProperties.$filters = {
    currencyGTQ(value){
      return currency.format(value);
    }
  }
  appDetalleCompra.mount('#detalle_compra')

//Venta:
const appVenta = createApp(Venta)
  appVenta.config.globalProperties.$filters = {
    currencyGTQ(value){
      return currency.format(value);
    }
  }
  appVenta.mount('#venta')

const appDetalleVenta = createApp(DetalleVenta)
  appDetalleVenta.config.globalProperties.$filters = {
    currencyGTQ(value){
      return currency.format(value);
    }
  }
  appDetalleVenta.mount('#detalle_venta')

const appPagos = createApp(Pagos)
  appPagos.config.globalProperties.$filters = {
    currencyGTQ(value){
      return currency.format(value);
    }
  }
  appPagos.mount('#pagos')

//Inventario:
const appAjusteInventario = createApp(AjusteInventario)
appAjusteInventario.config.globalProperties.$filters = {
  currencyGTQ(value){
    return currency.format(value);
  }
}
appAjusteInventario.mount('#ajuste_inventario')

const appDevolucion = createApp(Devolucion)
appDevolucion.config.globalProperties.$filters = {
  currencyGTQ(value){
    return currency.format(value);
  }
}
appDevolucion.mount('#devolucion')

const appDetalleDevolucion = createApp(DetalleDevolucion)
appDetalleDevolucion.config.globalProperties.$filters = {
  currencyGTQ(value){
    return currency.format(value);
  }
}
appDetalleDevolucion.mount('#detalle_devolucion')


//Reportes:
const appUtilidadesDelProducto = createApp(UtilidadesDelProducto)
  appUtilidadesDelProducto.config.globalProperties.$filters = {
    currencyGTQ(value){
      return currency.format(value);
    }
  }
  appUtilidadesDelProducto.mount('#utilidades_del_producto')

const appKardex = createApp(Kardex)
  appKardex.config.globalProperties.$filters = {
    currencyGTQ(value){
      return currency.format(value);
    }
  }
  appKardex.mount('#kardex')

//Aplicaciones:
createApp(Calendario)
  .mount('#calendario')

//Consultorio:
createApp(Consultorio)
  .mount('#consultorio')

createApp(Medico)
  .mount('#medico')

createApp(Asistente)
  .mount('#asistente')

createApp(Expediente)
  .mount('#expediente')

createApp(DetalleExpediente)
  .mount('#detalle_expediente')


//Agencia Viaje:
createApp(Vehiculo)
  .mount('#vehiculo')

createApp(Viaje)
  .mount('#viaje')

createApp(ViajeCliente)
  .mount('#viaje_cliente')

createApp(MisViajes)
  .mount('#mis_viajes')
//Home:
createApp(Home)
  .mount('#home')