<template>
      <Header/>
      <Nav/>
      <PerfilUsuario/>

</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'
import PerfilUsuario from './components/empresa/perfil_usuario.vue'


export default {
  name: 'PerfilUsuarioComponent',

  components: {
    Header,
	Nav,
	PerfilUsuario,

  },

  data: () => ({
    //
  }),
}
</script>
