<template>
      <Header/>
      <Nav/>
      <DetalleCompra/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import DetalleCompra from '../../components/compra/compra/detalle_compra.vue'



export default {
  name: 'DetalleCompraComponent',

  components: {
    Header,
    Nav,
    DetalleCompra,
  },

  data: () => ({
    //
  }),
}
</script>
