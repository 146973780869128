<template>
      <Header/>
      <Nav/>
      <Gasto/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import Gasto from '../../components/empresa/gasto/gasto.vue'



export default {
  name: 'GastoComponent',

  components: {
  Header,
	Nav,
	Gasto,
  },

  data: () => ({
    //
  }),
}
</script>
