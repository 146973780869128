<template>
    <div class="page-wrapper">
        <div class="page-content">
            <!--breadcrumb-->
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Agencia de Viajes</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Administrar Viajes</li>
                        </ol>
                    </nav>
                </div>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-light">Settings</button>
                        <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	<a class="dropdown-item" href="javascript:;">Action</a>
                            <a class="dropdown-item" href="javascript:;">Another action</a>
                            <a class="dropdown-item" href="javascript:;">Something else here</a>
                            <div class="dropdown-divider"></div>	<a class="dropdown-item" href="javascript:;">Separated link</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end breadcrumb-->
            <div class="card">
                <div class="card-body">
                    <div class="d-lg-flex align-items-center mb-4 gap-3">
                        <div class="position-relative">
                            <input type="text" class="form-control ps-5 radius-30" v-model="buscarViaje" placeholder="Buscar Viaje"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                        </div>
                        <div class="ms-auto">
                            <add_viaje ref="child_add"></add_viaje>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th>Código</th>
                                    <th>Origen</th>
                                    <th>Destino</th>
                                    <th>Fecha Salida</th>
                                    <th>Hora Salida</th>
                                    <th>Fecha Regreso</th>
                                    <th>Hora Regreso</th>
                                    <th>Tarifa</th>
                                    <th>Vehículo</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in viajesDataFilter" :key="item.id">
                                    <td>{{item.codigo}}</td>
                                    <td>{{item.origen_nombre}}</td>
                                    <td>{{item.destino_nombre}}</td>
                                    <td>{{item.fecha_salida}}</td>
                                    <td>{{item.hora_salida}}</td>
                                    <td>{{item.fecha_regreso}}</td>
                                    <td>{{item.hora_regreso}}</td>
                                    <td>{{item.tarifa}}</td>
                                    <td>{{item.vehiculo_nombre}}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <button type="button" class="btn btn-light"   data-bs-toggle="modal" data-bs-target="#verAsientos" @click="viaje_mod = item;">Ver Asientos</button>
                                            <a v-if="item.tiene_reservas === false" href="javascript:;" data-bs-toggle="modal" data-bs-target="#modificarModal" @click="viaje_mod = item;" class="ms-3"><i class='bx bxs-edit'></i></a>
                                            <a v-if="item.tiene_reservas === false" href="javascript:;" data-bs-toggle="modal" data-bs-target="#eliminarModal" @click="id_viaje=item.id;
                                            titulo=item.origen_nombre+' - '+item.destino_nombre" class="ms-3"><i class='bx bxs-trash'></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>
    </div>
<!-- Modal -->
<div class="modal fade" id="agregarAsientos" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Asientos</h5>
                <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body d-flex flex-column">
                <div style="text-align: center;">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Listso</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Modal -->
<!-- Modal Eliminar -->
<div class="modal fade"  id="eliminarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Eliminar Viaje</h5>
                <button type="button" id="closedel" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <del_viaje :id_viaje="id_viaje" :titulo="titulo" ref="child_del"></del_viaje>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button"  data-bs-dismiss="modal" @click="$refs.child_del.eliminarViaje(); " class="btn btn-danger">Eliminar</button>
            </div>
        </div>
    </div>
</div>
<!-- End Modal -->
<!-- Modal Modificar -->
<div class="modal fade"  id="modificarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modificar Viaje {{ viaje_mod.codigo }}</h5>
                <button type="button" id="closeMod" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <mod_viaje :viajes="viaje_mod" ref="child_mod"></mod_viaje>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button"  @click="$refs.child_mod.modificarViaje(); " class="btn btn-primary">Modificar</button>
            </div>
        </div>
    </div>
</div>
<!-- End Modal -->
<!-- Modal Asientos -->
<div class="modal fade" id="verAsientos" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Asientos</h5>
                <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body d-flex flex-column">
                <div style="text-align: center;">
                </div>
                <asientos_viaje :viaje_asientos="viaje_mod" ref="child_aientos"></asientos_viaje>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Listo</button>
            </div>
        </div>
    </div>
</div>
</div>
        <!-- End Modal -->
</template>
<style lang="less">
</style>
<script>

import add_viaje from "./agregar_viaje.vue";
import del_viaje from "./eliminar_viaje.vue";
import mod_viaje from "./modificar_viaje.vue";
import asientos_viaje from "./asientos.vue";

export default {
    name: 'viaje_component',
    components:{
        add_viaje,
        del_viaje,
        mod_viaje,
        asientos_viaje,
        /*del_event,*/
    },
    data() {
    return{
        URL_TENANT: '',
        viajesData:  [],
        buscarViaje: '',
        id_viaje: '',
        titulo: '',
        viaje_mod: {},
        }
    },
    watch: {
},
    computed: {
        viajesDataFilter() {
        console.log("LENGT");
        if (this.buscarViaje != ''){
            return this.viajesData.results.filter((item) => {
            return item.codigo.toLowerCase().includes(this.buscarViaje.toLowerCase()) || item.origen_nombre.toLowerCase().includes(this.buscarViaje.toLowerCase()) || item.destino_nombre.toLowerCase().includes(this.buscarViaje.toLowerCase())
        })
        }else{
            return this.viajesData.results;
        }
    },
    },

    methods: {
        async getViajesData(){
            const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/viaje/')
            this.viajesData = await response.json();
        },
    },
    mounted() {
        this.$watch(
            "$refs.child_add.viaje_añadido",
            () => (this.getViajesData()),
        );
        this.$watch(
            "$refs.child_del.viaje_eliminado",
            () => (this.getViajesData()),
        );
        this.$watch(
            "$refs.child_mod.viaje_modificado",
            () => {
                this.getViajesData();
                document.getElementById('closeMod').click();
            }
        );
        },
    created() {
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.getViajesData();
    },
}
</script>