<template>
      <Header/>
      <Nav/>
</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'


export default {
  name: 'DashboardComponent',

  components: {
    Header,
    Nav,
  },

  data: () => ({
    //
  }),
}
</script>
