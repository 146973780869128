
<template>
<a href="javascript:;" @click="consulta_añadido = false; createConsulta()" class="btn btn-light mt-2 mt-lg-0"><i class="bx bxs-add-to-queue"></i>Agregar Consulta</a>
</template>
<style>
</style>
<script>

let notificaciones = require('@/assets/plugins/notifications//js/notification-custom-script.js');
import $ from 'jquery';
import  Cookies from 'js-cookie';
import { ref } from 'vue';
export default {
    props: ['id_expediente',],
    name: 'agregar_consultas_paciente_component',
    components:{
    },
    setup() {
        // In case of a range picker, you'll receive [Date, Date]
        const date = ref(new Date());
        function formatDate(userDate) {
            // format from M/D/YYYY to YYYYMMDD
            return (new Date(userDate).toJSON().slice(0,10).split('-').reverse().join('-'));
            }
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var today_is = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
        var today_date = formatDate(today_is);
        return {
            date,
            format,
            today_date,
        }
    },
data() {
    return{

        consulta_añadido: false,
        URL_TENANT: '',
        consulta: {
            fecha: this.today_date,
        }
        }
    },
    watch: {
    },
    methods: {
        async createConsulta(){
            const formData = new FormData();
            formData.append("fecha", this.consulta.fecha);
            formData.append("expediente", this.id_expediente);

            await fetch(this.URL_TENANT+'/api/consulta/',{
                method: 'POST',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                }, body: formData
            })
            notificaciones.round_success_noti($.msg='Nueva Consulta Creada');
            this.consulta_añadido = true;

        },
    },
    mounted() {
        },
    created() {
        const result = window.location.origin;
        this.URL_TENANT = result;
    },
}
</script>