
<template>
    <div class="ms-auto">
    <!-- Button trigger modal -->
    <button type="button" ref="buscarProductoBtn" data-bs-toggle="modal" @click="getProductosBusquedaData()" data-bs-target="#agregarModal" class="btn btn-light">Nuevo Ajuste de Inventario</button></div>
        <!-- Modal -->
        <div class="modal fade" id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content bg-dark">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Buscar Producto</h5>
                        <button type="button" class="btn-close" id="agregar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-12">
                            <div class="position-relative">
                                <input ref="busquedaPorTexto" type="text" class="form-control ps-5" v-model="busqueda" autofocus  placeholder="Buscar Producto...(Por Nombre o Código de Barra)"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                            </div>
                        </div>
                        <hr/>
                        <table class="table mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th><h6>Código</h6></th>
                                    <th><h6>Código de Barra</h6></th>
                                    <th><h6>Producto</h6></th>
                                    <th><h6>Unidades de Medida</h6></th>
                                    <th><h6>Seleccionar</h6></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="itemProd in productoDataFilter" :key="itemProd.id">
                                    <td><h6>{{ itemProd.codigo }}</h6></td>
                                    <td><h6>
                                        <div v-for='cb in itemProd.codigos_barra_producto' :key="cb.id" class="col">
                                            <ul>
                                                <li>
                                                {{cb.codigo_barra}}
                                                </li>
                                            </ul>
                                        </div></h6>
                                    </td>
                                    <td><h6>
                                        <label>{{itemProd.nombre}}</label></h6>
                                    </td>
                                    <td><h6>
                                        <div v-for='um in itemProd.unidades_medida_producto' :key="um.id" class="col">
                                            <ul>
                                                <li>
                                                {{um.nombre_unidad_medida}}
                                                </li>
                                            </ul>
                                        </div></h6>
                                    </td>
                                    <td><h6>
                                        <button type="button" data-bs-dismiss="modal"  @click="buscarProductoPorBarra(itemProd.id, 'id')"  class="btn btn-light">Seleccionar</button>
                                    </h6></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal -->
    <!-- Modal Agregar Producto-->
<div class="modal fade" id="modalAgregarProducto" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content bg-dark">
        <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">Ajuste de Inventario</h1>
            <button type="button" class="btn-close2" id="agregar_detalle_close2" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-sm-4">
                    <h6 class="mb-0">Código</h6>
                </div>
                <div class="col-sm-8">
                    <input type="text"  id="producto"  class="form-control" v-model="productoEncontrado.codigo"  readonly/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4">
                    <h6 class="mb-0">Producto</h6>
                </div>
                <div class="col-sm-8">
                    <input type="text"  id="producto"  class="form-control" v-model="productoEncontrado.nombre"  readonly/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4">
                    <h6 class="mb-0">Inventario</h6>
                </div>
                <div class="col-sm-8" v-if="productoEncontrado.unidades_medida[0]">
                    <input type="text" readonly  id="precio"  class="form-control" v-model="productoEncontrado.unidades_medida[0].producto_inventario" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4">
                    <h6 class="mb-0">Cantidad</h6>
                </div>
                <div class="col-sm-8">
                    <input type="text"  id="cantidad"  class="form-control" v-model="productoEncontrado.cantidad" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4">
                    <h6 class="mb-0">Nuevo Inventario</h6>
                </div>
                <div class="col-sm-8">
                    <input type="text"  id="nuevoInventario" readonly  class="form-control" v-model="nuevoInventario" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4">
                    <h6 class="mb-0">Tipo</h6>
                </div>
                <div class="col-sm-8">
                    <select v-model="productoEncontrado.tipo" required class="form-select form-control mb-3" aria-label="Default select example">
                        <option v-for="item in tipos" :key="item.id" :value="item.id">{{item.nombre}}</option>
                    </select>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4">
                    <h6 class="mb-0">Motivo</h6>
                </div>
                <div class="col-sm-8">
                    <textarea cols="1" rows="4" id="motivo" class="form-control" v-model="productoEncontrado.motivo" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            <button class="btn btn-primary"  @click="ajustarInventario();">Ajustar Inventario</button>
        </div>
        </div>
    </div>
</div>
<!-- Fin Modal Agregar Producto -->
</template>
<style>
</style>
<script>
    import  Cookies from 'js-cookie';
    import { Modal } from 'bootstrap';
    import $ from 'jquery';
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    import '@vuepic/vue-datepicker/dist/main.css';
    console.log(Cookies.get());
    export default {
        name: 'agregar_ajuste_inventario_component',
        components:{
        },
    setup() {
    },
    data() {
        return{
            //Globales:
            ajuste_inventario_creada: false,
            id_ajuste_inventario_creada: '',
            URL_TENANT: '',
            cliente: null,
            empresaData: [0],
            usuariosData: [0],
            focusInput: null,
            //Crear Ajuste
            condicion_nit: 0,
            busqueda: '',
            productosBusqueda: [],
            productoEncontrado: {
                codigo: '',
                cantidad: '',
                producto: '',
                nombre: '',
                unidades_medida: [],
                unidad_medida: [],
                inventario: '',
                motivo: '',
                tipo: ''
            },
            tipos: [
            {
                id: 'ERROR DE CONTEO', nombre: 'Error de Conteo',
            },
            {
                id: 'DAÑO', nombre: 'Daño',
            },
            {
                id: 'CADUCIDAD', nombre: 'Caducidad',
            },
            {
                id: 'PERDIDA', nombre: 'Pérdida',
            },
            {
                id: 'DIFERENCIAS DE AUDITORIA', nombre: 'Diferencias de Auditoría',
            },
            {
                id: 'OBSOLESCENCIA', nombre: 'Obsolescencia',
            },
            {
                id: 'MERMA NATURAL', nombre: 'Merma Natural',
            },
            {
                id: 'AJUSTE POR REVALORIZACION', nombre: 'Ajuste por Revalorización',
            }
        ],
            }
        },

computed: {
    productoDataFilter() {
        if (this.busqueda != ''){
            return this.productosBusqueda.filter((item) => {
                return  item.nombre.toLowerCase().includes(this.busqueda.toLowerCase()) || item.codigo.toLowerCase().includes(this.busqueda.toLowerCase()) || item.codigos_barra_producto.some(t => t.codigo_barra.toLowerCase().includes(this.busqueda.toLowerCase()))
        })
        }else{
            return [];
        }
    },
    nuevoInventario(){
        if(this.productoEncontrado.unidades_medida[0]){
            var inv = this.productoEncontrado.unidades_medida[0].producto_inventario;
            var cant = this.productoEncontrado.cantidad == "" ? 0 : this.productoEncontrado.cantidad;
            return parseInt(inv) + parseInt(cant);
        }else{
            return 0
        }
    }
},
    watch:{
    },
    methods: {
        async limpiarProductoEncontrado(){
        this.productoEncontrado = {
            codigo: '',
            cantidad: '',
            producto: '',
            nombre: '',
            unidades_medida: [],
            unidad_medida: [],
            inventario: '',
            motivo: '',
            tipo: ''
        }
    },
        async getProductosBusquedaData() {
            console.log("LLAMANDO DETALLE VENTA");
            console.log(this.id_venta);
            const response = await fetch(this.URL_TENANT+'/api/producto_busqueda/');
            this.productosBusqueda = await response.json();
        },
        async buscarProductoPorBarra(dato, tipo){
            this.limpiarProductoEncontrado();
            if(tipo === 'id'){
                console.log("id.....");
                console.log(dato);
                this.obj = this.productosBusqueda.filter((item) => {
                        return  item.id.includes(dato);
                    });
                this.productoEncontrado.producto = this.obj[0].id;
                this.productoEncontrado.nombre = this.obj[0].nombre;
                this.productoEncontrado.codigo = this.obj[0].codigo;
                this.buscarUnidadesProductoData(this.obj[0].id);
                document.getElementById('agregar_detalle_close').click();
                // Código para mostrar un el Modal desde Vue:
                this.myModal = new Modal(document.getElementById('modalAgregarProducto'), {
                            keyboard: false
                        });
                    this.myModal.show();
            }
        },
        //Buscar Unidades de Medida por  producto:
        async buscarUnidadesProductoData(id_producto){
            const response = await fetch(this.URL_TENANT+'/api/producto_unidades_medida_busqueda/?id_producto='+id_producto);
            this.productoEncontrado.unidades_medida = await response.json();
            console.log("UNIDADES MEDIDASSSSSSS....");
            console.log(this.productoEncontrado.unidades_medida);
        },
        async limpiarInputs(){
            this.ajuste_inventario_creada = false;
        },
        // Validadores:
        validateInputs(){
                this.nameError =  (this.productoEncontrado.cantidad == '') ? ['Debe Ingresar la Cantidad'] : [];
                this.nameError = (this.productoEncontrado.tipo == '') ? [...this.nameError, 'Debe Seleccionar Tipo de Ajuste'] : this.nameError;
                this.nameError = (this.productoEncontrado.motivo == '') ? [...this.nameError, 'Debe Ingresar el Motivo de Ajuste'] : this.nameError;
            },
        //Crear Ajuste de Inventario:
        async ajustarInventario(){
            this.validateInputs();
            if(this.nameError.length != 0){
            console.log("NAME ERROR");
            this.nameError.forEach((element) =>{
                notificaciones.round_info_noti($.msg=element);
            });
        }else{
            try{
                const formData = new FormData();
                formData.append("producto", this.productoEncontrado.unidades_medida[0].id);
                formData.append("cantidad_anterior", this.productoEncontrado.unidades_medida[0].producto_inventario);
                formData.append("cantidad", this.productoEncontrado.cantidad);
                formData.append("cantidad_nueva", this.nuevoInventario);
                formData.append("tipo", this.productoEncontrado.tipo);
                formData.append("motivo", this.productoEncontrado.motivo);
                const response = await fetch(this.URL_TENANT+'/api/ajuste_inventario/',{
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: formData})
                    if(response.ok){
                        const data = await response.json();
                        this.id_ajuste_inventario_creada = data.id;
                        notificaciones.round_success_noti($.msg='Ajuste Realizado Exitosamente');
                        document.getElementById('agregar_detalle_close2').click();
                        this.ajuste_inventario_creada = true;
                        setInterval(function(){ },2000);
                    }else {
                        switch(response.status){
                            default:
                                notificaciones.round_error_noti($.msg='Error Desconocido');
                                break;
                        }
                    }
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
            }
        },
        },
mounted() {
    var myModalEl = document.getElementById('agregarModal')
        myModalEl.addEventListener('shown.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.inputInModal.focus();
                this.$refs.inputInModal.select();
            });
        });
    },
beforeUnmount() {
    var myModalEl = document.getElementById('agregarModal')
    myModalEl.removeEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.inputInModal.focus();
        });
    });
},
created() {
        // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
    },
}
    </script>