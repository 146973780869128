
<template>
<button type="button" class="btn btn-light" @click="limpiarInputs()" data-bs-toggle="modal" data-bs-target="#agregarModal">Agregar Consultorio</button>
<!-- Modal -->
    <div class="modal fade"  v-if="modalShow" id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content bg-dark">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Crear Consultorio</h5>
                    <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Nombre</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text"  class="form-control" v-model="consultorio.nombre"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Direccion</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="consultorio.direccion"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Teléfono</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="consultorio.telefono"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Celular</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="consultorio.telefono2"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Correo</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="email" class="form-control" v-model="consultorio.correo" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Sitio Web</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="consultorio.sitio_web" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Descripción</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="consultorio.descripcion" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Logo</h6>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group mb-3">
                                <label class="input-group-text" for="inputGroupFile01">Subir</label>
                                <input type="file" class="form-control" id="imagenU2">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" @click="agregarConsultorio()" class="btn btn-primary">Guardar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- End Modal -->

</template>
<style>
</style>
<script>

let notificaciones = require('@/assets/plugins/notifications//js/notification-custom-script.js');
import $ from 'jquery';
import  Cookies from 'js-cookie';
export default {
    name: 'agregar_consultorio_component',
    components:{
    },
data() {
    return{
        modalShow: true,
        consultorio_añadido: false,
        URL_TENANT: '',
        consultorio:  {
            nombre: "",
            direccion: "",
            telefono: "",
            correo: "",
            logo: "",
            sitio_web: "",
            descripcion: "",
            telefono2: ""
            },
        }
    },
    watch: {
    },
    methods: {
        async  limpiarInputs(){
            this.consultorio_añadido= false;
            this.consultorio =  {
            nombre: "",
            direccion: "",
            telefono: "",
            correo: "",
            logo: "",
            sitio_web: "",
            descripcion: "",
            telefono2: ""
            }
        },
        async getConsultoriosData(){
            const response = await fetch(this.URL_TENANT+'/api/consultorio/')
            this.consultoriosData = await response.json();
        },
        //Agregar Consultorio:
        async agregarConsultorio(){
            if(!this.consultorio.nombre){
                notificaciones.round_error_noti($.msg='El Nombre del Consultorio es Obligatorio');
            }else{
                try{
                    const logo = document.getElementById("imagenU2");
                    const formData = new FormData();
                    formData.append("nombre", this.consultorio.nombre);
                    formData.append("direccion", this.consultorio.direccion);
                    formData.append("telefono", this.consultorio.telefono);
                    formData.append("telefono2", this.consultorio.telefono2);
                    formData.append("correo", this.consultorio.correo);
                    formData.append("sitio_web", this.consultorio.sitio_web);
                    formData.append("descripcion", this.consultorio.descripcion);
                    console.log("LOGO");
                    console.log(logo);
                    console.log( logo.files[0]);
                    if(logo.files.length == 0){
                        formData.append("logo", '')
                    }else{
                        formData.append("logo", logo.files[0]);
                    }
                    await fetch(this.URL_TENANT+'/api/consultorio/',{
                        method: 'POST',
                        headers: {
                            'X-CSRFToken': Cookies.get('csrftoken'),
                            },
                        body: formData})
                    notificaciones.round_success_noti($.msg='Consultorio Creado Exitosamente');
                    this.consultorio_añadido = true;
                    document.getElementById('close').click();
                }catch(error){
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
            }
        },
    },
    mounted() {
        },
    created() {
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.getConsultoriosData();
    },
}
</script>