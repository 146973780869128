
<template>
    <div class="page-wrapper">
                <div class="page-content">
                    <!--breadcrumb-->
                    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div class="breadcrumb-title pe-3">Gastos</div>
                        <div class="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0 p-0">
                                    <li class="breadcrumb-item"><a href="javascript:;"><i class='bx bx-wallet-alt'></i></a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">gasto</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <!--end breadcrumb-->
                    <div class="card">
                        <div class="card-body">
                            <div class="d-lg-flex align-items-center mb-4 gap-3">
                                <div class="position-relative">
                                    <input type="text" class="form-control ps-5 radius-30" v-model="buscarGasto" placeholder="Buscar Gasto"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                                </div>
                                <div v-if="userData && userData[0] && !userData[0].caja_del_dia" class="ms-auto">
                                    <span class="badge bg-warning text-dark"><i  class='bx bxs-lock-alt'></i>No Has Aperturado la Caja</span>
                                </div>
                                <div v-show="userData && userData[0] && userData[0].caja_del_dia" class="ms-auto">
                                    <add_gasto ref="child_add"></add_gasto>
                                </div>

                            </div>
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th><h6>Fecha</h6></th>
                                            <th><h6>Monto</h6></th>
                                            <th><h6>Descripción</h6></th>
                                            <th><h6>Caja</h6></th>
                                            <th><h6>Usuario</h6></th>
                                            <th><h6>Acción</h6></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in gastosDataFilter" :key="item.id">
                                            <td ><h6>
                                                <div class="d-flex align-items-center">
                                                    <div>
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-0 font-14">{{item.fecha_creacion}}</h6>
                                                    </div>
                                                </div></h6>
                                            </td>
                                            <td>
                                                <h6>{{ $filters.currencyGTQ(item.monto)}}</h6>
                                            </td>
                                            <td><h6>{{item.descripcion}}</h6></td>
                                            <td><h6>{{item.nombre_caja}}</h6></td>
                                            <td><h6>{{item.nombre_usuario}}</h6></td>
                                            <td><h6>
                                                <div class="d-flex order-actions">
                                                    <a href="javascript:;" v-if="!item.es_caja_cerrada_o_gasto_anterior" data-bs-toggle="modal" @click="setModificarGasto(item)" data-bs-target="#modificarDetalleModal" class="ms-3"><i class='bx bxs-edit'></i></a>
                                                </div></h6>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Paginacion -->
                        <div class="card-body">
                            <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="javascript:;" @click="cambiarUrl(gastosData.links.previous, 'atras')">Atrás</a>
                                        </li>
                                        <li class="page-item" :class="{active: indexPagination == index}" v-for="index in gastosData.total_pages" :key="index">
                                            <a class="page-link"  href="javascript:;javascript:;" @click="cambiarUrl(this.URL_TENANT+'/api/gasto/?page='+index+'', index)">{{ index }}</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="javascript:;" @click="cambiarUrl(gastosData.links.next, 'adelante')">Siguiente</a>
                                        </li>
                                    </ul>
                            </nav>
                        </div>
                        <!-- Fin Paginacion -->
                    </div>

                </div>
            </div>
<!-- Modal Modificar Producto -->
<div class="modal fade"  id="modificarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modificar Gasto</h5>
                <button type="button" class="btn-close" id="modificar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Monto</h6>
                    </div>
                    <div class="col-sm-10">
                        <input class="form-control"  v-model="modificar_gasto.monto" type="number" >
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Descripción</h6>
                    </div>
                    <div class="col-sm-10">
                    <input class="form-control" ref="cantidadModificarProducto" v-model="modificar_gasto.descripcion" type="text" placeholder="Descripción del Gasto">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" @click="modificarGasto()" data-bs-dismiss="modal" class="btn btn-warning">Modificar</button>
            </div>
        </div>
    </div>
    </div>
    <!-- End Modal Modificar Producto -->
    </template>
    <style>
    .btn-common {
        width: 100%;
        height: 100%;
        max-width: 175px; /* Ancho máximo para limitar el crecimiento excesivo en pantallas grandes */
        max-height: 50px; /* Altura máxima para limitar el crecimiento excesivo en pantallas grandes */
    }
    </style>
    <script>
    import  Cookies from 'js-cookie';
    import $ from 'jquery';
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    import '@vuepic/vue-datepicker/dist/main.css';
    import add_gasto from "./agregar_gasto.vue"
    //import { ref } from 'vue';
    console.log(Cookies.get());
    export default {
        name: 'ventas_component',
        components:{
            add_gasto,
        },
    setup() {

    },
    data() {
        return{
            //Globales:
            URL_TENANT: '',
            userData: {},
            url: "/api/gasto/",
            indexPagination: 1,
            buscarGasto: '',
            gastosData: [],
            modificar_gasto: {},
            nameError: []
            }
        },

    computed: {
        gastosDataFilter() {
            if (this.buscarGasto != ''){
                return this.gastosData.results.filter((item) => {
                return item.referencia.toLowerCase().includes(this.buscarGasto.toLowerCase())
            })
            }else{
                return this.gastosData.results;
            }
        },
    },
    watch:{
        url: function () {
            this.getgastosData();
        },
    },
    methods: {
        validateInputs(){
            this.nameError =  (this.modificar_gasto.monto == '') ? ['Debe Ingresar el Monto'] : [];
            this.nameError = (this.modificar_gasto.descripcion == '') ? [...this.nameError, 'Debe Ingresar una Descripción'] : this.nameError;
        },
        async modificarGasto(){
            this.validateInputs();
                if(this.nameError.length != 0){
                    this.nameError.forEach((element) =>{
                        notificaciones.round_info_noti($.msg=element);
                    });
                }else{
                    const formData = new FormData();
                    formData.append("caja", this.userData[0].caja_del_dia.id);
                    formData.append("monto", this.modificar_gasto.monto);
                    formData.append("descripcion", this.modificar_gasto.descripcion);
                    formData.append("usuario", this.modificar_gasto.usuario);
                    await fetch(this.URL_TENANT+'/api/gasto/'+this.modificar_gasto.id+'/',{
                    method: 'PUT',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: formData})
                    notificaciones.round_success_noti($.msg='Gasto Modificado Exitosamente');
                    //document.getElementById('agregarModal').click();
                    document.getElementById('modificar_detalle_close').click();
                }
        },
        async setModificarGasto(item){
            this.modificar_gasto = item;
        },
        //Paginación:
        async cambiarUrl(link, index){
            this.url = link;
            if (index === "atras" && link){
                this.indexPagination = this.indexPagination - 1;
            }
            else if (index === "adelante" && link != null){
                this.indexPagination = this.indexPagination + 1;
            }
            else if (link){
                this.indexPagination = index;
            }
        },
        // Llenado:
        async getgastosData() {
            const response = await fetch(this.url);
            this.gastosData = await response.json();
        },
        async getUserData(){
		const response = await fetch(this.URL_TENANT+'/api/user/');
		this.userData = await response.json();
	},
        },
    mounted() {
        console.log("MOUNTED");
        this.$watch(
            "$refs.child_add.gasto_creada",
            () => (this.getgastosData()),
        );
        this.$watch(
            "$refs.child_add.id_venta_creada",
            () => (  console.log("SE CREO LA VENTAS"),window.location.href = this.URL_TENANT+'/venta/detalle_venta/'+this.$refs.child_add.id_venta_creada),
        );
        },
created() {
          // Fetch tasks on page load
        console.log("CREATED");

        const result = window.location.origin;
        this.URL_TENANT = result;
        this.url = this.URL_TENANT + this.url
        this.getUserData();
        this.getgastosData();
        },
    }
    </script>