<template>
      <Header/>
      <Nav/>
      <OrdenCompra/>

</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'
import OrdenCompra from './components/compra/orden_compra.vue'



export default {
  name: 'OrdenCompraComponent',

  components: {
  Header,
	Nav,
	OrdenCompra,
  },

  data: () => ({
    //
  }),
}
</script>
