<template>
      <Header/>
      <Nav/>
      <Asistente/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import Asistente from '../../components/consultorio_virtual/asistente/asistente.vue'



export default {
  name: 'AsistenteComponent',
  components: {
    Header,
	Nav,
	Asistente,
  },

  data: () => ({
    //
  }),
}
</script>
