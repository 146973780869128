
<template>
<div class="modal-body d-flex flex-column">
<!-- Start Plane -->
<div class="body-body-m">
    <div class="plane" >
        <div class="cabin" :style="{ width: cabinWidth, height: cabinHeight }">
            <div v-for="(row, i) in seats" :key="i" class="seat-row">
                <div v-for="seat in row" :key="seat.codigo" class="seat" :class="{ habilitado: seat.habilitado }">
                    <label class="label-s" :for="seat.codigo">
                        <input :id="seat.codigo" type="checkbox" class="seat-check visuallyhidden" :checked="seat.habilitado" @click="reserveSeat(seat.codigo); modificarAsientos(seat.id, seat.habilitado, seat.asiento, seat.vehiculo);" />
                        <span class="seat-label">{{ seat.number }}</span>
                        <div class="aisle">
                                <span class="aisle-number">{{seat.codigo}}</span>
                        </div>
                    </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- End Plane -->
</div>
</template>
<style lang="less">
    @import "./style.less";
</style>
<script>

    let notificaciones = require('@/assets/plugins/notifications//js/notification-custom-script.js');
    //import VueNextSelect from 'vue-next-select';

    import $ from 'jquery';
    import  Cookies from 'js-cookie';
    export default {
        props: ['id_vehiculo', 'filas', 'asientos_fila'],
        name: 'asientos_component',
        components:{
        //'vue-select': VueNextSelect,
        },
    data() {
        return{
            modalShow: true,
            vehiculo_añadido: false,
            tiposVehiculosData: [],
            numFilas: 0,
        numColumnas: 0,
        seats: [],
        numSeats:this.asientos_fila,
        numFiles:this.filas,
            URL_TENANT: '',
            asientos_modificados: false,
            vehiculo:  {
                tipo: "",
                modelo: "",
                año: "",
                capacidad_pasajeros: "",
                descripcion: "",
                estado: "",
                },
            }
        },
        watch: {
            id_vehiculo:{
            handler: function(){
                this.seats = [];
                this.generarArray();
                this.asientos_modificados=false;
            }
        },
        },
computed: {
    cabinWidth() {
        return this.asientos_fila * 70 + 'px'; // 60 es la anchura de cada asiento
    },
    cabinHeight() {
        return this.filas * 70 + 'px'; // 60 es la anchura de cada asiento
    }
},
methods: {
    async generarArray() {
        console.log("ASIENTOS: ");
        console.log(this.id_vehiculo);
        const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/asiento/?vehiculo='+this.id_vehiculo);
        const _seats = await response.json();
        console.log(_seats);
        let cont = 0;
        for(let i=0; i<this.filas; i++){
            const filaArray = [];
            for(let j=0; j<this.asientos_fila; j++){
                filaArray.push({
                    id: _seats[cont].id,
                    codigo: _seats[cont].codigo,
                    asiento: _seats[cont].asiento,
                    vehiculo: _seats[cont].vehiculo,
                    habilitado: _seats[cont].habilitado,
                    estado: _seats[cont].estado
                });
                cont = cont+1;
            }
            this.seats.push(filaArray);
        }
        console.log(this.seats);
        this.contarAsientos();
    },
    contarAsientos(){
        if(!this.seats.length === 0){
            this.numSeats = this.seats[0].length;
            this.numFiles = this.seats.length;
        }
    },
    reserveSeat(id) {
        console.log("ID: "+id);
        const seat = this.seats.flat().find(s => s.codigo === id);
        console.log(seat);
        seat.habilitado === false ? seat.habilitado = true : seat.habilitado = false;
    },
    async modificarAsientos(id, habilitado, asiento, vehiculo){
        var estado = "";
        if(habilitado === true){
            estado = "Disponible";
        }else{
            estado = "No Disponible";
        }
        const formData  = new FormData();
        formData.append("habilitado", habilitado);
        formData.append("asiento", asiento);
        formData.append("vehiculo", vehiculo);
        formData.append("estado", estado);
        await fetch(this.URL_TENANT+'/api/agencia_viaje/asiento/'+id+'/',{
        method: 'PUT',
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
        body: formData});
        notificaciones.round_success_noti($.msg='Asiento Modificado Exitosamente');

    },
        },
        mounted() {
            },
        created() {
            const result = window.location.origin;
            this.URL_TENANT = result;
            this.generarArray();
        },
    }
    </script>