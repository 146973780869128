<template>
<div class="page-wrapper">
            <div class="page-content">
                <!--breadcrumb-->
                <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div class="breadcrumb-title pe-3">Compra</div>
                    <div class="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb mb-0 p-0">
                                <li class="breadcrumb-item"><a href="javascript:;"><i class='bx bx-cart'></i></a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Detalle de Compra</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="ms-auto">
						<div class="btn-group">
							<a href="/compra/compra/" type="button" class="btn btn-light">Regresar a Compras</a>
						</div>
					</div>
                </div>
                <!--end breadcrumb-->
                    <div class="main-body">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex flex-column align-items-center text-center">
                                            <div class="mt-3">
                                                <h4># Compra: {{ compraData.numero }}</h4>
                                                <h4>Total: {{ $filters.currencyGTQ(compraData.total) }}</h4>
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                        <ul class="list-group list-group-flush" >
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-id-card">&nbsp;</i>Nit</h6>
                                                <span class="text-white">{{ compraData.nit_proveedor }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-user">&nbsp;</i>Proveedor</h6>
                                                <span class="text-white">{{ compraData.nombre_proveedor }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-radio-circle">&nbsp;</i>Estado</h6>
                                                <span class="text-white">{{ compraData.nombre_estado }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-calendar">&nbsp;</i>Fecha</h6>
                                                <span class="text-white">{{ compraData.fecha_creacion }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="lni lni-money-location">&nbsp;</i>Tipo de Pago</h6>
                                                <span class="text-white">{{ compraData.tipo_pago }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-user-pin">&nbsp;</i>Usuario</h6>
                                                <span class="text-white">{{ compraData.nombre_usuario }}</span>
                                            </li>
                                        </ul>
                                        <div class="d-flex flex-column align-items-center text-center">
                                            <div class="mt-3">
                                                <button type="button" ref="finalizarCompraBtn" data-bs-toggle="modal" data-bs-target="#finalizarDetalleCompra" class="btn btn-success px-5">Finalizar Compra (F12)</button>
                                                <!-- Modal Finalizar Compra -->
                                                <div class="modal fade"  id="finalizarDetalleCompra" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content bg-dark">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Finalizar Compra</h5>
                                                                <button type="button" class="btn-close" id="agregar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">NIT</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input ref="inputInModal" type="text" id="nit" readonly class="form-control" v-model="compraData.nit_proveedor"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Proveedor</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input ref="inputInModal" type="text" id="nit" readonly class="form-control" v-model="compraData.nombre_proveedor"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Nombre</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="nombre" readonly class="form-control" v-model="compraData.nombre_proveedor"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Total</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="total" readonly class="form-control" v-model="compraData.total"  />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" @click="finalizarCompra();"  class="btn btn-success px-5">Finalizar</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End Modal Finalizar Compra -->
                                            </div>
                                            <div class="mt-3">
                                                <button type="button" data-bs-toggle="modal" data-bs-target="#anularCompra" class="btn btn-danger px-5">Anular Compra</button>
                                                <!-- Modal Finalizar Compra -->
                                                <div class="modal fade"  id="anularCompra" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content bg-dark">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Anular Compra</h5>
                                                                <button type="button" class="btn-close" id="agregar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="row mb-3">
                                                                    <h5 class="mb-1">¿Desea Anular la Compra {{ compraData.numero }}?</h5>
                                                                </div>
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" @click="anularCompra();"  class="btn btn-danger px-5">Anular Compra</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End Modal Finalizar Compra -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="card">
                                    <div class="card-body p-4">
                                        <div class="row">
                                            <div class="col-lg-9">
                                                <h3>Detalle de Compra</h3>
                                            </div>
                                            <div class="col-lg-3">
                                                <button type="button" ref="buscarProductoBtn" data-bs-toggle="modal" @click="getProductosBusquedaData()" data-bs-target="#agregarDetalleModal" class="btn btn-light">Buscar Producto (F1)</button>
                                                <!-- Modal -->
                                                <div class="modal fade" id="agregarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content bg-dark">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Añadir Producto a la Compra</h5>
                                                                <button type="button" class="btn-close" id="agregar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="col-12">
                                                                    <div class="position-relative">
                                                                        <input ref="busquedaPorTexto" type="text" class="form-control ps-5" v-model="busqueda" autofocus  placeholder="Buscar Producto...(Por Nombre o Código de Barra)"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                                                                    </div>
                                                                </div>
                                                                <hr/>
                                                                <table class="table mb-0">
                                                                    <thead class="table-light">
                                                                        <tr>
                                                                            <th><h6>Código</h6></th>
                                                                            <th><h6>Código de Barra</h6></th>
                                                                            <th><h6>Producto</h6></th>
                                                                            <th><h6>Unidades de Medida</h6></th>
                                                                            <th><h6>Seleccionar</h6></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="itemProd in productoDataFilter" :key="itemProd.id">
                                                                            <td><h6>
                                                                                <label>{{itemProd.codigo}}</label></h6>
                                                                            </td>
                                                                            <td><h6>
                                                                                <div v-for='cb in itemProd.codigos_barra_producto' :key="cb.id" class="col">
                                                                                    <ul>
                                                                                        <li>
                                                                                        {{cb.codigo_barra}}
                                                                                        </li>
                                                                                    </ul>
                                                                                </div></h6>
                                                                            </td>
                                                                            <td><h6>
                                                                                <label>{{itemProd.nombre}}</label></h6>
                                                                            </td>
                                                                            <td><h6>
                                                                                <div v-for='um in itemProd.unidades_medida_producto' :key="um.id" class="col">
                                                                                    <ul>
                                                                                        <li>
                                                                                        {{um.nombre_unidad_medida}}
                                                                                        </li>
                                                                                    </ul>
                                                                                </div></h6>
                                                                            </td>
                                                                            <td><h6>
                                                                                <button type="button" data-bs-dismiss="modal"  @click="buscarProductoPorBarra(itemProd.id, 'id')"  class="btn btn-light">Seleccionar</button>
                                                                            </h6></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="modal-footer">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End Modal -->
                                            </div><!-- End Col-2 -->
                                        </div><!-- End Row -->
                                        <div class="row">
                                            <hr/>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <input type="text" v-model="barraBusqueda" autofocus class="form-control ps-5" @keyup.enter="buscarProductoPorBarra(barraBusqueda, 'barra');" placeholder="Código del Producto | Código de Barra">
                                            </div>
                                            <div class="col-lg-6">
                                                <button type="button" @click="buscarProductoPorBarra(barraBusqueda, 'barra')" class="btn btn-light">Ir</button>
                                            </div>
                                        </div>
                                        <div class="form-body mt-4">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="border border-3 p-4 rounded">
                                                        <div class="modal-body">
                                                            <table class="table mb-0">
                                                                <thead class="table-light">
                                                                    <tr>
                                                                        <th><h6>Producto</h6></th>
                                                                        <th><h6>Cantidad</h6></th>
                                                                        <th><h6>Unidad Medida</h6></th>
                                                                        <th><h6>Precio</h6></th>
                                                                        <th><h6>Descuento</h6></th>
                                                                        <th><h6>Subtotal</h6></th>
                                                                        <th><h6>Acciones</h6></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="item in detalleCompraData" :key="item.id">
                                                                        <td><h6>{{ item.nombre_producto }}</h6></td>
                                                                        <td><h6>{{ item.cantidad }}</h6></td>
                                                                        <td><h6>{{ item.nombre_unidad_medida }}</h6></td>
                                                                        <td><h6>{{ $filters.currencyGTQ(item.precio) }}</h6></td>
                                                                        <td><h6>{{ $filters.currencyGTQ(item.descuento) }}</h6></td>
                                                                        <td><h6>{{ $filters.currencyGTQ((item.precio * item.cantidad) - item.descuento)  }}</h6></td>
                                                                        <td><h6>
                                                                            <div class="d-flex order-actions">
                                                                                <a href="javascript:;" data-bs-toggle="modal" @click="modificarCantidadProductos(item.id, item.cantidad, item.compra, item.producto, item.nombre_producto, item.precio, item.descuento)" data-bs-target="#modificarDetalleModal" class="ms-3"><i class='bx bxs-edit'></i></a>
                                                                                <a href="javascript:;"  data-bs-target="#eliminarDetalleModal" data-bs-toggle="modal" @click="modificarCantidadProductos(item.id, item.cantidad, item.compra, item.producto, item.nombre_producto, item.precio, item.descuento)"  class="ms-3"><i class='bx bxs-trash'></i></a>
                                                                            </div></h6>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!--end row-->
                                        </div>
                                    </div><!-- End Card Body -->
                                </div><!-- End Card -->
                            </div><!-- End Col-8 -->
                        </div><!-- End Row -->
                    </div>
                </div>
            </div>
<!-- Modal Agregar Producto-->
<div class="modal fade" id="modalAgregarProducto" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
        <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">Producto</h1>
            <button type="button" class="btn-close" id="agregar_detalle_close2" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-sm-4">
                    <h6 class="mb-0">Producto</h6>
                </div>
                <div class="col-sm-8">
                    <input type="text"  id="producto"  class="form-control" v-model="productoEncontrado.nombre"  readonly/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4">
                    <h6 class="mb-0">Unidad Medida</h6>
                </div>
                <div class="col-sm-8">
                    <vue-select  :options="productoEncontrado.unidades_medida"
                        @blur="removeObjectArray(productoEncontrado, 'unidad_medida')"
                        v-model="productoEncontrado.unidad_medida"
                        label-by="nombre_unidad_medida"
                        track-by="nombre_unidad_medida"
                        value-by="id"
                        search-placeholder="Selecciona una Unidad de Medida"
                        multiple
                        collapse-tags="true"
                        taggable
                        searchable
                        placeholder="Selecciona una Unidad de Medida"
                        close-on-select>
                        <template #label="{ selected }">
                        <template v-if="selected">
                            <div style="color:white;">{{ selected.nombre_unidad_medida }} </div>
                        </template>
                        <template v-else > <div style="color:white;">Seleccione una Unidad de Medida</div></template>
                        </template>
                        <template #dropdown-item="{ option }">
                        <div style="color:white;">{{ option.nombre_unidad_medida }}</div>
                        </template>
                    </vue-select>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4">
                    <h6 class="mb-0">Precio Compra en Sistema</h6>
                </div>
                <div class="col-sm-8">
                    <input type="text" readonly  id="precio"  class="form-control" v-model="productoEncontrado.precio_compra" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4">
                    <h6 class="mb-0">Cantidad</h6>
                </div>
                <div class="col-sm-8">
                    <input type="text"  id="cantidad"  class="form-control" v-model="productoEncontrado.cantidad" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4">
                    <h6 class="mb-0">Precio Compra</h6>
                </div>
                <div class="col-sm-8">
                    <input type="text"   id="precio"  class="form-control" v-model="productoEncontrado.precio_compra_ingreso" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4">
                    <h6 class="mb-0">Descuento</h6>
                </div>
                <div class="col-sm-8">
                    <input type="text" id="descuento"  class="form-control" v-model="productoEncontrado.descuento" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" @click="agregarProductoDetalle();">Agregar</button>
        </div>
        </div>
    </div>
</div>
<!-- Fin Modal Agregar Producto -->
<!-- Modal Modificar Producto -->
<div class="modal fade"  id="modificarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modificar Producto</h5>
                <button type="button" class="btn-close" id="modificar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Producto</h6>
                    </div>
                    <div class="col-sm-10">
                        <input class="form-control" readonly v-model="productoDetalleModificar.nombre_producto" type="text" >
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Cantidad</h6>
                    </div>
                    <div class="col-sm-10">
                    <input class="form-control" v-model="productoDetalleModificar.cantidad" type="number" placeholder="Cantidad de Unidades">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Precio de Compra</h6>
                    </div>
                    <div class="col-sm-10">
                    <input class="form-control" v-model="productoDetalleModificar.precio" type="number" placeholder="Precio de Compra">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Descuento</h6>
                    </div>
                    <div class="col-sm-10">
                    <input class="form-control"  v-model="productoDetalleModificar.descuento" type="number" placeholder="Descuento">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" @click="modificarDetalleCompra()" data-bs-dismiss="modal" class="btn btn-warning">Modificar</button>
            </div>
        </div>
    </div>
    </div>
    <!-- End Modal Modificar Producto -->
    <!-- Modal Eliminar Producto -->
<div class="modal fade"  id="eliminarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Eliminar Producto</h5>
                <button type="button" class="btn-close" id="modificar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Producto</h6>
                    </div>
                    <div class="col-sm-10">
                        <input class="form-control" readonly v-model="productoDetalleModificar.nombre_producto" type="text" >
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Cantidad</h6>
                    </div>
                    <div class="col-sm-10">
                    <input class="form-control" readonly v-model="productoDetalleModificar.cantidad" type="number" placeholder="Cantidad">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Precio de Compra</h6>
                    </div>
                    <div class="col-sm-10">
                    <input class="form-control" readonly v-model="productoDetalleModificar.precio" type="number" placeholder="Precio de Compra">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Descuento</h6>
                    </div>
                    <div class="col-sm-10">
                    <input class="form-control" readonly v-model="productoDetalleModificar.descuento" type="number" placeholder="Descuento">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" @click="eliminarDetalleCompra()" data-bs-dismiss="modal" class="btn btn-danger">Eliminar</button>
            </div>
        </div>
    </div>
    </div>
    <!-- End Modal Eliminar Producto -->
</template>

<script>
import  Cookies from 'js-cookie';
import $ from 'jquery';
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
//import * as mykey from '../assets/js/mykey.js'
import { Modal } from 'bootstrap';
import VueNextSelect from 'vue-next-select';

console.log(Cookies.get());
export default {
    name: 'DetalleCompraComponent',
    components:{
        'vue-select': VueNextSelect,
        },
data() {
	return{
        barraBusqueda: '',
        busqueda: '',
        id_compra: '',
        id: '',
		compraData: [],
        nameError: [],
        detalleCompraData: [],
        productoDetalleModificar: {
            id: '',
            cantidad: '',
        },
        productosBusqueda: [],
        productoEncontrado: {
            cantidad: '',
            precio_compra: '',
            precio_compra_ingreso: '',
            descuento: '0',
            producto: '',
            nombre: '',
            unidades_medida: [],
            unidad_medida: [],
        },
        productoSeleccionado: '',
		URL_TENANT: '',
        mostrarModalNuevo: false,
		}
	},
watch:{
    productoSeleccionado: {
            handler: function () {
                if(this.productoSeleccionado.length > 1){
                    this.productoSeleccionado.shift();
                }
            },
            deep: true,
        },
},
computed: {
    productoDataFilter() {
        if (this.busqueda != ''){
            return this.productosBusqueda.filter((item) => {
                return  item.nombre.toLowerCase().includes(this.busqueda.toLowerCase()) || item.codigo.toLowerCase().includes(this.busqueda.toLowerCase()) || item.codigos_barra_producto.some(t => t.codigo_barra.toLowerCase().includes(this.busqueda.toLowerCase()))
        })
        }else{
            return [];
        }
    },
},


methods: {
    // Finalizar Compra:
    async finalizarCompra(){
        const formData = new FormData();
        formData.append("proveedor", this.compraData.proveedor);
        formData.append("tipo_pago", this.compraData.tipo_pago);
        formData.append("total", this.compraData.total);
        formData.append("estado", 'F');
        await fetch(this.URL_TENANT+'/api/compra/'+this.compraData.id+'/',{
            method: 'PUT',
            headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
            body: formData});
            notificaciones.round_success_noti($.msg='Compra Finalizada');
            for(let x=0; x<this.detalleCompraData.length; x++){
                const formData2 = new FormData();
                formData2.append("tipo_operacion", "COMPRA");
                formData2.append("referencia", this.compraData.id);
                formData2.append("producto", this.detalleCompraData[x].producto);
                formData2.append("cantidad", this.detalleCompraData[x].cantidad);
                formData2.append("observacion", "Ingreso por medio de una Compra");
                await fetch(this.URL_TENANT+'/api/ingreso_salida_producto/',{
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },
                    body: formData2});
            }
        setInterval(function(){location.href = '/compra/compra/'},1500);

    },
    // Anular Compra:
    async anularCompra(){
        const formData = new FormData();
        formData.append("proveedor", this.compraData.proveedor);
        formData.append("tipo_pago", this.compraData.tipo_pago);
        formData.append("total", this.compraData.total);
        formData.append("estado", '9');
        await fetch(this.URL_TENANT+'/api/compra/'+this.compraData.id+'/',{
            method: 'PUT',
            headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
            body: formData});
        notificaciones.round_success_noti($.msg='Compra Anulada');
        setInterval(function(){location.href = '/compra/compra/'},1500);
    },
    // Modificar Cantidad de Productos:
    async modificarCantidadProductos(_id, _cantidad, _compra, _producto, _nombre_producto, _precio, _descuento){
        this.productoDetalleModificar = {
            id: _id,
            cantidad: _cantidad,
            compra: _compra,
            producto: _producto,
            nombre_producto: _nombre_producto,
            precio: _precio,
            descuento: _descuento
        }
    },
    async modificarDetalleCompra(){
        const formData = new FormData();
        formData.append("id", this.productoDetalleModificar.id);
        formData.append("compra", this.productoDetalleModificar.compra);
        formData.append("producto", this.productoDetalleModificar.producto);
        formData.append("cantidad", this.productoDetalleModificar.cantidad);
        formData.append("precio", this.productoDetalleModificar.precio);
        formData.append("descuento", this.productoDetalleModificar.descuento);
        await fetch(this.URL_TENANT+'/api/compra_detalle/'+this.productoDetalleModificar.id+'/',{
        method: 'PUT',
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
        body: formData});
        notificaciones.round_success_noti($.msg='Producto Modificado Exitosamente');
        this.getdetalleCompraData();
        this.getCompraData();
    },
    async eliminarDetalleCompra(){
        const formData = new FormData();
        formData.append("id", this.productoDetalleModificar.id);
        await fetch(this.URL_TENANT+'/api/compra_detalle/'+this.productoDetalleModificar.id+'/',{
        method: 'DELETE',
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
        body: formData});
        notificaciones.round_success_noti($.msg='Producto Eliminado Exitosamente');
        this.getdetalleCompraData();
        this.getCompraData();
    },
    // Validadores:
    validateInputs(){
        this.nameError =  (!this.productoEncontrado.unidad_medida || this.productoEncontrado.unidad_medida.length === 0) ? ['Debe Elegir Unidad de Medida'] : [];
        this.nameError = (!this.productoEncontrado.cantidad) ? [...this.nameError, 'Debe Ingresar la Cantidad'] : this.nameError;
        this.nameError = (!this.productoEncontrado.precio_compra_ingreso) ? [...this.nameError, 'Debe Ingresar Precio de Compra de Ingreso'] : this.nameError;
    },
    // Agregar Productos al Detalle:
    async agregarProductoDetalle(){
        this.validateInputs();
        if(this.nameError.length != 0){
            console.log("NAME ERROR");
            this.nameError.forEach((element) =>{
                notificaciones.round_info_noti($.msg=element);
            });
        }else{
            const formData = new FormData();
            formData.append("compra", this.id_compra.id_compra);
            formData.append("cantidad", this.productoEncontrado.cantidad);
            formData.append("precio", this.productoEncontrado.precio_compra_ingreso);
            formData.append("descuento", this.productoEncontrado.descuento == '' ? '0': this.productoEncontrado.descuento);
            formData.append("producto", this.productoEncontrado.unidad_medida[0]);
            await fetch(this.URL_TENANT+'/api/compra_detalle/',{
                method: 'POST',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
                notificaciones.round_success_noti($.msg='Producto Agregado Exitosamente');
                document.getElementById('agregar_detalle_close2').click();
                this.getdetalleCompraData();
                this.getCompraData();
        }
    },
    //Quitar Objeto:
    async removeObjectArray(array, atributo) {
        //console.log("REMOVE ARRAY");
        if (array[atributo].length > 1) {
            array[atributo].shift();
        }
        if(atributo == 'unidad_medida'){
            this.seleccionUnidadMedida();
        }
    },
    async seleccionUnidadMedida(){
        this.obj = this.productoEncontrado.unidades_medida.filter((item) => {
            return  item.id == this.productoEncontrado.unidad_medida[0];
        })
        console.log("OBJ::::");
        console.log(this.obj);
        this.productoEncontrado.unidad_medida[0] = this.obj[0]['id'];
        this.productoEncontrado.precio_compra = this.obj[0]['precio_compra'];
    },
    async cambiarUrl(link, index){
            this.url = link;
            if (index === "atras" && link){
                this.indexPagination = this.indexPagination - 1;
            }
            else if (index === "adelante" && link != null){
                this.indexPagination = this.indexPagination + 1;
            }
            else if (link){
                this.indexPagination = index;
            }
        },
    async mostrarRefs(){
        console.log("Mis Refs:");
        console.log(this.$refs);
        console.log(this.$refs.child);
        console.log(this.$refs.child.searchingInputValue);
    },
    async getCompraData() {
        const response = await fetch(this.URL_TENANT+'/api/compra/'+this.id_compra.id_compra+'/');
        this.compraData = await response.json();
    },
    async getdetalleCompraData() {
        const response = await fetch(this.URL_TENANT+'/api/compra_detalle/?id_compra='+this.id_compra.id_compra);
        this.detalleCompraData = await response.json();
    },
    async getProductosBusquedaData() {
        const response = await fetch(this.URL_TENANT+'/api/producto_busqueda/?tipo=compra');
        this.productosBusqueda = await response.json();
    },
    //Buscar Unidades de Medida por  producto:
    async buscarUnidadesProductoData(id_producto, barra = ''){
        const response = await fetch(this.URL_TENANT+'/api/producto_unidades_medida_busqueda/?id_producto='+id_producto);
        this.productoEncontrado.unidades_medida = await response.json();
        console.log("UNIDADES MEDIDASSSSSSS....");
        console.log(barra);
        console.log(this.productoEncontrado.unidades_medida);
        if(barra != ''){
            this.obj = this.productoEncontrado.unidades_medida.filter((item) => {
                return  item.codigo_barra == barra;
            })
            console.log("OBJ::::");
            console.log(this.obj);
            this.productoEncontrado.unidad_medida[0] = this.obj[0]['id'];
            this.productoEncontrado.precio_compra = this.obj[0]['precio_compra'];
        }
    },
    async limpiarProductoEncontrado(){
        this.productoEncontrado= {
            cantidad: '',
            precio_compra: '',
            precio_compra_ingreso: '',
            descuento: '0',
            producto: '',
            nombre: '',
            unidades_medida: [],
            unidad_medida: [],
        }
    },
    // Buscar Producto por Barra:
    async buscarProductoPorBarraData(){
        const response = await fetch(this.URL_TENANT+'/api/producto_busqueda/?tipo=compra');
        this.productosBusqueda = await response.json();
    },
    async buscarProductoPorBarra(dato, tipo){
        this.limpiarProductoEncontrado();
        if(tipo === 'barra'){
            console.log("barra.....");
            console.log(dato);
            try{
                this.obj = this.productosBusqueda.filter((item) => {
                    return  item.codigos_barra_producto.some(t => t.codigo_barra.toLowerCase().includes(dato.toLowerCase())) || item.codigo.toLowerCase().includes(dato.toLowerCase())
                })
            }catch(error){
                console.log("firulais");
                this.obj = [];
            }
            //let obj = this.productosBusqueda.find(item => item.codigos_barra_producto.some(t => t.codigo_barra.toLowerCase().includes(this.dato.toLowerCase())));
            console.log(this.obj);
            //console.log(this.obj[0].id);
            if(this.obj.length >0){
                console.log(this.obj[0]);
                this.productoEncontrado.producto = this.obj[0].id;
                this.productoEncontrado.nombre = this.obj[0].nombre;
                this.id_barra = '';
                this.obj.forEach((element) =>{
                    console.log(element);
                    for (let xx of element.codigos_barra_producto){
                        console.log("es:" + xx.id);
                        if (xx.codigo_barra == dato){
                            this.id_barra = xx.id;
                        }
                    }
                })
                this.buscarUnidadesProductoData(this.obj[0].id, this.id_barra );
                // Código para mostrar un el Modal desde Vue:
                this.myModal = new Modal(document.getElementById('modalAgregarProducto'), {
                        keyboard: false
                    });
                this.myModal.show();
            }else{
                console.log("No se encontró");
                notificaciones.round_error_noti($.msg='No se encontró el Código del Producto | Código de Barra');
            }
        }
        else if(tipo === 'id'){
            console.log("id.....");
            console.log(dato);
            this.obj = this.productosBusqueda.filter((item) => {
                    return  item.id.includes(dato);
                });
            this.productoEncontrado.producto = this.obj[0].id;
            this.productoEncontrado.nombre = this.obj[0].nombre;
            this.buscarUnidadesProductoData(this.obj[0].id);
            document.getElementById('agregar_detalle_close').click();
            // Código para mostrar un el Modal desde Vue:
            this.myModal = new Modal(document.getElementById('modalAgregarProducto'), {
                        keyboard: false
                    });
                this.myModal.show();
        }
    },
    accionTecla(event) {
        if (event.key === 'F1') {
        event.preventDefault();
            this.$refs.buscarProductoBtn.click();
        }
        if (event.key === 'F12') {
        event.preventDefault();
            this.$refs.finalizarCompraBtn.click();
        }
      // puedes agregar más acciones para otras teclas aquí
    }
},

beforeMount(){
    this.id_compra = JSON.parse(document.getElementsByTagName('body')[0].getAttribute('data') || '{}');
},
created() {
    // Fetch tasks on page load
	const result = window.location.origin;
    this.URL_TENANT = result;
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    console.log("ID::::");
    console.log(params);
},
mounted(){
    window.addEventListener('keydown', this.accionTecla);
    this.getCompraData();
    this.getdetalleCompraData();
    this.getProductosBusquedaData()
    console.log("REFS:");
    for (var ref in this.$refs) {
        console.log(this.$refs[ref]);
    }
    this.$watch(
        "$refs.child.searchingInputValue",
        () => (this.busqueda = this.$refs.child.searchingInputValue),
    );
    var myModalEl = document.getElementById('agregarDetalleModal')
        myModalEl.addEventListener('shown.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.busquedaPorTexto.focus();
            });
        });
},
beforeUnmount() {
    window.removeEventListener('keydown', this.accionTecla);
    var myModalEl = document.getElementById('agregarDetalleModal')
    myModalEl.removeEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.busquedaPorTexto.focus();
        });
    });
},
}
</script>
