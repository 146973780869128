
<template>
                        <!--
                        <button type="button" class="btn btn-light" @click="getEventData()" data-bs-toggle="modal" data-bs-target="#modificarModal">Modificar Evento </button>
                            <div class="modal fade" style=""  v-if="modalShow" id="modificarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content bg-dark">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">Modificar Evento</h5>
                                            <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>-->
                                        <div class="modal-body">
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Título</h6>
                                                </div>
                                                <div class="col-sm-10">
                                                    <input type="text" placeholder="Título del Evento" autofocus id="titulo"  class="form-control" v-model="evento.titulo"  />
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Fecha Inicio</h6>
                                                </div>
                                                <div class="col-sm-8">
                                                    <Datepicker
                                                    v-model="evento.inicio"
                                                    dark placeholder="Selecciona una Fecha"
                                                    autoApply
                                                    :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                    :enableTimePicker="false"
                                                    monthNameFormat="long"
                                                    :format="format"
                                                    modelType="dd-MM-yyyy"
                                                    locale="es">
                                                    </Datepicker>
                                                </div>
                                                <div class="col-sm-2">
                                                    <Datepicker v-model="evento.inicio_tiempo" :startTime="startTime" timePicker dark  minutesIncrement="5" />
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Fecha Fin</h6>
                                                </div>
                                                <div class="col-sm-8">
                                                    <Datepicker
                                                    v-model="evento.fin"
                                                    dark placeholder="Selecciona una Fecha"
                                                    autoApply
                                                    :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                    :enableTimePicker="false"
                                                    monthNameFormat="long"
                                                    :format="format"
                                                    modelType="dd-MM-yyyy"
                                                    locale="es">
                                                    </Datepicker>
                                                </div>
                                                <div class="col-sm-2">
                                                    <Datepicker v-model="evento.fin_tiempo" :startTime="startTime" timePicker dark  minutesIncrement="5" />
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Descripción</h6>
                                                </div>
                                                <div class="col-sm-10">
                                                    <textarea placeholder="Descripción del Evento" id="descripcion" rows="3"  class="form-control" v-model="evento.descripcion"></textarea>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Color</h6>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="dropdown">
                                                        <button type="button" class="btn btn-light" >
                                                            <span class="dot2" :style=" {'background-color': selectedColor }"></span>
                                                        </button>
                                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Elegir un Color
                                                        </button>
                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li>
                                                                <button type="button" @click="SeleccionarColor(item.color)" class="btn" v-for="item in coloresData" :key="item.id">
                                                                    <span class="dot2" :style=" {'background-color': item.color }"></span>
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Cliente</h6>
                                                </div>
                                                <div class="col-sm-10">
                                                    <vue-select
                                                        :options="clientesData"
                                                        v-model="evento.cliente"
                                                        label-by="nombre"
                                                        track-by="nombre"
                                                        value-by="id"
                                                        search-placeholder="Selecciona un Cliente"
                                                        multiple
                                                        collapse-tags="true"
                                                        taggable
                                                        searchable
                                                        placeholder="Selecciona un Cliente"
                                                        close-on-select>
                                                        <template #label="{ selected }">
                                                        <template v-if="selected">
                                                            <div style="color:white;">{{ selected.nombre }}</div>
                                                        </template>
                                                        <template v-else > <div style="color:white;">Seleccione un Cliente</div></template>
                                                        </template>
                                                        <template #dropdown-item="{ option }">
                                                        <div style="color:white;">{{ option.nombre }}</div>
                                                        </template>
                                                    </vue-select>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Categoría</h6>
                                                </div>
                                                <div class="col-sm-10">
                                                    <vue-select
                                                        :options="categoriasData"
                                                        v-model="evento.categoria"
                                                        label-by="nombre"
                                                        track-by="nombre"
                                                        value-by="id"
                                                        search-placeholder="Selecciona una Categoría"
                                                        multiple
                                                        collapse-tags="true"
                                                        taggable
                                                        searchable
                                                        placeholder="Selecciona una Categoría"
                                                        close-on-select>
                                                        <template #label="{ selected }">
                                                        <template v-if="selected">
                                                            <div style="color:white;">{{ selected.nombre }}</div>
                                                        </template>
                                                        <template v-else > <div style="color:white;">Seleccione una Categoría</div></template>
                                                        </template>
                                                        <template #dropdown-item="{ option }">
                                                        <div style="color:white;">{{ option.nombre }}</div>
                                                        </template>
                                                    </vue-select>
                                                </div>
                                            </div>
                                        </div>
                                        <!--
                                            <button type="button" @click="modificarEvento()" class="btn btn-primary">Guardar Cambios</button>
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>-->
                            <!-- End Modal -->
    </template>
    <script>
    import  Cookies from 'js-cookie';
    //import Select2 from 'vue3-select2-component';
    //import $ from 'jquery';
    //let select2 = require('@/assets/plugins/select2/js/select2.min.js');
    //import * as mykey from '../assets/js/mykey.js'
    import $ from 'jquery';
    import { Modal } from "bootstrap";
    import 'bootstrap/dist/css/bootstrap.min.css'
    import 'bootstrap/dist/js/bootstrap.bundle.js'
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    //let calendar = require('@/assets/plugins/fullcalendar/js/notification-custom-script.js');
    import VueNextSelect from 'vue-next-select'
    import Datepicker from '@vuepic/vue-datepicker';
    import { ref } from 'vue';
    import 'vue-cal/dist/vuecal.css'
    //Componentes Propios:
    console.log(Cookies.get());
    export default {
        props: ['id_modificar'],
        name: 'modificar_evento',
        components:{
            'vue-select': VueNextSelect,
            'Datepicker': Datepicker,
        },
    setup() {
        const date = ref(new Date());
            const format = (date) => {
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
            const startTime = ref({ hours: 0, minutes: 0 });
            const first_time = ref({
                hours: new Date().getHours()+1,
                minutes: 0
            });
            const last_time = ref({
                hours: new Date().getHours()+2,
                minutes: 0
            });
            //
            return {
                date,
                format,
                first_time,
                last_time,
                startTime,
            }
    },
    data() {
        return{
            URL_TENANT: '',
            // Evento a Modificar:
            evento: {
                cliente: '',
                categoria: '',
            },
            evento_modificado: false,
            //Anteriores
            evento_añadido: false,
            modalShow: true,
            uniqueModal:null,
            selectedEvent: {},
            showDialog: false,
            OpenClose:this.visible,
            clientesData: [],
            categoriasData: [],
            coloresData: [],
            selectedColor: '',
            /*events: [
        {
            start: '2022-11-11 08:00',
            end: '2022-11-11 09:00',
            title: 'Need to go shopping',
            content: '<i class="icon material-icons">shopping_cart</i>',
            class: 'leisure'
            },
            {
            start: '2022-11-11 08:00',
            end: '2022-11-11 11:00',
            title: 'Golf with John',
            content: '<i class="icon material-icons">golf_course</i>',
            class: 'sport',
            client: 'firulais',
            repeat: {
                every: 'week',
                until: new Date('2019/06/01') // You can also use a Javascript Date.
            }
            },
            {
            start: '2022-11-11 14:00',
            end: '2022-11-11 15:00',
            title: 'Dads birthday!',
            content: '<i class="icon material-icons">cake</i>',
            class: 'sport'
            }
        ],*/
            empresaData: [0],
            usuariosData: [0],
            }
        },

    computed: {
    },
    watch: {
        evento: {
            handler: function () {
                this.evento_modificado = false;
                if(this.evento.cliente.length > 1 ){
                    this.evento.cliente.shift();
                }
                if(this.evento.categoria.length > 1){
                    this.evento.categoria.shift();
                }
                // Fecha Inicio es Mayor que Fecha Fin:
                var parts_inicio = this.evento.inicio.split('-');
                var f_inicio = new Date(parts_inicio[2], parts_inicio[1] -1, parts_inicio[0]);
                var parts_fin = this.evento.fin.split('-');
                var f_fin = new Date(parts_fin[2], parts_fin[1] -1, parts_fin[0]);
                if(f_inicio > f_fin){
                    this.evento.fin = this.evento.inicio;
                }
                // Hora Inicio es mayor que Hora Fin:
                var h_ini = this.evento.inicio_tiempo.hours;
                var h_fin = this.evento.fin_tiempo.hours;
                var m_ini = this.evento.inicio_tiempo.minutes;
                var m_fin = this.evento.fin_tiempo.minutes;
                if(h_ini >= h_fin){
                    if(m_ini >= m_fin){
                        this.evento.fin_tiempo = this.evento.inicio_tiempo;
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        async modificarEvento(){
            if (!this.evento.inicio  || !this.evento.inicio_tiempo || !this.evento.fin  || !this.evento.fin_tiempo || !this.evento.titulo ){
                notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
            }else{
                try{
                    const formData = new FormData();
                    let inicio_ing = this.evento.inicio.concat(" ", this.evento.inicio_tiempo.hours, ":",this.evento.inicio_tiempo.minutes);
                    let fin_ing = this.evento.fin.concat(" ", this.evento.fin_tiempo.hours, ":",this.evento.fin_tiempo.minutes);
                    console.log("TIEMPO PARA INGRESAR");
                    console.log(inicio_ing);
                    console.log(fin_ing);
                    formData.append("inicio", inicio_ing);
                    formData.append("fin", fin_ing);
                    formData.append("titulo", this.evento.titulo);
                    formData.append("descripcion", this.evento.descripcion);
                    formData.append("estado", this.evento.estado);
                    if(this.evento.cliente){formData.append("cliente", this.evento.cliente[0]);}
                    if(this.evento.categoria){formData.append("categoria", this.evento.categoria[0]);}
                    formData.append("color", this.evento.color);
                    console.log("DATOS USER");
                    await fetch(this.URL_TENANT+'/api/evento/'+this.id_modificar+'/',{
                    method: 'PUT',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: formData})
                    notificaciones.round_success_noti($.msg='Evento Modificado Existosamente');
                    this.evento_modificado = true;

                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
            }
        },
        async SeleccionarColor(color){
            this.selectedColor = color;
            this.evento.color = color;
        },
        onEventClick (event, e) {
            this.uniqueModal = new Modal(document.getElementById("detalleEvento"),{ keyboard: false });
            this.uniqueModal.show();
            this.selectedEvent = event;
            this.showDialog = true;
            // Prevent navigating to narrower view (default vue-cal behavior).
            e.stopPropagation()
        },
        async getClientesData() {
            const response = await fetch(this.URL_TENANT+'/api/cliente/');
            this.clientesData = await response.json();
        },
        async getCategoriasData() {
            const response = await fetch(this.URL_TENANT+'/api/evento_categoria/');
            this.categoriasData = await response.json();
        },
        async getColoresData() {
            const response = await fetch(this.URL_TENANT+'/api/global_colores/');
            this.coloresData = await response.json();
            const random = Math.floor(Math.random() * this.coloresData.length);
            this.selectedColor =  this.coloresData[random].color;
            //this.eventoNuevo.color =  this.coloresData[random].color;

        },
        async getEventData() {
            const response = await fetch(this.URL_TENANT+'/api/evento/'+this.id_modificar+'/');
            this.evento_ = await response.json();
            console.log(this.evento_);
            /*const horas_minutos_inicio_ = new Date(this.evento_.inicio);
            const horas_minutos_fin_ = new Date(this.evento_.fin);
            const horas_inicio =  horas_minutos_inicio_.getHours();
            const minutos_inicio =  horas_minutos_inicio_.getMinutes();
            const horas_minutos_inicio = {
                hours: horas_inicio,
                minutes: minutos_inicio,
                seconds: 0
            }
            console.log(this.evento_.inicio.split(' ')[0]);
            const fecha_inicio =  horas_minutos_inicio_.toISOString().split(' ')[0]
            const horas_fin =  horas_minutos_fin_.getHours();
            const minutos_fin =  horas_minutos_fin_.getMinutes();
            const horas_minutos_fin = {
                hours: horas_fin,
                minutes: minutos_fin,
                seconds: 0
            }
            const fecha_fin =  horas_minutos_fin_.toISOString().split(' ')[0];
            */
            const fecha_inicio = this.evento_.inicio.split(' ')[0];
            const fecha_fin = this.evento_.fin.split(' ')[0];
            const horas_minutos_inicio_ = this.evento_.inicio.split(' ')[1];
            const horas_inicio =  horas_minutos_inicio_.split(':')[0];
            const minutos_inicio =  horas_minutos_inicio_.split(':')[1];
            const horas_minutos_inicio = {
                hours: horas_inicio,
                minutes: minutos_inicio,
                seconds: 0
            }
            const horas_minutos_fin_ = this.evento_.fin.split(' ')[1];
            const horas_fin =  horas_minutos_fin_.split(':')[0];
            const minutos_fin =  horas_minutos_fin_.split(':')[1];
            const horas_minutos_fin = {
                hours: horas_fin,
                minutes: minutos_fin,
                seconds: 0
            }
            if(!this.evento_.cliente){
                console.log("NO HAY");
                this.cliente_añadir = '';
            }else{
                this.cliente_añadir = [this.evento_.cliente];
            }
            if(!this.evento_.categoria){
                console.log("NO HAY");
                this.categoria_añadir = '';
            }else{
                this.categoria_añadir = [this.evento_.categoria];
            }
            console.log("ERRORRRRRRRRRRRRRRRRRRRRRRRRRRRRRR");
            console.log(this.evento_.categoria);
            console.log(this.categoria_añadir);
            this.evento = {
                titulo: this.evento_.titulo,
                inicio: fecha_inicio,
                inicio_tiempo: horas_minutos_inicio,
                fin: fecha_fin,
                fin_tiempo: horas_minutos_fin,
                descripcion: this.evento_.descripcion,
                estado: this.evento_.estado,
                cliente: this.cliente_añadir,
                categoria: this.categoria_añadir,
                color: this.evento_.color,
            }
            this.selectedColor =  this.evento_.color;

        },
        },
    mounted() {
            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', 'https://unpkg.com/vue-next-select/dist/vue-next-select.iife.prod.js')
            document.head.appendChild(recaptchaScript)
        },
    created() {
          // Fetch tasks on page load
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.getClientesData();
        this.getCategoriasData();
        this.getColoresData();
        },
    }

    </script>
    <style>
    </style>