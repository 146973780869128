<template>
      <Header/>
      <Nav/>
      <ProductosAgregar/>

</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'
import ProductosAgregar from './components/catalogo/producto/producto_agregar.vue'



export default {
  name: 'ProductosComponent',

  components: {
    Header,
    Nav,
    ProductosAgregar,
  },

  data: () => ({
    //
  }),
}
</script>
