<template>
      <Header/>
      <Nav/>
      <Requisicion/>

</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'
import Requisicion from './components/compra/requisicion.vue'
import '@/assets/plugins/datetimepicker/css/classic.css';
import '@/assets/plugins/datetimepicker/css/classic.time.css';
import '@/assets/plugins/datetimepicker/css/classic.date.css';
import '@/assets/plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.min.css';


export default {
  name: 'RequisicionComponent',

  components: {
  Header,
	Nav,
	Requisicion,
  },

  data: () => ({
    //
  }),
}
</script>
