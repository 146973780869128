
<template>
<div class="wrapper">
		<div class="authentication-reset-password d-flex align-items-center justify-content-center">
			<div class="row">
				<div class="col-12 col-lg-10 mx-auto">
					<div class="card">
						<div class="row g-0">
							<div class="col-lg-5 border-end">
								<div class="card-body">
									<div class="p-5">
										<div class="topbar-logo-header">
											<div class="">
												<img :src="empresaData[0].logo" class="logo-icon" alt="logo icon">
											</div>
											<div class="">
												<h4 class="logo-text">{{ empresaData[0].nombre }}</h4>

											</div>
										</div>
										<h4 class="mt-5 font-weight-bold">Generar Nueva Contraseña</h4>
										<p class="">Hemos recibido una petición para cambiar tu contraseña. Por favor, ingresa una contraseña nueva.</p>
										<div class="mb-3 mt-5">
											<label class="form-label">Contraseña Antigua</label>
											<input type="password" v-model="contraseñaVieja" id="contraseñaVieja" class="form-control" placeholder="Ingresa tu contraseña anterior" autofocus />
										</div>
										<div class="mb-3">
											<label class="form-label">Nueva Contraseña</label>
											<input type="password" v-model="contraseñaNueva" minlength="8" id="contraseñaNueva" class="form-control" placeholder="Ingresa una nueva contraseña" />
										</div>
										<div class="mb-3">
											<label class="form-label">Confirmar Contraseña</label>
											<input type="password" v-model="contraseñaNuevaConfirmar" minlength="8" id="contraseñaNuevaConfirmar" class="form-control" placeholder="Confirma la contraseña" />
										</div>
										<div class="d-grid gap-2">
											<button type="button" @click="updateData" class="btn btn-light">Cambiar Contraseña</button> <a href="/" class="btn btn-light"><i class='bx bx-arrow-back mr-1'></i>Regresar al Inicio</a>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-7">
								<img class="card-img login-img h-100" alt="...">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import  Cookies from 'js-cookie';
import $ from 'jquery';
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')
//import * as mykey from '../assets/js/mykey.js'
console.log(Cookies.get());
export default {
    name: 'cambiar_contraseña_component',
data() { 
	return{
        URL_TENANT: '',
		usuarioData: [0],
        empresaData: [0],
        changeUsuarioData: [0],
		contraseñaNueva: '',
		contraseñaNuevaConfirmar: '',
		contraseñaVieja: '',
		}
	},
watch: {
    contraseñaVieja: function () {
        this.getusuarioChangeData();
        }
    },
methods: {
    async getEmpresaData() {
        const response = await fetch(this.URL_TENANT+'/api/empresa/');
        this.empresaData = await response.json();
          //console.log(userData);
    },
    async getusuarioData() {
        const response = await fetch(this.URL_TENANT+'/api/user/');

        this.usuarioData = await response.json();


        //console.log(usuarioData[0]);
    },
	async getusuarioChangeData() {
        console.log("USER");
        console.log(this.usuarioData[0].id);
        const response2 = await fetch(this.URL_TENANT+'/api/user_pass/'+this.usuarioData[0].id+'/');
        this.changeUsuarioData = await response2.json();


        //console.log(usuarioData[0]);
    },
	async updateData() {
        console.log("UPDATE");

        //Verificar Contraseñas
        if (this.contraseñaNueva === "" || this.contraseñaNuevaConfirmar === "" || this.contraseñaVieja === ""){
            console.log("ESTA VACÍA LA CONTRASEÑA NUEVA");
            notificaciones.round_error_noti($.msg='Debe llenar todos los campos');
        }
        else if (this.contraseñaNueva != this.contraseñaNuevaConfirmar) {
            // Si las constraseñas no coinciden mostramos un mensaje
            notificaciones.round_error_noti($.msg='Las Contraseñas No Coinciden');
            return false;
        } else {

        try{
            console.log("UPDETEANDO");
            console.log(this.usuarioData[0].id);
            console.log(this.changeUsuarioData.id);
            console.log(this.changeUsuarioData.username);
            console.log(this.contraseñaNueva);
            console.log(this.contraseñaVieja);

        await fetch(this.URL_TENANT+'/api/user_pass/'+this.usuarioData[0].id+'/',{
            method: 'put',
            headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
            body: JSON.stringify({
            id: this.changeUsuarioData.id,
            username: this.changeUsuarioData.username,
            password: this.contraseñaNueva,
            old_password: this.contraseñaVieja
            })
        }
        ).then((response) =>
        {
            if(response.status >=400 && response.status <600){
                notificaciones.round_error_noti($.msg='La Contraseña Antigua no Coincide');
            }
            if(response.status==200 ){
                notificaciones.round_success_noti($.msg='La contraseña ha sido modificada.');
                setInterval(function(){location.href = '/'},2500);
            }
            return response;

        });
    }catch(error){
        console.log(error);
        notificaciones.round_error_noti($.msg='Error, algo salió mal');
    }
    }
    },

},
mounted(){
this.getusuarioData();
this.getEmpresaData();

},
created() {
    const result = window.location.origin;
    this.URL_TENANT = result;
      // Fetch tasks on page load

	}
}
</script>
