<template>
      <Header/>
      <Nav/>
      <Compra/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import Compra from '../../components/compra/compra/compra.vue'



export default {
  name: 'CompraComponent',

  components: {
  Header,
	Nav,
	Compra,
  },

  data: () => ({
    //
  }),
}
</script>
