
<template>
    <div class="page-wrapper">
                <div class="page-content">
                    <!--breadcrumb-->
                    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div class="breadcrumb-title pe-3">Inventario</div>
                        <div class="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0 p-0">
                                    <li class="breadcrumb-item"><a href="javascript:;"><i class='bx  bx-log-out-circle'></i></a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">Ajuste de Inventario</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <!--end breadcrumb-->
                    <div  class="card">
                        <div class="card-body">
                            <div class="d-lg-flex align-items-center mb-4 gap-3">
                                <div class="position-relative">
                                    <input type="text" class="form-control ps-5 radius-30" v-model="buscarAjusteInventario" placeholder="Buscar Ajuste"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                                </div>
                                <add_ajuste ref="child_add"></add_ajuste>
                            </div>
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th><h6>Fecha</h6></th>
                                            <th><h6>Tipo de Ajuste</h6></th>
                                            <th><h6>Motivo</h6></th>
                                            <th><h6>Producto</h6></th>
                                            <th><h6>Inventario Anterior</h6></th>
                                            <th><h6>Ajuste</h6></th>
                                            <th><h6>Inventario Nuevo</h6></th>
                                            <th><h6>Usuario</h6></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in ajusteInventarioDataFilter" :key="item.id">
                                            <td><h6>
                                                <div class="d-flex align-items-center">
                                                    <div>
                                                        <input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-0 font-14">#{{item.fecha_creacion}}</h6>
                                                    </div>
                                                </div></h6>
                                            </td>
                                            <td><h6>{{item.tipo}}</h6></td>
                                            <td><h6>{{item.motivo}}</h6></td>
                                            <td><h6>{{item.producto_nombre}}</h6></td>
                                            <td><h6>{{item.cantidad_anterior}}</h6></td>
                                            <td><h6>{{item.cantidad}}</h6></td>
                                            <td><h6>{{item.cantidad_nueva}}</h6></td>
                                            <td><h6>{{item.nombre_usuario}}</h6></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Paginacion -->
                        <div class="card-body">
                            <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="javascript:;" @click="cambiarUrl(ajusteInventarioData.links.previous, 'atras')">Atrás</a>
                                        </li>
                                        <li class="page-item" :class="{active: indexPagination == index}" v-for="index in ajusteInventarioData.total_pages" :key="index">
                                            <a class="page-link"  href="javascript:;javascript:;" @click="cambiarUrl(this.URL_TENANT+'/api/ajuste_inventario/?page='+index+'', index)">{{ index }}</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="javascript:;" @click="cambiarUrl(ajusteInventarioData.links.next, 'adelante')">Siguiente</a>
                                        </li>
                                    </ul>
                            </nav>
                        </div>
                        <!-- Fin Paginacion -->
                    </div>

                </div>
            </div>
    </template>
    <style>
    .btn-common {
        width: 100%;
        height: 100%;
        max-width: 175px; /* Ancho máximo para limitar el crecimiento excesivo en pantallas grandes */
        max-height: 50px; /* Altura máxima para limitar el crecimiento excesivo en pantallas grandes */
    }
    </style>
    <script>
    import  Cookies from 'js-cookie';

    import '@vuepic/vue-datepicker/dist/main.css';
    import add_ajuste from "./agregar_ajuste_inventario.vue"
    //import { ref } from 'vue';
    console.log(Cookies.get());
    export default {
        name: 'ajuste_inventario_component',
        components:{
            add_ajuste,
        },
    setup() {

    },
    data() {
        return{
            //Globales:
            URL_TENANT: '',
            empresaData: [0],
            usuariosData: [0],
            url: "/api/ajuste_inventario/",
            indexPagination: 1,
            buscarAjusteInventario: '',
            ajusteInventarioData: [],
            }
        },

    computed: {
        ajusteInventarioDataFilter() {
            if (this.buscarAjusteInventario != ''){
                return this.ajusteInventarioData.results.filter((item) => {
                return item.fecha_creacion.toLowerCase().includes(this.buscarAjusteInventario.toLowerCase()) || item.producto_nombre.toLowerCase().includes(this.buscarAjusteInventario.toLowerCase()) || item.tipo.toLowerCase().includes(this.buscarAjusteInventario.toLowerCase())
            })
            }else{
                return this.ajusteInventarioData.results;
            }
        },
    },
    watch:{
        url: function () {
            this.getAjusteInventarioData();
        },
    },
    methods: {
        //Paginación:
        async cambiarUrl(link, index){
            this.url = link;
            if (index === "atras" && link){
                this.indexPagination = this.indexPagination - 1;
            }
            else if (index === "adelante" && link != null){
                this.indexPagination = this.indexPagination + 1;
            }
            else if (link){
                this.indexPagination = index;
            }
        },
        // Llenado:
        async getEmpresaData() {
            const response = await fetch(this.URL_TENANT+'/api/empresa/');
            this.empresaData = await response.json();
        },
        async getAjusteInventarioData() {
            const response = await fetch(this.url);
            this.ajusteInventarioData = await response.json();
        },
        },
    mounted() {
        this.$watch(
            "$refs.child_add.ajuste_inventario_creada",
            () => (this.getAjusteInventarioData()),
        );
        },
created() {
          // Fetch tasks on page load
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.url = this.URL_TENANT + this.url
        this.getAjusteInventarioData();
        this.getEmpresaData();
        },
    }
    </script>