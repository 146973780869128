
<template>
    <div class="modal-body">
        <div class="row mb-3">
            <div class="col-sm-6">
                <h4 class="mb-0">¿Deseas eliminar el Viaje?</h4>
            </div>
            <div class="col-sm-6">
                <input type="text" readonly :value="titulo" placeholder="Título del Viaje" autofocus id="titulo"  class="form-control" />
            </div>
        </div>
    </div>
</template>
    <script>
    import  Cookies from 'js-cookie';
    import $ from 'jquery';
    import 'bootstrap/dist/css/bootstrap.min.css'
    import 'bootstrap/dist/js/bootstrap.bundle.js'
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    import 'vue-cal/dist/vuecal.css'
    export default {
        props: ['id_viaje', 'titulo'],
        name: 'eliminar_viaje',
        components:{
        },
    setup() {
    },
    data() {
        return{
            URL_TENANT: '',
            // Evento a Modificar:
            viaje_eliminado: false,
            //Anteriores
            }
        },

    computed: {
    },
    watch: {
        titulo: {
            handler: function () {
                this.viaje_eliminado = false;
            },
            deep: true,
        },
    },
    methods: {
        async eliminarViaje(){
                try{
                    await fetch(this.URL_TENANT+'/api/agencia_viaje/viaje/'+this.id_viaje+'/',{
                    method: 'DELETE',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: ''})
                    notificaciones.round_success_noti($.msg='Viaje Eliminado Existosamente');
                    this.viaje_eliminado = true;

                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
            }
        },
    mounted() {
        },
    created() {
            const result = window.location.origin;
            this.URL_TENANT = result;
        },
    }
    </script>
    <style>
    </style>