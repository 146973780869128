
<template>
    <button type="button" class="btn btn-light" @click="limpiarInputs()" data-bs-toggle="modal" data-bs-target="#agregarModal">Agregar Asistente</button>
    <!-- Modal -->
        <div class="modal fade"  v-if="modalShow" id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content bg-dark">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Agregar Asistente</h5>
                        <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Usuario</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="usernameNuevoUsuario"  class="form-control" v-model="asistente.usuario"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Contraseña</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="password" id="usernameNuevoContraseña"  class="form-control" v-model="asistente.contraseña"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Nombre</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="nombreUsuario"  class="form-control" v-model="asistente.nombre"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Apellido</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="apellidoUsuario" class="form-control" v-model="asistente.apellido" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Correo</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="email" id="correoUsuario" class="form-control" v-model="asistente.correo" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Teléfono Casa</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="telefonoUsuario" class="form-control" v-model="asistente.telefono" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Celular</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="celularUsuario" class="form-control" v-model="asistente.celular" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Dirección</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="direccionUsuario" class="form-control" v-model="asistente.direccion" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Imágen</h6>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group mb-3">
                                <label class="input-group-text" for="inputGroupFile01">Subir</label>
                                <input type="file" class="form-control" id="imagenDoctor">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Observaciones</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="direccionUsuario" class="form-control" v-model="asistente.observaciones" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Consultorio</h6>
                        </div>
                        <div class="col-sm-9">
                            <vue-select
                                :options="consultoriosData"
                                v-model="asistente.consultorio"
                                label-by="nombre"
                                track-by="nombre"
                                value-by="id"
                                search-placeholder="Selecciona un Consultorio"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Consultorio"
                                close-on-select>
                                <template #label="{ selected }">
                                <template v-if="selected">
                                    <div style="color:white;">{{ selected.nombre }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Consultorio</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.nombre }}</div>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Médico</h6>
                        </div>
                        <div class="col-sm-9">
                            <vue-select
                                :options="medicosData"
                                v-model="asistente.medico"
                                label-by="nombre"
                                track-by="nombre"
                                value-by="id"
                                search-placeholder="Selecciona un Médico"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Médico"
                                close-on-select>
                                <template #label="{ selected }">
                                <template v-if="selected">
                                    <div style="color:white;">{{ selected.nombre }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Médico</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.nombre }}</div>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" @click="agregarasistente()" class="btn btn-primary">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal -->
    </template>
    <style>
    </style>
    <script>
    let notificaciones = require('@/assets/plugins/notifications//js/notification-custom-script.js');
    import $ from 'jquery';
    import  Cookies from 'js-cookie';
    import VueNextSelect from 'vue-next-select'
    export default {
        name: 'agregar_asistente_component',
        components:{
            'vue-select': VueNextSelect,
        },
    data() {
        return{
            modalShow: true,
            asistente_añadido: false,
            URL_TENANT: '',
            asistente:  {
                usuario: "",
                contraseña: "",
                nombre: "",
                apellido: "",
                correo: "",
                telefono: "",
                celular: "",
                direccion: "",
                observaciones: "",
                consultorio: "",
                medico: ""
                },
            consultoriosData: [],
            medicosData: [],
            }
        },
        watch: {
            'asistente.consultorio':{
                handler: function () {
                    if(this.asistente.consultorio.length > 1){
                        this.asistente.consultorio.shift();
                    }
                }
            },
            'asistente.medico':{
                handler: function () {
                    if(this.asistente.medico.length > 1){
                        this.asistente.medico.shift();
                    }
                }
            }
        },
        methods: {
            async  limpiarInputs(){
                this.asistente_añadido= false;
                this.asistente =  {
                    usuario: "",
                    contraseña: "",
                    nombre: "",
                    apellido: "",
                    correo: "",
                    telefono: "",
                    celular: "",
                    direccion: "",
                    observaciones: "",
                    consultorio: "",
                    medico: ""
                }
            },
            async getConsultoriosData(){
                const response = await fetch(this.URL_TENANT+'/api/consultorio/')
                this.consultoriosData = await response.json();
            },
            async getMedicosData(){
                const response = await fetch(this.URL_TENANT+'/api/medico_get/')
                this.medicosData = await response.json();
            },
            //Agregar Médico:
            async agregarasistente(){
                if(!this.asistente.usuario || !this.asistente.contraseña || !this.asistente.nombre){
                    notificaciones.round_error_noti($.msg='Usuario, Contraseña y Nombre son Obligatorios');
                }else{
                    try{
                        const imagen = document.getElementById("imagenDoctor");
                        const formData = new FormData();
                        formData.append("username", this.asistente.usuario);
                        formData.append("password", this.asistente.contraseña);
                        formData.append("first_name", this.asistente.nombre);
                        formData.append("last_name", this.asistente.apellido);
                        formData.append("email", this.asistente.correo);
                        formData.append("is_staff", true);
                        formData.append("is_active", true);
                        formData.append("is_superuser", false);
                        formData.append("telefono", this.asistente.telefono);
                        formData.append("celular", this.asistente.celular);
                        formData.append("direccion", this.asistente.direccion);
                        console.log("CONSULTORIO");
                        console.log(this.asistente.consultorio);
                        console.log(this.asistente.consultorio.length);
                        if(this.asistente.consultorio != ""){
                            formData.append("consultorio", this.asistente.consultorio[0]);
                            console.log("CON CONSULTORIO");
                        }else{
                            console.log("CONSULTORIO VACÍO");
                            formData.append("consultorio", '');
                        }
                        if(this.asistente.medico != ""){
                            formData.append("medico", this.asistente.medico[0]);
                            console.log("CON medico");
                        }else{
                            console.log("medico VACÍO");
                            formData.append("medico", '');
                        }
                        formData.append("observaciones", this.asistente.observaciones);
                        if(imagen.files.length == 0){
                            formData.append("imagen", '')
                        }else{
                            formData.append("imagen", imagen.files[0]);
                        }
                        await fetch(this.URL_TENANT+'/api/asistente_create/',{
                            method: 'POST',
                            headers: {
                                'X-CSRFToken': Cookies.get('csrftoken'),
                                },
                            body: formData})
                        notificaciones.round_success_noti($.msg='Asistente Creado Exitosamente');
                        this.asistente_añadido = true;
                        document.getElementById('close').click();
                    }catch(error){
                        notificaciones.round_error_noti($.msg='Error, algo salió mal');
                    }
                }
            },
        },
        mounted() {
            },
        created() {
            const result = window.location.origin;
            this.URL_TENANT = result;
            this.getConsultoriosData();
            this.getMedicosData();
        },
    }
    </script>