<template>
      <Header/>
      <Nav v-if="!isMainDomain.is_main_domain"></Nav>
      <Home/>

</template>

<script>
import Header from '../../components/header_home.vue'
import Nav from '../../components/nav.vue'
import Home from '../../components/globales/home.vue'

export default {
  name: 'HomeComponent',

  components: {
    Header,
	Nav,
    Home,
  },

  data: () => ({
    //
    isMainDomain: null,
  }),
  beforeMount(){
      this.isMainDomain = JSON.parse(document.getElementsByTagName('body')[0].getAttribute('data') || '{}');
    },
}
</script>
