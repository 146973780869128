<template>
    <div class="page-wrapper">
        <div class="page-content">
            <!--breadcrumb-->
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Agencia de Viajes</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Vehículos</li>
                        </ol>
                    </nav>
                </div>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-light">Settings</button>
                        <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	<a class="dropdown-item" href="javascript:;">Action</a>
                            <a class="dropdown-item" href="javascript:;">Another action</a>
                            <a class="dropdown-item" href="javascript:;">Something else here</a>
                            <div class="dropdown-divider"></div>	<a class="dropdown-item" href="javascript:;">Separated link</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end breadcrumb-->
            <div class="card">
                <div class="card-body">
                    <div class="d-lg-flex align-items-center mb-4 gap-3">
                        <div class="position-relative">
                            <input type="text" class="form-control ps-5 radius-30" v-model="buscarVehiculo" placeholder="Buscar Vehículo"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                        </div>
                        <div class="ms-auto">
                            <add_vehiculo ref="child_add"></add_vehiculo>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th>Vehículo</th>
                                    <th>Modelo</th>
                                    <th>Año</th>
                                    <th>Capacidad de Pasajeros</th>
                                    <th>Descripción</th>
                                    <th>Estado</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in vehiculosDataFilter.results" :key="item.id">
                                    <td>{{item.vehiculo_tipo}}</td>
                                    <td>{{item.modelo}}</td>
                                    <td>{{item.año}}</td>
                                    <td>{{item.capacidad_pasajeros}}</td>
                                    <td>{{item.descripcion}}</td>
                                    <td>{{item.estado}}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <button type="button" class="btn btn-light" @click="id_vehiculo=item.id, filas=item.filas, asientos_fila=item.asientos_fila"  data-bs-toggle="modal" data-bs-target="#agregarAsientos">Ver Asientos</button>

                                            <a  class="ms-3"><i class='bx bxs-trash'></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>
    </div>
        <!-- Modal -->
        <div class="modal fade" id="agregarAsientos" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content bg-dark">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Asientos</h5>
                        <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body d-flex flex-column">
                        <div style="text-align: center;">
                            <img src="./disponible_nodisponible.png" alt="Leyendas">
                        </div>
                        <add_asientos :id_vehiculo="id_vehiculo" :filas="filas" :asientos_fila="asientos_fila" ref="child_aientos"></add_asientos>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Listo</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal -->
</div>
</template>
<style lang="less">
    @import "./style.less";
</style>
<script>

import add_vehiculo from "./agregar_vehiculo.vue";
import add_asientos from "./asientos.vue";
//import mod_event from "./modificar_medico.vue"
//import del_event from "./eliminar_medico.vue"

export default {
    name: 'vehiculo_component',
    components:{
        add_vehiculo,
        add_asientos,
        /*del_event,*/
    },
data() {
    return{
        URL_TENANT: '',
        vehiculosData:  [],
        buscarVehiculo: '',
        id_vehiculo: '',
        filas: '',
        asientos_fila: '',
        }
    },
    watch: {
},
    computed: {
        vehiculosDataFilter() {
        console.log("LENGT");
        if (this.buscarVehiculo != ''){
            return this.vehiculosData.filter((item) => {
            return item.modelo.toLowerCase().includes(this.buscarVehiculo.toLowerCase()) || item.descripcion.toLowerCase().includes(this.buscarVehiculo.toLowerCase())
        })
        }else{
            return this.vehiculosData;
        }
    },
    },

    methods: {
        async getVehiculosData(){
            const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/vehiculo/')
            this.vehiculosData = await response.json();
        },
    },
    mounted() {
        this.$watch(
            "$refs.child_add.vehiculo_añadido",
            () => (this.getVehiculosData()),
        );
        },
    created() {
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.getVehiculosData();
    },
}
</script>