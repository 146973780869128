
<template>
<div class="page-wrapper">
			<div class="page-content">
				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Usuario</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Administración de Usuarios</li>
							</ol>
						</nav>
					</div>

				</div>
				<!--end breadcrumb-->
				<div class="card">
					<div class="card-body">
						<div class="d-lg-flex align-items-center mb-4 gap-3">
							<div class="position-relative">
								<input type="text" class="form-control ps-5 radius-30" v-model="buscarUsuario" placeholder="Buscar Usuario"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
							</div>
                            <!-- Button trigger modal -->
                            <!-- Modal -->
                            <div class="ms-auto"><add_event ref="child_add"></add_event></div>

                        </div>
						<!--
                            <div class="ms-auto"><a href="javascript:;" @click="cleanAñadirData" data-bs-toggle="modal" data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Añadir Nuevo Usuario</a></div>
                            <div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-lg">
								<div class="modal-content bg-dark">
									<div class="modal-header">
										<h5 class="modal-title" id="exampleModalLabel">Añadir Usuario</h5>
										<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Usuario</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="usernameNuevoUsuario"  class="form-control" v-model="usernameNuevoUsuario"  />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Nombre</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="nombreUsuario"  class="form-control" v-model="nombreNuevoUsuario"  />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Apellido</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="apellidoUsuario" class="form-control" v-model="apellidoNuevoUsuario" />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Correo</h6>
											</div>
											<div class="col-sm-9">
												<input type="email" id="correoUsuario" class="form-control" v-model="correoNuevoUsuario" />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Teléfono Casa</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="telefonoUsuario" class="form-control" v-model="telefonoNuevoUsuario" />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Celular</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="celularUsuario" class="form-control" v-model="celularNuevoUsuario" />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Dirección</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="direccionUsuario" class="form-control" v-model="direccionNuevoUsuario" />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Departamento</h6>
											</div>
											<div class="col-sm-9">
                                                <vue-select
                                                    :options="departamentosData"
                                                    v-model="selectedDepartamento"
                                                    label-by="nombre"
                                                    track-by="nombre"
                                                    hide-selected="true"
                                                    value-by="id"
                                                    placeholder="Selecciona un Departamento"
                                                    search-placeholder="Selecciona un Departamento"
                                                    collapse-tags="true"
                                                    multiple
                                                    taggable
                                                    searchable
                                                    >
                                                </vue-select>
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Puesto</h6>
											</div>
											<div class="col-sm-9">
                                                <vue-select   :options="puestosData"
                                                    v-model="selectedPuesto"
                                                    label-by="nombre"
                                                    hide-selected="true"
                                                    track-by="nombre"
                                                    value-by="id"
                                                    placeholder="Selecciona un Puesto"
                                                    close-on-select>
                                                    <template #label="{ selected }">
                                                    <template v-if="selected">
                                                        <div style="color:white;">{{ selected.nombre }}</div>
                                                    </template>
                                                    <template v-else > <div style="color:white;">Seleccione un Puesto</div></template>
                                                    </template>
                                                    <template #dropdown-item="{ option }" >
                                                    <div  style="color:white;">{{ option.nombre }}</div>
                                                    </template>
                                                </vue-select>
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Imágen</h6>
											</div>
											<div class="col-sm-9">
												<div class="input-group mb-3">
                                                    <label class="input-group-text" for="inputGroupFile01">Subir</label>
                                                    <input type="file" class="form-control" id="imagenU">
                                                </div>
											</div>
										</div>
                                    </div>
									<div class="modal-footer">
										<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
										<button type="button" @click="agregarNuevoUsuario" class="btn btn-primary">Guardar</button>
									</div>
								</div>
							</div>
						</div>
                        -->

						<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th><h6># ID</h6></th>
										<th><h6>Usuario</h6></th>
										<th><h6>Nombre Completo</h6></th>
										<th><h6>Puesto</h6></th>
										<th><h6>Departamento</h6></th>
										<th><h6>Estado</h6></th>
										<th><h6>Ver Ficha</h6></th>
										<th><h6>Acciones</h6></th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in usuariosDataFilter" :key="item.id">
										<td >
											<div class="d-flex align-items-center">
												<div>
													<input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
												</div>
												<div class="ms-2">
													<h6 class="mb-0 font-14">#{{item.id}}</h6>
												</div>
											</div>
										</td>
										<td><h6>{{item.username}}</h6></td>
										<td><h6>{{item.first_name}} {{item.last_name}}</h6></td>
										<td><h6>{{item.puesto_nombre}}</h6></td>
										<td><h6>{{item.departamento_nombre}}</h6></td>
										<td><h6>
                                            <div class="badge rounded-pill bg-light p-2 text-uppercase px-3">
                                                <div v-if="[[item.is_active]] == 'false'"> <i  class='bx bx-minus me-1'></i>Inactivo</div>
                                                <div v-if="[[item.is_active]] == 'true'"> <i  class='bx bx-circle me-1'></i>Activo</div>
                                            </div></h6>
                                        </td>
										<td><button type="button" class="btn btn-light btn-sm radius-30 px-4">Ver Ficha</button></td>
										<td>
											<div class="d-flex order-actions">
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modificarModal" @click="fillModificarUsuario(item.id)" class=""><i class='bx bxs-edit'></i></a>
        <div class="modal fade"  v-if="modalShow" id="modificarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content bg-dark">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Crear Evento</h5>
                    <button type="button" id="close_mod" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
					<div class="modal-body">
                        <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Usuario</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="usernameNuevoUsuario"  class="form-control" v-model="modificar_usuario.usuario"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Nombre</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="nombreUsuario"  class="form-control" v-model="modificar_usuario.nombre"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Apellido</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="apellidoUsuario" class="form-control" v-model="modificar_usuario.apellido" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Correo</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="email" id="correoUsuario" class="form-control" v-model="modificar_usuario.correo" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Teléfono Casa</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="telefonoUsuario" class="form-control" v-model="modificar_usuario.telefono" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Celular</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="celularUsuario" class="form-control" v-model="modificar_usuario.celular" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Dirección</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="direccionUsuario" class="form-control" v-model="modificar_usuario.direccion" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Departamento</h6>
                        </div>
                        <div class="col-sm-9">
                            <vue-select  :options="departamentosData"
                                v-model="modificar_usuario.departamento"
                                label-by="nombre"
                                track-by="nombre"
                                value-by="id"
                                search-placeholder="Selecciona un Proveedor"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Proveedor"
                                close-on-select>
                                <template #label="{ selected }">
                                <template v-if="selected">
                                    <div style="color:white;">{{ selected.nombre }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Proveedor</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.nombre }}</div>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Puesto</h6>
                        </div>
                        <div class="col-sm-9">
                            <vue-select  :options="puestosData"
                                v-model="modificar_usuario.puesto"
                                label-by="nombre"
                                track-by="nombre"
                                value-by="id"
                                search-placeholder="Selecciona un Proveedor"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Proveedor"
                                close-on-select>
                                <template #label="{ selected }">
                                <template v-if="selected">
                                    <div style="color:white;">{{ selected.nombre }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Proveedor</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.nombre }}</div>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Imágen</h6>
                        </div>
                        <div class="col-sm-1" v-if="modificar_usuario.imagen">
                            <div class="input-group mb-3">
                                <img :src="modificar_usuario.imagen" class="user-img" alt="user avatar">
                            </div>
                        </div>
                        <div class="col-sm-8">
                            <div class="input-group mb-3">
                                <label class="input-group-text" for="inputGroupFile01">Subir</label>
                                <input type="file" class="form-control" id="imagenUMod">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Estado</h6>
                        </div>
                        <div class="col-sm-9">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="modificar_usuario.estado">
                                <label v-if="modificar_usuario.estado == false" class="form-check-label" for="flexCheckChecked">Inactivo</label>
                                <label v-if="modificar_usuario.estado == true" class="form-check-label" for="flexCheckChecked">Activo</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Super Usuario</h6>
                        </div>
                        <div class="col-sm-9">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="modificar_usuario.superusuario">
                                <label v-if="modificar_usuario.superusuario == false" class="form-check-label" for="flexCheckChecked">No Es Superusuario</label>
                                <label v-if="modificar_usuario.superusuario == true" class="form-check-label" for="flexCheckChecked">Es Superusuario</label>
                            </div>
                        </div>
                    </div>
                    </div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
						<button type="button" @click="modificarUsuario()" class="btn btn-primary">Guardar</button>
					</div>
				</div>
			</div>
		</div>
		<!-- End Modal Modify -->
                                                <!--
                                                    <mod_event :id_modificar="item.id" ref="child_mod"></mod_event>
                                                -->
												<a href="javascript:;" class="ms-3"><i class='bx bxs-trash'></i></a>
											</div>
										</td>
									</tr>


								</tbody>
							</table>
						</div>
					</div>
				</div>


			</div>
		</div>

</template>
<style>
</style>
<script>
import  Cookies from 'js-cookie';
//import Select2 from 'vue3-select2-component';
//import $ from 'jquery';
//let select2 = require('@/assets/plugins/select2/js/select2.min.js');
//import * as mykey from '../assets/js/mykey.js'
import add_event from "./usuarios/agregar_usuario.vue"
//import mod_event from "./usuarios/modificar_usuario.vue"

import $ from 'jquery';
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')
import VueNextSelect from 'vue-next-select'
console.log(Cookies.get());
export default {
    name: 'usuarios_component',
    components:{
        add_event,
        'vue-select': VueNextSelect,
    },
data() {
	return{
        modalShow: true,
        empresaData: [0],
        usuariosData: [],
        departamentosData: [0],
        puestosData: [0],
        modificar_usuario: {
            id: null,
            usuario: '',
            nombre: '',
            apellido: '',
            correo: '',
            telefono: '',
            celular: '',
            direccion: '',
            departamento: [],
            puesto: [],
            estado: false,
            superusuario: false,
        },
        modificarId: null,
		buscarUsuario: '',
        URL_TENANT: '',
		}
	},
    watch: {
        modificar_usuario: {
            handler: function () {
                if(this.modificar_usuario.departamento.length > 1 ){
                    this.modificar_usuario.departamento.shift();
                }
                if(this.modificar_usuario.puesto.length > 1){
                    this.modificar_usuario.puesto.shift();
                }
                if(this.modificar_usuario.departamento.length > 0 ){
                    this.getPuestosDepartamentoData();
                }
            },
            deep: true,
    },
},

computed: {
    usuariosDataFilter() {
        console.log("LENGT");
        console.log(this.usuariosData.length);
        if (this.buscarUsuario != ''){
            return this.usuariosData.filter((item) => {
            return item.first_name.toLowerCase().includes(this.buscarUsuario.toLowerCase())|| item.last_name.toLowerCase().includes(this.buscarUsuario.toLowerCase()) || item.username.toLowerCase().includes(this.buscarUsuario.toLowerCase())
        })
        }else{
            return this.usuariosData;
        }
    },
},



methods: {
    async modificarUsuario(){

if (!this.modificar_usuario.usuario){
    notificaciones.round_error_noti($.msg='Nombre de Usuario Obligatorio');
}else{
    console.log("ESTAS UPDETEANDO");
    try{
        const imagenUsuario = document.getElementById("imagenUMod");
        const formData = new FormData();
        formData.append("username", this.modificar_usuario.usuario);
        formData.append("first_name", this.modificar_usuario.nombre);
        formData.append("last_name", this.modificar_usuario.apellido);
        formData.append("email", this.modificar_usuario.correo);
        formData.append("is_staff", "true");
        formData.append("is_active", this.modificar_usuario.estado);
        formData.append("is_superuser", this.modificar_usuario.superusuario);
        formData.append("telefono", this.modificar_usuario.telefono);
        formData.append("celular", this.modificar_usuario.celular);
        formData.append("direccion", this.modificar_usuario.direccion);
        if(this.modificar_usuario.puesto.length > 0){
            formData.append("puesto", this.modificar_usuario.puesto[0]);
        }else{
            formData.append("puesto", '');
        }
        formData.append("empresa", this.empresaData.length != 0 ? this.empresaData[0].id : '');
        formData.append("puesto_nombre", '');
        formData.append("departamento_nombre", '');
        if(imagenUsuario.files.length == 0){
            console.log("NO HAY IMAGEN");
        }else{
            formData.append("imagen", imagenUsuario.files[0]);
        }
        await fetch(this.URL_TENANT+'/api/user_update_img/'+this.modificar_usuario.id+'/',{
        method: 'put',
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
        },body: formData})
        document.getElementById('close_mod').click();
        notificaciones.round_success_noti($.msg='Usuario Modificado Exitosamente');
        this.getusuariosData();
    }catch(error){
        console.log(error);
        notificaciones.round_error_noti($.msg='Error, algo salió mal');
    }

}
},
    async getPuestosDepartamentoData(){
            console.log("NUEVOS PUESTOS");
            console.log(this.modificar_usuario.departamento)
            const response = await fetch(this.URL_TENANT+'/api/puesto?departamento='+this.modificar_usuario.departamento+'')
            this.puestosData = await response.json();
        },
    async fillModificarUsuario(id){
        this.modificar_usuario = {
            id: null,
            usuario: '',
            nombre: '',
            apellido: '',
            correo: '',
            telefono: '',
            celular: '',
            direccion: '',
            departamento: [],
            puesto: [],
            estado: false,
            superusuario: false,
        },
        this.getPuestosData();
        console.log("THIS IS ID");
        console.log(id);
        const response = await fetch(this.URL_TENANT+'/api/users/'+id+'/');
        this.modificar_usuario_ = await response.json();
        console.log(this.modificar_usuario_);
        /*try{
            this.departamento_ = [this.puestosData.find(element => element.id == this.modificar_usuario_.puesto).departamento];
        }catch(e){
            this.departamento_ = [''];
            console.log(this.departamento_);
        }*/
        if(!this.modificar_usuario_.puesto){
            console.log("kaka");
            console.log(this.modificar_usuario_.puesto);
            this.puesto_ = [];
            this.departamento_  = [];
        }else{
            console.log("ELSE");
            this.puesto_ = [this.modificar_usuario_.puesto];
            this.departamento_ = [this.puestosData.find(element => element.id == this.modificar_usuario_.puesto).departamento];
            console.log(this.puesto_);
            console.log(this.departamento_);
        }
        this.modificar_usuario = {
            id: this.modificar_usuario_.id,
            usuario: this.modificar_usuario_.username,
            nombre: this.modificar_usuario_.first_name,
            apellido: this.modificar_usuario_.last_name,
            correo: this.modificar_usuario_.email,
            telefono: this.modificar_usuario_.telefono,
            celular: this.modificar_usuario_.celular,
            direccion: this.modificar_usuario_.direccion,
            departamento: this.departamento_,
            puesto:    this.puesto_,
            imagen: this.modificar_usuario_.imagen,
            estado: this.modificar_usuario_.is_active,
            superusuario: this.modificar_usuario_.is_superuser,
        }
    },
    async getEmpresaData() {
        const response = await fetch(this.URL_TENANT+'/api/empresa/');
        this.empresaData = await response.json();
    },
	async getusuariosData() {
        const response = await fetch(this.URL_TENANT+'/api/users/');
        this.usuariosData = await response.json();
    },
    async getPuestosData(){
        const response = await fetch(this.URL_TENANT+'/api/puesto/')
        this.puestosData = await response.json();
    },
    async getDepartamentosData() {
        const response = await fetch(this.URL_TENANT+'/api/departamento/');
        this.departamentosData = await response.json();
    },
    },
mounted() {
        this.$watch(
            "$refs.child_add.usuario_añadido",
            () => (this.getusuariosData()),
        );
        /*let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://unpkg.com/vue-next-select/dist/vue-next-select.iife.prod.js')
        document.head.appendChild(recaptchaScript)*/
    },
created() {
      // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
	this.getusuariosData();
    this.getPuestosData();
    this.getDepartamentosData();
    this.getEmpresaData();
    console.log("token");
	console.log(Cookies.get('csrftoken'));
	},
}

</script>
