<template>
      <Header/>
      <Nav/>
      <Viaje/>
</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import Viaje from '../../components/agencia_viaje/viaje/viaje.vue'



export default {
  name: 'ViajeComponent',
  components: {
  Header,
	Nav,
	Viaje,
  },

  data: () => ({
    //
  }),
}
</script>
