<template>
      <Header/>
      <Nav/>
      <Usuarios/>

</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'
import Usuarios from './components/empresa/usuarios.vue'



export default {
  name: 'UsuariosComponent',

  components: {
    Header,
	Nav,
	Usuarios,
  },

  data: () => ({
    //
  }),
}
</script>
