<template>
      <Header/>
      <Nav/>
      <Vehiculo/>
</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import Vehiculo from '../../components/agencia_viaje/vehiculo/vehiculo.vue'



export default {
  name: 'VehiculoComponent',
  components: {
    Header,
	Nav,
	Vehiculo,
  },

  data: () => ({
    //
  }),
}
</script>
