
<template>
<div class="page-wrapper">
			<div class="page-content">
				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Inventario</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Categorías</li>
							</ol>
						</nav>
					</div>
				</div>
				<!--end breadcrumb-->
				<div class="card">
					<div class="card-body">
						<div class="d-lg-flex align-items-center mb-4 gap-3">
							<div class="position-relative">
								<input type="text" class="form-control ps-5 radius-30" v-model="buscarCategoria" placeholder="Buscar Categoria"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
							</div>
						<div class="ms-auto"><a href="javascript:;" @click="cleanAñadirData" data-bs-toggle="modal" data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Añadir Nueva Categoría</a></div>
						</div>
                        <!-- Button trigger modal -->
						<!-- Modal -->
						<div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-lg">
								<div class="modal-content bg-dark">
									<div class="modal-header">
										<h5 class="modal-title" id="exampleModalLabel">Añadir Categoria</h5>
										<button type="button" class="btn-close" id="close_categoria" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Código Categoria</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" autofocus id="codigoNuevaCategoria"  class="form-control" v-model="codigoNuevaCategoria"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Nombre Categoria</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" autofocus id="nombreNuevaCategoria"  class="form-control" v-model="nombreNuevaCategoria"  />
											</div>
										</div>
                                    </div>
									<div class="modal-footer">
										<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
										<button type="button" @click="agregarNuevaCategoria" class="btn btn-primary">Guardar</button>
									</div>
								</div>
							</div>
						</div>
						<!-- End Modal -->
                        <!-- Modal Modify -->
						<div class="modal fade"  id="modificarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-lg">
								<div class="modal-content bg-dark">
									<div class="modal-header">
										<h5 class="modal-title" id="exampleModalLabel">Modificar Categoría</h5>
										<button type="button" class="btn-close" id="close_mod" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Código</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="codigoNuevaCategoria"  class="form-control" v-model="codigoNuevaCategoria"  />
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Categoria</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="nombreNuevaCategoria"  class="form-control" v-model="nombreNuevaCategoria"  />
											</div>
										</div>
                                        <div class="row mb-3">
                                        <div class="col-sm-3">
                                            <h6 class="mb-0">Estado</h6>
                                        </div>
                                        <div class="col-sm-9">
                                            <vue-select   :options="estados"
                                                v-model="selectedEstado"
                                                label-by="nombre"
                                                hide-selected="true"
                                                track-by="nombre"
                                                value-by="id"
                                                placeholder="Selecciona un Estado"
                                                close-on-select>
                                                <template #label="{ selected }">
                                                <template v-if="selected">
                                                    <div style="color:white;">{{ selected.nombre }}</div>
                                                </template>
                                                <template v-else > <div style="color:white;">Seleccione un Estado</div></template>
                                                </template>
                                                <template #dropdown-item="{ option }" >
                                                <div  style="color:white;">{{ option.nombre }}</div>
                                                </template>
                                            </vue-select>
                                        </div>
                                    </div>
                                    </div>
									<div class="modal-footer">
										<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
										<button type="button" @click="modificarCategoria" class="btn btn-primary">Guardar</button>
									</div>
								</div>
							</div>
						</div>
						<!-- End Modal Modify -->
						<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th><h6>Código</h6></th>
										<th><h6>Categoria</h6></th>
										<th><h6>Estado</h6></th>
										<th><h6>Acciones</h6></th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in categoriasDataFilter" :key="item.id">
										<td><h6>{{item.codigo}}</h6></td>
										<td><h6>{{item.nombre}}</h6></td>
										<td>
                                            <div class="badge rounded-pill bg-light p-2 text-uppercase px-3">
                                                <div v-if="[[item.estado]] == 'I'"> <i  class='bx bx-minus me-1'></i>Inactivo</div>
                                                <div v-if="[[item.estado]] == 'A'"> <i  class='bx bx-circle me-1'></i>Activo</div>
                                            </div>
                                        </td>
										<td>
											<div class="d-flex order-actions">
												<a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modificarModal" @click="fillmodificarCategoria([[item.id]])" class=""><i class='bx bxs-edit'></i></a>
												<a href="javascript:;" data-bs-toggle="modal" data-bs-target="#eliminarModal" v-if="item.esta_en_productos == false" @click="fillEliminarCategoria(item.id, item.nombre)" class="ms-3"><i class='bx bxs-trash'></i></a>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>


			</div>
		</div>
<!-- Modal Modify -->
<div class="modal fade"  id="eliminarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Eliminar Categoría</h5>
                <button type="button" class="btn-close" id="close_mod" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                        <h6 class="mb-0">¿Desea Eliminar la Categoría <strong>{{ nombreEliminarCategoria }}</strong>?</h6>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" @click="eliminarCategoria();" data-bs-dismiss="modal" class="btn btn-danger">Eliminar</button>
            </div>
        </div>
    </div>
</div>
<!-- End Modal Modify -->
</template>
<style>
</style>
<script>
import  Cookies from 'js-cookie';
//import Select2 from 'vue3-select2-component';
//import $ from 'jquery';
//let select2 = require('@/assets/plugins/select2/js/select2.min.js');
//import * as mykey from '../assets/js/mykey.js'
import $ from 'jquery';
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')
import VueNextSelect from 'vue-next-select'
console.log(Cookies.get());
export default {
    name: 'categoria_component',
    components:{
        'vue-select': VueNextSelect,
    },
data() {
	return{
        usuariosData: [0],
        departamentosData: [0],
        puestosData: [0],
        categoriasData: [],
        selectedEstado: null,
        nameError: [],
        estados: [
            {
                id: 'A',
                nombre: 'Activo',
            },
            {
                id: 'I',
                nombre: 'Inactivo',
            }
        ],
        nombreNuevaCategoria: null,
        codigoNuevaCategoria: '',
        modificarId: null,
        eliminarId: null,
        nombreEliminarCategoria: '',
		buscarCategoria: '',
        URL_TENANT: '',
		}
	},

computed: {
    categoriasDataFilter() {
        console.log("LENGT");
        console.log(this.categoriasData.length);
        if (this.buscarCategoria != ''){
            return this.categoriasData.filter((item) => {
            return item.nombre.toLowerCase().includes(this.buscarCategoria.toLowerCase()) || (item.codigo ? item.codigo.toLowerCase().includes(this.buscarCategoria.toLowerCase()) : false)
        })
        }else{
            return this.categoriasData;
        }

    },
},
methods: {
    async cleanAñadirData() {
        console.log("CLEAN AÑADIR DATA");
        this.nombreNuevaCategoria = null;
        this.codigoNuevaCategoria = '';
    },
    async fillmodificarCategoria(idMod) {
        this.modificarId = idMod[0][0];
        for(var k in this.categoriasData){
            if(this.categoriasData[k].id==this.modificarId){
            console.log(k, this.categoriasData[k]);
            this.nombreNuevaCategoria = this.categoriasData[k].nombre;
            this.categoriaNuevaCategoria = this.categoriasData[k].categoria;
            this.selectedEstado = this.categoriasData[k].estado;
            }
        }
    },
    async fillEliminarCategoria(id, nombre){
        this.eliminarId =  id;
        this.nombreEliminarCategoria = nombre;
    },
    async eliminarCategoria(){
        await fetch(this.URL_TENANT+'/api/categoria/'+this.eliminarId+'/',{
        method: 'DELETE',
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },});
        notificaciones.round_success_noti($.msg='Producto Eliminado Exitosamente');
        this.getCategoriasData();
    },
    // Validadores:
    validateInputs(){
        this.nameError =  (!this.nombreNuevaCategoria || this.nombreNuevaCategoria == '') ? ['Debe de Ingresar el Nombre de Categoría'] : [];
        this.nameError = (!this.codigoNuevaCategoria) ? [...this.nameError, 'Debe de Ingresar un Código'] : this.nameError;
    },
    async modificarCategoria(){
        this.validateInputs();
        if(this.nameError.length != 0){
            this.nameError.forEach((element) =>{
                notificaciones.round_info_noti($.msg=element);
            });
        }else{
            console.log("ESTAS UPDETEANDO");
            try{
                const formData = new FormData();
                formData.append("codigo", this.codigoNuevaCategoria);
                formData.append("nombre", this.nombreNuevaCategoria);
                formData.append("estado", this.selectedEstado);
                await fetch(this.URL_TENANT+'/api/categoria/'+this.modificarId+'/',{
                method: 'put',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Categoría Modificada');
            document.getElementById('close_mod').click();
            this.getCategoriasData();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }
        }
    },
    async agregarNuevaCategoria(){
        this.validateInputs();
        if(this.nameError.length != 0){
            this.nameError.forEach((element) =>{
                notificaciones.round_info_noti($.msg=element);
            });
        }else{
            try{
                const formData = new FormData();
                formData.append("codigo", this.codigoNuevaCategoria);
                formData.append("nombre", this.nombreNuevaCategoria);
                await fetch(this.URL_TENANT+'/api/categoria/',{
                method: 'post',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Nueva Categoría Agregada');
            document.getElementById('close_categoria').click();
            this.getCategoriasData();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }

        }
    },
	async getCategoriasData() {
        const response = await fetch(this.URL_TENANT+'/api/categoria/');
        this.categoriasData = await response.json();
    },
    },
created() {
      // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
    console.log("URL:::::::::"+ result);
    this.getCategoriasData();
	},
}
</script>
