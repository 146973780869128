
<template>
<div class="nav-container">
			<div class="mobile-topbar-header">
				<div>
					<img src="" class="logo-icon" alt="logo icon">
				</div>
				<div>
					<h4 class="logo-text">Dashtreme</h4>
				</div>
				<div class="toggle-icon ms-auto"><i class='bx bx-arrow-to-left'></i>
				</div>
			</div>
			<nav class="topbar-nav">
				<ul class="metismenu" id="menu">
					<li>
						<a href="javascript:;" class="has-arrow">
							<div class="parent-icon"><i class='bx bx-home-circle'></i>
							</div>
							<div class="menu-title">Empresa</div>
						</a>
						<ul>
							<li> <a href="/dashboard/"><i class="bx bx-right-arrow-alt"></i>Dashboard</a>
							</li>
							<li v-if="userData && userData[0] && userData[0].is_superuser"> <a href="/empresa/usuarios/"><i class="bx bx-right-arrow-alt"></i> Administrar Usuarios</a>
							</li>
							<li v-if="userData && userData[0] && userData[0].is_superuser"> <a href="/empresa/departamentos/"><i class="bx bx-right-arrow-alt"></i>Departamentos</a>
							</li>
							<li v-if="userData && userData[0] && userData[0].is_superuser"> <a href="/empresa/puestos/"><i class="bx bx-right-arrow-alt"></i>Puestos</a>
							</li>
						</ul>
					</li>
					<li>
						<a href="javascript:;" class="has-arrow">
							<div class="parent-icon"><i class="bx bxs-category"></i>
							</div>
							<div class="menu-title">Catalogo</div>
						</a>
						<ul>
							<li v-if="userData && userData[0] && userData[0].is_superuser"> <a href="/catalogo/proveedor/"><i class="bx bx-right-arrow-alt"></i>Proveedores</a>
							</li>
							<li> <a href="/catalogo/cliente/"><i class="bx bx-right-arrow-alt"></i>Clientes</a>
							</li>
							<li v-if="userData && userData[0] && userData[0].is_superuser"> <a href="/catalogo/categoria/"><i class="bx bx-right-arrow-alt"></i>Categorias</a>
							</li>
							<li v-if="userData && userData[0] && userData[0].is_superuser"> <a href="/catalogo/producto/"><i class="bx bx-right-arrow-alt"></i>Productos</a>
							</li>
							<li> <a href="/catalogo/buscar_producto/"><i class="bx bx-right-arrow-alt"></i>Buscar Productos</a>
							</li>
						</ul>
					</li>
					<li>
						<a class="has-arrow" href="javascript:;">
							<div class="parent-icon"><i class='bx bx-package'></i>
							</div>
							<div class="menu-title">Inventario</div>
						</a>
						<ul>
							<li v-if="userData && userData[0] && userData[0].is_superuser"> <a href="/inventario/ajuste_inventario/"><i class="bx bx-right-arrow-alt"></i>Ajuste de Inventario</a>
							</li>
						</ul>
					</li>
					<li>
						<a class="has-arrow" href="javascript:;">
							<div class="parent-icon"><i class="bx bx-cart"></i>
							</div>
							<div class="menu-title">Compras</div>
						</a>
						<ul>
							<!-- <li> <a href="/compra/requisicion/"><i class="bx bx-right-arrow-alt"></i>Requisiciones</a>
							</li>
							<li v-if="userData && userData[0] && userData[0].is_superuser"> <a href="/compra/cotizacion_compra/"><i class="bx bx-right-arrow-alt"></i>Cotizaciones</a>
							</li>
							<li v-if="userData && userData[0] && userData[0].is_superuser"> <a href="/compra/orden_compra/"><i class="bx bx-right-arrow-alt"></i>Ordenes Compra</a>
							</li> -->
							<li v-if="userData && userData[0] && (userData[0].is_superuser || userData[0].username === 'marilynsalazar') "> <a href="/compra/compra/"><i class="bx bx-right-arrow-alt"></i>Compra</a>
							</li>
						</ul>
					</li>
					<li>
						<a class="has-arrow" href="javascript:;">
							<div class="parent-icon"><i class='bx bx-money'></i>
							</div>
							<div class="menu-title">Ventas</div>
						</a>
						<ul>
							<li> <a href="/venta/venta/"><i class="bx bx-right-arrow-alt"></i>Venta</a>
							</li>
							<li> <a href="/venta/pagos/"><i class="bx bx-right-arrow-alt"></i>Pagos</a>
							</li>
						</ul>
					</li>
					<li>
						<a class="has-arrow" href="javascript:;">
							<div class="parent-icon"><i class='bx bx-log-out-circle'></i>
							</div>
							<div class="menu-title">Devoluciones</div>
						</a>
						<ul>
							<li> <a href="/devolucion/devolucion/"><i class="bx bx-right-arrow-alt"></i>Devoluciones</a>
							</li>
						</ul>
					</li>
					<li>
						<a class="has-arrow" href="javascript:;">
							<div class="parent-icon">
								<i v-if="userData && userData[0] && userData[0].caja_del_dia" class="bx bx-lock-open"></i>
								<i v-if="userData && userData[0] && !userData[0].caja_del_dia" class="bx bxs-lock"></i>
							</div>
							<div class="menu-title">Caja</div>
						</a>
						<ul>
							<li> <a href="/empresa/caja/"><i class="bx bx-right-arrow-alt"></i>Caja</a>
							</li>
							<li> <a href="/empresa/gasto/"><i class="bx bx-right-arrow-alt"></i>Gastos</a>
							</li>
						</ul>
					</li>
					<li>
						<a class="has-arrow" href="javascript:;">
							<div class="parent-icon">
								<i class='bx bxs-report'></i>
							</div>
							<div class="menu-title">Reportes</div>
						</a>
						<ul>
							<li>
								<a href="/reportes/kardex/"><i class="bx bx-right-arrow-alt"></i>Kardex</a>
							</li>
							<li>
								<a href="/reportes/utilidades_del_producto/"><i class="bx bx-right-arrow-alt"></i>Utilidades del Producto</a>
							</li>
						</ul>
					</li>
				</ul>
			</nav>
		</div>
</template>

<script>
//import * as mykey from '../assets/js/mykey.js'

export default {
    name: 'NavComponent',
data: () => ({
    nav: 'nav',
	userData: {},
	}
),
methods: {
	async getUserData(){
		const response = await fetch(this.URL_TENANT+'/api/user/');
		this.userData = await response.json();
	},
},
created() {
    // Fetch tasks on page load
	const result = window.location.origin;
    this.URL_TENANT = result;
	this.getUserData();
	}
}
</script>
