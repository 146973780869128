
<template>
                        <button type="button" class="btn btn-light" @click="limpiarInputs()" data-bs-toggle="modal" data-bs-target="#agregarModal">Crear Evento </button>
                        <!-- Modal -->
                            <div class="modal fade"  v-if="modalShow" id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content bg-dark">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">Crear Evento</h5>
                                            <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Título</h6>
                                                </div>
                                                <div class="col-sm-10">
                                                    <input type="text" placeholder="Título del Evento" autofocus id="titulo"  class="form-control" v-model="eventoNuevo.titulo"  />
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Fecha Inicio</h6>
                                                </div>
                                                <div class="col-sm-8">
                                                    <Datepicker
                                                    v-model="eventoNuevo.inicio"
                                                    dark placeholder="Selecciona una Fecha"
                                                    autoApply
                                                    :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                    :enableTimePicker="false"
                                                    monthNameFormat="long"
                                                    :format="format"
                                                    modelType="dd-MM-yyyy"
                                                    locale="es">
                                                    </Datepicker>
                                                </div>
                                                <div class="col-sm-2">
                                                    <Datepicker v-model="eventoNuevo.inicio_tiempo" :startTime="startTime" timePicker dark  minutesIncrement="5" />
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Fecha Fin</h6>
                                                </div>
                                                <div class="col-sm-8">
                                                    <Datepicker
                                                    v-model="eventoNuevo.fin"
                                                    dark placeholder="Selecciona una Fecha"
                                                    autoApply
                                                    :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                    :enableTimePicker="false"
                                                    monthNameFormat="long"
                                                    :format="format"
                                                    modelType="dd-MM-yyyy"
                                                    locale="es">
                                                    </Datepicker>
                                                </div>
                                                <div class="col-sm-2">
                                                    <Datepicker v-model="eventoNuevo.fin_tiempo" :startTime="startTime" timePicker dark  minutesIncrement="5" />
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Descripción</h6>
                                                </div>
                                                <div class="col-sm-10">
                                                    <textarea placeholder="Descripción del Evento" id="descripcion" rows="3"  class="form-control" v-model="eventoNuevo.descripcion"></textarea>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Cliente</h6>
                                                </div>
                                                <div class="col-sm-10">
                                                    <vue-select
                                                        :options="clientesData"
                                                        v-model="eventoNuevo.cliente"
                                                        label-by="nombre"
                                                        track-by="nombre"
                                                        value-by="id"
                                                        search-placeholder="Selecciona un Cliente"
                                                        multiple
                                                        collapse-tags="true"
                                                        taggable
                                                        searchable
                                                        placeholder="Selecciona un Cliente"
                                                        close-on-select>
                                                        <template #label="{ selected }">
                                                        <template v-if="selected">
                                                            <div style="color:white;">{{ selected.nombre }}</div>
                                                        </template>
                                                        <template v-else > <div style="color:white;">Seleccione un Cliente</div></template>
                                                        </template>
                                                        <template #dropdown-item="{ option }">
                                                        <div style="color:white;">{{ option.nombre }}</div>
                                                        </template>
                                                    </vue-select>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Categoría</h6>
                                                </div>
                                                <div class="col-sm-10">
                                                    <vue-select
                                                        :options="categoriasData"
                                                        v-model="eventoNuevo.categoria"
                                                        label-by="nombre"
                                                        track-by="nombre"
                                                        value-by="id"
                                                        search-placeholder="Selecciona una Categoría"
                                                        multiple
                                                        collapse-tags="true"
                                                        taggable
                                                        searchable
                                                        placeholder="Selecciona una Categoría"
                                                        close-on-select>
                                                        <template #label="{ selected }">
                                                        <template v-if="selected">
                                                            <div style="color:white;">{{ selected.nombre }}</div>
                                                        </template>
                                                        <template v-else > <div style="color:white;">Seleccione una Categoría</div></template>
                                                        </template>
                                                        <template #dropdown-item="{ option }">
                                                        <div style="color:white;">{{ option.nombre }}</div>
                                                        </template>
                                                    </vue-select>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Color</h6>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="dropdown">
                                                        <button type="button" class="btn btn-light" >
                                                            <span class="dot2" :style=" {'background-color': selectedColor }"></span>
                                                        </button>
                                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Elegir un Color
                                                        </button>
                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li>
                                                                <button type="button" @click="SeleccionarColor(item.color)" class="btn" v-for="item in coloresData" :key="item.id">
                                                                    <span class="dot2" :style=" {'background-color': item.color }"></span>
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                            <button type="button" @click="agregarEventoNuevo()" class="btn btn-primary">Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Modal -->
    
    </template>
    
    <script>
    import  Cookies from 'js-cookie';
    //import Select2 from 'vue3-select2-component';
    //import $ from 'jquery';
    //let select2 = require('@/assets/plugins/select2/js/select2.min.js');
    //import * as mykey from '../assets/js/mykey.js'
    import $ from 'jquery';
    import { Modal } from "bootstrap";
    import 'bootstrap/dist/css/bootstrap.min.css'
    import 'bootstrap/dist/js/bootstrap.bundle.js'
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    //let calendar = require('@/assets/plugins/fullcalendar/js/notification-custom-script.js');
    import VueNextSelect from 'vue-next-select'
    import Datepicker from '@vuepic/vue-datepicker';
    import { ref } from 'vue';
    import 'vue-cal/dist/vuecal.css'
    //Componentes Propios:
    export default {
        name: 'agregar_evento',
        components:{
            'vue-select': VueNextSelect,
            'Datepicker': Datepicker,
        },
    setup() {
        const date = ref(new Date());
            function formatDate(userDate) {
            // format from M/D/YYYY to YYYYMMDD
            return (new Date(userDate).toJSON().slice(0,10).split('-').reverse().join('-'));
            }
            const format = (date) => {
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
            const startTime = ref({ hours: 0, minutes: 0 });
            const first_time = ref({
                hours: new Date().getHours()+1,
                minutes: 0
            });
            const last_time = ref({
                hours: new Date().getHours()+2,
                minutes: 0
            });
            //const today_date = ref(new Date().toISOString().split('T')[0]);
            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            var today_is = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
            //var today_dat = new Date().toLocaleString("en-US", {timeZone: "America/New_York"})
            //console.log("TIEMPO: "+ formatDate(today_date));
            var today_date = formatDate(today_is);
            //
            return {
                date,
                format,
                first_time,
                last_time,
                startTime,
                today_date,
            }
    },
    data() {
        return{
            URL_TENANT: '',
            evento_añadido: false,
            modalShow: true,
            uniqueModal:null,
            selectedEvent: {},
            showDialog: false,
            OpenClose:this.visible,
            clientesData: [],
            categoriasData: [],
            coloresData: [],
            events: [],
            clases_css: [],
            selectedColor: '',
            cla: 'deportes',
            eventoNuevo: {
            },
            empresaData: [0],
            usuariosData: [0],
            }
        },
    computed: {
    },
    watch: {
        eventoNuevo: {
            handler: function () {
                if(this.eventoNuevo.cliente.length > 1 ){
                    this.eventoNuevo.cliente.shift();
                }
                if(this.eventoNuevo.categoria.length > 1){
                    this.eventoNuevo.categoria.shift();
                }
                // Fecha Inicio es Mayor que Fecha Fin:
                var parts_inicio = this.eventoNuevo.inicio.split('-');
                var f_inicio = new Date(parts_inicio[2], parts_inicio[1] -1, parts_inicio[0]);
                var parts_fin = this.eventoNuevo.fin.split('-');
                var f_fin = new Date(parts_fin[2], parts_fin[1] -1, parts_fin[0]);
                if(f_inicio > f_fin){
                    this.eventoNuevo.fin = this.eventoNuevo.inicio;
                }
                // Hora Inicio es mayor que Hora Fin:
                var h_ini = this.eventoNuevo.inicio_tiempo.hours;
                var h_fin = this.eventoNuevo.fin_tiempo.hours;
                var m_ini = this.eventoNuevo.inicio_tiempo.minutes;
                var m_fin = this.eventoNuevo.fin_tiempo.minutes;
                if(h_ini >= h_fin){
                    if(m_ini >= m_fin){
                        this.eventoNuevo.fin_tiempo = this.eventoNuevo.inicio_tiempo;
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        async limpiarInputs(){
            this.eventoNuevo = {
                titulo: '',
                inicio: this.today_date,
                inicio_tiempo: this.first_time,
                fin: this.today_date,
                fin_tiempo: this.last_time,
                descripcion: '',
                estado: 'A',
                cliente: '',
                categoria: '',
                color: '',
            }
            this.getColoresData();
            this.evento_añadido = false;

        },
        async agregarEventoNuevo(){
            if (!this.eventoNuevo.inicio  || !this.eventoNuevo.inicio_tiempo || !this.eventoNuevo.fin  || !this.eventoNuevo.fin_tiempo || !this.eventoNuevo.titulo ){
                notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
            }else{
                try{
                    let inicio_ing = this.eventoNuevo.inicio.concat(" ", this.eventoNuevo.inicio_tiempo.hours, ":",this.eventoNuevo.inicio_tiempo.minutes);
                    let fin_ing = this.eventoNuevo.fin.concat(" ", this.eventoNuevo.fin_tiempo.hours, ":",this.eventoNuevo.fin_tiempo.minutes);
                    const formData = new FormData();
                    formData.append("inicio", inicio_ing);
                    formData.append("fin", fin_ing);
                    formData.append("titulo", this.eventoNuevo.titulo);
                    formData.append("descripcion", this.eventoNuevo.descripcion);
                    formData.append("estado", this.eventoNuevo.estado);
                    if(this.eventoNuevo.cliente){formData.append("cliente", this.eventoNuevo.cliente[0]);}
                    if(this.eventoNuevo.categoria){formData.append("categoria", this.eventoNuevo.categoria[0]);}
                    formData.append("color", this.eventoNuevo.color);
                    await fetch(this.URL_TENANT+'/api/evento/',{
                    method: 'post',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: formData})
                notificaciones.round_success_noti($.msg='Nuevo Evento Agregado');
                //document.getElementById('agregarModal').click();
                document.getElementById('close').click();
                this.getEventsData();
                this.evento_añadido = true;
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
            }
        },
        async SeleccionarColor(color){
            this.selectedColor = color;
            this.eventoNuevo.color = color;
        },
        onEventClick (event, e) {
            this.uniqueModal = new Modal(document.getElementById("detalleEvento"),{ keyboard: false });
            this.uniqueModal.show();
            this.selectedEvent = event;
            this.showDialog = true;
            // Prevent navigating to narrower view (default vue-cal behavior).
            e.stopPropagation()
        },
        async getEventsData() {
            const response = await fetch(this.URL_TENANT+'/api/evento_lista/');
            this.events = await response.json();
            this.events.forEach(object => {
                object.class = object.color;
            });
            const response2 = await fetch(this.URL_TENANT+'/api/evento_categoria');
            this.clases_css = await response2.json();
        },
        async getClientesData() {
            const response = await fetch(this.URL_TENANT+'/api/cliente/');
            this.clientesData = await response.json();
            console.log(this.clientesData);
        },
        async getCategoriasData() {
            const response = await fetch(this.URL_TENANT+'/api/evento_categoria/');
            this.categoriasData = await response.json();
        },
        async getColoresData() {
            const response = await fetch(this.URL_TENANT+'/api/global_colores/');
            this.coloresData = await response.json();
            const random = Math.floor(Math.random() * this.coloresData.length);
            this.selectedColor =  await this.coloresData[random].color;
            this.eventoNuevo.color =  await this.coloresData[random].color;
        },
        },
    mounted() {
            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', 'https://unpkg.com/vue-next-select/dist/vue-next-select.iife.prod.js')
            document.head.appendChild(recaptchaScript)
        },
    created() {
          // Fetch tasks on page load
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.limpiarInputs();
        this.getEventsData();
        this.getClientesData();
        this.getCategoriasData();
        this.getColoresData();
        },
    }

    </script>
    <style>
    body, html {
        font-family: Roboto,Helvetica,Arial,sans-serif;
    }
    /*
    .vuecal__event.leisure {background-color: rgba(253, 156, 66, 0.9);border: 1px solid rgb(233, 136, 46);color: #fff;}
    .vuecal__event.sport {background-color: rgba(255, 102, 102, 0.9);border: 1px solid rgb(235, 82, 82);color: #fff;}*/
    
    
    .dot {
        height: 16px;
        width: 16px;
        background-color: v-bind('selectedEvent.class');;
        border-radius: 50%;
        display: inline-block;
    }
    .dot2 {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        display: inline-block;
    }
    .vuecal__event {cursor: pointer;
        left: calc((100% - 0px) * 0 + 0px);
        width: calc((100% - 0px) * 1);
        position: relative;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background-color: var(--surface);
        margin-top: 0px;
        outline: none;
    }
        .vuecal__event-title {
        font-size: 13px;
        margin: 1px 0 0px;
        padding-left: 12px;
        }
    
        .vuecal__event-time {
        display: inline-block;
        margin-bottom: 12px;
        padding-bottom: 12px;
        padding-left: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
        .vuecal__cell {text-align: left;padding: 4px;}
        .vuecal__event-content {
        font-style: italic;
        }
    </style>