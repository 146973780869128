
<template>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Usuario</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="usernameNuevoUsuario"  class="form-control" v-model="medico.username"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Contraseña</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="password" placeholder="Escriba una Nueva Contraseña si Desea Cambiarla" id="usernameNuevoContraseña"  class="form-control" v-model="medico.password"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Nombre</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="nombreUsuario"  class="form-control" v-model="medico.first_name"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Apellido</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="apellidoUsuario" class="form-control" v-model="medico.last_name" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Correo</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="email" id="correoUsuario" class="form-control" v-model="medico.email" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Es Activo</h6>
                        </div>
                        <div class="col-sm-2">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="medico.is_active">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Es Super Usuario</h6>
                        </div>
                        <div class="col-sm-2">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="medico.is_superuser">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Teléfono Casa</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="telefonoUsuario" class="form-control" v-model="medico.telefono" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Celular</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="celularUsuario" class="form-control" v-model="medico.celular" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Dirección</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="direccionUsuario" class="form-control" v-model="medico.direccion" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Imágen</h6>
                        </div>
                        <div class="col-sm-1" v-if="medico.imagen">
                            <div class="input-group mb-3">
                                <img :src="medico.imagen" class="user-img" alt="Imagen Médico">
                            </div>
                        </div>
                        <div class="col-sm-8">
                            <div class="input-group mb-3">
                                <label class="input-group-text" for="inputGroupFile01">Subir</label>
                                <input type="file" class="form-control" id="imagenDoctorMod">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Colegiado</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="direccionUsuario" class="form-control" v-model="medico.colegiado" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Especialidades</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="direccionUsuario" class="form-control" v-model="medico.especialidades" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Observaciones</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="direccionUsuario" class="form-control" v-model="medico.observaciones" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Consultorio</h6>
                        </div>
                        <div class="col-sm-9">
                            <vue-select
                                :options="consultoriosData"
                                v-model="medico.consultorio"
                                label-by="nombre"
                                track-by="nombre"
                                value-by="id"
                                search-placeholder="Selecciona un Consultorio"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Consultorio"
                                close-on-select>
                                <template #label="{ selected }">
                                <template v-if="selected">
                                    <div style="color:white;">{{ selected.nombre }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Consultorio</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.nombre }}</div>
                                </template>
                            </vue-select>
                        </div>
                    </div>
        <!-- End Modal -->
    </template>
    <style>
    </style>
    <script>
    let notificaciones = require('@/assets/plugins/notifications//js/notification-custom-script.js');
    import $ from 'jquery';
    import  Cookies from 'js-cookie';
    import VueNextSelect from 'vue-next-select'
    export default {
        props: ['id_medico'],
        name: 'modificar_medico_component',
        components:{
            'vue-select': VueNextSelect,
        },
    data() {
        return{
            modalShow: true,
            medico_modificado: false,
            URL_TENANT: '',
            medico:  {
                username: "",
                password: "",
                first_name: "",
                last_name: "",
                email: "",
                is_staff: "",
                is_active: "",
                is_superuser: "",
                telefono: "",
                celular: "",
                imagen: "",
                direccion: "",
                colegiado: "",
                especialidades: "",
                observaciones: "",
                consultorio: []
                },
            consultoriosData: [],
            }
        },
        watch: {
            id_medico:{
            handler: function(){
                this.getMedicoData();
                this.medico_modificado=false;
            }
        },
        'medico.consultorio':{
                handler: function () {
                    if(this.medico.consultorio.length > 1){
                        this.medico.consultorio.shift();
                    }
                }
            },
        },
        methods: {
            async getConsultoriosData(){
                const response = await fetch(this.URL_TENANT+'/api/consultorio/')
                this.consultoriosData = await response.json();
            },
            async getMedicoData(){
                await fetch(this.URL_TENANT+'/api/medico_get/'+this.id_medico+'/')
                .then(response => response.json())
                .then(json => this.medico_ = json);
                var consul = this.medico_.consultorio || "";
                if(consul != ""){consul = [consul]}
                this.medico = {
                    username: this.medico_.username,
                    password: "",
                    first_name: this.medico_.first_name,
                    last_name: this.medico_.last_name,
                    email: this.medico_.email,
                    is_active: this.medico_.is_active,
                    is_superuser: this.medico_.is_superuser,
                    telefono: this.medico_.telefono,
                    imagen: this.medico_.imagen,
                    celular: this.medico_.celular,
                    direccion: this.medico_.direccion,
                    colegiado: this.medico_.colegiado,
                    especialidades: this.medico_.especialidades,
                    observaciones: this.medico_.observaciones,
                    consultorio: consul
                }
                console.log("GET DATA MEDICO");
            },
            //Modificar Médico:
            async modificarMedico(){
                if(!this.medico.username || !this.medico.first_name){
                    notificaciones.round_error_noti($.msg='Usuario y Nombre son Obligatorios');
                }else{
                    try{
                        const imagenMod = document.getElementById("imagenDoctorMod");
                        const formData = new FormData();
                        formData.append("username", this.medico.username);
                        formData.append("password", this.medico.password);
                        formData.append("first_name", this.medico.first_name);
                        formData.append("last_name", this.medico.last_name);
                        formData.append("email", this.medico.email);
                        formData.append("is_active", this.medico.is_active);
                        formData.append("is_superuser", this.medico.is_superuser);
                        formData.append("telefono", this.medico.telefono);
                        formData.append("celular", this.medico.celular);
                        formData.append("direccion", this.medico.direccion);
                        formData.append("colegiado", this.medico.colegiado);
                        formData.append("especialidades", this.medico.especialidades);
                        console.log("CONSULTORIO");
                        console.log(this.medico.consultorio);
                        console.log(this.medico.consultorio.length);
                        if(this.medico.consultorio != ""){
                            console.log("CON CONSULTORIO");
                            formData.append("consultorio", this.medico.consultorio[0]);
                        }else{
                            console.log("CONSULTORIO VACÍO");
                            formData.append("consultorio", '');
                        }
                        formData.append("observaciones", this.medico.observaciones);
                        if(!this.medico.password){
                            console.log("CON PASSWORD");
                            formData.append("password", this.medico.password);
                        }
                        console.log("passs pass");
                        console.log(imagenMod);
                        if(imagenMod.files.length == 0){
                            console.log("CON IMAGEN");

                            formData.append("imagen", '')
                        }else{
                            console.log("SIN IMAGEN");

                            formData.append("imagen", imagenMod.files[0]);
                        }
                        console.log("CASI");
                        await fetch(this.URL_TENANT+'/api/medico_create/'+this.id_medico+'/',{
                            method: 'PUT',
                            headers: {
                                'X-CSRFToken': Cookies.get('csrftoken'),
                                },
                            body: formData})
                        console.log("ERR");

                        notificaciones.round_success_noti($.msg='Médico Modificado Exitosamente');
                        this.medico_modificado = true;
                        document.getElementById('close').click();
                    }catch(error){
                        notificaciones.round_error_noti($.msg='Error, algo salió mal');
                    }
                }
            },
        },
        mounted() {
            },
        created() {
            const result = window.location.origin;
            this.URL_TENANT = result;
            this.getConsultoriosData();
        },
    }
    </script>