<template>
      <Header/>
      <Nav/>
      <DetalleDevolucion/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import DetalleDevolucion from '../../components/inventario/devolucion/detalle_devolucion.vue'



export default {
  name: 'DetalleDevolucionComponent',

  components: {
    Header,
    Nav,
    DetalleDevolucion,
  },

  data: () => ({
    //
  }),
}
</script>
