
<template>
<div class="page-wrapper">
			<div class="page-content">
				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Usuario</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Perfil</li>
							</ol>
						</nav>
					</div>
				</div>
				<!--end breadcrumb-->
				<div class="container" id="kc-app" >
					<div class="main-body">
						<div class="row">
							<div class="col-lg-4">
								<div class="card">
									<div class="card-body">
										<div class="d-flex flex-column align-items-center text-center">
											<img v-if="usuarioData[0].imagen" :src="usuarioData[0].imagen" alt="Admin" class="rounded-circle p-1 bg-primary" width="110">
											<div class="mt-3" :key="usuarioData[0].id"> 
												<h4 v-if="usuarioData[0].first_name && usuarioData[0].last_name">{{usuarioData[0].first_name}} {{ usuarioData[0].last_name }}</h4>
												<p class="mb-1" v-if="usuarioData[0].puesto_nombre">{{usuarioData[0].puesto_nombre}}</p>
												<p class="font-size-sm" v-if="usuarioData[0].departamento_nombre">{{usuarioData[0].departamento_nombre}}</p>
												<a href="/empresa/cambiar_contraseña/" class="btn btn-light">Cambiar Contraseña</a>&nbsp;
												<input  @change="updateImage" type="file" id="inputImagenCambiar" name="file" style="display: none;" required>
												
												<a><label  data-bs-toggle="modal" data-bs-target="#cambiarImagen" class="btn btn-light">Cambiar Imagen</label></a>
												<!--<a><label for="inputImagenCambiar" id="labelarchivo" class="btn btn-light">Cambiar Imagen</label></a>-->

											</div>
										</div>
										<hr class="my-4" />
										<ul class="list-group list-group-flush" v-if="[[usuarioData[0].is_superuser]] == 'true'">
											
										</ul>
									</div>
								</div>
							</div>
							<div class="col-lg-8">
								<div class="card">
									<div class="card-body">
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Nombre</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="nombreUsuario"  class="form-control" v-model="nombreUsuario"  />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Apellido</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="apellidoUsuario" class="form-control" v-model="apellidoUsuario" />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Correo</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="correoUsuario" class="form-control" v-model="correoUsuario" />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Teléfono Casa</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="telefonoUsuario" class="form-control" v-model="telefonoUsuario" />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Celular</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="celularUsuario" class="form-control" v-model="celularUsuario" />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Dirección</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="direccionUsuario" class="form-control" v-model="direccionUsuario" />
											</div>
										</div>
										<div class="row">
											<div class="col-sm-3"></div>
											<div class="col-sm-9">
												<input type="submit" @click="updateData" class="btn btn-light px-4" value="Guardar Cambios" />
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-12">
										<div class="card">
											<div class="card-body">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
<!-- Modal -->
		<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="cambiarImagen" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content bg-dark">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Cambiar Imágen</h5>
                        <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
						<cropper :stencil-component="$options.components.CircleStencil" ref="cropper" class="cropper" @change="change" :src="image.src" />
						<div class="button-wrapper">
							<input @change="uploadImage($event)" type="file" id="inputImagenCambiar2" name="file" style="display: none;" required>
							<a><label  for="inputImagenCambiar2" class="btn btn-light">Seleccionar Imágen</label></a>
						</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" @click="cropImage()" data-bs-dismiss="modal" class="btn btn-primary">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal -->
</template>

<script>

import  Cookies from 'js-cookie';
import $ from 'jquery';
/* eslint-disable vue/no-unused-components */
import { CircleStencil, Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
//import * as mykey from '../assets/js/mykey.js'
console.log(Cookies.get());
export default {
    name: 'PerfilComponent',
	components: {
		Cropper,
		CircleStencil,
	},
data() {
	return{
		usuarioData: [0],
		nombreUsuario: '',
		apellidoUsuario: '',
		correoUsuario: '',
		telefonoUsuario: '',
		celularUsuario: '',
		direccionUsuario: '',
		URL_TENANT: '',
		image: {
				src: null,
				type: null
			}
		}
	},
	unmounted() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
		URL.revokeObjectURL(this.image.src);
    }
	},
methods: {
	change(result) {
		console.log(result);
	},
	cropImage() {
		const { canvas } = this.$refs.cropper.getResult();
		canvas.toBlob((blob) => {
			const file = new File([blob], "cropped-image.jpeg", { type: "image/jpeg" });
			this.updateImage(file);
			}, "image/jpeg");
		},
	uploadImage(event) {
		/// Reference to the DOM input element
		const { files } = event.target;
		// Ensure that you have a file before attempting to read it
		if (files && files[0]) {
			// 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
			if (this.image.src) {
			URL.revokeObjectURL(this.image.src);
			}
			// 2. Create the blob link to the file to optimize performance:
			const blob = URL.createObjectURL(files[0]);

			// 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
			this.image = {
			src: blob,
			type: files[0].type,
			};
		}
    },
	async getusuarioData() {
        const response = await fetch(this.URL_TENANT+'/api/user/');
        this.usuarioData = await response.json();
		this.nombreUsuario = this.usuarioData[0].first_name;
		this.apellidoUsuario = this.usuarioData[0].last_name;
		this.direccionUsuario = this.usuarioData[0].direccion;
		this.telefonoUsuario = this.usuarioData[0].telefono;
		this.celularUsuario = this.usuarioData[0].celular;
		this.correoUsuario = this.usuarioData[0].email;


        //console.log(usuarioData[0]);
    },
	async updateImage(img){
		console.log("CAMBIANDO IMAGEN");
		try{
			//const imagenUsuario = document.getElementById("inputImagenCambiar");
			const formData = new FormData();
			formData.append("username", this.usuarioData[0].username,);
            formData.append("first_name", this.nombreUsuario);
            formData.append("last_name", this.apellidoUsuario);
            formData.append("email", this.correoUsuario);
            formData.append("is_staff", this.usuarioData[0].is_staff);
            formData.append("is_active", this.usuarioData[0].is_active);
            formData.append("is_superuser", this.usuarioData[0].is_superuser);
            formData.append("telefono", this.telefonoUsuario);
            formData.append("celular", this.celularUsuario);
            formData.append("direccion", this.direccionUsuario);
            formData.append("puesto", this.usuarioData[0].puesto);
            formData.append("empresa", this.usuarioData[0].empresa);
            formData.append("puesto_nombre", '');
            formData.append("departamento_nombre", '');
			formData.append("imagen", img);
			console.log("DATOS USER");
			for (const value of formData.values()){
			console.log(value);
			}
			await fetch(this.URL_TENANT+'/api/user_img/'+this.usuarioData[0].id+'/',{
			method: 'put',
			headers: {
				'X-CSRFToken': Cookies.get('csrftoken'),
			},body: formData})
            notificaciones.round_success_noti($.msg='La Imagen Ha Sido Cambiada');
            this.getusuarioData();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }

	},
	async updateData() {
    console.log("UPDATE");

    try{
		console.log("CSRF");
		console.log(this.nombreUsuario);
        await fetch(this.URL_TENANT+'/api/user_update/'+this.usuarioData[0].id+'/',{
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
        },
        body: JSON.stringify({
			username: this.usuarioData[0].username,
            first_name: this.nombreUsuario,
            last_name: this.apellidoUsuario,
            email: this.correoUsuario,
			is_staff: this.usuarioData[0].is_staff,
			is_active: this.usuarioData[0].is_active,
			is_superuser: this.usuarioData[0].is_superuser,
            telefono: this.telefonoUsuario,
            celular: this.celularUsuario,
            direccion: this.direccionUsuario,
            puesto_nombre: this.usuarioData[0].puesto_nombre,
            departamento_nombre: this.usuarioData[0].departamento_nombre,
            empresa: this.usuarioData[0].empresa,
            puesto: this.usuarioData[0].puesto,
        })
        }
        );
        await this.getusuarioData();
        notificaciones.round_success_noti($.msg='Los datos de usuario han sido modificados');

    }catch(error){
        console.log(error);
        //round_error_noti(msg='Error, algo salió mal');
    }
    },
},
created() {
    // Fetch tasks on page load
	const result = window.location.origin;
    this.URL_TENANT = result;
	this.getusuarioData();
	}
}
</script>
