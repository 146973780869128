<template>
      <Header/>
      <Nav/>
      <Productos/>


</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'
import Productos from './components/catalogo/producto/producto.vue'




export default {
  name: 'ProductosComponent',

  components: {
    Header,
    Nav,
    Productos,
  },

  data: () => ({
    //
  }),
}
</script>
