
<template>
    <div class="modal-body d-flex flex-column">
    <!-- Start Plane -->
    <div class="body-body-m">
        <div class="plane" >
            <div class="cabin" :style="{ width: cabinWidth, height: cabinHeight }">
                <div v-for="(row, i) in seats" :key="i" class="seat-row">
                    <div v-for="seat in row" :key="seat.codigo" class="seat" :class="{ habilitado: seat.habilitado }">
                        <label class="label-s" :for="seat.codigo">
                            <input :required="seat.reservado == true" @click="reserveSeat(seat.codigo);" :disabled="seat.estado == 'No Disponible' || seat.estado == 'Reservado'" :id="seat.codigo" :indeterminate="seat.estado == 'Reservado'" type="checkbox" class="seat-check visuallyhidden" :checked="seat.habilitado"  />
                            <span class="seat-label">{{ seat.number }}</span>
                            <div class="aisle">
                                    <span class="aisle-number">{{seat.codigo}}</span>
                            </div>
                        </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- End Plane -->
    </div>
    </template>
    <style lang="less">
    </style>
    <script>
    import  Cookies from 'js-cookie';
    import $ from 'jquery';
    let notificaciones = require('@/assets/plugins/notifications//js/notification-custom-script.js');
        export default {
            props: {
                viaje_asientos: {
                type: Object,
                required: true
                }
            },
            //props: ['id_vehiculo', 'filas', 'asientos_fila'],
            name: 'asientos_component',
            components:{
            //'vue-select': VueNextSelect,
            },
        data() {
            return{
                modalShow: true,
                asientos_seleccionados: false,
                tiposVehiculosData: [],
                numFilas: 0,
            numColumnas: 0,
            seats: [],
            reserved: [],
            usuarioData: '',
            asientos_reservados: [],
            numSeats:this.viaje_asientos.asientos_fila,
            numFiles:this.viaje_asientos.filas,
                URL_TENANT: '',
                asientos_modificados: false,
                vehiculo:  {
                    tipo: "",
                    modelo: "",
                    año: "",
                    capacidad_pasajeros: "",
                    descripcion: "",
                    estado: "",
                    },
                }
            },
            watch: {
                viaje_asientos:{
                handler: function(){
                    this.seats = [];
                    this.numFiles = this.viaje_asientos.filas,
                    this.generarArray();
                    this.asientos_modificados=false;

                },
                deep: true
            },
            },
    computed: {
        cabinWidth() {
            return this.viaje_asientos.asientos_fila * 70 + 'px'; // 60 es la anchura de cada asiento
        },
        cabinHeight() {
            return this.viaje_asientos.filas * 70 + 'px'; // 60 es la anchura de cada asiento
        }
    },
    methods: {
        async getUsuarioData() {
        const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/cliente_log/');
        this.usuarioData = await response.json();
            //console.log(userData);
        },
        async reservarAsientos(){
            const formData = new FormData();
            const arrayReservados = [];
            for (let i = 0; i < this.asientos_reservados.length; i++) {
                formData.append("viaje", this.viaje_asientos.id);
                formData.append("asiento", this.asientos_reservados[i].id);
                formData.append("cliente", this.usuarioData[0].usuario);
                const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/ticket_abordaje/',{
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                        },
                    body: formData})
                    const data = await response.json();
                    arrayReservados.push(data.id);
                console.log("AGREGAR");

                notificaciones.round_success_noti($.msg='Asiento '+this.asientos_reservados[i].codigo+' Reservado Exitosamente');
            }
            console.log("IMPRESIÓN DE TICKETS:");
            console.log(arrayReservados);
            const url = this.URL_TENANT+'/agencia_viaje/generar_ticket/'+arrayReservados+'/';

            window.open(url, '_blank');
            this.asientos_seleccionados = true;
            this.asientos_reservados = [];

        },
        reserveSeat(id) {
            console.log("ID: "+id);
            const seat = this.seats.flat().find(s => s.codigo === id);
            console.log(seat);
            seat.habilitado === false ? seat.habilitado = true : seat.habilitado = false;
            seat.habilitado === true ? (seat.reservado = false, this.asientos_reservados = this.asientos_reservados.filter(asiento => asiento.id !== seat.id)) : (seat.reservado = true,this.asientos_reservados.push(seat));
        },
        async listaReservados() {
            const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/ticket_abordaje/?viaje='+this.viaje_asientos.id);
            this.reserved = await response.json();
            console.log("RESERVADOOOOS");
            console.log(this.reserved);
            for (let i = 0; i < this.reserved.length; i++) {
                for (let j = 0; j < this.seats.length; j++) {
                    for (let k = 0; k < this.seats[j].length; k++) {
                        console.log("seats:"+this.seats[j][k].id + '---'+this.reserved[i].asiento);
                    if (this.reserved[i].asiento === this.seats[j][k].id) {
                        console.log("RESERVADO................................................................");
                        this.seats[j][k].estado = "Reservado";
                    }
                    }
                }
            }
        },
        async generarArray() {
            this.asientos_seleccionados = false;
            console.log("ASIENTOS: ");
            const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/asiento/?vehiculo='+this.viaje_asientos.vehiculo);
            const _seats = await response.json();
            console.log(_seats);
            let cont = 0;
            for(let i=0; i<this.viaje_asientos.filas; i++){
                const filaArray = [];
                for(let j=0; j<this.viaje_asientos.asientos_fila; j++){
                    filaArray.push({
                        id: _seats[cont].id,
                        codigo: _seats[cont].codigo,
                        asiento: _seats[cont].asiento,
                        vehiculo: _seats[cont].vehiculo,
                        habilitado: _seats[cont].habilitado,
                        estado: _seats[cont].estado
                    });
                    cont = cont+1;
                }
                this.seats.push(filaArray);
            }
            console.log(this.seats);
            this.contarAsientos();
            this.listaReservados();
        },
        contarAsientos(){
            if(!this.seats.length === 0){
                this.numSeats = this.seats[0].length;
                this.numFiles = this.seats.length;
            }
        },
    },
            mounted() {
                },
            created() {
                const result = window.location.origin;
                this.URL_TENANT = result;
                this.getUsuarioData();
            },
        }
        </script>