
<template>
<div class="page-wrapper">
			<div class="page-content">
				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Empresa</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Puestos</li>
							</ol>
						</nav>
					</div>
				</div>
				<!--end breadcrumb-->

				<div class="card">
					<div class="card-body">
						<div class="d-lg-flex align-items-center mb-4 gap-3">
							<div class="position-relative">
								<input autofocus type="text" class="form-control ps-5 radius-30" v-model="buscarPuesto" placeholder="Buscar Puesto"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
							</div>
						<div class="ms-auto"><a href="javascript:;" @click="cleanAñadirData" data-bs-toggle="modal" data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Añadir Nuevo Puesto</a></div>
						</div>
                        <!-- Button trigger modal -->
						<!-- Modal -->
						<div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-lg">
								<div class="modal-content bg-dark">
									<div class="modal-header">
										<h5 class="modal-title" id="exampleModalLabel">Añadir Puesto</h5>
										<button type="button" class="btn-close" id="close_puesto" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Nombre Puesto</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="nombreNuevoPuesto"  class="form-control" v-model="nombreNuevoPuesto"  />
											</div>
                                            </div>
                                            <div class="row mb-3">
                                            <div class="col-sm-3">
												<h6 class="mb-0">Departamento</h6>
											</div>
											<div class="col-sm-9">
									<vue-select  :options="departamentosData" 
                                        v-model="selectedDepartamento"
                                        label-by="nombre"
                                        track-by="nombre"
                                        value-by="id"
                                        search-placeholder="Selecciona un Departamento"
                                        multiple
										collapse-tags="true"
										taggable
										searchable
                                        placeholder="Selecciona un Departamento"
                                        close-on-select> 
                                        <template #label="{ multiple }">
                                        <template v-if="multiple">
                                        <div style="color:white;">{{ multiple.nombre }}</div>
                                        </template>
                                        <template v-else > <div style="color:white;">Seleccione un Departamento</div></template>
                                        </template>
                                        <template #dropdown-item="{ option }">
                                        <div style="color:white;">{{ option.nombre }}</div>
                                        </template>
                                    </vue-select>
											</div>
										</div>
                                    </div>
									<div class="modal-footer">
										<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
										<button type="button" @click="agregarNuevoPuesto" class="btn btn-primary">Guardar</button>
									</div>
								</div>
							</div>
						</div>
						<!-- End Modal -->
						<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th><h6>Puesto</h6></th>
										<th><h6>Departamento</h6></th>
										<th><h6>Estado</h6></th>
										<th><h6>Acciones</h6></th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in puestosDataFilter" :key="item.id">
										<td><h6>{{item.nombre}}</h6></td>
										<td><h6>{{item.departamento_nombre}}</h6></td>
										<td>
                                            <div class="badge rounded-pill bg-light p-2 text-uppercase px-3">
                                                <div v-if="[[item.estado]] == 'I'"> <i  class='bx bx-minus me-1'></i>Inactivo</div>
                                                <div v-if="[[item.estado]] == 'A'"> <i  class='bx bx-circle me-1'></i>Activo</div>
                                            </div>
                                        </td>
										<td>
											<div class="d-flex order-actions">
												<a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modificarModal" @click="fillModificarPuesto([[item.id]])" class=""><i class='bx bxs-edit'></i></a>
												<a href="javascript:;" class="ms-3"><i class='bx bxs-trash'></i></a>
											</div>
										</td>
									</tr>


								</tbody>
							</table>
						</div>
					</div>
				</div>


			</div>
		</div>
        <!-- Modal Modify -->
		<div class="modal fade"  id="modificarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content bg-dark">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">Modificar Puesto</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" id="close_mod" aria-label="Close"></button>
					</div>
					<div class="modal-body">
                        <div class="row mb-3">
							<div class="col-sm-3">
								<h6 class="mb-0">Puesto</h6>
							</div>
							<div class="col-sm-9">
								<input type="text" id="nombreNuevoPuesto"  class="form-control" v-model="nombreNuevoPuesto"  />
							</div>
						</div>
                    </div>
					<div class="modal-body">
                                    <div class="row mb-3">
							<div class="col-sm-3">
								<h6 class="mb-0">Estado</h6>
							</div>
                            <div class="col-sm-9">
                                <vue-select   :options="estados"
                                    v-model="selectedEstado"
                                    label-by="nombre"
                                    hide-selected="true"
                                    track-by="nombre"
                                    value-by="id"
                                    placeholder="Selecciona un Estado"
                                    close-on-select>
                                    <template #label="{ selected }">
                                    <template v-if="selected">
                                        <div style="color:white;">{{ selected.nombre }}</div>
                                    </template>
                                    <template v-else > <div style="color:white;">Seleccione un Estado</div></template>
                                    </template>
                                    <template #dropdown-item="{ option }" >
                                    <div  style="color:white;">{{ option.nombre }}</div>
                                    </template>
                                </vue-select>
							</div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
								<h6 class="mb-0">Departamento</h6>
							</div>
							<div class="col-sm-9">
                            <vue-select  :options="departamentosData" 
                                v-model="selectedDepartamento"
                                label-by="nombre"
                                track-by="nombre"
                                value-by="id"
                                search-placeholder="Selecciona un Departamento"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Departamento"
                                close-on-select> 
                                <template #label="{ multiple }">
                                <template v-if="multiple">
                                <div style="color:white;">{{ multiple.nombre }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Departamento</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.nombre }}</div>
                                </template>
                            </vue-select>
							</div>
						</div>
                    </div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
						<button type="button" @click="modificarPuesto" class="btn btn-primary">Guardar</button>
					</div>
				</div>
			</div>
		</div>
		<!-- End Modal Modify -->
</template>
<style>
</style>
<script>
import  Cookies from 'js-cookie';
//import Select2 from 'vue3-select2-component';
//import $ from 'jquery';
//let select2 = require('@/assets/plugins/select2/js/select2.min.js');
//import * as mykey from '../assets/js/mykey.js'
import $ from 'jquery';
import VueNextSelect from 'vue-next-select'
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')
export default {
    name: 'puesto_component',
    components:{
        'vue-select': VueNextSelect,
    },
data() {
	return{
        URL_TENANT: '',
        departamentosData: [],
        empresaData: [],
        puestosData: [],
		buscarPuesto: '',
        // PARA AGREGAR PUESTO:
        nombreNuevoPuesto: '',
        //nombreModificarPuesto: '',

        selectedDepartamento: [],
        //selectedModificarDepartamento: [],

        // PARA MODIFICAR DEPARTAMENTO:
        selectedEstado: null,
        //selectedModificarEstado: null,

        modificarId : null,
        estados: [
            {
                id: 'A',
                nombre: 'Activo',
            },
            {
                id: 'I',
                nombre: 'Inactivo',
            }
        ],
		}
	},
watch: {
    selectedDepartamento: {
        handler: function () {
            if(this.selectedDepartamento.length > 1 ){
                this.selectedDepartamento.shift();
            }
        },
        deep: true,
    },
},
computed: {
    puestosDataFilter() {
        if (this.buscarPuesto != ''){
            return this.puestosData.filter((item) => {
            return item.nombre.toLowerCase().includes(this.buscarPuesto.toLowerCase())
        })
        }else{
            return this.puestosData;
        }

    },
},
methods: {
    async cleanAñadirData() {
        console.log("CLEAN AÑADIR DATA");
        this.nombreNuevoPuesto = null;
    },
    async fillModificarPuesto(idMod) {
        console.log("ID MOD");
        console.log(idMod[0][0]);
        this.modificarId = idMod[0][0];
        for(var k in this.puestosData){
            if(this.puestosData[k].id==this.modificarId){
                console.log("ENTRO AQUI");
                this.nombreNuevoPuesto = this.puestosData[k].nombre;
                this.selectedEstado = this.puestosData[k].estado;
                //this.selectedModificarDepartamento[0] = this.puestosData[k].departamento;
                this.selectedDepartamento[0] = this.puestosData[k].departamento;
                console.log(this.puestosData[k].nombre);
                console.log(this.puestosData[k].estado);
                console.log(this.puestosData[k].departamento);

            }
        }
    },
    async modificarPuesto(){

        if (!this.selectedEstado  || !this.nombreNuevoPuesto || !this.modificarId ){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        }else{
            console.log("ESTAS UPDETEANDO");
            try{
                const formData = new FormData();
                formData.append("nombre", this.nombreNuevoPuesto);
                formData.append("estado", this.selectedEstado);
                formData.append("departamento", this.selectedDepartamento);

                await fetch(this.URL_TENANT+'/api/puesto/'+this.modificarId+'/',{
                method: 'put',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Puesto Modificado');
            document.getElementById('close_mod').click();
            this.getPuestosData();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }

        }
    },
    async agregarNuevoPuesto(){
        console.log("LENG");
        console.log(this.selectedDepartamento.length);
        if (!this.nombreNuevoPuesto || this.selectedDepartamento.length == 0){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        }else{
            try{
                const formData = new FormData();
                formData.append("nombre", this.nombreNuevoPuesto);
                formData.append("departamento", this.selectedDepartamento);

                await fetch(this.URL_TENANT+'/api/puesto/',{
                method: 'post',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Nuevo Departamento Agregado');
            document.getElementById('close_puesto').click();
            this.getPuestosData();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }
        }
    },
    async getEmpresaData() {
        const response = await fetch(this.URL_TENANT+'/api/empresa/');
        this.empresaData = await response.json();
    },
    async getDepartamentosData() {
        const response = await fetch(this.URL_TENANT+'/api/departamento/');
        this.departamentosData = await response.json();
    },
	async getPuestosData() {
        const response = await fetch(this.URL_TENANT+'/api/puesto/');
        this.puestosData = await response.json();
    },
    },
mounted() {
        /*let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://unpkg.com/vue-next-select/dist/vue-next-select.iife.prod.js')
        document.head.appendChild(recaptchaScript)*/
    },
created() {
    // al cargar la página:
    const result = window.location.origin;
    this.URL_TENANT = result;
    this.getDepartamentosData();
    this.getPuestosData();
    this.getEmpresaData();
	},
}
</script>