<template>
      <Header/>
      <Nav/>
      <MisViajes/>
</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import MisViajes from '../../components/agencia_viaje/viaje/mis_viajes.vue'



export default {
  name: 'MisViajeComponent',
  components: {
  Header,
	Nav,
	MisViajes,
  },

  data: () => ({
    //
  }),
}
</script>
