
<template>
    <button type="button" class="btn btn-light" @click="limpiarInputs()" data-bs-toggle="modal" data-bs-target="#agregarModal">Agregar Viaje</button>
    <!-- Modal -->
        <div class="modal fade"  v-if="modalShow" id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content bg-dark">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Agregar Viaje</h5>
                        <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Origen</h6>
                            </div>
                            <div class="col-sm-10">
                                <vue-select
                                    :options="lugaresData"
                                    @blur="removeObjectArray(viaje, 'origen')"
                                    v-model="viaje.origen"
                                    label-by="nombre"
                                    track-by="nombre"
                                    value-by="id"
                                    search-placeholder="Selecciona un Origen"
                                    multiple
                                    collapse-tags="true"
                                    taggable
                                    searchable
                                    placeholder="Selecciona un Origen"
                                    close-on-select>
                                    <template #label="{ selected }">
                                    <template v-if="selected">
                                        <div style="color:white;">{{ selected.nombre }}</div>
                                    </template>
                                    <template v-else > <div style="color:white;">Seleccione un Origen</div></template>
                                    </template>
                                    <template #dropdown-item="{ option }">
                                    <div style="color:white;">{{ option.nombre }}</div>
                                    </template>
                                </vue-select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Destino</h6>
                            </div>
                            <div class="col-sm-10">
                                <vue-select
                                    :options="lugaresData"
                                    @blur="removeObjectArray(viaje, 'destino')"
                                    v-model="viaje.destino"
                                    label-by="nombre"
                                    track-by="nombre"
                                    value-by="id"
                                    search-placeholder="Selecciona un Origen"
                                    multiple
                                    collapse-tags="true"
                                    taggable
                                    searchable
                                    placeholder="Selecciona un Destino"
                                    close-on-select>
                                    <template #label="{ selected }">
                                    <template v-if="selected">
                                        <div style="color:white;">{{ selected.nombre }}</div>
                                    </template>
                                    <template v-else > <div style="color:white;">Seleccione un Destino</div></template>
                                    </template>
                                    <template #dropdown-item="{ option }">
                                    <div style="color:white;">{{ option.nombre }}</div>
                                    </template>
                                </vue-select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Fecha Salida</h6>
                            </div>
                            <div class="col-sm-6">
                                <Datepicker
                                v-model="viaje.fecha_salida"
                                dark placeholder="Selecciona una Fecha"
                                autoApply
                                :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                :enableTimePicker="false"
                                monthNameFormat="long"
                                :format="format"
                                modelType="dd-MM-yyyy"
                                locale="es">
                                </Datepicker>
                            </div>
                            <div class="col-sm-2">
                                <h6 class="mb-0">Hora Salida</h6>
                            </div>
                            <div class="col-sm-2">
                                <Datepicker v-model="viaje.hora_salida" :startTime="startTime" timePicker dark  minutesIncrement="5" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Fecha Regreso</h6>
                            </div>
                            <div class="col-sm-6">
                                <Datepicker
                                v-model="viaje.fecha_regreso"
                                dark placeholder="Selecciona una Fecha"
                                autoApply
                                :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                :enableTimePicker="false"
                                monthNameFormat="long"
                                :format="format"
                                modelType="dd-MM-yyyy"
                                locale="es">
                                </Datepicker>
                            </div>
                            <div class="col-sm-2">
                                <h6 class="mb-0">Hora Regreso</h6>
                            </div>
                            <div class="col-sm-2">
                                <Datepicker v-model="viaje.hora_regreso" :startTime="last_time" timePicker dark  minutesIncrement="5" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Vehículo</h6>
                            </div>
                            <div class="col-sm-5">
                                <vue-select
                                    :options="vehiculosData"
                                    @blur="removeObjectArray(viaje, 'vehiculo')"
                                    v-model="viaje.vehiculo"
                                    label-by="tipo_modelo"
                                    track-by="tipo_modelo"
                                    value-by="id"
                                    search-placeholder="Selecciona un Vehículo"
                                    multiple
                                    collapse-tags="true"
                                    taggable
                                    searchable
                                    placeholder="Selecciona un Vehículo"
                                    close-on-select>
                                    <template #label="{ selected }">
                                    <template v-if="selected">
                                        <div style="color:white;">{{ selected.tipo_modelo }}</div>
                                    </template>
                                    <template v-else > <div style="color:white;">Seleccione un Vehículo</div></template>
                                    </template>
                                    <template #dropdown-item="{ option }">
                                    <div style="color:white;">{{ option.tipo_modelo }}</div>
                                    </template>
                                </vue-select>
                            </div>
                            <div class="col-sm-2">
                                <h6 class="mb-0">Piloto</h6>
                            </div>
                            <div class="col-sm-3">
                                <vue-select
                                    :options="pilotosData"
                                    @blur="removeObjectArray(viaje, 'piloto')"
                                    v-model="viaje.piloto"
                                    label-by="nombre"
                                    track-by="nombre"
                                    value-by="id"
                                    search-placeholder="Selecciona un Piloto"
                                    multiple
                                    collapse-tags="true"
                                    taggable
                                    searchable
                                    placeholder="Selecciona un Piloto"
                                    close-on-select>
                                    <template #label="{ selected }">
                                    <template v-if="selected">
                                        <div style="color:white;">{{ selected.nombre }}</div>
                                    </template>
                                    <template v-else > <div style="color:white;">Seleccione un Piloto</div></template>
                                    </template>
                                    <template #dropdown-item="{ option }">
                                    <div style="color:white;">{{ option.nombre }}</div>
                                    </template>
                                </vue-select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Tarifa</h6>
                            </div>
                            <div class="col-sm-5">
                                <input type="number"  class="form-control" v-model="viaje.tarifa"  />
                            </div>
                            <div class="col-sm-2">
                                <h6 class="mb-0">Estado</h6>
                            </div>
                            <div class="col-sm-3">
                                <vue-select
                                    :options="estadosData"
                                    @blur="removeObjectArray(viaje, 'estado')"
                                    v-model="viaje.estado"
                                    label-by="nombre"
                                    track-by="nombre"
                                    value-by="nombre"
                                    search-placeholder="Selecciona un Estado"
                                    multiple
                                    collapse-tags="true"
                                    taggable
                                    searchable
                                    placeholder="Selecciona un Estado"
                                    close-on-select>
                                    <template #label="{ selected }">
                                    <template v-if="selected">
                                        <div style="color:white;">{{ selected.nombre }}</div>
                                    </template>
                                    <template v-else > <div style="color:white;">Seleccione un Estado</div></template>
                                    </template>
                                    <template #dropdown-item="{ option }">
                                    <div style="color:white;">{{ option.nombre }}</div>
                                    </template>
                                </vue-select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Abordaje</h6>
                            </div>
                            <div class="col-sm-10">
                                <textarea  class="form-control"  v-model="viaje.abordaje" placeholder="¿Dónde será el abordaje?..." rows="3"></textarea>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-2">
                                <h6 class="mb-0">Descripcion</h6>
                            </div>
                            <div class="col-sm-10">
                                <textarea  class="form-control"  v-model="viaje.descripcion" placeholder="Agrega una Descripción..." rows="4"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" @click="agregarViaje()" class="btn btn-primary">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal -->
    </template>
    <style>
    </style>
    <script>
    let notificaciones = require('@/assets/plugins/notifications//js/notification-custom-script.js');
    import VueNextSelect from 'vue-next-select';
    import { ref } from 'vue';
    import Datepicker from '@vuepic/vue-datepicker';

    import $ from 'jquery';
    import  Cookies from 'js-cookie';
    export default {
        name: 'agregar_viaje_component',
        components:{
        'vue-select': VueNextSelect,
        'Datepicker': Datepicker,
        },
        setup() {
        const date = ref(new Date());
            const format = (date) => {
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
            const startTime = ref({ hours: 0, minutes: 0 });
            const first_time = ref({
                hours: new Date().getHours()+1,
                minutes: 0
            });
            const last_time = ref({
                hours: new Date().getHours()+2,
                minutes: 0
            });
            function formatDate(userDate) {
                // format from M/D/YYYY to YYYYMMDD
                return (new Date(userDate).toJSON().slice(0,10).split('-').reverse().join('-'));
                }
            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            var today_is = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
            var today_date = formatDate(today_is);
        return {
                startTime,
                first_time,
                last_time,
                date,
                format,
                today_date,
            }
    },
    data() {
        return{
            modalShow: true,
            viaje_añadido: false,
            nameError: [],
            lugaresData: [],
            vehiculosData: [],
            pilotosData: [],
            estadosData: [
                {nombre: "Disponible"},
                {nombre: "No Disponible"},
                {nombre: "Realizado"},
            ],
            URL_TENANT: '',
            viaje:  {
                origen: '',
                destino: '',
                fecha_salida: this.today_date,
                fecha_regreso: this.today_date,
                hora_salida: this.first_time,
                hora_regreso: this.last_time,
                vehiculo: '',
                tarifa: '',
                descripcion: '',
                abordaje: '',
                piloto: '',
                estado: ['Disponible']
                },
            }
        },
        watch: {
        viaje: {
            handler: function () {
                // Fecha Inicio es Mayor que Fecha Fin:
                var parts_inicio = this.viaje.fecha_salida.split('-');
                var f_inicio = new Date(parts_inicio[2], parts_inicio[1] -1, parts_inicio[0]);
                var parts_fin = this.viaje.fecha_regreso.split('-');
                var f_fin = new Date(parts_fin[2], parts_fin[1] -1, parts_fin[0]);
                if(f_inicio > f_fin){
                    this.viaje.fecha_regreso = this.viaje.fecha_salida;
                    notificaciones.round_error_noti($.msg='Fecha Regreso no puede ser menor a Fecha Salida');
                }
            },
            deep: true,
        },
    },
        methods: {
            //Validador:
            validateInputs(){
                this.nameError = (!this.viaje.tarifa) ? ['Debe de Ingresar la Tarifa'] : [];
                this.nameError = (!this.viaje.destino || this.viaje.destino.length === 0) ? [...this.nameError, 'Debe de Seleccionar el Destino'] : this.nameError;
                this.nameError = (!this.viaje.origen || this.viaje.origen.length === 0) ? [...this.nameError, 'Debe de Seleccionar el Origen'] : this.nameError;
                this.nameError = (!this.viaje.vehiculo || this.viaje.vehiculo.length === 0) ? [...this.nameError, 'Debe de Seleccionar el Vehículo'] : this.nameError;
                this.nameError = (!this.viaje.estado || this.viaje.estado.length === 0) ? [...this.nameError, 'Debe de Seleccionar el Estado'] : this.nameError;
            },
            //Quitar Objeto:
            async removeObjectArray(array, atributo) {
                //console.log("REMOVE ARRAY");
                if (array[atributo].length > 1) {
                    array[atributo].shift();
                }
            },
            async  limpiarInputs(){
                this.viaje_añadido = false;
                this.viaje =  {
                    origen: '',
                    destino: '',
                    fecha_salida: this.today_date,
                    fecha_regreso: this.today_date,
                    hora_salida: this.first_time,
                    hora_regreso: this.last_time,
                    vehiculo: '',
                    tarifa: '',
                    descripcion: '',
                    abordaje: '',
                    piloto: '',
                    estado: ['Disponible']
                }
            },
            async getlugaresData(){
                const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/lugares/');
                const _lugaresData = await response.json();
                this.lugaresData = _lugaresData.results;
            },
            async getVehiculosData(){
                const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/vehiculo/');
                const _vehiculosData = await response.json();
                this.vehiculosData = _vehiculosData.results;
            },
            async getPilotosData(){
                const response = await fetch(this.URL_TENANT+'/api/agencia_viaje/piloto/');
                const _pilotosData = await response.json();
                this.pilotosData = _pilotosData.results;
            },
            //Agregar Viaje:
            async agregarViaje(){
                this.validateInputs();
                if(this.nameError.length != 0){
                    console.log("NAME ERROR");
                    this.nameError.forEach((element) =>{
                        notificaciones.round_error_noti($.msg=element);
                    });
                }else{
                    try{
                        console.log("AGREGAR VIAJECT");
                        const formData = new FormData();
                        let _hora_salida = this.viaje.hora_salida.hours + ':' + this.viaje.hora_salida.minutes;
                        let _hora_regreso = this.viaje.hora_regreso.hours + ':' + this.viaje.hora_regreso.minutes;
                        formData.append("origen", this.viaje.origen[0]);
                        formData.append("destino", this.viaje.destino[0]);
                        formData.append("vehiculo", this.viaje.vehiculo[0]);
                        formData.append("estado", this.viaje.estado[0]);
                        formData.append("piloto", this.viaje.piloto == "" ? "": this.viaje.piloto[0]);
                        formData.append("abordaje", this.viaje.abordaje);
                        formData.append("descripcion", this.viaje.descripcion);
                        formData.append("fecha_salida", this.viaje.fecha_salida);
                        formData.append("hora_salida", _hora_salida);
                        formData.append("fecha_regreso", this.viaje.fecha_regreso);
                        formData.append("hora_regreso", _hora_regreso);
                        formData.append("tarifa", this.viaje.tarifa);
                        console.log("switfh");
                        await fetch(this.URL_TENANT+'/api/agencia_viaje/viaje/',{
                            method: 'POST',
                            headers: {
                                'X-CSRFToken': Cookies.get('csrftoken'),
                                },
                            body: formData})
                        console.log("AGREGAR");

                        notificaciones.round_success_noti($.msg='Viaje Creado Exitosamente');
                        document.getElementById('close').click();
                        this.viaje_añadido = true;
                    }catch(error){
                        console.log(error);
                        notificaciones.round_error_noti($.msg='Error, algo salió mal');
                    }
                }
            },
        },
        mounted() {
            },
        created() {
            const result = window.location.origin;
            this.URL_TENANT = result;
            this.getlugaresData();
            this.getPilotosData();
            this.getVehiculosData();
        },
    }
    </script>