
<template>
    <div class="page-wrapper">
        <div class="page-content">
            <!--breadcrumb-->
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Consultorio Virtual</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Consultorios</li>
                        </ol>
                    </nav>
                </div>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-light">Settings</button>
                        <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	<a class="dropdown-item" href="javascript:;">Action</a>
                            <a class="dropdown-item" href="javascript:;">Another action</a>
                            <a class="dropdown-item" href="javascript:;">Something else here</a>
                            <div class="dropdown-divider"></div>	<a class="dropdown-item" href="javascript:;">Separated link</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end breadcrumb-->
            <!-- MODAL MODIFICAR -->
            <div class="modal fade"  id="modificarModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content bg-dark">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Modificar Consultorio</h5>
                            <button type="button" id="closemod" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <mod_event :id_consultorio="id_consultorio" ref="child_mod"></mod_event>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button"  data-bs-dismiss="modal" @click="$refs.child_mod.modificarConsultorio(); " class="btn btn-success">Modificar</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->
            <div class="modal fade"  id="eliminarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content bg-dark">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Eliminar Consultorio</h5>
                            <button type="button" id="closedel" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <del_event :id_consultorio="id_consultorio" :titulo="titulo" ref="child_del"></del_event>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button"  data-bs-dismiss="modal" @click="$refs.child_del.eliminarConsultorio(); " class="btn btn-danger">Eliminar</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->
            <div class="card">
                <div class="card-body">
                    <div class="d-lg-flex align-items-center mb-4 gap-3">
                        <div class="position-relative">
                            <input type="text" class="form-control ps-5 radius-30" v-model="buscarConsultorio" placeholder="Buscar Consultorio"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                        </div>
                        <div class="ms-auto"><add_event ref="child_add"></add_event></div>
                    </div>
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th>Nombre</th>
                                    <th>Dirección</th>
                                    <th>Teléfono</th>
                                    <th>Correo</th>
                                    <th>Descripción</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in consultoriosDataFilter" :key="item.id">
                                    <td>{{item.nombre}}</td>
                                    <td>{{item.direccion}}</td>
                                    <td>{{item.telefono}}</td>
                                    <td>{{item.correo}}</td>
                                    <td>{{item.descripcion}}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modificarModal1" @click="id_consultorio=item.id;
                                            " class=""><i class='bx bxs-edit'></i></a>

                                            <a v-if="item.medico_consultorio.length ==0 && item.asistente_consultorio.length ==0" href="javascript:;" data-bs-toggle="modal" data-bs-target="#eliminarModal" @click="id_consultorio=item.id;
                                            titulo=item.nombre" class="ms-3"><i class='bx bxs-trash'></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>
<style>
</style>
<script>
import add_event from "./agregar_consultorio.vue"
import mod_event from "./modificar_consultorio.vue"
import del_event from "./eliminar_consultorio.vue"

export default {
    name: 'consultorio_component',
    components:{
        add_event,
        mod_event,
        del_event,
    },
data() {
    return{
        URL_TENANT: '',
        consultoriosData:  [],
        buscarConsultorio: '',
        id_consultorio: '',
        titulo: '',
        }
    },
    watch: {
},
    computed: {
        consultoriosDataFilter() {
        console.log("LENGT");
        if (this.buscarConsultorio != ''){
            return this.consultoriosData.filter((item) => {
            return item.nombre.toLowerCase().includes(this.buscarConsultorio.toLowerCase()) || item.direccion.toLowerCase().includes(this.buscarConsultorio.toLowerCase())
        })
        }else{
            return this.consultoriosData;
        }
    },
    },

    methods: {
        async getConsultoriosData(){
            const response = await fetch(this.URL_TENANT+'/api/consultorio/')
            this.consultoriosData = await response.json();
        },
    },
    mounted() {
        this.$watch(
            "$refs.child_add.consultorio_añadido",
            () => (this.getConsultoriosData()),
        );
        this.$watch(
            '$refs.child_mod.consultorio_modificado',
            () => (this.getConsultoriosData()),
        );
        this.$watch(
            '$refs.child_del.consultorio_eliminado',
            () => (this.getConsultoriosData()),
        );
        },
    created() {
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.getConsultoriosData();
    },
}
</script>