<template>
      <Header/>
      <Nav/>
      <BuscarProductos/>


</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import BuscarProductos from '../../components/catalogo/producto/buscar_producto.vue'





export default {
  name: 'BuscarProductosComponent',

  components: {
    Header,
    Nav,
    BuscarProductos,
  },

  data: () => ({
    //
  }),
}
</script>
