
<template>
<div class="page-wrapper">
			<div class="page-content">
				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Compra</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Cotización</li>
							</ol>
						</nav>
					</div>
				</div>
				<!--end breadcrumb-->
				<div  v-if="option==1"  class="card">
					<div class="card-body">
						<div class="d-lg-flex align-items-center mb-4 gap-3">
							<div class="position-relative">
								<input type="text" class="form-control ps-5 radius-30" v-model="buscarCotizacion" placeholder="Buscar Cotización"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
							</div>
						<div class="ms-auto"><a href="javascript:;" data-bs-toggle="modal"  data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Crear Nueva Cotización</a></div>
						</div>
                        <!-- Button trigger modal -->
						<!-- Modal -->
						<div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-lg">
								<div class="modal-content bg-dark">
									<div class="modal-header">
										<h5 class="modal-title" id="exampleModalLabel">Crear Cotización</h5>
										<button type="button" id="agregar_close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
                                        <div class="row mb-3">
											<div class="col-sm-2">
												<h6 class="mb-0">Requisicion</h6>
											</div>
											<div class="col-sm-2">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="conRequisicion">
                                                    <label v-if="conRequisicion == false" class="form-check-label" for="flexCheckChecked">Click para buscar</label>
                                                </div>
                                            </div>
											<div class="col-sm-8" v-if="conRequisicion == true">
                                                <vue-select  :options="requisicionesData"
                                                    v-model="cotizacion_compra.requisicion_compra"
                                                    label-by="referencia"
													track-by="referencia"
													value-by="id"
													search-placeholder="Selecciona una Requisicion"
													multiple
													collapse-tags="true"
													taggable
													searchable
													placeholder="Selecciona una Requisicion"
													close-on-select>
                                                    <template #label="{ selected }">
                                                    <template v-if="selected">
                                                        <div style="color:white;">{{ selected.referencia }}</div>
                                                    </template>
                                                    <template v-else > <div style="color:white;">Seleccione una Requisicion</div></template>
                                                    </template>
                                                    <template #dropdown-item="{ option }">
                                                    <div style="color:white;">{{ option.referencia }}</div>
                                                    </template>
                                                </vue-select>
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-2">
												<h6 class="mb-0">Proveedor</h6>
											</div>
											<div class="col-sm-10">
                                                <vue-select  :options="proveedoresData"
                                                    v-model="cotizacion_compra.proveedor"
                                                    label-by="nombre"
													track-by="nombre"
													value-by="id"
													search-placeholder="Selecciona un Proveedor"
													multiple
													collapse-tags="true"
													taggable
													searchable
													placeholder="Selecciona un Proveedor"
													close-on-select>
                                                    <template #label="{ selected }">
                                                    <template v-if="selected">
                                                        <div style="color:white;">{{ selected.nombre }}</div>
                                                    </template>
                                                    <template v-else > <div style="color:white;">Seleccione un Proveedor</div></template>
                                                    </template>
                                                    <template #dropdown-item="{ option }">
                                                    <div style="color:white;">{{ option.nombre }}</div>
                                                    </template>
                                                </vue-select>
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-2">
												<h6 class="mb-0">Fecha</h6>
											</div>
											<div class="col-sm-10">
                                                <Datepicker
                                                v-model="cotizacion_compra.fecha"
                                                dark placeholder="Selecciona una Fecha"
                                                autoApply
                                                :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                :enableTimePicker="false"
                                                monthNameFormat="long"
                                                :format="format"
                                                modelType="dd-MM-yyyy"
                                                locale="es">
                                                </Datepicker>
											</div>
										</div>
                                        <div class="row mb-3">
											<div class="col-sm-2">
												<h6 class="mb-0">Fecha Vencimiento</h6>
											</div>
											<div class="col-sm-10">
                                                <Datepicker
                                                v-model="cotizacion_compra.fecha_vencimiento"
                                                dark placeholder="Selecciona una Fecha de Vencimiento"
                                                autoApply
                                                :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                :enableTimePicker="false"
                                                monthNameFormat="long"
                                                :format="format"
                                                modelType="dd-MM-yyyy"
                                                locale="es">
                                                </Datepicker>
											</div>
										</div>
                                    </div>
									<div class="modal-footer">
										<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
										<button type="button" @click="crearCotizacionCompra()" class="btn btn-primary">Guardar</button>
									</div>
								</div>
							</div>
						</div>
						<!-- End Modal -->
                        <!-- Modal Modify -->
						<div class="modal fade"  id="modificarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-lg">
								<div class="modal-content bg-dark">
									<div class="modal-header">
										<h5 class="modal-title" id="exampleModalLabel">Modificar Categoría</h5>
										<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
                                        <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Categoria</h6>
											</div>
											<div class="col-sm-9">
												<input type="text" id="nombreNuevaCategoria"  class="form-control" v-model="nombreNuevaCategoria"  />
											</div>
										</div>
                                    </div>
									<div class="modal-body">
                                    <div class="row mb-3">
											<div class="col-sm-3">
												<h6 class="mb-0">Estado</h6>
											</div>
                                            <div class="col-sm-9">
                                                <vue-select   :options="estados"
                                                    v-model="selectedEstado"
                                                    label-by="nombre"
                                                    hide-selected="true"
                                                    track-by="nombre"
                                                    value-by="id"
                                                    placeholder="Selecciona un Estado"
                                                    close-on-select>
                                                    <template #label="{ selected }">
                                                    <template v-if="selected">
                                                        <div style="color:white;">{{ selected.nombre }}</div>
                                                    </template>
                                                    <template v-else > <div style="color:white;">Seleccione un Estado</div></template>
                                                    </template>
                                                    <template #dropdown-item="{ option }" >
                                                    <div  style="color:white;">{{ option.nombre }}</div>
                                                    </template>
                                                </vue-select>
											</div>
                                        </div>
                                    </div>

									<div class="modal-footer">
										<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
										<button type="button" @click="modificarCategoria" class="btn btn-primary">Guardar</button>
									</div>
								</div>
							</div>
						</div>
						<!-- End Modal Modify -->
						<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th>#Referencia</th>
										<th>Fecha</th>
										<th>Proveedor</th>
										<th>Estado</th>
										<th>Ver Detalle</th>
										<th>Requisición</th>
										<th>Usuario</th>
										<th>Acción</th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in cotizacionesDataFilter" :key="item.id">
										<td >
											<div class="d-flex align-items-center">
												<div>
													<input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
												</div>
												<div class="ms-2">
													<h6 class="mb-0 font-14">#{{item.referencia}}</h6>
												</div>
											</div>
										</td>
										<td>{{item.fecha}}</td>
										<td>{{item.proveedor_nombre}}</td>
										<td>
                                            <div class="badge rounded-pill bg-light p-2 text-uppercase px-3">
                                                <div v-if="[[item.estado]] == '9'"> <i  class='bx bx-minus me-1'></i>Anulado</div>
                                                <div v-if="[[item.estado]] == 'A'"> <i  class='bx bx-radio-circle me-1'></i>Abierto</div>
                                                <div v-if="[[item.estado]] == 'C'"> <i  class='bx bx-radio-circle-marked me-1'></i>Cerrado</div>
                                            </div>
                                        </td>
										<td>
                                            <div v-if="item.detalle_cotizacion_compra.length >0">
                                                <button type="button" data-bs-toggle="modal" :data-bs-target="'#detalleCotizacion'+item.id" class="btn btn-light position-relative me-lg-5"> <i class='bx bx-comment-detail align-middle'></i> Ver Productos <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{item.detalle_cotizacion_compra.length}}</span>
												</button>
                                                <!-- Modal -->
												<div class="modal fade" :id="'detalleCotizacion'+item.id" tabindex="-1" aria-hidden="true">
													<div class="modal-dialog modal-lg">
														<div class="modal-content">
															<div class="modal-header">
																<h5 class="modal-title">Detalle de Cotización Compra</h5>
																<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
															</div>
															<div class="modal-body">
																<div class="card">
																	<div class="card-body">
																		<table class="table mb-0">
                                                                            <thead class="table-light">
                                                                                <tr>
                                                                                    <th>Cantidad</th>
                                                                                    <th>Unidad Medida</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr v-for="item2 in item.detalle_cotizacion_compra" :key="item2.id">
                                                                                    <td>{{item2.cantidad}}</td>
                                                                                    <td>{{item2.unidad_medida_nombre}} / {{item2.unidad_medida_nombre_unidad}}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
																	</div>
																</div>
															</div>
															<div class="modal-footer">
																<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
															</div>
														</div>
													</div>
												</div>
                                                <!-- FIN Modal -->
                                            </div>
                                            <div v-else>
                                                <button v-if="[[item.estado]] == 'A'" type="button" @click="fillModificarCotizacion([[item.id]],1)" class="btn btn-light position-relative me-lg-5"> <i class='bx bx-comment-detail align-middle'></i> Agregar Productos
												</button>
                                            </div>
                                        </td>
										<td>
                                            <button v-if="item.requisicion_compra" type="button" class="btn btn-light position-relative me-lg-5"> <i class='bx bx-left-top-arrow-circle align-middle'></i> Ver Requisición</button>
                                        </td>
										<td>{{item.nombre_usuario}}</td>

										<td>
											<div class="d-flex order-actions">
												<a href="javascript:;" @click="fillModificarCotizacion([[item.id]],1)" class=""><i class='bx bxs-edit'></i></a>
												<a href="javascript:;" class="ms-3"><i class='bx bxs-trash'></i></a>
                                                <div class="ms-3">
                                                    <button  v-if="item.orden_compra" type="button" class="btn btn-light"><i class="bx bx-fast-forward"></i>Ver Orden de Compra</button>
                                                    <button  @click="fillCrearOrdenCompra(item.referencia, item.id, item.fecha, item.proveedor)" v-if="!item.orden_compra && [[item.estado]] == 'C' " type="button" data-bs-toggle="modal" data-bs-target="#convertirOrdenModal" class="btn btn-light"><i class="bx bx-send mr-1"></i>Crear Orden de Compra</button>
                                                        <!-- Modal Eliminar -->
                                                    <div class="modal fade"  id="convertirOrdenModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div class="modal-dialog">
                                                            <div class="modal-content bg-dark">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title" id="exampleModalLabel">Convertir en Orden de Compra</h5>
                                                                    <button type="button" class="btn-close" id="convertir_orden_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div class="modal-body">
                                                                        ¿Desea convertir la Requisición {{crearOrdenCompra.referencia}} en una Orden de Compra?
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                    <button type="button" @click="convertirOrdenCompra()" class="btn btn-primary">Crear Orden de Compra</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- End Modal Eliminar -->
                                                </div>
											</div>
										</td>
									</tr>


								</tbody>
							</table>
						</div>
					</div>
                    <!-- Paginacion -->
					<div class="card-body">
                        <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a class="page-link" href="javascript:;" @click="cambiarUrl(cotizacionesData.links.previous, 'atras')">Atrás</a>
                                    </li>
                                    <li class="page-item" :class="{active: indexPagination == index}" v-for="index in cotizacionesData.total_pages" :key="index">
                                        <a class="page-link"  href="javascript:;javascript:;" @click="cambiarUrl('http://127.0.0.1:8000/api/cotizacion_compra/?page='+index+'', index)">{{ index }}</a>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="javascript:;" @click="cambiarUrl(cotizacionesData.links.next, 'adelante')">Siguiente</a>
                                    </li>
                                </ul>
                        </nav>
                    </div>
                    <!-- Fin Paginacion -->
				</div>
                <!-- Modificar Card -->
                <div v-if="option==2" class="card">
                    <!--INICIO MODIFICAR-->
                    <div class="card">
                    <div class="card-body">
                        <div class="row">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" data-bs-toggle="tab" href="#requisicionCompra" role="tab" aria-selected="true">
                                    <div class="d-flex align-items-center">
                                        <div class="tab-icon"><i class='bx bx-spreadsheet font-18 me-1'></i>
                                        </div>
                                        <div class="tab-title">Editar Cotización de Compra</div>
                                    </div>
                                </a>
                            </li>
                            <div class="ms-auto">
                                <button type="button" @click="cambiarEstado(idOption=1)" class="btn btn-light" style="margin-right:10px;">Regresar</button>
                        </div>
                        </ul>
                        </div>
                        <div class="tab-content py-3">
                            <div class="tab-pane fade show active" id="requisicionCompra" role="tabpanel">
                                <div class="card">
                                    <div class="card-body p-4">
                                        <div class="row">
                                            <div class="col-lg-10">
                                                <h5 class="card-title">Cotización de Compra</h5>
                                            </div>
                                            <div class="col-lg-2">
                                                <button type="button"   @click="modificarCotizacion()" class="btn btn-light">Modificar Cotización Compra</button>
                                            </div>
                                        </div>

                                        <hr/>
                                        <div class="form-body mt-4">
                                        <div class="row">
                                            <div class="col-lg-12">
                                            <div class="border border-3 p-4 rounded">
                                                <div class="modal-body">
                                                    <div class="row mb-3">
                                                        <div class="col-sm-1">
                                                            <h6 class="mb-0">#Referencia</h6>
                                                        </div>
                                                        <div class="col-sm-5">
                                                            <h4>{{ cotizacionModificar.referencia }}</h4>
                                                        </div>
                                                            <div class="col-sm-1">
                                                                <h6 class="mb-0">Estado</h6>
                                                            </div>
                                                            <div class="col-sm-5">
                                                                <vue-select   :options="estados"
                                                                    v-model="cotizacionModificar.estado"
                                                                    label-by="nombre"
                                                                    hide-selected="true"
                                                                    track-by="nombre"
                                                                    value-by="id"
                                                                    placeholder="Selecciona un Estado"
                                                                    close-on-select>
                                                                    <template #label="{ selected }">
                                                                    <template v-if="selected">
                                                                        <div style="color:white;">{{ selected.nombre }}</div>
                                                                    </template>
                                                                    <template v-else > <div style="color:white;">Seleccione un Estado</div></template>
                                                                    </template>
                                                                    <template #dropdown-item="{ option }" >
                                                                    <div  style="color:white;">{{ option.nombre }}</div>
                                                                    </template>
                                                                </vue-select>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-sm-1">
                                                                <h6 class="mb-0">Requisición de Compra</h6>
                                                            </div>
                                                            <div class="col-sm-5">
                                                                <vue-select  :options="requisicionesData"
                                                                    v-model="cotizacionModificar.requisicion_compra"
                                                                    label-by="referencia"
                                                                    track-by="referencia"
                                                                    value-by="id"
                                                                    search-placeholder="Selecciona una Requisicion"
                                                                    multiple
                                                                    collapse-tags="true"
                                                                    taggable
                                                                    searchable
                                                                    placeholder="Selecciona una Requisicion"
                                                                    close-on-select>
                                                                    <template #label="{ selected }">
                                                                    <template v-if="selected">
                                                                        <div style="color:white;">{{ selected.referencia }}</div>
                                                                    </template>
                                                                    <template v-else > <div style="color:white;">Seleccione una Requisicion</div></template>
                                                                    </template>
                                                                    <template #dropdown-item="{ option }">
                                                                    <div style="color:white;">{{ option.referencia }}</div>
                                                                    </template>
                                                                </vue-select>
                                                            </div>
                                                            <div class="col-sm-1">
                                                                <h6 class="mb-0">Proveedor</h6>
                                                            </div>
                                                            <div class="col-sm-5">
                                                                <vue-select  :options="proveedoresData"
                                                                    v-model="cotizacionModificar.proveedor"
                                                                    label-by="nombre"
                                                                    track-by="nombre"
                                                                    value-by="id"
                                                                    search-placeholder="Selecciona un Proveedor"
                                                                    multiple
                                                                    collapse-tags="true"
                                                                    taggable
                                                                    searchable
                                                                    placeholder="Selecciona un Proveedor"
                                                                    close-on-select>
                                                                    <template #label="{ selected }">
                                                                    <template v-if="selected">
                                                                        <div style="color:white;">{{ selected.nombre }}</div>
                                                                    </template>
                                                                    <template v-else > <div style="color:white;">Seleccione un Proveedor</div></template>
                                                                    </template>
                                                                    <template #dropdown-item="{ option }">
                                                                    <div style="color:white;">{{ option.nombre }}</div>
                                                                    </template>
                                                                </vue-select>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                                <div class="col-sm-1">
                                                                    <h6 class="mb-0">Fecha</h6>
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <Datepicker
                                                                    v-model="cotizacionModificar.fecha"
                                                                    dark placeholder="Selecciona una Fecha"
                                                                    autoApply
                                                                    :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                    :enableTimePicker="false"
                                                                    monthNameFormat="long"
                                                                    :format="format"
                                                                    modelType="dd-MM-yyyy"
                                                                    locale="es">
                                                                    </Datepicker>
                                                                </div>
                                                                <div class="col-sm-1">
                                                                    <h6 class="mb-0">Fecha Vencimiento</h6>
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <Datepicker
                                                                    v-model="cotizacionModificar.fecha_vencimiento"
                                                                    dark placeholder="Selecciona una Fecha"
                                                                    autoApply
                                                                    :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                    :enableTimePicker="false"
                                                                    monthNameFormat="long"
                                                                    :format="format"
                                                                    modelType="dd-MM-yyyy"
                                                                    locale="es">
                                                                    </Datepicker>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                                <div class="card-body p-4">
                                                    <div class="row">
                                                        <div class="col-lg-10">
                                                            <h5 class="card-title">Detalle de Cotización de Compra</h5>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <button type="button" data-bs-toggle="modal" v-if="[[cotizacionModificar.estado]] == 'A'" @click="getProductosUnidadMedida()" data-bs-target="#agregarDetalleModal" class="btn btn-light">Añadir Producto a Cotización</button>
                                                            <!-- Modal -->
                                                            <div class="modal fade"  id="agregarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div class="modal-dialog modal-lg">
                                                                    <div class="modal-content bg-dark">
                                                                        <div class="modal-header">
                                                                            <h5 class="modal-title" id="exampleModalLabel">Añadir Producto a Cotización</h5>
                                                                            <button type="button" class="btn-close" id="agregar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>
                                                                        <div class="modal-body">
                                                                            <div class="row mb-3">
                                                                <div class="col-sm-4">
                                                                    <h6 class="mb-0">Producto / Unidad Medida</h6>
                                                                </div>
                                                                <div class="col-sm-8">
                                                                    <vue-select  :options="productosUnidadMedidaData"
                                                                        v-model="detalleCotizacion.producto_um"
                                                                        label-by="nombre_producto"
                                                                        track-by="nombre_producto"
                                                                        value-by="id"
                                                                        search-placeholder="Selecciona un Producto"
                                                                        multiple
                                                                        collapse-tags="true"
                                                                        taggable
                                                                        searchable
                                                                        placeholder="Selecciona un Producto"
                                                                        close-on-select>
                                                                        <template #tag="{ option, remove }">
                                                                        <div>
                                                                        {{ option.nombre_producto }} - {{ option.nombre_unidad_medida }}/{{option.cantidad_unidad_medida}}
                                                                        <img style="display: inline-flex;" @click.stop="remove" src="data:image/svg+xml;base64,PHN2ZyBpZD0iZGVsZXRlIiBkYXRhLW5hbWU9ImRlbGV0ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHRpdGxlPmRlbGV0ZTwvdGl0bGU+PHBhdGggZD0iTTI1NiwyNEMzODMuOSwyNCw0ODgsMTI4LjEsNDg4LDI1NlMzODMuOSw0ODgsMjU2LDQ4OCwyNC4wNiwzODMuOSwyNC4wNiwyNTYsMTI4LjEsMjQsMjU2LDI0Wk0wLDI1NkMwLDM5Ny4xNiwxMTQuODQsNTEyLDI1Niw1MTJTNTEyLDM5Ny4xNiw1MTIsMjU2LDM5Ny4xNiwwLDI1NiwwLDAsMTE0Ljg0LDAsMjU2WiIgZmlsbD0iIzViNWI1ZiIvPjxwb2x5Z29uIHBvaW50cz0iMzgyIDE3Mi43MiAzMzkuMjkgMTMwLjAxIDI1NiAyMTMuMjkgMTcyLjcyIDEzMC4wMSAxMzAuMDEgMTcyLjcyIDIxMy4yOSAyNTYgMTMwLjAxIDMzOS4yOCAxNzIuNzIgMzgyIDI1NiAyOTguNzEgMzM5LjI5IDM4MS45OSAzODIgMzM5LjI4IDI5OC43MSAyNTYgMzgyIDE3Mi43MiIgZmlsbD0iIzViNWI1ZiIvPjwvc3ZnPg==" alt="delete tag" class="icon delete">
                                                                        </div>
                                                                        </template>
                                                                        <template #label="{ selected }">
                                                                        <template v-if="selected">
                                                                            <div style="color:white;">{{ selected.nombre_producto }}</div>
                                                                        </template>
                                                                        <template v-else > <div style="color:white;">Seleccione un Producto</div></template>
                                                                        </template>
                                                                        <template #dropdown-item="{ option }">
                                                                        <div style="color:white;">{{ option.nombre_producto }} - {{ option.nombre_unidad_medida}}/{{option.cantidad_unidad_medida}} </div>
                                                                        </template>
                                                                    </vue-select>
                                                                </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-4">
                                                                        <h6 class="mb-0">Cantidad a Solicitar</h6>
                                                                    </div>
                                                                    <div class="col-sm-8">
                                                                    <input class="form-control" type="number" v-model="detalleCotizacion.cantidad" placeholder="Cantidad de Unidades" rows="3">
                                                                    </div>
                                                                </div>
                                                                        </div>
                                                                        <div class="modal-footer">
                                                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                            <button type="button" @click="crearDetalleCotizacion" class="btn btn-primary">Crear</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- End Modal -->
                                                        </div>
                                                    </div>
                                        <hr/>
                                        <div class="form-body mt-4">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="border border-3 p-4 rounded">
                                                    <div class="modal-body">
                                                        <table class="table mb-0">
                                                            <thead class="table-light">
                                                                <tr>
                                                                    <th>Cantidad</th>
                                                                    <th>Unidad Medida</th>
                                                                    <th>Precio</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="item2 in detalleCotizacionModificar" :key="item2.id">
                                                                    <td>{{item2.cantidad}}</td>
                                                                    <td>{{item2.unidad_medida_nombre}} / {{item2.unidad_medida_nombre_unidad}}</td>
                                                                    <td>{{item2.precio}}</td>
                                                                    <td>
                                                                        <div class="d-flex order-actions">
                                                                            <a href="javascript:;" data-bs-toggle="modal" v-if="[[cotizacionModificar.estado]] == 'A'" @click="fillDetalleModificar(item2.id, item2.producto_um, item2.cantidad, item2.precio);" data-bs-target="#modificarDetalleModal" class=""><i class='bx bxs-edit'></i></a>

                                                                            <!-- Modal -->
                                                                            <div class="modal fade"  id="modificarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                                <div class="modal-dialog modal-lg">
                                                                                    <div class="modal-content bg-dark">
                                                                                        <div class="modal-header">
                                                                                            <h5 class="modal-title" id="exampleModalLabel">Modificar Producto a Cotización</h5>
                                                                                            <button type="button" class="btn-close" id="modificar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        </div>
                                                                                        <div class="modal-body">
                                                                                            <div class="row mb-3">
                                                                                                <!--
                                                                                <div class="col-sm-4">
                                                                                    <h6 class="mb-0">Producto / Unidad Medida</h6>
                                                                                </div>
                                                                                <div class="col-sm-8">
                                                                                    <vue-select  :options="productosUnidadMedidaData"
                                                                                        v-model="detalleCotizacion.producto_um"
                                                                                        label-by="nombre_producto"
                                                                                        track-by="nombre_producto"
                                                                                        value-by="id"
                                                                                        search-placeholder="Selecciona un Producto"
                                                                                        multiple
                                                                                        collapse-tags="true"
                                                                                        taggable
                                                                                        searchable
                                                                                        placeholder="Selecciona un Producto"
                                                                                        close-on-select>
                                                                                        <template #tag="{ option, remove }">
                                                                                        <div>
                                                                                        {{ option.nombre_producto }} - {{ option.nombre_unidad_medida }}/{{option.cantidad_unidad_medida}}
                                                                                        <img style="display: inline-flex;" @click.stop="remove" src="data:image/svg+xml;base64,PHN2ZyBpZD0iZGVsZXRlIiBkYXRhLW5hbWU9ImRlbGV0ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHRpdGxlPmRlbGV0ZTwvdGl0bGU+PHBhdGggZD0iTTI1NiwyNEMzODMuOSwyNCw0ODgsMTI4LjEsNDg4LDI1NlMzODMuOSw0ODgsMjU2LDQ4OCwyNC4wNiwzODMuOSwyNC4wNiwyNTYsMTI4LjEsMjQsMjU2LDI0Wk0wLDI1NkMwLDM5Ny4xNiwxMTQuODQsNTEyLDI1Niw1MTJTNTEyLDM5Ny4xNiw1MTIsMjU2LDM5Ny4xNiwwLDI1NiwwLDAsMTE0Ljg0LDAsMjU2WiIgZmlsbD0iIzViNWI1ZiIvPjxwb2x5Z29uIHBvaW50cz0iMzgyIDE3Mi43MiAzMzkuMjkgMTMwLjAxIDI1NiAyMTMuMjkgMTcyLjcyIDEzMC4wMSAxMzAuMDEgMTcyLjcyIDIxMy4yOSAyNTYgMTMwLjAxIDMzOS4yOCAxNzIuNzIgMzgyIDI1NiAyOTguNzEgMzM5LjI5IDM4MS45OSAzODIgMzM5LjI4IDI5OC43MSAyNTYgMzgyIDE3Mi43MiIgZmlsbD0iIzViNWI1ZiIvPjwvc3ZnPg==" alt="delete tag" class="icon delete">
                                                                                        </div>
                                                                                        </template>
                                                                                        <template #label="{ selected }">
                                                                                        <template v-if="selected">
                                                                                            <div style="color:white;">{{ selected.nombre_producto }}</div>
                                                                                        </template>
                                                                                        <template v-else > <div style="color:white;">Seleccione un Producto</div></template>
                                                                                        </template>
                                                                                        <template #dropdown-item="{ option }">
                                                                                        <div style="color:white;">{{ option.nombre_producto }} - {{ option.nombre_unidad_medida}}/{{option.cantidad_unidad_medida}} </div>
                                                                                        </template>
                                                                                    </vue-select>
                                                                                </div>
                                                                                -->
                                                                                </div>
                                                                                <div class="row mb-3">
                                                                                    <div class="col-sm-4">
                                                                                        <h6 class="mb-0">Cantidad a Solicitar</h6>
                                                                                    </div>
                                                                                    <div class="col-sm-8">
                                                                                    <input class="form-control" type="number" v-model="detalleCotizacion.cantidad" placeholder="Cantidad de Unidades" rows="3">
                                                                                    </div>
                                                                                </div>
                                                                                        </div>
                                                                                        <div class="modal-footer">
                                                                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                            <button type="button" @click="modificarDetalleCotizacion()" class="btn btn-primary">Modificar</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <!-- End Modal -->
                                                                            <a href="javascript:;" v-if="[[cotizacionModificar.estado]] == 'A'" @click="fillEliminarDetalleCotizacion(item2.id, item2.unidad_medida_nombre,item2.unidad_medida_nombre_unidad, item2.cantidad)" data-bs-target="#eliminarDetalleModal" data-bs-toggle="modal"  class="ms-3"><i class='bx bxs-trash'></i></a>
                                                                            <!-- Modal Eliminar -->
                                                                            <div class="modal fade"  id="eliminarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                                <div class="modal-dialog modal-lg">
                                                                                    <div class="modal-content bg-dark">
                                                                                        <div class="modal-header">
                                                                                            <h5 class="modal-title" id="exampleModalLabel">Modificar Producto a Requisición</h5>
                                                                                            <button type="button" id="eliminar_detalle_close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        </div>
                                                                                        <div class="modal-body">
                                                                                            <div class="row mb-3">
                                                                                                ¿Desea Eliminar el Producto {{ detalleCotizacionEliminar.producto_nombre }}?
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="modal-footer">
                                                                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                            <button type="button" @click="eliminarDetalleCotizacion(detalleCotizacionEliminar.id)" class="btn btn-danger">Eliminar</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <!-- End Modal Eliminar -->
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!--end row-->
                                        </div>
                                    </div>
                                            </div>
                                        </div><!--end row-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--FIN MODIFICAR-->

                </div>
                <!-- Fin Modificar Card -->

			</div>
		</div>

</template>
<style>
</style>
<script>
import  Cookies from 'js-cookie';
//import Select2 from 'vue3-select2-component';
//import $ from 'jquery';
//let select2 = require('@/assets/plugins/select2/js/select2.min.js');
//import * as mykey from '../assets/js/mykey.js'
import $ from 'jquery';
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')
import VueNextSelect from 'vue-next-select';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref } from 'vue';
console.log(Cookies.get());
export default {
    name: 'cotizacion_component',
    components:{
        'vue-select': VueNextSelect,
        'Datepicker': Datepicker,
    },
setup() {
    // In case of a range picker, you'll receive [Date, Date]
    const date = ref(new Date());
    const format = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }
    return {
        date,
        format,
    }
},
data() {
	return{
        URL_TENANT: '',
        empresaData: [0],
        usuariosData: [0],
        option: 1,
        url: "/api/cotizacion_compra/",
        indexPagination: 1,
        productosUnidadMedidaData: [],
		buscarCotizacion: '',
        cotizacionesData: [],
        estados: [
            {
                id: 'A',
                nombre: 'Abierto',
            },
            {
                id: 'C',
                nombre: 'Cerrado',
            },
            {
                id: '9',
                nombre: 'Anulado',
            }
        ],
        selectedEstado: null,
        // Lista Requisiciones:
        conRequisicion: false,
        requisicionesData: [],
        // Proveedores:
        proveedoresData: [],
        // Crear Cotización:
        cotizacion_compra: {
            fecha: '',
            fecha_vencimiento: '',
            proveedor: '',
            requisicion_compra: '',
        },
        //Modificar Cotización:
        cotizacionModificar: {
            id: null,
            fecha: null,
            fecha_vencimiento: null,
            requisicion_compra: '',
            proveedor: [{}],
            referencia: null,
            estado: null
        },
        detalleCotizacion: {
            id: null,
            cantidad: null,
            producto_um: [],
            precio: null,

        },
        detalleCotizacionModificar: [],
        detalleCotizacionEliminar: {
            id: null,
            producto_nombre: null,
            cantidad: null,
        },
        //Crear Orden Compra
        crearOrdenCompra: []
		}
	},

computed: {
    cotizacionesDataFilter() {
        if (this.buscarCotizacion != ''){
            return this.cotizacionesData.results.filter((item) => {
            return item.referencia.toLowerCase().includes(this.buscarCotizacion.toLowerCase())
        })
        }else{
            return this.cotizacionesData.results;
        }

    },

},
watch:{
    conRequisicion: function(){
        if(this.conRequisicion == false){
            this.cotizacion_compra.requisicion_compra = '';
        }
    },
    url: function () {
        this.getCotizacionesData();
    },
    cotizacion_compra: {
        handler: function () {
            if(this.cotizacion_compra.requisicion_compra){
                this.cotizacion_compra.proveedor = [this.requisicionesData.find(x => x.id === this.cotizacion_compra.requisicion_compra[0]).proveedor];
            }
            if(this.cotizacion_compra.requisicion_compra.length > 1){
                this.cotizacion_compra.requisicion_compra.shift();
            }
            if(this.cotizacion_compra.proveedor.length > 1){
                this.cotizacion_compra.proveedor.shift();
            }
        },
        deep: true,
    },
    detalleCotizacion: {
        handler: function () {
            if(this.detalleCotizacion.producto_um.length > 1){
                this.detalleCotizacion.producto_um.shift();
            }
        },
        deep: true,
    },
    cotizacionModificar: {
        handler: function () {
            if(this.cotizacionModificar.proveedor.length > 1){
                this.cotizacionModificar.proveedor.shift();
            }
            if(this.cotizacionModificar.requisicion_compra){

                if(this.cotizacionModificar.requisicion_compra.length > 1){
                    this.cotizacionModificar.requisicion_compra.shift();
                }
                if(this.cotizacionModificar.requisicion_compra.length > 0){
                    console.log("DATA FILTER");
                    console.log(this.cotizacionModificar.requisicion_compra[0]);
                    this.x =this.requisicionesData.find(x => x.id === this.cotizacionModificar.requisicion_compra[0]);

                    console.log(this.requisicionesData.find(x => x.id === this.cotizacionModificar.requisicion_compra[0]));
                    console.log(this.x);
                    this.cotizacionModificar.proveedor = [this.requisicionesData.find(x => x.id === this.cotizacionModificar.requisicion_compra[0]).proveedor];
                }
            }

        },
        deep: true,
    },
},
methods: {
    //Modificar:
    async cambiarEstado(idOption) {
		this.option = idOption;
        if (idOption == 1){
            this.requisicionesData.pop();
            this.cotizacionModificar = {
            id: null,
            fecha: null,
            fecha_vencimiento: null,
            requisicion_compra: '',
            proveedor: [{}],
            referencia: null,
            estado: null
            }
        }
    },
    async fillModificarCotizacion(idMod, id) {
        this.modificarId = idMod[0][0];
        console.log("MODIFICAR");
        console.log(this.modificarId);
        for(var k in this.cotizacionesData.results){
            console.log("For 1");
            console.log(this.cotizacionesData.results[k]);
            if(this.cotizacionesData.results[k].id==this.modificarId){
                console.log("if 1");
                this.cotizacionModificar.proveedor = [this.cotizacionesData.results[k].proveedor];
				this.cotizacionModificar.id = this.cotizacionesData.results[k].id;
                this.cotizacionModificar.fecha = this.cotizacionesData.results[k].fecha;
                this.cotizacionModificar.fecha_vencimiento = this.cotizacionesData.results[k].fecha_vencimiento;
                this.cotizacionModificar.referencia = this.cotizacionesData.results[k].referencia;
                this.cotizacionModificar.estado = this.cotizacionesData.results[k].estado;

                //Llenamos Requisición:
                if(this.cotizacionesData.results[k].requisicion_compra){
                    if(id == 1){
                        const response = await fetch(this.URL_TENANT+'/api/requisicion_compra/'+this.cotizacionesData.results[k].requisicion_compra+'/');
                        this.requisicionUp = await response.json();
                        const obj1 = {
                            id: this.requisicionUp.id,
                            referencia: this.requisicionUp.referencia,
                            fecha: this.requisicionUp.fecha,
                            proveedor: this.requisicionUp.proveedor,
                        }
                        this.requisicionesData.push(obj1);
                    }
                    this.cotizacionModificar.requisicion_compra = [this.cotizacionesData.results[k].requisicion_compra];
                } // Finaliza
                this.detalleCotizacionModificar = [];
                if(this.cotizacionesData.results[k].detalle_cotizacion_compra)
                    for(var j in this.cotizacionesData.results[k].detalle_cotizacion_compra){
                        console.log("for 2");
                        //Lleno el detalle a modificar:
                        const obj = {
                            id: this.cotizacionesData.results[k].detalle_cotizacion_compra[j].id,
                            cantidad: this.cotizacionesData.results[k].detalle_cotizacion_compra[j].cantidad,
                            requisicion_compra: this.cotizacionesData.results[k].detalle_cotizacion_compra[j].requisicion_compra,
                            producto_um: this.cotizacionesData.results[k].detalle_cotizacion_compra[j].producto_um,
                            unidad_medida_nombre: this.cotizacionesData.results[k].detalle_cotizacion_compra[j].unidad_medida_nombre,
                            unidad_medida_nombre_unidad: this.cotizacionesData.results[k].detalle_cotizacion_compra[j].unidad_medida_nombre_unidad,
                            precio: this.cotizacionesData.results[k].detalle_cotizacion_compra[j].precio,
                        }
                        this.detalleCotizacionModificar.push(obj);
                    }
            }
        }
        this.option=2;
    },
    async modificarCotizacion(){
        if (!this.cotizacionModificar.fecha || !this.cotizacionModificar.id || !this.cotizacionModificar.proveedor[0] || !this.cotizacionModificar.fecha_vencimiento){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        }else{
            console.log("ESTAS UPDETEANDO");
            try{
                const formData = new FormData();
                console.log("ESTAS UPDETEANDO 2");
                formData.append("fecha", this.cotizacionModificar.fecha);
                formData.append("fecha_vencimiento", this.cotizacionModificar.fecha_vencimiento);
                formData.append("requisicion_compra", this.cotizacionModificar.requisicion_compra[0]);
                formData.append("proveedor", this.cotizacionModificar.proveedor[0]);
                formData.append("estado", this.cotizacionModificar.estado);
                await fetch(this.URL_TENANT+'/api/cotizacion_compra/'+this.cotizacionModificar.id+'/',{
                method: 'put',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Cotización de Compra Modificada');
            this.getCotizacionesData();
            this.getListaRequisicionesData();
            this.getProductosUnidadMedida();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }
        }
    },
    async fillDetalleModificar(id, producto_um, cantidad, precio){
        this.detalleCotizacion.id = id;
        this.detalleCotizacion.producto_um = [producto_um];
        this.detalleCotizacion.cantidad = cantidad;
        this.detalleCotizacion.precio = precio;
        await this.getProductosUnidadMedida();
    },
    async modificarDetalleCotizacion(){
        if (!this.detalleCotizacion.id || !this.detalleCotizacion.producto_um[0] || !this.detalleCotizacion.cantidad){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        }else{
            console.log("ESTAS UPDETEANDO");
            try{
                const formData = new FormData();
                formData.append("cantidad", this.detalleCotizacion.cantidad);
                formData.append("producto_um", this.detalleCotizacion.producto_um[0]);
                formData.append("cotizacion_compra", this.cotizacionModificar.id);
                formData.append("precio", this.productosUnidadMedidaData.find(x => x.id === this.detalleCotizacion.producto_um[0]).precio_compra);
                await fetch(this.URL_TENANT+'/api/cotizacion_compra_detalle/'+this.detalleCotizacion.id+'/',{
                method: 'put',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
                document.getElementById('modificar_detalle_close').click();
            notificaciones.round_success_noti($.msg='Producto Modificado Exitosamente');
            this.getProductosUnidadMedida();
            await this.getCotizacionesData();
            this.fillModificarCotizacion([[this.cotizacionModificar.id]], 2)
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }
        }
    },
    //Creación:
    async convertirOrdenCompra(){
        try{
            const formDataCot = new FormData();
            console.log("VOY A CREAR ORDEN COMPRA");
            formDataCot.append("desdeCotizacion", 'desdeCotizacion');
            formDataCot.append("fecha", this.crearOrdenCompra.fecha);
            formDataCot.append("proveedor", this.crearOrdenCompra.proveedor);
            formDataCot.append("usuario", '1');
            formDataCot.append("cotizacion", this.crearOrdenCompra.id);
            await fetch(this.URL_TENANT+'/api/orden_compra/',{
            method: 'post',
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
            },body: formDataCot})
            notificaciones.round_success_noti($.msg='Se Creó la Orden de Compra a partir de la Cotización: '+this.crearOrdenCompra.referencia+'');
            document.getElementById('convertir_orden_close').click();
            this.getProductosUnidadMedida();
            await this.getCotizacionesData();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }
    },
    async fillCrearOrdenCompra(referencia, id, fecha, proveedor){
        this.crearOrdenCompra.referencia = referencia;
        this.crearOrdenCompra.id = id;
        this.crearOrdenCompra.fecha = fecha;
        this.crearOrdenCompra.proveedor = proveedor;
    },
    async crearDetalleCotizacion(){
    console.log("crearDetalleCotizacion");
    console.log(this.detalleCotizacion.cantidad);
    console.log(this.detalleCotizacion.producto_um);

        if (!this.detalleCotizacion.cantidad || !this.detalleCotizacion.producto_um[0]){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        }else{
            try{
                document.getElementById('agregar_detalle_close').click();
                const formData = new FormData();
                console.log("VOY A INGRESAR");
                formData.append("cotizacion_compra", this.cotizacionModificar.id);
                formData.append("cantidad", this.detalleCotizacion.cantidad);
                formData.append("producto_um", this.detalleCotizacion.producto_um[0]);
                formData.append("precio", this.productosUnidadMedidaData.find(x => x.id === this.detalleCotizacion.producto_um[0]).precio_compra);
                await fetch(this.URL_TENANT+'/api/cotizacion_compra_detalle/',{
                method: 'post',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Producto Agregado a la Cotización');
            this.getProductosUnidadMedida();
            await this.getCotizacionesData();
            this.fillModificarCotizacion([[this.cotizacionModificar.id]], 2);

            this.detalleCotizacion = {
                id: null,
                cantidad: null,
                producto_um: [],
            }
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }

        }
    },
    async crearCotizacionCompra(){
        if (this.cotizacion_compra.requisicion_compra[0]){
            this.requisicion_compra_ing = this.cotizacion_compra.requisicion_compra[0];
        }else{
            this.requisicion_compra_ing = '';

        }
        if (!this.cotizacion_compra.fecha && !this.cotizacion_compra.proveedor[0] && !this.cotizacion_compra.fecha_vencimiento ){
            notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
        }else{
            try{
                const formData = new FormData();
                console.log("Ingresando Cotización Compra");
                formData.append("fecha", this.cotizacion_compra.fecha);
                formData.append("fecha_vencimiento", this.cotizacion_compra.fecha_vencimiento);
                formData.append("proveedor", this.cotizacion_compra.proveedor[0]);
                formData.append("requisicion", this.requisicion_compra_ing);

                await fetch(this.URL_TENANT+'/api/cotizacion_compra/',{
                method: 'post',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
            notificaciones.round_success_noti($.msg='Cotización de Compra Creada');
            document.getElementById('agregar_close').click();
            this.getCotizacionesData();
            this.getListaRequisicionesData();
            this.getProductosUnidadMedida();
            this.limpiarCrearCotizacion();
            }catch(error){
                console.log(error);
                notificaciones.round_error_noti($.msg='Error, algo salió mal');
            }

        }
    },
    // Eliminación:
    async fillEliminarDetalleCotizacion(id, unidad_medida_nombre, unidad_medida_nombre_unidad, cantidad){
        console.log("ELIMINAR FILL");
        this.producto_nombre = unidad_medida_nombre + unidad_medida_nombre_unidad;
        this.detalleCotizacionEliminar.id = id;
        this.detalleCotizacionEliminar.producto_nombre = this.producto_nombre;
        this.detalleCotizacionEliminar.cantidad = cantidad;
    },
    async eliminarDetalleCotizacion(idProductoEliminar){
        console.log("ID A ELIMINAR");
        console.log(idProductoEliminar);
		const requestOptions = {
			method: 'DELETE',
			headers: {
			'X-CSRFToken': Cookies.get('csrftoken'),
			}
		};
		await fetch(this.URL_TENANT+'/api/cotizacion_compra_detalle/'+idProductoEliminar+'',  requestOptions);
        document.getElementById('eliminar_detalle_close').click();
		notificaciones.round_success_noti($.msg='Producto Eliminado de la Cotización');
		this.getProductosUnidadMedida();
        await this.getCotizacionesData();
        this.fillModificarCotizacion([[this.cotizacionModificar.id]], 2);
	},
    async limpiarCrearCotizacion(){
        this.cotizacion_compra= {
            fecha: '',
            fecha_vencimiento: '',
            proveedor: '',
            requisicion_compra: '',
        }
    },
    async cambiarUrl(link, index){
        this.url = link;
        if (index === "atras" && link){
            this.indexPagination = this.indexPagination - 1;
        }
        else if (index === "adelante" && link != null){
            this.indexPagination = this.indexPagination + 1;
        }
        else if (link){
            this.indexPagination = index;
        }
    },
    // Llenado:
    async getEmpresaData() {
        const response = await fetch(this.URL_TENANT+'/api/empresa/');
        this.empresaData = await response.json();
    },
    async getProveedoresData() {
        const response = await fetch(this.URL_TENANT+'/api/proveedor/');
        this.proveedoresData = await response.json();
    },
	async getCotizacionesData() {
        const response = await fetch(this.url);
        this.cotizacionesData = await response.json();
    },
    async getListaRequisicionesData(){
        const response = await fetch(this.URL_TENANT+'/api/lista_requisicion_compra/');
        this.requisicionesData = await response.json();
    },
    async getProductosUnidadMedida() {
        const response = await fetch(this.URL_TENANT+'/api/producto_unidades_medida/');
        this.productosUnidadMedidaData = await response.json();
    },
    },
mounted() {
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://unpkg.com/vue-next-select/dist/vue-next-select.iife.prod.js')
        document.head.appendChild(recaptchaScript)
    },
created() {
      // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
    this.url = this.URL_TENANT + this.url
    this.getCotizacionesData();
    this.getEmpresaData();
    this.getListaRequisicionesData();
    this.getProveedoresData();
    this.getProductosUnidadMedida();

	},
}

</script>
