<template>
      <Header/>
      <Nav/>
      <Pagos/>

</template>

<script>
import Header from '../../components/header.vue'
import Nav from '../../components/nav.vue'
import Pagos from '../../components/venta/pagos.vue'



export default {
  name: 'PagosComponent',

  components: {
  Header,
	Nav,
	Pagos,
  },

  data: () => ({
    //
  }),
}
</script>
