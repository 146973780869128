
<template>
<header>
			<div class="topbar d-flex align-items-center" >
				<nav class="navbar navbar-expand">
					<div class="topbar-logo-header" >
						<div class="">
							<img :src="empresaData[0].logo" height="40" width="70" alt="logo icon">
						</div>
						<div class="">
							<h4 class="logo-text">{{ empresaData[0].nombre }}</h4>
						</div>
					</div>
					<div class="mobile-toggle-menu"><i class='bx bx-menu'></i></div>
					<div class="search-bar flex-grow-1">
						<div class="position-relative search-bar-box">
							<!--
							<input type="text" class="form-control search-control" placeholder="Escribe para buscar..."> <span class="position-absolute top-50 search-show translate-middle-y"><i class='bx bx-search'></i></span>
							-->
							<span class="position-absolute top-50 search-close translate-middle-y"><i class='bx bx-x'></i></span>
						</div>
					</div>
					<div class="top-menu ms-auto">
						<ul class="navbar-nav align-items-center">
							<li class="nav-item mobile-search-icon">
								<a class="nav-link" href="#">	<i class='bx bx-search'></i>
								</a>
							</li>
							<li class="nav-item dropdown dropdown-large">
								<a class="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">	<i class='bx bx-category'></i>
								</a>
								<div class="dropdown-menu dropdown-menu-end">
									<div class="row row-cols-3 g-3 p-3">
										<div class="col text-center">
										</div>
										<div class="col text-center">
										</div>
										<div class="col text-center">
										</div>
										<div class="col text-center">
										</div>
										<div class="col text-center">
										</div>
										<div class="col text-center">
										</div>
									</div>
								</div>
							</li>
							<li class="nav-item dropdown dropdown-large">
								<a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count"></span>
									<i class='bx bx-bell'></i>
								</a>
								<div class="dropdown-menu dropdown-menu-end">
									<a href="javascript:;">
										<div class="msg-header">
										</div>
									</a>
									<div class="header-notifications-list">
									</div>
									<a href="javascript:;">
										<div class="text-center msg-footer">Ver Todas las Notificaciones</div>
									</a>
								</div>
							</li>
							<li class="nav-item dropdown dropdown-large">
								<a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count"></span>
									<i class='bx bx-comment'></i>
								</a>
								<div class="dropdown-menu dropdown-menu-end">
									<a href="javascript:;">
										<div class="msg-header">
										</div>
									</a>
									<div class="header-message-list">
									</div>
									<a href="javascript:;">
										<div class="text-center msg-footer">Ver Todos los Mensajes</div>
									</a>
								</div>
							</li>
						</ul>
					</div>
					<div class="user-box dropdown">
						<a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							<img :src="usuarioData[0].imagen" class="user-img" alt="user avatar">
							<div class="user-info ps-3">
								<p class="user-name mb-0">{{usuarioData[0].first_name}} {{usuarioData[0].last_name}}</p>
								<p class="designattion mb-0">{{usuarioData[0].puesto_nombre}} - {{usuarioData[0].departamento_nombre}}</p>
							</div>
						</a>
						<ul class="dropdown-menu dropdown-menu-end">
							<li><a class="dropdown-item" href="/empresa/perfil_usuario/"><i class="bx bx-user"></i><span>Perfil</span></a>
							</li>
							<li><a class="dropdown-item" href="javascript:;"><i class="bx bx-cog"></i><span>Settings</span></a>
							</li>
							<li><a class="dropdown-item" href="javascript:;"><i class='bx bx-home-circle'></i><span>Dashboard</span></a>
							</li>
							<li><a class="dropdown-item" href="javascript:;"><i class='bx bx-dollar-circle'></i><span>Earnings</span></a>
							</li>
							<li><a class="dropdown-item" href="javascript:;"><i class='bx bx-download'></i><span>Downloads</span></a>
							</li>
							<li>
								<div class="dropdown-divider mb-0"></div>
							</li>
							<li><a class="dropdown-item" href="/logout/"><i class='bx bx-log-out-circle'></i><span>Cerrar Sesión</span></a>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</header>
</template>

<script>


export default {
    name: 'HeaderComponent',
	components:{
        },
data: () => ({
    empresaData: [0],
    usuarioData: [0],
	URL_TENANT: '',
	update: '',
	}
),
watch:{
	update: {
            handler: function () {
                this.getEmpresaData()
            },
            deep: true,
        },
},
methods: {
    async getEmpresaData() {
        const response = await fetch(this.URL_TENANT+'/api/empresa/');
        this.empresaData = await response.json();
          //console.log(userData);
    },
	async getUsuarioData() {
        const response = await fetch(this.URL_TENANT+'/api/user/');
        this.usuarioData = await response.json();
        //console.log(userData);
    },
},
created() {
	const result = window.location.origin;
    this.URL_TENANT = result;
      // Fetch tasks on page load
    this.getEmpresaData();
	this.getUsuarioData();
	}
}
</script>
