
<template>
<div class="page-wrapper">
			<div class="page-content">

				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Catálogo</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Agregar Producto</li>
							</ol>
						</nav>
					</div>
				</div>
				<!--end breadcrumb-->
			<div class="card">
				<div class="card-body">
					<div class="row">
					<ul class="nav nav-tabs" role="tablist">
						<li class="nav-item" role="presentation">
							<a class="nav-link active" data-bs-toggle="tab" href="#datosProducto" role="tab" aria-selected="true">
								<div class="d-flex align-items-center">
									<div class="tab-icon"><i class='bx bx-spreadsheet font-18 me-1'></i>
									</div>
									<div class="tab-title">Datos del Producto</div>
								</div>
							</a>
						</li>
						<li class="nav-item" role="presentation">
							<a class="nav-link" data-bs-toggle="tab" href="#codigosBarraProducto" role="tab" aria-selected="false">
								<div class="d-flex align-items-center">
									<div class="tab-icon"><i class='bx bx-barcode font-18 me-1'></i>
									</div>
									<div class="tab-title">Códigos de Barras</div>
								</div>
							</a>
						</li>
						<li class="nav-item" role="presentation">
							<a class="nav-link" data-bs-toggle="tab" href="#unidadesMedidaProducto" role="tab" aria-selected="false">
								<div class="d-flex align-items-center">
									<div class="tab-icon"><i class='bx bx-package font-18 me-1'></i>
									</div>
									<div class="tab-title">Unidades de Medida</div>
								</div>
							</a>
						</li>
						<li class="nav-item" role="presentation">
							<a class="nav-link" data-bs-toggle="tab" href="#proveedoresProducto" role="tab" aria-selected="false">
								<div class="d-flex align-items-center">
									<div class="tab-icon"><i class='bx bx-group font-18 me-1'></i>
									</div>
									<div class="tab-title">Proveedores</div>
								</div>
							</a>
						</li>
						<div class="ms-auto">
							<button type="button" @click="irProducto(time=200)" class="btn btn-light" style="margin-right:10px;">Regresar</button>
							<button type="button" @click="agregarProducto(caso=1)" class="btn btn-light" style="margin-right:10px;">Guardar y Añadir Otro</button>
							<button type="button"  @click="agregarProducto(caso=2)" class="btn btn-light">Guardar Producto</button>
					</div>
					</ul>
					</div>
					<div class="tab-content py-3">
						<div class="tab-pane fade show active" id="datosProducto" role="tabpanel">
							<div class="card">
								<div class="card-body p-4">
									<h5 >Agregar Nuevo Producto</h5>
									<hr/>
									<div class="form-body mt-4">
									<div class="row">
										<div class="col-lg-8">
										<div class="border border-3 p-4 rounded">
											<div class="row g-3">
											<div class="mb-3">
												<h6><label class="form-label">Código</label></h6>
												<input v-model="codigoNuevo" type="text" class="form-control" id="codigoNuevo" placeholder="Ingrese el Código del Producto">
											</div>
											<div class="mb-3">
												<h6><label class="form-label">Nombre Producto *</label></h6>
												<input v-model="nombreNuevo" type="text" class="form-control" id="nombreNuevo" placeholder="Ingrese el Nombre del Producto">
											</div>
											<div class="mb-3">
												<h6><label class="form-label">Marca</label></h6>
												<input v-model="marcaNuevo" type="text" class="form-control" id="marcaNuevo" placeholder="Ingrese la Marca">
											</div>
											<div class="mb-3">
												<h6><label class="form-label">Descripción</label></h6>
												<textarea v-model="descripcionNuevo" class="form-control" placeholder="Ingresa una Descripción del Producto" id="descripcionNuevo" rows="3"></textarea>
											</div>
											</div>
											<div class="mb-3">
												<h6><label class="form-label">Observaciones</label></h6>
												<textarea v-model="observacionesNuevo" class="form-control" placeholder="Observaciones del Producto" id="observacionesNuevo" rows="3"></textarea>
											</div>
											</div>
										</div>
										<div class="col-lg-4">
											<div class="border border-3 p-4 rounded">
											<div class="row g-3">
												<div class="input-group mb-3">
													<div class="input-group-text">
														<input v-model="permitirCompraNuevo" class="form-check-input"  id="permitirCompraNuevo" type="checkbox" checked aria-label="Checkbox for following text input">
													</div>
													<input type="text" class="form-control" disabled :value="permitirCompraMostrar" aria-label="Text input with checkbox">
												</div>
												<div class="input-group mb-3">
													<div class="input-group-text">
														<input v-model="permitirVentaNuevo" class="form-check-input"  id="permitirVentaNuevo" type="checkbox" checked aria-label="Checkbox for following text input">
													</div>
													<input type="text" class="form-control" disabled :value="permitirVentaMostrar" aria-label="Text input with checkbox">
												</div>
												<div class="input-group mb-3">
													<div class="input-group-text">
														<input v-model="estadoNuevo" class="form-check-input"  id="permitirVentaNuevo" type="checkbox" checked aria-label="Checkbox for following text input">
													</div>
													<input type="text" class="form-control" disabled :value="estadoMostrar" aria-label="Text input with checkbox">
												</div>
												<div class="col-12">
													<h6><label for="inputProductType" class="form-label">Categoría</label></h6>
													<vue-select  :options="categoriasData"
														v-model="selectedCategoria"
														label-by="nombre"
														track-by="nombre"
														value-by="id"
														search-placeholder="Selecciona una Categoría"
														multiple
														collapse-tags="true"
														taggable
														searchable
														placeholder="Selecciona una Categoría"
														close-on-select>
														<template #label="{ multiple }">
														<template v-if="multiple">
														<div style="color:white;">{{ multiple.nombre }}</div>
														</template>
														<template v-else > <div style="color:white;">Seleccione una Categoría</div></template>
														</template>
														<template #dropdown-item="{ option }">
														<div style="color:white;">{{ option.nombre }}</div>
														</template>
													</vue-select>
												</div>
												<h6><label class="form-label">Subir Imagen(es)</label></h6><br>
												<div class="input-group mb-3">
													<input type="file" class="form-control" id="imagenesNuevo" multiple>
													<label class="input-group-text">Subir</label>
												</div>
											</div>
										</div>
										</div>
									</div><!--end row-->
									</div>
								</div>
							</div>
						</div>
						<div class="tab-pane fade" id="codigosBarraProducto" role="tabpanel">
							<div class="card">
								<div class="card-body p-4">
									<h5>Códigos de Barra</h5>
									<hr/>
									<div class="form-body mt-4">
									<div class="row">
										<div class="col-lg-12">
										<div class="border border-3 p-4 rounded">
											<div class="row g-3">
											<div class="col-5">
												<h6><label class="form-label">Código de Barra</label></h6>
												<input v-model="codigoBarra" type="number" class="form-control" id="nombreNuevo" placeholder="Ingrese el Nombre del Producto">
											</div>
											<div class="col-5">
												<h6><label class="form-label">Prioridad</label></h6>
												<vue-select  :options="estadosPrioridad"
														v-model="selectedPrioridad"
														label-by="nombre"
														track-by="nombre"
														value-by="id"
														search-placeholder="Seleccione una Prioridad"
														multiple
														collapse-tags="true"
														taggable
														searchable
														placeholder="Seleccione una Prioridad"
														close-on-select>
														<template #label="{ multiple }">
														<template v-if="multiple">
														<div style="color:white;">{{ multiple.nombre }}</div>
														</template>
														<template v-else > <div style="color:white;">Seleccione una Prioridad</div></template>
														</template>
														<template #dropdown-item="{ option }">
														<div style="color:white;">{{ option.nombre }}</div>
														</template>
													</vue-select>
											</div>
											<div class="col-2">
												<h6><label class="form-label">Acción</label></h6>
												<input  type="button" class="form-control btn-light" @click="agregarCodigosBarra()" value="Agregar Codigo Barra" >
											</div>
											</div>
											</div>
										</div>
									</div><!--end row-->
									</div>
									<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th><h6>Código de Barra</h6></th>
										<th><h6>Prioridad</h6></th>
										<th><h6>Acciones</h6></th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in codigosDeBarra" :key="item.id">
										<td><h6>
											<div class="d-flex align-items-center">
												<div>
													<input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
												</div>
												<div class="ms-2">
													<h6 class="mb-0 font-14">{{item.codigo_barra}}</h6>
												</div>
											</div>
										</h6></td>
										<td><h6>{{item.estado[0]}}</h6></td>
										<td><h6>
											<div class="d-flex order-actions">
												<a href="javascript:;" class="ms-3" data-bs-toggle="modal" data-bs-target="#eliminarModal"  @click="eliminarBarra(item.codigo_barra)"><i class='bx bxs-trash'></i></a>
											</div>
										</h6></td>
									</tr>
								</tbody>
							</table>
						</div>
								</div>
							</div>
						</div>
						<div class="tab-pane fade" id="unidadesMedidaProducto" role="tabpanel">
							<div class="card">
								<div class="card-body p-4">
									<h5>Unidades de Medida</h5>
									<hr/>
									<div class="form-body mt-4">
									<div class="row">
										<div class="col-lg-12">
										<div class="border border-3 p-4 rounded">
											<div class="row g-3">
											<div class="col-2">
												<h6><label class="form-label">Nombre</label></h6>
												<input v-model="nombreUnidadMedida" type="text" class="form-control" id="nombreUnidadMedida" placeholder="Ingrese Nombre de la Unidad de Medida">
											</div>
											<div class="col-2">
												<h6><label class="form-label">Cantidad Unidades</label></h6>
												<input v-model="cantidadUnidadMedida" type="text" class="form-control" id="cantidadUnidadMedida" placeholder="IngreseCantidad de Unidades">
											</div>
											<div class="col-2">
												<h6><label class="form-label">Precio Compra</label></h6>
												<input v-model="precioCompraUnidadMedida" type="number" step="0.01" class="form-control" id="precioCompraUnidadMedida" placeholder="Ingrese Precio de Compra">
											</div>
											<div class="col-2">
												<h6><label class="form-label">Precio Venta</label></h6>
												<input v-model="precioVentaUnidadMedida" type="number" step="0.01" class="form-control" id="precioVentaUnidadMedida" placeholder="Ingrese el Precio Venta">
											</div>
											<div class="col-2">
												<h6><label class="form-label">Codigo de Barra</label></h6>
												<!--<input v-model="codigobarraUnidadMedida" type="text" class="form-control" id="nombreUnidadMedida" placeholder="Ingrese Nombre de la Unidad de Medida">-->
												<vue-select  :options="codigosDeBarra"
														v-model="codigobarraUnidadMedida"
														label-by="codigo_barra"
														track-by="codigo_barra"
														value-by="codigo_barra"
														search-placeholder="Selecciona una Categoría"
														multiple
														collapse-tags="true"
														taggable
														searchable
														placeholder="Selecciona una Categoría"
														close-on-select>
														<template #label="{ multiple }">
														<template v-if="multiple">
														<div style="color:white;">{{ multiple.codigo_barra }}</div>
														</template>
														<template v-else > <div style="color:white;">Seleccione un Código de Barra</div></template>
														</template>
														<template #dropdown-item="{ option }">
														<div style="color:white;">{{ option.codigo_barra }}</div>
														</template>
													</vue-select>
											</div>
											<div class="col-2">
												<h6><label class="form-label">Acción</label></h6>
												<input  type="button" class="form-control btn-light" @click="agregarUnidadesMedida()" value="Agregar Unidad Medida" >
											</div>
											</div>
										</div>
										<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th><h6>Nombre</h6></th>
										<th><h6>Cantidad Unidades</h6></th>
										<th><h6>Precio Compra</h6></th>
										<th><h6>Precio Venta</h6></th>
										<th><h6>Código de Barra</h6></th>
										<th><h6>Acciones</h6></th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in unidadesMedida" :key="item.id">
										<td><h6>
											<div class="d-flex align-items-center">
												<div>
													<input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
												</div>
												<div class="ms-2">
													<h6 class="mb-0 font-14">{{item.nombre}}</h6>
												</div>
											</div>
										</h6></td>
										<td><h6>{{item.cantidadUnidades}}</h6></td>
										<td><h6>{{item.precioCompra}}</h6></td>
										<td><h6>{{item.precioVenta}}</h6></td>
										<td><h6>{{item.codigobarra[0]}}</h6></td>
										<td><h6>
											<div class="d-flex order-actions">
												<a href="javascript:;" class="ms-3" data-bs-toggle="modal" data-bs-target="#eliminarModal"  @click="eliminarUnidadMedida(item.id)"><i class='bx bxs-trash'></i></a>
											</div>
										</h6></td>
									</tr>
								</tbody>
							</table>
						</div>
										</div>
									</div><!--end row-->
									</div>
								</div>
							</div>
						</div>
						<div class="tab-pane fade" id="proveedoresProducto" role="tabpanel">
							<div class="card">
								<div class="card-body p-4">
									<h5>Proveedores</h5>
									<hr/>
									<div class="form-body mt-4">
									<div class="row">
										<div class="col-lg-12">
										<div class="border border-3 p-4 rounded">
											<div class="row g-3">
											<div class="col-5">
													<h6><label class="form-label">Proveedor</label></h6>
													<vue-select  :options="proveedoresData"
														v-model="selectedProveedor"
														label-by="nombre"
														track-by="nombre"
														value-by="id"
														search-placeholder="Selecciona un Proveedor"
														multiple
														collapse-tags="true"
														taggable
														searchable
														placeholder="Selecciona un Proveedor"
														close-on-select>
														<template #label="{ multiple }">
														<template v-if="multiple">
														<div style="color:white;">{{ multiple.nombre }}</div>
														</template>
														<template v-else > <div style="color:white;">Seleccione un Proveedor</div></template>
														</template>
														<template #dropdown-item="{ option }">
														<div style="color:white;">{{ option.nombre }}</div>
														</template>
													</vue-select>
												</div>
												<div class="col-5">
												<h6><label class="form-label">Estado</label></h6>
												<vue-select  :options="estadosProveedor"
														v-model="selectedEstadoProveedor"
														label-by="nombre"
														track-by="nombre"
														value-by="id"
														search-placeholder="Seleccione un Estado"
														multiple
														collapse-tags="true"
														taggable
														searchable
														placeholder="Seleccione un Estado"
														close-on-select>
														<template #label="{ multiple }">
														<template v-if="multiple">
														<div style="color:white;">{{ multiple.nombre }}</div>
														</template>
														<template v-else > <div style="color:white;">Seleccione un Estado</div></template>
														</template>
														<template #dropdown-item="{ option }">
														<div style="color:white;">{{ option.nombre }}</div>
														</template>
													</vue-select>
											</div>
											<div class="col-2">
												<h6><label class="form-label">Acción</label></h6>
												<input  type="button" class="form-control btn-light" @click="agregarProveedores()" value="Agregar Proveedor" >
											</div>
											</div>
										</div>
										<div class="table-responsive">
							<table class="table mb-0">
								<thead class="table-light">
									<tr>
										<th><h6>Proveedor</h6></th>
										<th><h6>Estado</h6></th>
										<th><h6>Acciones</h6></th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="item in proveedores" :key="item.id">
										<td><h6>
											<div class="d-flex align-items-center">
												<div>
													<input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
												</div>
												<div class="ms-2">
													<h6 class="mb-0 font-14">{{item.proveedor}}</h6>
												</div>
											</div>
										</h6></td>
										<td><h6>{{item.estado[0].nombre}}</h6></td>
										<td><h6>
											<div class="d-flex order-actions">
												<a href="javascript:;" class="ms-3" data-bs-toggle="modal" data-bs-target="#eliminarModal"  @click="eliminarProveedor(item.id)"><i class='bx bxs-trash'></i></a>
											</div>
										</h6></td>
									</tr>
								</tbody>
							</table>
						</div>
										</div>
									</div><!--end row-->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			</div>
		</div>
</template>
<style>
@import '@/assets/css/imageuploadify.min.css';
</style>
<script>
import  Cookies from 'js-cookie';
//import Select2 from 'vue3-select2-component';
//import $ from 'jquery';
//let select2 = require('@/assets/plugins/select2/js/select2.min.js');
//import * as mykey from '../assets/js/mykey.js'
import $ from 'jquery';
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')

import VueNextSelect from 'vue-next-select'
console.log(Cookies.get());
export default {
    name: 'producto_component',
    components:{
        'vue-select': VueNextSelect,
    },
data() {
	return{
		URL_TENANT: '',
        productosData: [],
		categoriasData: [],
		proveedoresData: [],
		//para Registro de Nuevo Producto:
		idNuevoProducto:'',
		codigo_barraNuevo: '',
		marcaNuevo: '',
		codigoNuevo: '',
		nombreNuevo: '',
		descripcionNuevo: '',
		permitirCompraNuevo: true,
		permitirVentaNuevo: true,
		observacionesNuevo: '',
		selectedCategoria: '',
		imagenesNuevo: [],
		estadoNuevo: true,
		estadoNuevoIngresar: '',
		estadoMostrar: 'Activo',
		permitirCompraMostrar: 'Permitir Compra',
		permitirVentaMostrar: 'Permitir Venta',
		//CODIGOS DE BARRA:
		codigosDeBarra: [],
		codigoBarra: '',
		selectedPrioridad: '',
		estadoBarraIngresar: '',
		estadosPrioridad: [
            {
                id: 'Principal',
                nombre: 'Principal',
            },
            {
                id: 'Secundario',
                nombre: 'Secundario',
            }
        ],
		//UNIDADES DE MEDIDA:
		nombreUnidadMedida: 'Unidad',
		cantidadUnidadMedida: 1,
		precioCompraUnidadMedida: '',
		precioVentaUnidadMedida: '',
		codigobarraUnidadMedida: '',
		unidadesMedida: [],
		unidadesMedidaVerificar: [],
		//PROVEEDORES:
		proveedores: [],
		selectedProveedor: '',
		selectedEstadoProveedor: '',
		estadosProveedor: [{
			id: 'A',
			nombre: 'Activo',
		},
		{
			id: 'I',
			nombre: 'Inactivo',
		}
		],

		}
	},
watch: {

	estadoNuevo: function (){
		if(this.estadoNuevo == true){
			this.estadoMostrar = 'Activo';
		}else{
			this.estadoMostrar = 'Inactivo';
		}
	},
	permitirCompraNuevo: function (){
		if(this.permitirCompraNuevo == true){
			this.permitirCompraMostrar = 'Permitir Compra';
		}else{
			this.permitirCompraMostrar = 'No Permite Compra';
		}
	},
	permitirVentaNuevo: function (){
		if(this.permitirVentaNuevo == true){
			this.permitirVentaMostrar = 'Permitir Venta';
		}else{
			this.permitirVentaMostrar = 'No Permite Venta';
		}
	},
    selectedCategoria: function () {
		console.log("SELECTED");
        console.log(this.selectedCategoria[0]);
		if(this.selectedCategoria.length > 1){
		this.selectedCategoria.shift();
		console.log("QUEDO:");
        console.log(this.selectedCategoria[0]);
		}
        },
		selectedPrioridad: function () {
		if(this.selectedPrioridad.length > 1){
		this.selectedPrioridad.shift();
		}
        },
		codigobarraUnidadMedida: function () {
			if(this.codigobarraUnidadMedida.length > 1){
			this.codigobarraUnidadMedida.shift();
		}
        },
    },
methods: {
	async irProducto(time=0){
		setInterval(function(){location.href = '/catalogo/producto/'},time);
	},
	async limpiarInputs(){
		//para Registro de Nuevo Producto:
		this.idNuevoProducto = '';
		this.codigo_barraNuevo =  '';
		this.marcaNuevo =  '';
		this.codigoNuevo =  '';
		this.nombreNuevo =  '';
		this.descripcionNuevo =  '';
		this.permitirCompraNuevo =  true;
		this.permitirVentaNuevo =  true;
		this.observacionesNuevo =  '';
		this.selectedCategoria =  '';
		this.imagenesNuevo =  [];
		this.estadoNuevo =  true;
		this.estadoNuevoIngresar =  '';
		this.estadoMostrar =  'Activo';
		this.permitirCompraMostrar =  'Permitir Compra';
		this.permitirVentaMostrar =  'Permitir Venta';
		//CODIGOS DE BARRA:
		this.codigosDeBarra =  [];
		this.codigoBarra =  '';
		this.selectedPrioridad =  '';
		this.estadoBarraIngresar =  '';
		//UNIDADES DE MEDIDA:
		this.nombreUnidadMedida =  'Unidad';
		this.cantidadUnidadMedida =  1;
		this.precioCompraUnidadMedida =  '';
		this.precioVentaUnidadMedida =  '';
		this.codigobarraUnidadMedida = '';
		this.unidadesMedida =  [];
		this.unidadesMedidaVerificar = [];
		//PROVEEDORES:
		this.proveedores =  [];
		this.selectedProveedor =  '';
		this.selectedEstadoProveedor =  '';
	},
	async getProductosData() {
        const response = await fetch(this.URL_TENANT+'/api/producto/');
        this.productosData = await response.json();
    },
	async getCategoriasData() {
        const response = await fetch(this.URL_TENANT+'/api/categoria/');
        this.categoriasData = await response.json();
    },
	async getProveedoresData() {
        const response = await fetch(this.URL_TENANT+'/api/proveedor/');
        this.proveedoresData = await response.json();
    },
	async eliminarBarra(codigo_barra= 0){
		for(var x in this.codigosDeBarra){
				if(this.codigosDeBarra[x].codigo_barra==codigo_barra){
					this.codigosDeBarra.splice(x,1);
				}
			}
	},
	async eliminarUnidadMedida(id= 0){
		for(var x in this.unidadesMedida){
				if(this.unidadesMedida[x].id==id){
					this.unidadesMedida.splice(x,1);
				}
			}
	},
	async eliminarProveedor(id= 0){
		for(var x in this.proveedores){
				if(this.proveedores[x].id==id){
					this.proveedores.splice(x,1);
				}
			}
	},
	async agregarUnidadesMedida(){
		if (this.nombreUnidadMedida != '' && this.cantidadUnidadMedida != 0 && this.precioCompraUnidadMedida != 0 && this.precioVentaUnidadMedida != 0){
			const lenArr = this.unidadesMedida.length;
			const obj = {
				id: lenArr+1,
				nombre: this.nombreUnidadMedida,
				cantidadUnidades: this.cantidadUnidadMedida,
				precioCompra: this.precioCompraUnidadMedida,
				precioVenta: this.precioVentaUnidadMedida,
				codigobarra: this.codigobarraUnidadMedida,
			}
			this.unidadesMedida.push(obj);
			this.nombreUnidadMedida = '';
			this.cantidadUnidadMedida = 0;
			this.precioCompraUnidadMedida = '';
			this.precioVentaUnidadMedida = '';
			this.codigobarraUnidadMedida = '';
		}else{
			notificaciones.round_error_noti($.msg="Debe Llenar Todos los Campos");
		}
	},
	async agregarCodigosBarra(){
		if (this.codigoBarra != '' && this.selectedPrioridad != ''){
			const obj2 = {
				codigo_barra: this.codigoBarra,
				estado: this.selectedPrioridad,
			}
			this.codigosDeBarra.push(obj2);
			this.codigoBarra = '';
			this.selectedPrioridad = '';
		}else{
			notificaciones.round_error_noti($.msg="Debe Llenar Todos los Campos");
		}
	},
	async agregarProveedores(){
		if (this.selectedEstadoProveedor != '' && this.selectedProveedor != ''){
			for(var m in this.proveedoresData){
				if(this.selectedProveedor == this.proveedoresData[m].id){
					this.provIng = this.proveedoresData[m].nombre;
				}
			}
			if(this.selectedEstadoProveedor == 'A'){
				this.nombreIng = 'Activo';
			}
			if(this.selectedEstadoProveedor == 'I'){
				this.nombreIng = 'Inactivo';
			}
			const obj3 = {
				id: this.selectedProveedor[0],
				proveedor: this.provIng,
				estado: [{
					id: this.selectedEstadoProveedor[0],
					nombre: this.nombreIng,
				}],
			}
			this.proveedores.push(obj3);
			this.selectedEstadoProveedor = '';
			this.selectedProveedor = '';
		}else{
			notificaciones.round_error_noti($.msg="Debe Llenar Todos los Campos");
		}
	},
	async agregarProducto(caso = null) {
    console.log("Agregar");
	// Condición para el estado:
	console.log(this.unidadesMedida.length);
	if (this.nombreNuevo != '' && this.unidadesMedida.length >0){
	if (this.estadoNuevo == true){
		this.estadoNuevoIngresar = 'A';
	}else{
		this.estadoNuevoIngresar = 'I';
	}
	console.log(Cookies.get('csrftoken'));
		try{
			if(this.selectedCategoria[0] === undefined){this.catIng = ''}else{this.catIng = this.selectedCategoria[0]}
			const formData = new FormData();
			formData.append("descripcion", this.descripcionNuevo);
			formData.append("codigo", this.codigoNuevo);
			formData.append("nombre", this.nombreNuevo);
			formData.append("marca", this.marcaNuevo);
			formData.append("inventario", 0);
			formData.append("permitir_venta", this.permitirVentaNuevo);
			formData.append("permitir_compra", this.permitirCompraNuevo);
			formData.append("observaciones", this.observacionesNuevo);
			formData.append("categoria", this.catIng);
			formData.append("estado", this.estadoNuevoIngresar);
			formData.append("categoria_nombre", '');
			formData.append("_imagenes", []);
			for (var index = 0; index < $('#imagenesNuevo')[0].files.length; index++) {
				formData.append("imagenesNuevo", $('#imagenesNuevo')[0].files[index]);
			}
			console.log("DATOS USER");
			await fetch(this.URL_TENANT+'/api/producto/',{
			method: 'post',
			headers: {
				'X-CSRFToken': Cookies.get('csrftoken'),
			},body: formData})
			.then(response => response.json()).then(json => this.idNuevoProducto = json.id).catch(err => console.log(err));
			}
		catch(error){
			console.log(error);
			notificaciones.round_error_noti($.msg='Error, algo salió mal');
		} // Fin Agregar Producto
		try{ // Agrega Códigos de Barra
				console.log("BARRAAAAAA");
				console.log(this.idNuevoProducto);
			if(this.codigosDeBarra.length === 0){
				console.log("No hay barras que agregar");
				notificaciones.round_warning_noti($.msg='No Agregó Codigos de Barra');
			}else{
				console.log("DENTRO DE CODIGOS DE BARRA");
				for(var x in this.codigosDeBarra){
					console.log("FOR CODIGOS DE BARRA");
					if(this.codigosDeBarra[x].estado[0] === 'Principal'){
						this.estadoBarraIngresar = 'P';
					}
					if(this.codigosDeBarra[x].estado[0] === 'Secundario'){
						this.estadoBarraIngresar = 'S';
					}
					const formDataBarra = new FormData();
					formDataBarra.append("codigo_barra", this.codigosDeBarra[x].codigo_barra);
					formDataBarra.append("producto", this.idNuevoProducto);
					formDataBarra.append("estado", this.estadoBarraIngresar);
					await fetch(this.URL_TENANT+'/api/producto_barra/',{
					method: 'post',
					headers: {
						'X-CSRFToken': Cookies.get('csrftoken'),
					},body: formDataBarra})
					.then(response => response.json()).then(json => console.log(json)).catch(err => console.log(err));
				}//Finaliza For
			}
		}catch(error){
			console.log(error);
			notificaciones.round_error_noti($.msg='Error, algo salió mal');
		}// Finaliza Agregar Codigos de Barra
		try{ // Agrega Unidades de Medida
			if(this.unidadesMedida.length === 0){
				console.log("No hay Unidades de Medida que Agregar");
				notificaciones.round_warning_noti($.msg='No Agregó Unidades de Medida');

			}else{
				console.log("DENTRO DE UNIDADES DE MEDIDA");
				//Ingreso de Unidad de Medida, verifica si existe, sino lo crea.
				await fetch(this.URL_TENANT+'/api/unidades_medida/')
				.then(response => response.json()).then(json => this.unidadesMedidaVerificar = json).catch(err => console.log(err));
				console.log(this.idUnidadMedida);
				for(var f in this.unidadesMedida){
					this.idUnidadMedida= null;
					for(var i in this.unidadesMedidaVerificar){
						console.log("FOR VERIFICAR");
						if(this.unidadesMedida[f].nombre == this.unidadesMedidaVerificar[i].nombre && this.unidadesMedida[f].cantidadUnidades == this.unidadesMedidaVerificar[i].cantidad_unidades){
							this.idUnidadMedida =this.unidadesMedidaVerificar[i].id;
							console.log("DENTRO DEL IF");
							break;
						}
					}
					if(this.idUnidadMedida === null){
						console.log("AGREGANDO NUEVO");
						const formDataUnidad = new FormData();
						formDataUnidad.append("nombre", this.unidadesMedida[f].nombre);
						formDataUnidad.append("cantidad_unidades", this.unidadesMedida[f].cantidadUnidades);
						await fetch(this.URL_TENANT+'/api/unidades_medida/',{
						method: 'post',
						headers: {
							'X-CSRFToken': Cookies.get('csrftoken'),
						},body: formDataUnidad})
						.then(response => response.json()).then(json => this.idUnidadMedida = json.id).catch(err => console.log(err));
					}
					console.log("UNIDAD  DE MEDIDA A INGRESAR -----------------------");
					console.log(this.idUnidadMedida);
					const formDataUnidades = new FormData();
					formDataUnidades.append("unidad_medida", this.idUnidadMedida);
					formDataUnidades.append("producto", this.idNuevoProducto);
					formDataUnidades.append("estado", 'A');
					formDataUnidades.append("precio_compra", this.unidadesMedida[f].precioCompra);
					formDataUnidades.append("precio_venta", this.unidadesMedida[f].precioVenta);
					if(this.unidadesMedida[f].codigobarra.length >0){ this.barraUnidad = this.unidadesMedida[f].codigobarra[0]}else{this.barraUnidad = ''}
					console.log("CODIGO DE BARRA:::::::::::::");
					console.log(this.barraUnidad);
					formDataUnidades.append("codigo_barra", this.barraUnidad);
					await fetch(this.URL_TENANT+'/api/producto_unidades_medida/',{
					method: 'post',
					headers: {
						'X-CSRFToken': Cookies.get('csrftoken'),
					},body: formDataUnidades})
					.then(response => response.json()).then(json => console.log(json)).catch(err => console.log(err));
				}//Finaliza For
			}
		}catch(error){
			console.log(error);
			notificaciones.round_error_noti($.msg='Error, algo salió mal');
		}// Finaliza Agregar Unidades de Medida
		try{ // Agrega Proveedores
			if(this.proveedores.length === 0){
				console.log("No hay Proveedores que Agregar");
				notificaciones.round_warning_noti($.msg='No Agregó Proveedor(es)');
			}else{
				console.log("DENTRO DE PROVEEDORES");
				for(var g in this.proveedores){
					const formDataProveedores = new FormData();
					formDataProveedores.append("proveedor", this.proveedores[g].id);
					formDataProveedores.append("producto", this.idNuevoProducto);
					formDataProveedores.append("estado", this.proveedores[g].estado[0].id);
					await fetch(this.URL_TENANT+'/api/producto_proveedor/',{
					method: 'post',
					headers: {
						'X-CSRFToken': Cookies.get('csrftoken'),
					},body: formDataProveedores})
					.then(response => response.json()).then(json => console.log(json)).catch(err => console.log(err));
				}//Finaliza For
			}
		}catch(error){
			console.log(error);
			notificaciones.round_error_noti($.msg='Error, algo salió mal');
		}// Finaliza Agregar Proveedores
		notificaciones.round_success_noti($.msg='Producto Agregado Exitosamente');
			if (caso == 1){
				this.limpiarInputs();
			}
			if (caso == 2){
				console.log("CASO DOS");
				this.irProducto(this.time=4000);
			}
		}else{
			notificaciones.round_error_noti($.msg='El Nombre del Producto y una Unidad de Medida es Requerido');
		}
    },
    },
mounted() {
    },
created() {
      // Fetch tasks on page load
	const result = window.location.origin;
    this.URL_TENANT = result;
    this.getProductosData();
	this.getProveedoresData();
	this.getCategoriasData();
	console.log("token");
	console.log(Cookies.get('csrftoken'));

	},
}

</script>
