
<template>
    <div class="page-wrapper">
                <div class="page-content">
                    <!--breadcrumb-->
                    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div class="breadcrumb-title pe-3">Catálogo</div>
                        <div class="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0 p-0">
                                    <li class="breadcrumb-item"><a href="javascript:;"><i class='bx bx-home-alt'></i></a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">Buscar Producto</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <!--end breadcrumb-->
                    <div class="card">
                        <div class="card-body">
                            <div class="d-lg-flex align-items-center mb-4 gap-3">
                                    <input type="text" class="form-control ps-5 radius-30" autofocus v-model="busqueda" placeholder="Buscar por Código, Código de Barra o Nombre de Producto"> <span class="position-absolute top-50 product-show translate-middle-y"></span>
                            </div>
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th><h6>Código</h6></th>
                                            <th><h6>Código de Barra</h6></th>
                                            <th><h6>Producto</h6></th>
                                            <th><h6>Unidades de Medida</h6></th>
                                            <th><h6>Inventario</h6></th>
                                            <th><h6>Precio de Venta</h6></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="itemProd in productoDataFilter" :key="itemProd.id">
                                            <td><h6>{{ itemProd.codigo }}</h6></td>
                                            <td><h6>
                                                <div v-for='cb in itemProd.codigos_barra_producto' :key="cb.id" class="col">
                                                    <ul>
                                                        <li>
                                                        {{cb.codigo_barra}}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </h6></td>
                                            <td><h6>
                                                <label>{{itemProd.nombre}}</label>
                                            </h6></td>
                                            <td><h6>
                                                <div v-for='um in itemProd.unidades_medida_producto' :key="um.id" class="col">
                                                    <ul>
                                                        <li>
                                                            {{um.nombre_unidad_medida}}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </h6></td>
                                            <td><h6>
                                                <div v-for='um in itemProd.unidades_medida_producto' :key="um.id" class="col">
                                                    <ul>
                                                        <li>
                                                            {{ um.inventario }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </h6></td>
                                            <td><h6>
                                                <div v-for='um in itemProd.unidades_medida_producto' :key="um.id" class="col">
                                                    <ul>
                                                        <li>
                                                            {{um.precio_venta}}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </h6></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>
<style>
</style>
<script>
    import  Cookies from 'js-cookie';
    console.log(Cookies.get());

    export default {
        name: 'buscar_producto_component',
        components:{
        },
    data() {
        return{
            URL_TENANT: '',
            productosBusqueda: [],
            busqueda: '',
            }
        },
    watch: {
        },
    computed: {
        productoDataFilter() {
        if (this.busqueda != ''){
            return this.productosBusqueda.filter((item) => {
                return  item.nombre.toLowerCase().includes(this.busqueda.toLowerCase()) || (item.codigo ? item.codigo.toLowerCase().includes(this.busqueda.toLowerCase()) : false) || (item.codigos_barra_producto ? item.codigos_barra_producto.some(t => t.codigo_barra.toLowerCase().includes(this.busqueda.toLowerCase())) : false)

        })
        }else{
            return [];
        }
    },
    },
    methods: {
        async getProductosBusquedaData() {
        const response = await fetch(this.URL_TENANT+'/api/producto_busqueda/');
        this.productosBusqueda = await response.json();
    },
        },
        mounted() {
    },
    created() {
          // Fetch tasks on page load
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.getProductosBusquedaData();
        },
    }
    </script>