
<template>
    <div class="page-wrapper">
        <div class="page-content">
            <!--breadcrumb-->
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Consultorio Virtual</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Expedientes</li>
                        </ol>
                    </nav>
                </div>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-light">Settings</button>
                        <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	<a class="dropdown-item" href="javascript:;">Action</a>
                            <a class="dropdown-item" href="javascript:;">Another action</a>
                            <a class="dropdown-item" href="javascript:;">Something else here</a>
                            <div class="dropdown-divider"></div>	<a class="dropdown-item" href="javascript:;">Separated link</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end breadcrumb-->
            <!-- MODAL MODIFICAR -->
            <div class="modal fade"  id="modificarModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content bg-dark">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Modificar Médico</h5>
                            <button type="button" id="closemod" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <mod_event :id_expediente="id_expediente" ref="child_mod"></mod_event>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button"  data-bs-dismiss="modal" @click="$refs.child_mod.modificarMedico(); " class="btn btn-success">Modificar</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->
            <div class="modal fade"  id="eliminarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content bg-dark">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Eliminar Consultorio</h5>
                            <button type="button" id="closedel" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <!--  <del_event :id_expediente="id_expediente" :titulo="titulo" ref="child_del"></del_event>-->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button"  data-bs-dismiss="modal" @click="$refs.child_del.eliminarConsultorio(); " class="btn btn-danger">Eliminar</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->
            <div class="card">
                <div class="card-body">
                    <div class="d-lg-flex align-items-center mb-4 gap-3">
                        <div class="position-relative">
                            <input type="text" class="form-control ps-5 radius-30" v-model="buscarExpediente" placeholder="Buscar Médico"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                        </div>
                        <div class="ms-auto">
                            <add_event ref="child_add"></add_event>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th># Expediente</th>
                                    <th>Paciente</th>
                                    <th>Fecha Nacimiento</th>
                                    <th>Correo</th>
                                    <th>Celular</th>
                                    <th>Médico</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in expedientesDataFilter" :key="item.id">
                                    <td>{{item.numero_expediente}}</td>
                                    <td>{{item.nombre_completo}}</td>
                                    <td>{{item.fecha_nacimiento}}</td>
                                    <td>{{item.correo}}</td>
                                    <td>{{item.celular}}</td>
                                    <td>{{item.medico_nombre}}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <button type="button" @click="ir_detalle(item.id);"  class="btn btn-light position-relative me-lg-5"> <i class='bx bx-folder-open align-middle'></i>Abrir Expediente<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span>
                                            </button>
                                            <a  class="ms-3"><i class='bx bxs-trash'></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>
<style>
</style>
<script>

import add_event from "./agregar_expediente.vue"
import mod_event from "./modificar_medico.vue"
//import del_event from "./eliminar_medico.vue"

export default {
    name: 'expedientes_component',
    components:{
        add_event,
        mod_event,
        /*del_event,*/
    },
data() {
    return{
        URL_TENANT: '',
        expedientesData:  [],
        buscarExpediente: '',
        id_expediente: '',
        titulo: '',
        }
    },
    watch: {
},
    computed: {
        expedientesDataFilter() {
        console.log("LENGT");
        if (this.buscarExpediente != ''){
            return this.expedientesData.filter((item) => {
            return item.first_name.toLowerCase().includes(this.buscarExpediente.toLowerCase()) || item.last_name.toLowerCase().includes(this.buscarExpediente.toLowerCase())
        })
        }else{
            return this.expedientesData;
        }
    },
    },

    methods: {
        async ir_detalle(id_expediente){
            window.location.href = '/consultorio/detalle_expediente/'+id_expediente;
        },
        async getExpedientesData(){
            const response = await fetch(this.URL_TENANT+'/api/expediente_get/')
            this.expedientesData = await response.json();
        },
    },
    mounted() {
        this.$watch(
            "$refs.child_add.expediente_añadido",
            () => (this.getExpedientesData()),
        );
        this.$watch(
            '$refs.child_mod.medico_modificado',
            () => (this.getExpedientesData()),
        );
        this.$watch(
            '$refs.child_del.medico_eliminado',
            () => (this.getExpedientesData()),
        );
        },
    created() {
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.getExpedientesData();
    },
}
</script>