
<template>
<button type="button" class="btn btn-light" @click="limpiarInputs()" data-bs-toggle="modal" data-bs-target="#agregarModal">Agregar Usuario</button>
<!-- Modal -->
    <div class="modal fade"  v-if="modalShow" id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content bg-dark">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Crear Evento</h5>
                    <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Usuario</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="usernameNuevoUsuario"  class="form-control" v-model="nuevo_usuario.usuario"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Contraseña</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="password" id="usernameNuevoContraseña"  class="form-control" v-model="nuevo_usuario.contraseña"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Nombre</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="nombreUsuario"  class="form-control" v-model="nuevo_usuario.nombre"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Apellido</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="apellidoUsuario" class="form-control" v-model="nuevo_usuario.apellido" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Correo</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="email" id="correoUsuario" class="form-control" v-model="nuevo_usuario.correo" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Teléfono Casa</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="telefonoUsuario" class="form-control" v-model="nuevo_usuario.telefono" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Celular</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="celularUsuario" class="form-control" v-model="nuevo_usuario.celular" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Dirección</h6>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" id="direccionUsuario" class="form-control" v-model="nuevo_usuario.direccion" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Departamento</h6>
                        </div>
                        <div class="col-sm-9">
                            <vue-select
                                :options="departamentosData"
                                v-model="nuevo_usuario.departamento"
                                label-by="nombre"
                                track-by="nombre"
                                value-by="id"
                                search-placeholder="Selecciona un Departamento"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Departamento"
                                close-on-select>
                                <template #label="{ selected }">
                                <template v-if="selected">
                                    <div style="color:white;">{{ selected.nombre }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Departamento</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.nombre }}</div>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Puesto</h6>
                        </div>
                        <div class="col-sm-9">
                            <vue-select
                                :options="puestosData"
                                v-model="nuevo_usuario.puesto"
                                label-by="nombre"
                                track-by="nombre"
                                value-by="id"
                                search-placeholder="Selecciona un Puesto"
                                multiple
                                collapse-tags="true"
                                taggable
                                searchable
                                placeholder="Selecciona un Puesto"
                                close-on-select>
                                <template #label="{ selected }">
                                <template v-if="selected">
                                    <div style="color:white;">{{ selected.nombre }}</div>
                                </template>
                                <template v-else > <div style="color:white;">Seleccione un Puesto</div></template>
                                </template>
                                <template #dropdown-item="{ option }">
                                <div style="color:white;">{{ option.nombre }}</div>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Imágen</h6>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group mb-3">
                                <label class="input-group-text" for="inputGroupFile01">Subir</label>
                                <input type="file" class="form-control" id="imagenU">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" @click="agregarNuevoUsuario()" class="btn btn-primary">Guardar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- End Modal -->
    </template>
    <style>
    </style>
    <script>
    import  Cookies from 'js-cookie';
    //import Select2 from 'vue3-select2-component';
    //import $ from 'jquery';
    //let select2 = require('@/assets/plugins/select2/js/select2.min.js');
    //import * as mykey from '../assets/js/mykey.js'
    import $ from 'jquery';
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')
    import VueNextSelect from 'vue-next-select'
    console.log(Cookies.get());
    export default {
        name: 'añadir_usuario',
        components:{
            'vue-select': VueNextSelect,
        },
    data() {
        return{
            modalShow: true,
            empresaData: [0],
            usuariosData: [],
            departamentosData: [0],
            puestosData: [0],
            usuario_añadido: false,
            nuevo_usuario: {
                usuario: '',
                contraseña: '',
                nombre: '',
                apellido: '',
                correo: '',
                telefono: '',
                celular: '',
                direccion: '',
                departamento: '',
                puesto: '',

            },
            selectedDepartamento: '',
            selectedPuesto: null,
            buscarUsuario: '',
            URL_TENANT: '',

            }
        },
        watch: {
            nuevo_usuario: {
            handler: function () {
                if(this.nuevo_usuario.departamento.length > 1 ){
                    this.nuevo_usuario.departamento.shift();
                }
                if(this.nuevo_usuario.puesto.length > 1){
                    this.nuevo_usuario.puesto.shift();
                }
                if(this.nuevo_usuario.departamento.length > 0 ){
                    this.getPuestosDepartamentoData();
                }
            },
            deep: true,
        },
        },
    computed: {
    },
    methods: {
        async limpiarInputs() {
            console.log("CLEAN AÑADIR DATA");
            this.usuario_añadido = false;
            this.nuevo_usuario = {
                usuario: '',
                contraseña: '',
                nombre: '',
                apellido: '',
                correo: '',
                telefono: '',
                celular: '',
                direccion: '',
                departamento: '',
                puesto: '',
            }
        },
        async getEmpresaData() {
            const response = await fetch(this.URL_TENANT+'/api/empresa/');
            this.empresaData = await response.json();
        },
        async getDeparamentosData() {
            const response = await fetch(this.URL_TENANT+'/api/departamento/');
            this.departamentosData = await response.json();
        },
        async getPuestosData(){
            const response = await fetch(this.URL_TENANT+'/api/puesto/')
            this.puestosData = await response.json();
        },
        async getPuestosDepartamentoData(){
            console.log("NUEVOS PUESTOS");
            console.log(this.selectedDepartamento)
            const response = await fetch(this.URL_TENANT+'/api/puesto?departamento='+this.nuevo_usuario.departamento+'')
            this.puestosData = await response.json();
        },
        async agregarNuevoUsuario(){
            console.log("token");
            console.log(Cookies.get('csrftoken'));
            if (!this.nuevo_usuario.usuario || !this.nuevo_usuario.contraseña){
                notificaciones.round_error_noti($.msg='Nombre de Usuario y Contraseña Obligatorios');
            }else{
                try{
                    const imagenUsuario = document.getElementById("imagenU");
                    const formData = new FormData();
                    formData.append("username", this.nuevo_usuario.usuario);
                    formData.append("password", this.nuevo_usuario.contraseña);
                    formData.append("first_name", this.nuevo_usuario.nombre);
                    formData.append("last_name", this.nuevo_usuario.apellido);
                    formData.append("email", this.nuevo_usuario.correo);
                    formData.append("is_staff", "true");
                    formData.append("is_active", "true");
                    formData.append("is_superuser", "false");
                    formData.append("telefono", this.nuevo_usuario.telefono);
                    formData.append("celular", this.nuevo_usuario.celular);
                    formData.append("direccion", this.nuevo_usuario.direccion);
                    formData.append("puesto", this.nuevo_usuario.puesto);
                    formData.append("empresa", this.empresaData[0].id);
                    formData.append("puesto_nombre", '');
                    formData.append("departamento_nombre", '');
                    if(imagenUsuario.files.length == 0){
                        formData.append("imagen", '');

                    }else{
                        formData.append("imagen", imagenUsuario.files[0]);
                    }
                    console.log("DATOS USER");
                    for (const value of formData.values()){
                    console.log(value);
                    }
                    await fetch(this.URL_TENANT+'/api/user_create/',{
                    method: 'post',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: formData})
                notificaciones.round_success_noti($.msg='Nuevo Usuario Agregado');
                //document.getElementById('agregarModal').click();
                this.usuario_añadido = true;
                document.getElementById('close').click();
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
            }
        }},
    mounted() {
        },
    created() {
          // Fetch tasks on page load
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.getDeparamentosData();
        this.getPuestosData();
        this.getEmpresaData();
        },
    }
    </script>