<template>
      <Header/>
      <Nav/>
      <Departamento/>

</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'
import Departamento from './components/empresa/departamento.vue'



export default {
  name: 'DepartamentoComponent',

  components: {
    Header,
	Nav,
	Departamento,
  },

  data: () => ({
    //
  }),
}
</script>
