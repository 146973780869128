
<template>
  <div class="page-wrapper">
			<div class="page-content">
				<div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4">
					<div class="col" v-for="item in aplicacionesData" :key="item.id">
						<div class="card" style="height: 500px;">
              <div :id="'carouselExampleCaptions'+item.id" class="carousel slide" data-bs-ride="carousel">
                <ol class="carousel-indicators">
                  <li  v-for="(im, index) in item.imagenes_aplicaciones_navy_app" :key="im.id" :data-bs-target="'#carouselExampleCaptions'+item.id" :data-bs-slide-to="index" :class="{active: index === 0}"></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item" :class="{active: index === 0}" v-for="(im, index) in item.imagenes_aplicaciones_navy_app" :key="im.id">
                    <img :src="im.imagen" class="d-block w-100" alt="...">
                    <div class="carousel-caption d-none d-md-block">
                    </div>
                  </div>
                </div>
                <a class="carousel-control-prev" :href="'#carouselExampleCaptions'+item.id" role="button" data-bs-slide="prev">	<span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </a>
                <a class="carousel-control-next" :href="'#carouselExampleCaptions'+item.id" role="button" data-bs-slide="next">	<span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </a>
              </div>
							<!--<img :src="item.imagenes_aplicaciones_navy_app[0].imagen" class="card-img-top" alt="...">-->
							<div class="card-body" style="height: 100%; display: flex; flex-direction: column; justify-content: space-between;">
								<h5 class="card-title">{{ item.nombre }}</h5>
								<p class="card-text">{{ item.descripcion }}.</p>	<a href="javascript:;" class="btn btn-light">Conocer Más</a>
							</div>
						</div>
					</div>
			</div>
			</div>
		</div>
</template>
<style>

</style>
<script>
export default {
    name: 'home_component',
    components:{
    },
data() {
	return{
    URL_TENANT: '',
    aplicacionesData: [],
		}
	},

methods: {
  async getAplicacionesData(){
            //const response = await fetch(this.URL_TENANT+'/api/aplicaciones')
            //this.aplicacionesData = await response.json();
            this.aplicacionesData = [];
        },

    },

mounted() {
    },
created() {
    const result = window.location.origin;
    this.URL_TENANT = result;
    this.getAplicacionesData();
	},
}

</script>
