
<template>
    <div class="page-wrapper">
        <div class="page-content">
            <!--breadcrumb-->
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Consultorio Virtual</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Asistente</li>
                        </ol>
                    </nav>
                </div>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-light">Settings</button>
                        <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	<a class="dropdown-item" href="javascript:;">Action</a>
                            <a class="dropdown-item" href="javascript:;">Another action</a>
                            <a class="dropdown-item" href="javascript:;">Something else here</a>
                            <div class="dropdown-divider"></div>	<a class="dropdown-item" href="javascript:;">Separated link</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end breadcrumb-->
            <!-- MODAL MODIFICAR -->
            <div class="modal fade"  id="modificarModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content bg-dark">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Modificar Médico</h5>
                            <button type="button" id="closemod" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <mod_event :id_asistente="id_asistente" ref="child_mod"></mod_event>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button"  data-bs-dismiss="modal" @click="$refs.child_mod.modificarAsistente(); " class="btn btn-success">Modificar</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->
            <div class="modal fade"  id="eliminarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content bg-dark">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Eliminar Consultorio</h5>
                            <button type="button" id="closedel" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <!--  <del_event :id_asistente="id_asistente" :titulo="titulo" ref="child_del"></del_event>-->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button"  data-bs-dismiss="modal" @click="$refs.child_del.eliminarConsultorio(); " class="btn btn-danger">Eliminar</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->
            <div class="card">
                <div class="card-body">
                    <div class="d-lg-flex align-items-center mb-4 gap-3">
                        <div class="position-relative">
                            <input type="text" class="form-control ps-5 radius-30" v-model="buscarAsistente" placeholder="Buscar Asistente"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                        </div>
                        <div class="ms-auto">
                            <add_event ref="child_add"></add_event>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th>Nombre</th>
                                    <th>Apellido</th>
                                    <th>Teléfono</th>
                                    <th>Correo</th>
                                    <th>Médico Asignado</th>
                                    <th>Consultorio</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in asistentesDataFilter" :key="item.id">
                                    <td>{{item.first_name}}</td>
                                    <td>{{item.last_name}}</td>
                                    <td>{{item.telefono}}</td>
                                    <td>{{item.email}}</td>
                                    <td>{{item.medico_nombre}}</td>
                                    <td>{{item.consultorio_nombre}}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modificarModal1" @click="id_asistente=item.id;
                                            " class=""><i class='bx bxs-edit'></i></a>
                                            <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#eliminarModal" @click="id_asistente=item.id;
                                            titulo=item.first_name" class="ms-3"><i class='bx bxs-trash'></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>
<style>
</style>
<script>

import add_event from "./agregar_asistente.vue"
import mod_event from "./modificar_asistente.vue"
//import del_event from "./eliminar_medico.vue"

export default {
    name: 'medicos_component',
    components:{
        add_event,
        mod_event,
        /*del_event,*/
    },
data() {
    return{
        URL_TENANT: '',
        asistentesData:  [],
        buscarAsistente: '',
        id_asistente: '',
        titulo: '',
        }
    },
    watch: {
},
    computed: {
        asistentesDataFilter() {
        console.log("LENGT");
        if (this.buscarAsistente != ''){
            return this.asistentesData.filter((item) => {
            return item.first_name.toLowerCase().includes(this.buscarAsistente.toLowerCase()) || item.last_name.toLowerCase().includes(this.buscarAsistente.toLowerCase())
        })
        }else{
            return this.asistentesData;
        }
    },
    },

    methods: {
        async getAsistentesData(){
            const response = await fetch(this.URL_TENANT+'/api/asistente_get/')
            this.asistentesData = await response.json();
        },
    },
    mounted() {
        this.$watch(
            "$refs.child_add.asistente_añadido",
            () => (this.getAsistentesData()),
        );
        this.$watch(
            '$refs.child_mod.asistente_modificado',
            () => (this.getAsistentesData()),
        );
        this.$watch(
            '$refs.child_del.asistente_eliminado',
            () => (this.getAsistentesData()),
        );
        },
    created() {
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.getAsistentesData();
    },
}
</script>