<template>
      <Header/>
      <Nav/>
      <DetalleVenta/>

</template>

<script>
import Header from './components/header.vue'
import Nav from './components/nav.vue'
import DetalleVenta from './components/venta/detalle_venta.vue'



export default {
  name: 'DetalleVentaComponent',

  components: {
  Header,
	Nav,
	DetalleVenta,
  },

  data: () => ({
    //
  }),
}
</script>
