
<template>
    <div class="page-wrapper">
                <div class="page-content">
                    <!--breadcrumb-->
                    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div class="breadcrumb-title pe-3">Compra</div>
                        <div class="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0 p-0">
                                    <li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">Orden de Compra</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <!--end breadcrumb-->
                    <div  v-if="option==1"  class="card">
                        <div class="card-body">
                            <div class="d-lg-flex align-items-center mb-4 gap-3">
                                <div class="position-relative">
                                    <input type="text" class="form-control ps-5 radius-30" v-model="buscarOrdenCompra" placeholder="Buscar Orden de Compra"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                                </div>
                            <div class="ms-auto"><a href="javascript:;" data-bs-toggle="modal"  data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Crear Nueva Orden de Compra</a></div>
                            </div>
                            <!-- Button trigger modal -->
                            <!-- Modal -->
                            <div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content bg-dark">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">Crear Orden de Compra</h5>
                                            <button type="button" class="btn-close" id="agregar_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Cotización</h6>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="conCotizacion">
                                                        <label v-if="conCotizacion == false" class="form-check-label" for="flexCheckChecked">Click para buscar</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-8" v-if="conCotizacion == true">
                                                    <vue-select  :options="cotizacionesData"
                                                        v-model="orden_compra.cotizacion_compra"
                                                        label-by="referencia"
                                                        track-by="referencia"
                                                        value-by="id"
                                                        search-placeholder="Selecciona una Cotización"
                                                        multiple
                                                        collapse-tags="true"
                                                        taggable
                                                        searchable
                                                        placeholder="Selecciona una Cotización"
                                                        close-on-select>
                                                        <template #label="{ selected }">
                                                        <template v-if="selected">
                                                            <div style="color:white;">{{ selected.referencia }}</div>
                                                        </template>
                                                        <template v-else > <div style="color:white;">Seleccione una Cotización</div></template>
                                                        </template>
                                                        <template #dropdown-item="{ option }">
                                                        <div style="color:white;">{{ option.referencia }}</div>
                                                        </template>
                                                    </vue-select>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Proveedor</h6>
                                                </div>
                                                <div class="col-sm-10">
                                                    <vue-select  :options="proveedoresData"
                                                        v-model="orden_compra.proveedor"
                                                        label-by="nombre"
                                                        track-by="nombre"
                                                        value-by="id"
                                                        search-placeholder="Selecciona un Proveedor"
                                                        multiple
                                                        collapse-tags="true"
                                                        taggable
                                                        searchable
                                                        placeholder="Selecciona un Proveedor"
                                                        close-on-select>
                                                        <template #label="{ selected }">
                                                        <template v-if="selected">
                                                            <div style="color:white;">{{ selected.nombre }}</div>
                                                        </template>
                                                        <template v-else > <div style="color:white;">Seleccione un Proveedor</div></template>
                                                        </template>
                                                        <template #dropdown-item="{ option }">
                                                        <div style="color:white;">{{ option.nombre }}</div>
                                                        </template>
                                                    </vue-select>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Fecha</h6>
                                                </div>
                                                <div class="col-sm-10">
                                                    <Datepicker
                                                    v-model="orden_compra.fecha"
                                                    dark placeholder="Selecciona una Fecha"
                                                    autoApply
                                                    :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                    :enableTimePicker="false"
                                                    monthNameFormat="long"
                                                    :format="format"
                                                    modelType="dd-MM-yyyy"
                                                    locale="es">
                                                    </Datepicker>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                            <button type="button" @click="crearOrden()" class="btn btn-primary">Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Modal -->
                            <!-- Modal Modify -->
                            <div class="modal fade"  id="modificarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content bg-dark">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">Modificar Categoría</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row mb-3">
                                                <div class="col-sm-3">
                                                    <h6 class="mb-0">Categoria</h6>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input type="text" id="nombreNuevaCategoria"  class="form-control" v-model="nombreNuevaCategoria"  />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                        <div class="row mb-3">
                                                <div class="col-sm-3">
                                                    <h6 class="mb-0">Estado</h6>
                                                </div>
                                                <div class="col-sm-9">
                                                    <vue-select   :options="estados"
                                                        v-model="selectedEstado"
                                                        label-by="nombre"
                                                        hide-selected="true"
                                                        track-by="nombre"
                                                        value-by="id"
                                                        placeholder="Selecciona un Estado"
                                                        close-on-select>
                                                        <template #label="{ selected }">
                                                        <template v-if="selected">
                                                            <div style="color:white;">{{ selected.nombre }}</div>
                                                        </template>
                                                        <template v-else > <div style="color:white;">Seleccione un Estado</div></template>
                                                        </template>
                                                        <template #dropdown-item="{ option }" >
                                                        <div  style="color:white;">{{ option.nombre }}</div>
                                                        </template>
                                                    </vue-select>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                            <button type="button" @click="modificarCategoria" class="btn btn-primary">Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Modal Modify -->
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th>#Referencia</th>
                                            <th>Fecha</th>
                                            <th>Proveedor</th>
                                            <th>Estado</th>
                                            <th>Ver Detalle</th>
                                            <th>Cotización</th>
                                            <th>Usuario</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in ordenComprasDataFilter" :key="item.id">
                                            <td >
                                                <div class="d-flex align-items-center">
                                                    <div>
                                                        <input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-0 font-14">#{{item.referencia}}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{item.fecha}}</td>
                                            <td>{{item.proveedor_nombre}}</td>
                                            <td>
                                                <div class="badge rounded-pill bg-light p-2 text-uppercase px-3">
                                                    <div v-if="[[item.estado]] == '9'"> <i  class='bx bx-minus me-1'></i>Anulado</div>
                                                    <div v-if="[[item.estado]] == 'A'"> <i  class='bx bx-radio-circle me-1'></i>Abierto</div>
                                                    <div v-if="[[item.estado]] == 'C'"> <i  class='bx bx-radio-circle-marked me-1'></i>Cerrado</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="item.detalle_orden_compra.length >0">
                                                    <button type="button" data-bs-toggle="modal" :data-bs-target="'#detalleOrdenCompra'+item.id" class="btn btn-light position-relative me-lg-5"> <i class='bx bx-comment-detail align-middle'></i> Ver Productos <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{item.detalle_orden_compra.length}}</span>
                                                    </button>
                                                    <!-- Modal -->
                                                    <div class="modal fade" :id="'detalleOrdenCompra'+item.id" tabindex="-1" aria-hidden="true">
                                                        <div class="modal-dialog modal-lg">
                                                            <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title">Detalle de Cotización Compra</h5>
                                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div class="card">
                                                                        <div class="card-body">
                                                                            <table class="table mb-0">
                                                                                <thead class="table-light">
                                                                                    <tr>
                                                                                        <th>Cantidad</th>
                                                                                        <th>Unidad Medida</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="item2 in item.detalle_orden_compra" :key="item2.id">
                                                                                        <td>{{item2.cantidad}}</td>
                                                                                        <td>{{item2.unidad_medida_nombre}} / {{item2.unidad_medida_nombre_unidad}}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- FIN Modal -->
                                                </div>
                                                <div v-else>
                                                    <button v-if="[[item.estado]] == 'A'" type="button" @click="fillModificarOrdenCompra([[item.id]],1)" class="btn btn-light position-relative me-lg-5"> <i class='bx bx-comment-detail align-middle'></i> Agregar Productos
                                                    </button>
                                                </div>
                                            </td>
                                            <td>
                                                <button v-if="item.cotizacion_compra" type="button" class="btn btn-light position-relative me-lg-5"> <i class='bx bx-left-top-arrow-circle align-middle'></i> Ver Cotización</button>
                                            </td>
                                            <td>{{item.nombre_usuario}}</td>
                                            <td>
                                                <div class="d-flex order-actions">
                                                    <a href="javascript:;" @click="fillModificarOrdenCompra([[item.id]],1)" class=""><i class='bx bxs-edit'></i></a>
                                                    <a href="javascript:;" class="ms-3"><i class='bx bxs-trash'></i></a>
                                                    <div class="ms-3">
                                                            <!-- Modal Eliminar -->
                                                        <div class="modal fade"  id="convertirOrdenModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog">
                                                                <div class="modal-content bg-dark">
                                                                    <div class="modal-header">
                                                                        <h5 class="modal-title" id="exampleModalLabel">Convertir en Orden de Compra</h5>
                                                                        <button type="button" class="btn-close" id="convertir_orden_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                            ¿Desea convertir la Requisición {{crearOrdenCompra.referencia}} en una Orden de Compra?
                                                                    </div>
                                                                    <div class="modal-footer">
                                                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                        <button type="button" @click="convertirOrdenCompra()" class="btn btn-primary">Crear Orden de Compra</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- End Modal Eliminar -->
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
    
    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Paginacion -->
                        <div class="card-body">
                            <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="javascript:;" @click="cambiarUrl(ordenComprasDatalinks.previous, 'atras')">Atrás</a>
                                        </li>
                                        <li class="page-item" :class="{active: indexPagination == index}" v-for="index in ordenComprasDatatotal_pages" :key="index">
                                            <a class="page-link"  href="javascript:;javascript:;" @click="cambiarUrl(this.URL_TENANT+'/api/cotizacion_compra/?page='+index+'', index)">{{ index }}</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="javascript:;" @click="cambiarUrl(ordenComprasDatalinks.next, 'adelante')">Siguiente</a>
                                        </li>
                                    </ul>
                            </nav>
                        </div>
                        <!-- Fin Paginacion -->
                    </div>
                    <!-- Modificar Card -->
                    <div v-if="option==2" class="card">
                        <!--INICIO MODIFICAR-->
                        <div class="card">
                        <div class="card-body">
                            <div class="row">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#requisicionCompra" role="tab" aria-selected="true">
                                        <div class="d-flex align-items-center">
                                            <div class="tab-icon"><i class='bx bx-spreadsheet font-18 me-1'></i>
                                            </div>
                                            <div class="tab-title">Editar Cotización de Compra</div>
                                        </div>
                                    </a>
                                </li>
                                <div class="ms-auto">
                                    <button type="button" @click="cambiarEstado(idOption=1)" class="btn btn-light" style="margin-right:10px;">Regresar</button>
                            </div>
                            </ul>
                            </div>
                            <div class="tab-content py-3">
                                <div class="tab-pane fade show active" id="requisicionCompra" role="tabpanel">
                                    <div class="card">
                                        <div class="card-body p-4">
                                            <div class="row">
                                                <div class="col-lg-10">
                                                    <h5 class="card-title">Orden de Compra</h5>
                                                </div>
                                                <div class="col-lg-2">
                                                    <button type="button"   @click="modificarOrdenCompra()" class="btn btn-light">Modificar Orden de Compra</button>
                                                </div>
                                            </div>
    
                                            <hr/>
                                            <div class="form-body mt-4">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                <div class="border border-3 p-4 rounded">
                                                    <div class="modal-body">
                                                        <div class="row mb-3">
                                                            <div class="col-sm-1">
                                                                <h6 class="mb-0">#Referencia</h6>
                                                            </div>
                                                            <div class="col-sm-5">
                                                                <h4>{{ ordenCompraModificar.referencia }}</h4>
                                                            </div>
                                                                <div class="col-sm-1">
                                                                    <h6 class="mb-0">Estado</h6>
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <vue-select   :options="estados"
                                                                        v-model="ordenCompraModificar.estado"
                                                                        label-by="nombre"
                                                                        hide-selected="true"
                                                                        track-by="nombre"
                                                                        value-by="id"
                                                                        placeholder="Selecciona un Estado"
                                                                        close-on-select>
                                                                        <template #label="{ selected }">
                                                                        <template v-if="selected">
                                                                            <div style="color:white;">{{ selected.nombre }}</div>
                                                                        </template>
                                                                        <template v-else > <div style="color:white;">Seleccione un Estado</div></template>
                                                                        </template>
                                                                        <template #dropdown-item="{ option }" >
                                                                        <div  style="color:white;">{{ option.nombre }}</div>
                                                                        </template>
                                                                    </vue-select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-sm-1">
                                                                    <h6 class="mb-0">Cotización de Compra</h6>
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <vue-select  :options="cotizacionesData"
                                                                        v-model="ordenCompraModificar.cotizacion_compra"
                                                                        label-by="referencia"
                                                                        track-by="referencia"
                                                                        value-by="id"
                                                                        search-placeholder="Selecciona una Requisicion"
                                                                        multiple
                                                                        collapse-tags="true"
                                                                        taggable
                                                                        searchable
                                                                        placeholder="Selecciona una Requisicion"
                                                                        close-on-select>
                                                                        <template #label="{ selected }">
                                                                        <template v-if="selected">
                                                                            <div style="color:white;">{{ selected.referencia }}</div>
                                                                        </template>
                                                                        <template v-else > <div style="color:white;">Seleccione una Requisicion</div></template>
                                                                        </template>
                                                                        <template #dropdown-item="{ option }">
                                                                        <div style="color:white;">{{ option.referencia }}</div>
                                                                        </template>
                                                                    </vue-select>
                                                                </div>
                                                                <div class="col-sm-1">
                                                                    <h6 class="mb-0">Proveedor</h6>
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <vue-select  :options="proveedoresData"
                                                                        v-model="ordenCompraModificar.proveedor"
                                                                        label-by="nombre"
                                                                        track-by="nombre"
                                                                        value-by="id"
                                                                        search-placeholder="Selecciona un Proveedor"
                                                                        multiple
                                                                        collapse-tags="true"
                                                                        taggable
                                                                        searchable
                                                                        placeholder="Selecciona un Proveedor"
                                                                        close-on-select>
                                                                        <template #label="{ selected }">
                                                                        <template v-if="selected">
                                                                            <div style="color:white;">{{ selected.nombre }}</div>
                                                                        </template>
                                                                        <template v-else > <div style="color:white;">Seleccione un Proveedor</div></template>
                                                                        </template>
                                                                        <template #dropdown-item="{ option }">
                                                                        <div style="color:white;">{{ option.nombre }}</div>
                                                                        </template>
                                                                    </vue-select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                    <div class="col-sm-1">
                                                                        <h6 class="mb-0">Fecha</h6>
                                                                    </div>
                                                                    <div class="col-sm-5">
                                                                        <Datepicker
                                                                        v-model="ordenCompraModificar.fecha"
                                                                        dark placeholder="Selecciona una Fecha"
                                                                        autoApply
                                                                        :dayNames="['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do']"
                                                                        :enableTimePicker="false"
                                                                        monthNameFormat="long"
                                                                        :format="format"
                                                                        modelType="dd-MM-yyyy"
                                                                        locale="es">
                                                                        </Datepicker>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    <div class="card-body p-4">
                                                        <div class="row">
                                                            <div class="col-lg-10">
                                                                <h5 class="card-title">Detalle de Orden de Compra</h5>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <button type="button" data-bs-toggle="modal" v-if="[[ordenCompraModificar.estado]] == 'A'" @click="getProductosUnidadMedida()" data-bs-target="#agregarDetalleModal" class="btn btn-light">Añadir Producto a Orden de Compra</button>
                                                                <!-- Modal -->
                                                                <div class="modal fade"  id="agregarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                    <div class="modal-dialog modal-lg">
                                                                        <div class="modal-content bg-dark">
                                                                            <div class="modal-header">
                                                                                <h5 class="modal-title" id="exampleModalLabel">Añadir Producto a Orden de Compra</h5>
                                                                                <button type="button" class="btn-close" id="agregar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                            </div>
                                                                            <div class="modal-body">
                                                                                <div class="row mb-3">
                                                                    <div class="col-sm-4">
                                                                        <h6 class="mb-0">Producto / Unidad Medida</h6>
                                                                    </div>
                                                                    <div class="col-sm-8">
                                                                        <vue-select  :options="productosUnidadMedidaData"
                                                                            v-model="detalleOrdenCompra.producto_um"
                                                                            label-by="nombre_producto"
                                                                            track-by="nombre_producto"
                                                                            value-by="id"
                                                                            search-placeholder="Selecciona un Producto"
                                                                            multiple
                                                                            collapse-tags="true"
                                                                            taggable
                                                                            searchable
                                                                            placeholder="Selecciona un Producto"
                                                                            close-on-select>
                                                                            <template #tag="{ option, remove }">
                                                                            <div>
                                                                            {{ option.nombre_producto }} - {{ option.nombre_unidad_medida }}/{{option.cantidad_unidad_medida}}
                                                                            <img style="display: inline-flex;" @click.stop="remove" src="data:image/svg+xml;base64,PHN2ZyBpZD0iZGVsZXRlIiBkYXRhLW5hbWU9ImRlbGV0ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHRpdGxlPmRlbGV0ZTwvdGl0bGU+PHBhdGggZD0iTTI1NiwyNEMzODMuOSwyNCw0ODgsMTI4LjEsNDg4LDI1NlMzODMuOSw0ODgsMjU2LDQ4OCwyNC4wNiwzODMuOSwyNC4wNiwyNTYsMTI4LjEsMjQsMjU2LDI0Wk0wLDI1NkMwLDM5Ny4xNiwxMTQuODQsNTEyLDI1Niw1MTJTNTEyLDM5Ny4xNiw1MTIsMjU2LDM5Ny4xNiwwLDI1NiwwLDAsMTE0Ljg0LDAsMjU2WiIgZmlsbD0iIzViNWI1ZiIvPjxwb2x5Z29uIHBvaW50cz0iMzgyIDE3Mi43MiAzMzkuMjkgMTMwLjAxIDI1NiAyMTMuMjkgMTcyLjcyIDEzMC4wMSAxMzAuMDEgMTcyLjcyIDIxMy4yOSAyNTYgMTMwLjAxIDMzOS4yOCAxNzIuNzIgMzgyIDI1NiAyOTguNzEgMzM5LjI5IDM4MS45OSAzODIgMzM5LjI4IDI5OC43MSAyNTYgMzgyIDE3Mi43MiIgZmlsbD0iIzViNWI1ZiIvPjwvc3ZnPg==" alt="delete tag" class="icon delete">
                                                                            </div>
                                                                            </template>
                                                                            <template #label="{ selected }">
                                                                            <template v-if="selected">
                                                                                <div style="color:white;">{{ selected.nombre_producto }}</div>
                                                                            </template>
                                                                            <template v-else > <div style="color:white;">Seleccione un Producto</div></template>
                                                                            </template>
                                                                            <template #dropdown-item="{ option }">
                                                                            <div style="color:white;">{{ option.nombre_producto }} - {{ option.nombre_unidad_medida}}/{{option.cantidad_unidad_medida}} </div>
                                                                            </template>
                                                                        </vue-select>
                                                                    </div>
                                                                    </div>
                                                                    <div class="row mb-3">
                                                                        <div class="col-sm-4">
                                                                            <h6 class="mb-0">Cantidad a Solicitar</h6>
                                                                        </div>
                                                                        <div class="col-sm-8">
                                                                        <input class="form-control" type="number" v-model="detalleOrdenCompra.cantidad" placeholder="Cantidad de Unidades" rows="3">
                                                                        </div>
                                                                    </div>
                                                                            </div>
                                                                            <div class="modal-footer">
                                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                <button type="button" @click="crearDetalleOrdenCompra" class="btn btn-primary">Crear</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- End Modal -->
                                                            </div>
                                                        </div>
                                            <hr/>
                                            <div class="form-body mt-4">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="border border-3 p-4 rounded">
                                                        <div class="modal-body">
                                                            <table class="table mb-0">
                                                                <thead class="table-light">
                                                                    <tr>
                                                                        <th>Cantidad</th>
                                                                        <th>Unidad Medida</th>
                                                                        <th>Precio</th>
                                                                        <th>Acciones</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="item2 in detalleordenCompraModificar" :key="item2.id">
                                                                        <td>{{item2.cantidad}}</td>
                                                                        <td>{{item2.unidad_medida_nombre}} / {{item2.unidad_medida_nombre_unidad}}</td>
                                                                        <td>{{item2.precio_compra}}</td>
                                                                        <td>
                                                                            <div class="d-flex order-actions">
                                                                                <a href="javascript:;" data-bs-toggle="modal" v-if="[[ordenCompraModificar.estado]] == 'A'" @click="fillDetalleModificar(item2.id, item2.producto_um, item2.cantidad, item2.precio_compra);" data-bs-target="#modificarDetalleModal" class=""><i class='bx bxs-edit'></i></a>
    
                                                                                <!-- Modal -->
                                                                                <div class="modal fade"  id="modificarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                                    <div class="modal-dialog modal-lg">
                                                                                        <div class="modal-content bg-dark">
                                                                                            <div class="modal-header">
                                                                                                <h5 class="modal-title" id="exampleModalLabel">Modificar Producto a Cotización</h5>
                                                                                                <button type="button" class="btn-close" id="modificar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                            </div>
                                                                                            <div class="modal-body">
                                                                                                <div class="row mb-3">
                                                                                                    <!--
                                                                                    <div class="col-sm-4">
                                                                                        <h6 class="mb-0">Producto / Unidad Medida</h6>
                                                                                    </div>
                                                                                    <div class="col-sm-8">
                                                                                        <vue-select  :options="productosUnidadMedidaData"
                                                                                            v-model="detalleCotizacion.producto_um"
                                                                                            label-by="nombre_producto"
                                                                                            track-by="nombre_producto"
                                                                                            value-by="id"
                                                                                            search-placeholder="Selecciona un Producto"
                                                                                            multiple
                                                                                            collapse-tags="true"
                                                                                            taggable
                                                                                            searchable
                                                                                            placeholder="Selecciona un Producto"
                                                                                            close-on-select>
                                                                                            <template #tag="{ option, remove }">
                                                                                            <div>
                                                                                            {{ option.nombre_producto }} - {{ option.nombre_unidad_medida }}/{{option.cantidad_unidad_medida}}
                                                                                            <img style="display: inline-flex;" @click.stop="remove" src="data:image/svg+xml;base64,PHN2ZyBpZD0iZGVsZXRlIiBkYXRhLW5hbWU9ImRlbGV0ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHRpdGxlPmRlbGV0ZTwvdGl0bGU+PHBhdGggZD0iTTI1NiwyNEMzODMuOSwyNCw0ODgsMTI4LjEsNDg4LDI1NlMzODMuOSw0ODgsMjU2LDQ4OCwyNC4wNiwzODMuOSwyNC4wNiwyNTYsMTI4LjEsMjQsMjU2LDI0Wk0wLDI1NkMwLDM5Ny4xNiwxMTQuODQsNTEyLDI1Niw1MTJTNTEyLDM5Ny4xNiw1MTIsMjU2LDM5Ny4xNiwwLDI1NiwwLDAsMTE0Ljg0LDAsMjU2WiIgZmlsbD0iIzViNWI1ZiIvPjxwb2x5Z29uIHBvaW50cz0iMzgyIDE3Mi43MiAzMzkuMjkgMTMwLjAxIDI1NiAyMTMuMjkgMTcyLjcyIDEzMC4wMSAxMzAuMDEgMTcyLjcyIDIxMy4yOSAyNTYgMTMwLjAxIDMzOS4yOCAxNzIuNzIgMzgyIDI1NiAyOTguNzEgMzM5LjI5IDM4MS45OSAzODIgMzM5LjI4IDI5OC43MSAyNTYgMzgyIDE3Mi43MiIgZmlsbD0iIzViNWI1ZiIvPjwvc3ZnPg==" alt="delete tag" class="icon delete">
                                                                                            </div>
                                                                                            </template>
                                                                                            <template #label="{ selected }">
                                                                                            <template v-if="selected">
                                                                                                <div style="color:white;">{{ selected.nombre_producto }}</div>
                                                                                            </template>
                                                                                            <template v-else > <div style="color:white;">Seleccione un Producto</div></template>
                                                                                            </template>
                                                                                            <template #dropdown-item="{ option }">
                                                                                            <div style="color:white;">{{ option.nombre_producto }} - {{ option.nombre_unidad_medida}}/{{option.cantidad_unidad_medida}} </div>
                                                                                            </template>
                                                                                        </vue-select>
                                                                                    </div>
                                                                                    -->
                                                                                    </div>
                                                                                    <div class="row mb-3">
                                                                                        <div class="col-sm-4">
                                                                                            <h6 class="mb-0">Cantidad a Solicitar</h6>
                                                                                        </div>
                                                                                        <div class="col-sm-8">
                                                                                        <input class="form-control" type="number" v-model="detalleOrdenCompra.cantidad" placeholder="Cantidad de Unidades" rows="3">
                                                                                        </div>
                                                                                    </div>
                                                                                            </div>
                                                                                            <div class="modal-footer">
                                                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                                <button type="button" @click="modificarDetalleOrdenCompra()" class="btn btn-primary">Modificar</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <!-- End Modal -->
                                                                                <a href="javascript:;" v-if="[[ordenCompraModificar.estado]] == 'A'" @click="fillEliminarDetalleOrdenCompra(item2.id, item2.unidad_medida_nombre,item2.unidad_medida_nombre_unidad, item2.cantidad)" data-bs-target="#eliminarDetalleModal" data-bs-toggle="modal"  class="ms-3"><i class='bx bxs-trash'></i></a>
                                                                                <!-- Modal Eliminar -->
                                                                                <div class="modal fade"  id="eliminarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                                    <div class="modal-dialog modal-lg">
                                                                                        <div class="modal-content bg-dark">
                                                                                            <div class="modal-header">
                                                                                                <h5 class="modal-title" id="exampleModalLabel">Modificar Producto a Orden Compra</h5>
                                                                                                <button type="button" id="eliminar_detalle_close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                            </div>
                                                                                            <div class="modal-body">
                                                                                                <div class="row mb-3">
                                                                                                    ¿Desea Eliminar el Producto {{ detalleOrdenCompraEliminar.producto_nombre }}?
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="modal-footer">
                                                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                                                <button type="button" @click="eliminarDetalleOrdenCompra(detalleOrdenCompraEliminar.id)" class="btn btn-danger">Eliminar</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <!-- End Modal Eliminar -->
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!--end row-->
                                            </div>
                                        </div>
                                                </div>
                                            </div><!--end row-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--FIN MODIFICAR-->
    
                    </div>
                    <!-- Fin Modificar Card -->
    
                </div>
            </div>
    
    </template>
    <style>
    </style>
    <script>
    import  Cookies from 'js-cookie';
    //import Select2 from 'vue3-select2-component';
    //import $ from 'jquery';
    //let select2 = require('@/assets/plugins/select2/js/select2.min.js');
    //import * as mykey from '../assets/js/mykey.js'
    import $ from 'jquery';
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js')
    import VueNextSelect from 'vue-next-select';
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import { ref } from 'vue';
    console.log(Cookies.get());
    export default {
        name: 'orden_compra_component',
        components:{
            'vue-select': VueNextSelect,
            'Datepicker': Datepicker,
        },
    setup() {
        // In case of a range picker, you'll receive [Date, Date]
        const date = ref(new Date());
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
        return {
            date,
            format,
        }
    },
    data() {
        return{
            URL_TENANT: '',
            empresaData: [0],
            usuariosData: [0],
            option: 1,
            url: "/api/orden_compra/",
            indexPagination: 1,
            productosUnidadMedidaData: [],
            buscarOrdenCompra: '',
            ordenComprasData: [],
            estados: [
                {
                    id: 'A',
                    nombre: 'Abierto',
                },
                {
                    id: 'C',
                    nombre: 'Cerrado',
                },
                {
                    id: '9',
                    nombre: 'Anulado',
                }
            ],
            selectedEstado: null,
            // Lista Requisiciones:
            conCotizacion: false,
            cotizacionesData: [],
            // Proveedores:
            proveedoresData: [],
            // Crear Cotización:
            orden_compra: {
                fecha: '',
                proveedor: '',
                cotizacion_compra: '',
            },
            //Modificar Cotización:
            ordenCompraModificar: {
                id: null,
                fecha: null,
                fecha_vencimiento: null,
                cotizacion_compra: '',
                proveedor: [{}],
                referencia: null,
                estado: null
            },
            detalleOrdenCompra: {
                id: null,
                cantidad: null,
                producto_um: [],
                precio_compra: null,
            },
            detalleordenCompraModificar: [],
            detalleOrdenCompraEliminar: {
                id: null,
                producto_nombre: null,
                cantidad: null,
            },
            //Crear Orden Compra
            crearOrdenCompra: []
            }
        },

    computed: {
        ordenComprasDataFilter() {
            if (this.buscarOrdenCompra != ''){
                return this.ordenComprasData.results.filter((item) => {
                return item.referencia.toLowerCase().includes(this.buscarOrdenCompra.toLowerCase())
            })
            }else{
                return this.ordenComprasData.results;
            }
        },

    },
    watch:{
        conCotizacion: function(){
            if(this.conCotizacion == false){
                this.cotizacion_compra.requisicion_compra = '';
            }
        },
        url: function () {
            this.getOrdenComprasData();
        },
        orden_compra: {
            handler: function () {
                if(this.orden_compra.cotizacion_compra){
                    this.orden_compra.proveedor = [this.cotizacionesData.find(x => x.id === this.orden_compra.cotizacion_compra[0]).proveedor];
                }
                if(this.orden_compra.cotizacion_compra.length > 1){
                    this.orden_compra.cotizacion_compra.shift();
                }
                if(this.orden_compra.proveedor.length > 1){
                    this.orden_compra.proveedor.shift();
                }
            },
            deep: true,
        },
        detalleOrdenCompra: {
            handler: function () {
                if(this.detalleOrdenCompra.producto_um.length > 1){
                    this.detalleOrdenCompra.producto_um.shift();
                }
            },
            deep: true,
        },
        ordenCompraModificar: {
            handler: function () {
                if(this.ordenCompraModificar.proveedor.length > 1){
                    this.ordenCompraModificar.proveedor.shift();
                }
                if(this.ordenCompraModificar.cotizacion_compra){
                    if(this.ordenCompraModificar.cotizacion_compra.length > 1){
                        this.ordenCompraModificar.cotizacion_compra.shift();
                    }
                    if(this.ordenCompraModificar.cotizacion_compra.length > 0){
                        console.log("DATA FILTER");
                        console.log(this.ordenCompraModificar.cotizacion_compra[0]);
                        this.x =this.cotizacionesData.find(x => x.id === this.ordenCompraModificar.cotizacion_compra[0]);
                        console.log(this.cotizacionesData.find(x => x.id === this.ordenCompraModificar.cotizacion_compra[0]));
                        console.log(this.x);
                        this.ordenCompraModificar.proveedor = [this.cotizacionesData.find(x => x.id === this.ordenCompraModificar.cotizacion_compra[0]).proveedor];
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        //Modificar:
        async cambiarEstado(idOption) {
            this.option = idOption;
            if (idOption == 1){
                this.cotizacionesData.pop();
                this.ordenCompraModificar = {
                id: null,
                fecha: null,
                fecha_vencimiento: null,
                cotizacion_compra: '',
                proveedor: [{}],
                referencia: null,
                estado: null
                }
            }
        },
        async fillModificarOrdenCompra(idMod, id) {
            this.modificarId = idMod[0][0];
            console.log("MODIFICAR");
            console.log(this.modificarId);
            for(var k in this.ordenComprasData.results){
                console.log("For 1");
                console.log(this.ordenComprasData.results[k]);
                if(this.ordenComprasData.results[k].id==this.modificarId){
                    console.log("if 1");
                    this.ordenCompraModificar.proveedor = [this.ordenComprasData.results[k].proveedor];
                    this.ordenCompraModificar.id = this.ordenComprasData.results[k].id;
                    this.ordenCompraModificar.fecha = this.ordenComprasData.results[k].fecha;
                    this.ordenCompraModificar.referencia = this.ordenComprasData.results[k].referencia;
                    this.ordenCompraModificar.estado = this.ordenComprasData.results[k].estado;
                    //Llenamos Requisición:
                    if(this.ordenComprasData.results[k].cotizacion_compra){
                        if(id == 1){
                            const response = await fetch(this.URL_TENANT+'/api/cotizacion_compra/'+this.ordenComprasData.results[k].cotizacion_compra+'/');
                            this.cotizacionUp = await response.json();
                            const obj1 = {
                                id: this.cotizacionUp.id,
                                referencia: this.cotizacionUp.referencia,
                                fecha: this.cotizacionUp.fecha,
                                proveedor: this.cotizacionUp.proveedor,
                            }
                            this.cotizacionesData.push(obj1);
                        }
                        this.ordenCompraModificar.cotizacion_compra = [this.ordenComprasData.results[k].cotizacion_compra];
                    } // Finaliza
                    this.detalleordenCompraModificar = [];
                    if(this.ordenComprasData.results[k].detalle_orden_compra)
                        for(var j in this.ordenComprasData.results[k].detalle_orden_compra){
                            console.log("for 2");
                            //Lleno el detalle a modificar:
                            const obj = {
                                id: this.ordenComprasData.results[k].detalle_orden_compra[j].id,
                                cantidad: this.ordenComprasData.results[k].detalle_orden_compra[j].cantidad,
                                requisicion_compra: this.ordenComprasData.results[k].detalle_orden_compra[j].requisicion_compra,
                                producto_um: this.ordenComprasData.results[k].detalle_orden_compra[j].producto_um,
                                unidad_medida_nombre: this.ordenComprasData.results[k].detalle_orden_compra[j].unidad_medida_nombre,
                                unidad_medida_nombre_unidad: this.ordenComprasData.results[k].detalle_orden_compra[j].unidad_medida_nombre_unidad,
                                precio_compra: this.ordenComprasData.results[k].detalle_orden_compra[j].precio_compra,
                            }
                            this.detalleordenCompraModificar.push(obj);
                        }
                }
            }
            this.option=2;
        },
        async modificarOrdenCompra(){
            if (!this.ordenCompraModificar.fecha || !this.ordenCompraModificar.id || !this.ordenCompraModificar.proveedor[0]){
                notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
            }else{
                console.log("ESTAS UPDETEANDO");
                try{
                    const formData = new FormData();
                    console.log("ESTAS UPDETEANDO 2");
                    formData.append("fecha", this.ordenCompraModificar.fecha);
                    formData.append("cotizacion_compra", this.ordenCompraModificar.cotizacion_compra[0]);
                    formData.append("proveedor", this.ordenCompraModificar.proveedor[0]);
                    formData.append("estado", this.ordenCompraModificar.estado);
                    await fetch(this.URL_TENANT+'/api/orden_compra/'+this.ordenCompraModificar.id+'/',{
                    method: 'put',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: formData})
                notificaciones.round_success_noti($.msg='Orden de Compra Modificada');
                this.getOrdenComprasData();
                this.getListacotizacionesData();
                this.getProductosUnidadMedida();
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
            }
        },
        async fillDetalleModificar(id, producto_um, cantidad, precio_compra){
            this.detalleOrdenCompra.id = id;
            this.detalleOrdenCompra.producto_um = [producto_um];
            this.detalleOrdenCompra.cantidad = cantidad;
            this.detalleOrdenCompra.precio_compra = precio_compra;
            await this.getProductosUnidadMedida();
        },
        async modificarDetalleOrdenCompra(){
            if (!this.detalleOrdenCompra.id || !this.detalleOrdenCompra.producto_um[0] || !this.detalleOrdenCompra.cantidad){
                notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
            }else{
                console.log("ESTAS UPDETEANDO");
                try{
                    const formData = new FormData();
                    formData.append("cantidad", this.detalleOrdenCompra.cantidad);
                    formData.append("producto_um", this.detalleOrdenCompra.producto_um[0]);
                    formData.append("orden_compra", this.ordenCompraModificar.id);
                    formData.append("precio_compra", this.productosUnidadMedidaData.find(x => x.id === this.detalleOrdenCompra.producto_um[0]).precio_compra);
                    await fetch(this.URL_TENANT+'/api/orden_compra_detalle/'+this.detalleOrdenCompra.id+'/',{
                    method: 'put',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: formData})
                    document.getElementById('modificar_detalle_close').click();
                notificaciones.round_success_noti($.msg='Producto Modificado Exitosamente');
                this.getProductosUnidadMedida();
                await this.getOrdenComprasData();
                this.fillModificarOrdenCompra([[this.ordenCompraModificar.id]], 2)
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
            }
        },
        //Creación:
        async convertirOrdenCompra(){
            try{
                const formDataCot = new FormData();
                console.log("VOY A CREAR ORDEN COMPRA");
                formDataCot.append("desdeCotizacion", 'desdeCotizacion');
                formDataCot.append("fecha", this.crearOrdenCompra.fecha);
                formDataCot.append("proveedor", this.crearOrdenCompra.proveedor);
                formDataCot.append("usuario", '1');
                formDataCot.append("cotizacion", this.crearOrdenCompra.id);
                await fetch(this.URL_TENANT+'/api/orden_compra/',{
                method: 'post',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formDataCot})
                notificaciones.round_success_noti($.msg='Se Creó la Orden de Compra a partir de la Cotización: '+this.crearOrdenCompra.referencia+'');
                document.getElementById('convertir_orden_close').click();
                this.getProductosUnidadMedida();
                await this.getOrdenComprasData();
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
        },
        async fillCrearOrdenCompra(referencia, id, fecha, proveedor){
            this.crearOrdenCompra.referencia = referencia;
            this.crearOrdenCompra.id = id;
            this.crearOrdenCompra.fecha = fecha;
            this.crearOrdenCompra.proveedor = proveedor;
        },
        async crearDetalleOrdenCompra(){
        console.log("crearDetalleOrdenCompra");
        console.log(this.detalleOrdenCompra.cantidad);
        console.log(this.detalleOrdenCompra.producto_um);

            if (!this.detalleOrdenCompra.cantidad || !this.detalleOrdenCompra.producto_um[0]){
                notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
            }else{
                try{
                    document.getElementById('agregar_detalle_close').click();
                    const formData = new FormData();
                    console.log("VOY A INGRESAR");
                    formData.append("orden_compra", this.ordenCompraModificar.id);
                    formData.append("cantidad", this.detalleOrdenCompra.cantidad);
                    formData.append("producto_um", this.detalleOrdenCompra.producto_um[0]);
                    formData.append("precio_compra", this.productosUnidadMedidaData.find(x => x.id === this.detalleOrdenCompra.producto_um[0]).precio_compra);
                    await fetch(this.URL_TENANT+'/api/orden_compra_detalle/',{
                    method: 'post',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: formData})
                notificaciones.round_success_noti($.msg='Producto Agregado a la Orden de Compra');
                this.getProductosUnidadMedida();
                await this.getOrdenComprasData();
                this.fillModificarOrdenCompra([[this.ordenCompraModificar.id]], 2);

                this.detalleOrdenCompra = {
                    id: null,
                    cantidad: null,
                    producto_um: [],
                }
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }

            }
        },
        async crearOrden(){
            if (this.orden_compra.cotizacion_compra[0]){
                this.cotizacion_compra_ing = this.orden_compra.cotizacion_compra[0];
            }else{
                this.cotizacion_compra_ing = '';

            }
            if (!this.orden_compra.fecha && !this.orden_compra.proveedor[0]){
                notificaciones.round_error_noti($.msg='Debe de llenar todos los campos');
            }else{
                try{
                    const formData = new FormData();
                    console.log("Ingresando Cotización Compra");
                    formData.append("fecha", this.orden_compra.fecha);
                    formData.append("proveedor", this.orden_compra.proveedor[0]);
                    formData.append("cotizacion_compra", this.cotizacion_compra_ing);

                    await fetch(this.URL_TENANT+'/api/orden_compra/',{
                    method: 'post',
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },body: formData})
                notificaciones.round_success_noti($.msg='Orden de Compra Creada');
                document.getElementById('agregar_close').click();
                this.getOrdenComprasData();
                this.getListacotizacionesData();
                this.getProductosUnidadMedida();
                this.limpiarCrearOrden();
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }

            }
        },
        // Eliminación:
        async fillEliminarDetalleOrdenCompra(id, unidad_medida_nombre, unidad_medida_nombre_unidad, cantidad){
            console.log("ELIMINAR FILL");
            this.producto_nombre = unidad_medida_nombre + unidad_medida_nombre_unidad;
            this.detalleOrdenCompraEliminar.id = id;
            this.detalleOrdenCompraEliminar.producto_nombre = this.producto_nombre;
            this.detalleOrdenCompraEliminar.cantidad = cantidad;
        },
        async eliminarDetalleOrdenCompra(idProductoEliminar){
            console.log("ID A ELIMINAR");
            console.log(idProductoEliminar);
            const requestOptions = {
                method: 'DELETE',
                headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
                }
            };
            await fetch(this.URL_TENANT+'/api/orden_compra_detalle/'+idProductoEliminar+'',  requestOptions);
            document.getElementById('eliminar_detalle_close').click();
            notificaciones.round_success_noti($.msg='Producto Eliminado de la Orden de Compra');
            this.getProductosUnidadMedida();
            await this.getOrdenComprasData();
            this.fillModificarOrdenCompra([[this.ordenCompraModificar.id]], 2);
        },
        async limpiarCrearOrden(){
            this.orden_compra= {
                fecha: '',
                proveedor: '',
                cotizacion_compra: '',
            }
        },
        async cambiarUrl(link, index){
            this.url = link;
            if (index === "atras" && link){
                this.indexPagination = this.indexPagination - 1;
            }
            else if (index === "adelante" && link != null){
                this.indexPagination = this.indexPagination + 1;
            }
            else if (link){
                this.indexPagination = index;
            }
        },
        // Llenado:
        async getEmpresaData() {
            const response = await fetch(this.URL_TENANT+'/api/empresa/');
            this.empresaData = await response.json();
        },
        async getProveedoresData() {
            const response = await fetch(this.URL_TENANT+'/api/proveedor/');
            this.proveedoresData = await response.json();
        },
        async getOrdenComprasData() {
            const response = await fetch(this.url);
            this.ordenComprasData = await response.json();
        },
        async getListacotizacionesData(){
            const response = await fetch(this.URL_TENANT+'/api/lista_cotizacion_compra/');
            this.cotizacionesData = await response.json();
        },
        async getProductosUnidadMedida() {
            const response = await fetch(this.URL_TENANT+'/api/producto_unidades_medida/');
            this.productosUnidadMedidaData = await response.json();
        },
        },
    mounted() {
            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', 'https://unpkg.com/vue-next-select/dist/vue-next-select.iife.prod.js')
            document.head.appendChild(recaptchaScript)
        },
    created() {
          // Fetch tasks on page load
        const result = window.location.origin;
        this.URL_TENANT = result;
        this.url = this.URL_TENANT + this.url
        this.getOrdenComprasData();
        this.getEmpresaData();
        this.getListacotizacionesData();
        this.getProveedoresData();
        this.getProductosUnidadMedida();
        },
    }
    </script>